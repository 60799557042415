<template>
  <modal name="modal-edit-phone-number" :width="600">
    <v-container grid-list-xl create-user>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Edit Phone Number' }}
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <p>
            Please enter your phone number below.
          </p>
          <v-form @submit.prevent="savePhoneNumber" ref="form" lazy-validation>
            <v-text-field
              label="Phone number"
              outline
              v-model="newPhoneNumber"
              type="tel"
              :rules="phoneRules"
            />
            <v-btn
              block
              type="submit"
              style="height: 45px;"
            >Save</v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>
<script>
import { mapState } from 'vuex'
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { isNaN } from 'lodash-es'

export default {
  components: {
    Modal,
    ButtonFull
  },
  data () {
    return {
      phoneRules: [
        (v) => {
          if (v.trim() === '' || v.length < 1) {
            return 'Phone Number is required'
          }
          return true
        },
        (v) => this.validatePhoneNumber(v) || 'Phone number must be a valid number in your country'
      ],
      locations: [],
      newPhoneNumber: ''
    }
  },
  computed: {
    ...mapState({
      companyDetails: state => state.userDetails.companyDetails,
      user: state => state.userDetails
    }),
    companyUsesSSO () {
      let companySettings = this.companyDetails && this.companyDetails.companySettings
      if (!(companySettings && companySettings.length > 0)) {
        return false
      }
      for (let setting of companySettings) {
        if (setting.code === 'uses_sso' && setting.value) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
    let userDetails = this.$store.state.userDetails
    let companyDetails = this.$store.state.userDetails.companyDetails
    this.roles = userDetails.companyRoles
    this.locations = (userDetails && companyDetails) ? companyDetails.locations : null
    this.user.companyId = this.$store.state.userDetails.companyDetails.id
    if (!this.user.phoneNumberChanged && (this.companyUsesSSO || this.companyDetails.punchoutActive)) {
      this.$bus.$emit('modal-show', 'modal-edit-phone-number')
    }
  },
  methods: {
    validatePhoneNumber (number) {
      number = number.trim()
      if (!isNaN(number)) {
        let userCountryCode = this.$store.state.userDetails.locationDetails.locationAddressId.country
        let phoneEntry = parsePhoneNumberFromString(number, userCountryCode)
        if (!phoneEntry) {
          return 'Entry must be a number'
        }
        let formattedPhoneNumber = phoneEntry.number
        let phoneIsValid = phoneEntry.isValid()
        let countryCode = phoneEntry.country
        if (phoneIsValid && countryCode === userCountryCode) {
          this.newPhoneNumber = formattedPhoneNumber
          return true
        }
      }
    },
    savePhoneNumber () {
      if (this.$refs.form.validate()) {
        let userId = this.user.userId
        let userData = {
          phoneNumber: this.newPhoneNumber,
          userId,
          phoneNumberChanged: true
        }
        this.$bus.$emit('notification-progress-start', 'Saving phone number ...')
        this.$store.dispatch('updateUser', userData)
          .then((result) => {
            if (result.data.code === 200) {
              this.$store.dispatch('fetchSingleUserDetails', { userId, updateState: true })
                .then(() => {
                  this.$bus.$emit('notification-progress-stop')
                  this.$bus.$emit('notification', {
                    type: 'success',
                    message: 'Phone number updated successfully',
                    action1: { label: 'OK', action: 'close' }
                  })
                  this.close()
                  this.$bus.$emit('user-updated')
                })
            } else {
              this.$bus.$emit('notification', {
                type: 'error',
                message: result.data.result.message,
                action1: { label: 'OK', action: 'close' }
              })
            }
          }).catch(() => {
            this.$bus.$emit('notification-progress-stop')
          })
      }
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-edit-phone-number')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);
$color-white: color(white);

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

input::placeholder {
  color: rgba(130,130,130,0.25);
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.create-user {
  margin: 70px auto !important;
  padding: 0 0 30px !important;
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
