export default {
  title: 'Gloopro',
  titleTemplate: '%s',
  htmlAttrs: {
    lang: 'en'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Gloopro simplifies purchasing for large enterprises with eProcurement and commerce solutions.' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '../assets/favicon.ico', sizes: '16x16' },
    // { rel: 'icon', type: 'image/png', href: '../assets/brandmark.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '../assets/brandmark.png' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '../assets/brandmark.png', sizes: '640x1136' },
    { rel: 'manifest', href: '../assets/manifest.json' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' }
  ]
}
