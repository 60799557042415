<template>
  <button-outline color="light" :disabled="canBeAdded(product)" data-testid="addToCart">
    <span>Add to Cart</span>
  </button-outline>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'
// import addToCart from 'core/components/AddToCart'
import { formatProductMessages } from 'core/filters/product-messages'

export default {
  name: 'AddToCart',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  // mixins: [addToCart],
  directives: { focusClean },
  components: { ButtonOutline },
  methods: {
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    canBeAdded (product) {
      return formatProductMessages(product.errors) !== ''
    }
  },
  created () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>

<style lang="scss" scoped>
  button {
    padding: 20px 10px !important;
    max-height: 3em !important;
    padding-bottom: 40px !important;
    border-radius: 4px;
  }
  .light {
    text-transform: none;
    background-color: #fff;
  }
  .button-outline {
    width: 100%;
  }
</style>
