<template>
  <!-- Add To Cart Widget -->
  <div class="row col-xs-12 widget">
    <button-outline color="light" class="col-xs-4 decrement" @click.native="decrement()">
      <i class="material-icons h4 p5">remove</i>
    </button-outline>
    <div class="col-xs-4 widget-input cl-gloo-main">
      <input type="number" class="h4" v-model="qty" @blur="onBlur">
    </div>
    <button-outline color="light" class="col-xs-4 increment" @click.native="increment()">
      <i class="material-icons h4">add</i>
    </button-outline>
  </div>
</template>
<script>
import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'

export default {
  name: 'AddToCartWidget',
  components: {
    ButtonOutline
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  watch: {
    qty: function (val) {
      if (val === '') {
        return
      }
      this.qty = parseInt(val)
      if (this.qty < 0) {
        this.qty = 0
      }
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: parseInt(this.qty) })
    }
  },
  data () {
    return {
      qty: 0
    }
  },
  methods: {
    increment () {
      this.qty++
    },
    decrement () {
      this.qty--
    },
    onBlur () {
      if (this.qty === '') {
        this.qty = 0
      }
    }
  },
  created () {
    let cartItems = this.$store.state.cart ? this.$store.state.cart.cartItems : []
    let prod = cartItems.find(item => {
      return item.sku === this.product.sku
    })
    if (!prod) {
      this.qty = 0
    } else {
      this.qty = prod.qty
    }
    this.$bus.$on('cart-after-update', ({ product }) => {
      if (product.sku === this.product.sku) {
        this.qty = parseInt(product.qty)
        if (this.qty === 0) {
          this.$store.dispatch('cart/removeItem', product)
        }
      }
    })
    this.$bus.$on('cart-after-itemchanged', (event) => {
      if (event.item.sku === this.product.sku) {
        this.qty = event.item.qty
      }
    })
  }
}
</script>
<style scoped lang="scss">
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$gloo-main: color(gloo-main);

  .widget {
    padding: 0 !important;
    margin: 0 !important;
    border: 1px solid #3d4094;
    text-align: center;
  }
  .widget-input {
    width: 100%;
    height: 90%;
    border: none;
  }
  .widget {
    padding: 0 !important;
    margin: 0 !important;
  }
  .widget {
    border: 2px solid $gloo-main;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 40px;
  }
  .widget-input {
    width: 100%;
  }
  .widget-input input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 31px;
    padding: 0;
  }
  .widget-input input:focus {
    outline: none;
  }
  i {
    font-size: 20px;
    line-height: 31px;
    font-weight: 300;
  }
  button {
    padding: 0 !important;
    height: 40px;
  }
  .widget button {
      height: 100%;
      border: none;
      min-width: unset;
  }
  button.decrement {
    border-right: 2px solid;
    border-radius: unset;
  }
  button.increment {
    border-left: 2px solid;
    border-radius: unset;
  }
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .widget button{
    padding: 0 !important
  }
  .light {
    background-color: #fff;
  }
</style>
