<template>
  <div>
    <v-progress-linear :indeterminate="true" v-if="!order" color="success" />
    <v-layout align-center justify-center row wrap v-else>
      <v-flex xs12 row class="requester-details">
        <div class="myHeading">
          <div class="row">
            <div id="order-identity" class="col-sm-12 col-md-12 col-lg-6">
              <h3 id="order-number">ID: {{ order ? order.id : '' }}</h3>
              <p id="order-date">{{ formatDate(order.created_at) }}</p>
              <p id="order-chip1" class="order-status-chip m0" :class="[orderStatus[order.status]]">{{ order ? order.status : '' }}</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 order-actions" v-if="hasPermission('approve', 'order') && order && order.status === 'pending'">
              <v-btn large color="success" class="bg-cl-mine-shaft :bg-cl-th-secondary accept" @click="approveOrder">Approve Order</v-btn>
              <p id="decline-order" class="order-action-link" @click="showDisapproveModal">Decline Order</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 order-actions" v-if="order.status === 'disapproved'">
              <v-btn large color="success" @click="edit(order)" v-if="hasPermission('create', 'order')">Edit Order</v-btn>
            </div>
          </div>
        </div>
        <v-card class="myHeading order-info">
          <i class="material-icons icon order-status-icon" :class="[orderStatus[order.status]]">check_circle_outline</i>
          <v-card-title class="card-title order-info-caption">Order Information</v-card-title>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Submitted By:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p>{{ order ? order.requesterId.firstName : '' }} {{ order ? order.requesterId.lastName : '' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Delivery Location:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p>{{ deliveryAddress }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Order Status:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p class="order-status-chip" :class="[orderStatus[order.status]]">{{ order ? order.status : '' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Shipping Method:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p>{{ order ? order.addressInformation.deliveryMethod.name : 'N/A' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Payment Method:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p>{{ order ? order.addressInformation.paymentMethod.name : 'N/A' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row v-if="order && order.status === 'disapproved'">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3>Reason for Rejection:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p>{{ order ? (order.comment || order.linkedOrderId.comment) : '' }}</p>
            </div>
          </v-flex>
        </v-card>
      </v-flex>
      <v-flex xs12 class="order-table">
        <v-card id="title-and-search">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
              <v-card-title class="section-title">Items in Order</v-card-title>
            </div>
            <div class="search col-lg-4 col-md-4 col-sm-6 col-xs-6">
              <div class="external-search--box">
                <input type="text" placeholder="Search product name" class="search-box" v-model="search">
                <i class="material-icons" aria-hidden="true">search</i>
              </div>
            </div>
          </div>
        </v-card>
        <v-card id="order-dataTable-wrapper">
          <v-data-table
            v-if="order ? order.linkedOrderId : ''"
            :headers="headers"
            :items="order ? orderDifferential.productsArray : []"
            :loading="orderDifferential.productsArray.length < 1"
            :search="search"
            hide-actions
            :rows-per-page-items="[25, 50]"
          >
            <v-progress-linear slot="progress" color="success" indeterminate/>
            <template slot="items" slot-scope="props">
              <tr
                class="removed-product"
                style="text-decoration: line-through;"
                v-if="props.item.currentQty === 0 && props.item.previousQty > 0"
              >
                <td class="text-xs-left">{{ props.item.product }}</td>
                <td class="text-xs-right">{{ props.item.sku }}</td>
                <td class="text-xs-right">{{ props.item.price | currency }}</td>
                <td class="text-xs-right">{{ props.item.currentQty }}</td>
                <td class="text-xs-right">{{ props.item.currentCost | currency }}</td>
              </tr>
              <tr
                class="newly-added-product"
                v-else-if="props.item.previousQty === 0 && props.item.currentQty > 0"
              >
                <td class="text-xs-left">{{ props.item.product }}</td>
                <td class="text-xs-right">{{ props.item.sku }}</td>
                <td class="text-xs-right">{{ props.item.price | currency }}</td>
                <td class="text-xs-right">{{ props.item.currentQty }}</td>
                <td class="text-xs-right">{{ props.item.currentCost | currency }}</td>
              </tr>
              <tr
                class="quantity-changed"
                v-else-if="props.item.currentQty !== props.item.previousQty"
              >
                <td class="text-xs-left">{{ props.item.product }}</td>
                <td class="text-xs-right">{{ props.item.sku }}</td>
                <td class="text-xs-right">{{ props.item.price | currency }}</td>
                <td class="text-xs-right"><span>{{ props.item.currentQty }}</span> <span style="text-decoration: line-through">{{ props.item.previousQty }}</span></td>
                <td class="text-xs-right">{{ props.item.currentCost | currency }}</td>
              </tr>
              <tr
                class="retained-product"
                v-else
              >
                <td class="text-xs-left">{{ props.item.product }}</td>
                <td class="text-xs-right">{{ props.item.sku }}</td>
                <td class="text-xs-right">{{ props.item.price | currency }}</td>
                <td class="text-xs-right">{{ props.item.currentQty }}</td>
                <td class="text-xs-right">{{ props.item.currentCost | currency }}</td>
              </tr>
            </template>
            <template slot="footer">
              <!-- creates space for table row lines to correspond to each available td -->
              <tr>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
              </tr>
              <tr class="table-total-cost">
                <td/>
                <td/>
                <td/>
                <td class="text-xs-left cost-caption">Total Cost</td>
                <td class="text-xs-right cost-value">{{ totalCost | currency }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            :headers="headers"
            :items="order ? order.products : []"
            :search="search"
            :loading="order.products.length < 1"
            hide-actions
          >
            <v-progress-linear slot="progress" color="success" indeterminate/>
            <template slot="items" slot-scope="props">
              <tr>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-right">{{ props.item.sku }}</td>
                <td class="text-xs-right">{{ props.item.price | currency }}</td>
                <td class="text-xs-right">{{ props.item.qty }}</td>
                <td class="text-xs-right">{{ props.item.price * props.item.qty | currency }}</td>
              </tr>
            </template>
            <template slot="footer">
              <!-- creates space for table row lines to correspond to each available td -->
              <tr>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
              </tr>
              <tr class="table-total-cost">
                <td/>
                <td/>
                <td/>
                <td class="text-xs-left cost-caption">Total Cost</td>
                <td class="text-xs-right cost-value">{{ totalCost | currency }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-card>
      </v-flex>
    </v-layout>

    <edit-order-modal :order="order || {}" />

    <div id="disapprove-modal">
      <modal name="order-decline-confirm">
        <header class="modal-header py25 px65 h3 weight-700">
          <i
            slot="close"
            class="modal-close material-icons p15 cl-bg-black"
            @click="closeModal"
          >
            close
          </i>
          {{ 'Decline Order' }}
        </header>
        <div class="disapprove-modal-content">
          <v-card class="disapprove-modal-inner">
            <v-card-title class="confirmation-text--wrapper">
              <p>Are you sure you want to decline this order?</p>
            </v-card-title>
            <v-layout wrap>
              <v-textarea v-model="declineReason" label="State your reason(s)" required/>
            </v-layout>
            <v-card-actions>
              <v-btn large color="error" class="rejection" @click="disapproveOrder()">Decline Order</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Modal from 'theme/components/core/Modal.vue'
import _ from 'lodash'
import EditOrderModal from './EditOrderModal'

export default {
  name: 'SingleOrder',
  data () {
    return {
      order: null,
      declineReason: '',
      search: '',
      orderStatus: {
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order',
        'approved': 'approved-order',
        'resubmitted': 'resubmitted-order',
        'disapproved': 'disapproved-order'
      },
      headers: [
        {
          text: 'Product name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        { text: 'SKU', sortable: true, value: 'sku', align: 'right' },
        { text: 'Unit Price', sortable: true, value: 'price', align: 'right' },
        { text: 'Quantity', sortable: false, value: 'quantity', align: 'right' },
        { text: 'Line Total', sortable: false, value: 'cost', align: 'right' }
      ]
    }
  },
  components: {
    Modal,
    EditOrderModal
  },
  computed: {
    deliveryAddress () {
      if (!this.order) {
        return ''
      }
      const address = this.order.addressInformation.deliveryLocation.locationAddressId
      return `${address.streetAddress} ${address.addressLine2}, ${address.city}, ${address.state}`
    },
    totalCost () {
      let totalCost = 0
      for (let i = 0; i < this.order.products.length; i++) {
        totalCost += this.order.products[i].price * this.order.products[i].qty
      }
      return totalCost
    },
    orderDifferential () {
      if (!this.order) {
        return ''
      }
      let productsSKUSet = new Set()
      let currentOrder = this.order
      let previousOrder = this.order.linkedOrderId
      if (!currentOrder || !previousOrder) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Previous order and current order required to generate differential',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      let currentTotalCost = 0
      let keyedCurrentOrder = _.keyBy(currentOrder.products, (product) => {
        productsSKUSet.add(product.sku)
        currentTotalCost += product.qty * product.price
        return product.sku
      })
      let previousTotalCost = 0
      let keyedPreviousOrder = _.keyBy(previousOrder.products, (product) => {
        productsSKUSet.add(product.sku)
        previousTotalCost += product.qty * product.price
        return product.sku
      })
      let diffArray = []
      _.each(Array.from(productsSKUSet), sku => {
        let price = keyedCurrentOrder[sku] ? keyedCurrentOrder[sku].price : keyedPreviousOrder[sku].price
        let product = keyedCurrentOrder[sku] ? keyedCurrentOrder[sku].name : keyedPreviousOrder[sku].name
        let currentQty = keyedCurrentOrder[sku] ? keyedCurrentOrder[sku].qty : 0
        let previousQty = keyedPreviousOrder[sku] ? keyedPreviousOrder[sku].qty : 0
        let entry = {
          product,
          price,
          currentQty,
          currentCost: currentQty * price,
          previousQty,
          previousCost: previousQty * price,
          sku
        }
        diffArray.push(entry)
      })
      return {
        previousTotalCost,
        currentTotalCost,
        productsArray: diffArray
      }
    },
    productsInCart () {
      return this.$store.state.cart.cartItems
    }
  },
  created () {
    let orderId = this.$route.params.orderId
    if (!orderId) {
      this.$router.push('page-not-found')
    }
    let allOrdersFromState = this.$store.state.order.allCompanyOrders
    if (Object.keys(allOrdersFromState).length === 0) {
      this.$store.dispatch('order/fetchAllCompanyOrders')
        .then(() => {
          allOrdersFromState = this.$store.state.order.allCompanyOrders
          this.order = allOrdersFromState[orderId]
        })
    } else {
      this.order = allOrdersFromState[orderId]
      if (!this.order) {
        this.$store.dispatch('order/fetchAllCompanyOrders')
          .then(() => {
            allOrdersFromState = this.$store.state.order.allCompanyOrders
            this.order = allOrdersFromState[orderId]
          })
      }
    }
  },
  methods: {
    mouseOver () {
      console.log(this.$refs)
    },
    mouseLeave () {
      console.log(this.$refs)
    },
    showDisapproveModal () {
      this.$bus.$emit('modal-show', 'order-decline-confirm')
    },
    closeModal () {
      this.$bus.$emit('modal-hide', 'order-decline-confirm')
    },
    approveOrder () {
      const data = {
        status: 'approved',
        approvalDate: moment().format()
      }
      this.updateOrder(this.order.id, data)
    },
    disapproveOrder () {
      const data = {
        status: 'disapproved',
        comment: this.declineReason,
        approvalDate: moment().format()
      }
      this.updateOrder(this.order.id, data)
    },
    updateOrder (orderId, inputData) {
      this.$bus.$emit('notification-progress-start', 'Updating order status')
      this.$store.dispatch('order/approveOrder', { orderId, data: inputData })
        .then(({ data }) => {
          this.$bus.$emit('notification-progress-stop')
          if (data.code !== 200) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while updating order status',
              action1: { label: 'OK', action: 'close' }
            })
            return
          }
          let message = inputData.status === 'approved'
            ? 'Order has been approved'
            : 'Order has been disapproved'
          this.$bus.$emit('notification', {
            type: 'success',
            message: message,
            action1: { label: 'OK', action: 'close' }
          })
          this.$router.push(this.localizedRoute('/dashboard'))
        })
        .catch(err => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = err.message
          if (err.response && err.response.data.result) {
            errorMessage = err.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY') + ' at ' + moment(dateString).format('LT')
    },
    edit (order) {
      if (this.productsInCart && this.productsInCart.length > 0) {
        this.$bus.$emit('modal-show', 'modal-edit-order-confirmation')
      } else {
        this.editOrder()
      }
    },
    editOrder () {
      this.$store.dispatch('order/saveLinkedOrder', this.order.id)
      const items = this.order.products
      items.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-white-smoke: color(white-smoke);
$pending-background: color(gloo-pend);
$pending-text: color(gloo-pending);
$approved-background: color(gloo-completed);
$resubmitted-text: color(gloo-);
$color-success: color(gloo-success);
$color-pending: color(gloo-pending);
$color-error: color(gloo-danger);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-pend: color(gloo-pend);
$color-gloo-gray: color(gloo-gray);
$color-gloo-text: color(gloo-text);
$color-footer-text: color(gloo-footer-text);

.order-status-chip {
  text-transform: uppercase;
  font-size: 10px !important;
  height: unset !important;
  line-height: 20px !important;
  width: auto !important;
  padding: 0 15px !important;
}

//Order status classes and styles
.pending-order {
  background-color: $color-pend;
  color: $color-pending !important;
}
.approved-order {
  background-color: #008000;
  color: $color-white !important;
}
  // Ensures that order-status icon for approved orders is green and visible as against the inital white value above
  .order-status-icon.approved-order {
    color: #008000 !important;
  }
.resubmitted-order {
  background-color: $color-completed;
  color: $color-success !important;
}

.disapproved-order {
  background-color: $color-failed;
  color: $color-error !important;
}

.requester-details {
  text-align: left;
  h3, p {
    color: #828282;
  }

  h3 {
    font-weight: normal;
    padding: 5px 0;
    font-size: 20px;
  }

  .order-info {
    .card-title {
      padding-left: 5px !important;
      color: #212b36;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      margin-bottom: 20px;
    }
    .order-status-chip {
      margin-top: 5px;
      margin-bottom: 16px;
    }
    h3 {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }

    p {
      color: $color-gloo-text;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  h3#order-number {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    color: $color-gloo-gray;
    margin-bottom: 10px;
    @media (max-width: 460px) {
      font-size: 20px;
    }
    @media (max-width: 401px) {
      font-size: 18px;
    }
  }

  .order-actions {
    margin: auto;
    display: flex;
    justify-content: center;
    @media (max-width: 1499px) {
      text-align: center;
    }
    @media (min-width: 1200px) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .v-btn {
    font-weight: 600;
    margin: 10px;
  }

  p.order-action-link {
    margin: auto 15px;
    font-size: 15px;
    font-weight: 550;
    line-height: 17px;
    cursor: pointer;
  }

  p#decline-order {
    color: $color-error;
  }

  p {
    font-size: 16px;
    font-weight: normal;
  }

  i {
    font-weight: normal;
    vertical-align: text-bottom;
  }

  .order-status-icon {
    border-radius: 50%;
    padding: 0 !important;
    background-color: transparent !important;
  }

  p#order-date {
    padding: 0;
    display: inline-block;
    margin-right: 15px;
    @media (max-width: 340px) {
      margin-right: 0;
    }
  }

  #order-date {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .myHeading {
    padding: 20px;
  }

  .myHeading:first-child {
    padding: 0 0 20px;
  }

  .myHeading.order-info {
    @media (max-width: 959px) {
      h3 {
        padding-bottom: 0;
      }
    }
    .flex.xs12.row {
      padding: 0 !important;
    }
  }

}

.v-card {
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #d7d7e0 !important;
}

.v-card, div.myHeading {
  width: 100%;
}

.order-table {
  display: block;
  margin-top: 20px;
  margin-bottom: 40px;
}

#title-and-search {
  margin: 10px 0 1px;
}

// Style for to properly align search icon in Search bar on Data-table
.search {
  margin: auto;
}
.external-search--box {
  text-align: left;
}
.search-box {
  width: 100%;
}

.card-title {
  font-size: 24px;
  line-height: 2em;
  display: inline-block;
}

.order-table .section-title {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  padding: 20px 10px !important;
}

// Modal Design
header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}
.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}
.modal-container {
  width: 30% !important;
}
.disapprove-modal-content {
  padding: 20px;
  overflow-y: auto;
  .v-card {
    box-shadow: none !important;
  }
  .rejection {
    margin: 10px auto;
  }
  .confirmation-text--wrapper {
    padding: 0 10px !important;
  }
}

// Data Table and Footer Styles
.removed-product td {
  color: red !important;
}
.newly-added-product td {
  color: #188a18 !important;
}
.quantity-changed td {
  color: blue !important;
}
tfoot {
  tr {
    height: 70px;
  }
  td.cost-caption {
    font-size: 16px;
    line-height: 24px;
  }
  td.cost-caption {
    color: $color-footer-text;
    font-weight: 400;
    text-align: right !important;
  }
  td.cost-value {
    color: $color-gloo-text;
    font-weight: 600;
  }
}

@media (max-width: 1199px) {
  #order-identity {
    text-align: center;

    #order-number {
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  #order-actions {
    margin-top: 20px;
    justify-content: center !important;
  }
}
</style>
