<template>
  <div>
    <div id="location-reports">
      <div v-if="parent === 'single page'" class="page-title">
        <v-card-title>
          <span class="section-title">Reports</span>
        </v-card-title>
      </div>
      <div id="sales-reports">
        <!-- Date range selector and View by date/day-of-the-week/month/year -->
        <div id="controls" class="row panel panel-default pad-panel">
          <div class="col-lg-6 col-md-12 col-sm-12 controls-wrapper">
            <div class="form-group">
              <span class="section-title">Orders Location for</span>
              <div class="daterange-box">
                <date-range-picker
                  :locale-data="locale"
                  :opens="opens"
                  @update="update"
                  :auto-apply="true"
                  :ranges="ranges"
                >
                  <!--Optional scope for the input displaying the dates -->
                  <div slot="input" slot-scope="picker">
                    <img src="/assets/calendar.svg" class="img-responsive datepickericon">
                    <input class="daterange" type="text" v-model="dateRange">
                  </div>
                </date-range-picker>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 period-filter">
            <div class="btn-group">
              <button type="button" class="btn" :class="{ active: filter === 'day' }" @click="filter = 'day'">Day</button>
              <button type="button" class="btn" :class="{ active: filter === 'dayOfWeek' }" @click="filter = 'dayOfWeek'">Day of the Week</button>
              <button type="button" class="btn" :class="{ active: filter === 'month' }" @click="filter='month'">Month</button>
              <button type="button" class="btn" :class="{ active: filter === 'quarter' }" @click="filter = 'quarter'">Quarter</button>
              <button type="button" class="btn" :class="{ active: filter === 'year' }" @click="filter = 'year'">Year</button>
            </div>
          </div>
        </div>

        <div class="row panel panel-default pad-panel mb-5">
          <div class="col-md-12 pxy-5">
            <div class="row">
              <v-autocomplete
                v-model="selectedLocations"
                :items="locations"
                attach
                chips
                label="Locations"
                item-text="name"
                item-value="id"
                hide-details
                @click:clear="clearSelectedLocations"
                class="col-lg-6 col-md-12 col-sm-12 d-flex align-center"
                return-object
                multiple
                solo
                clearable
              >
                <template v-slot:prepend-item>
                  <v-checkbox
                    v-model="isAllLocationsSelected"
                    class="px-3 text-primary"
                    label="Select all Locations"
                  />
                </template>
              </v-autocomplete>

              <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-center export-btn-container">
                <v-select
                  v-if="selectedLocations.length > 0"
                  :items="exportOptions"
                  v-model="exportFilter"
                  outline
                  hide-details
                  class="d-flex align-center col-lg-5  col-md-4 col-sm-4 px-3 export-option"
                  label="Select an export option"
                  item-text="display"
                  item-value="id"
                />

                <button @click="exportAsCSV"
                        :disabled="selectedLocations.length === 0"
                        class="export-btn col-lg-4 col-sm-4  col-md-4 mr-3 px-3 ">Export as CSV</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Reports Chart -->
        <div id="chart" class="row panel panel-default pad-panel">
          <show-loader v-if="loading" />
          <div v-else class="col-md-12">
            <revenue-chart
              :chart-data="chartData"
              :height="150"
            />
          </div>
        </div>

        <!-- Data Table showing Reports -->
        <div id="tables" class="row panel panel-default pad-panel">
          <div class="col-md-12">
            <!-- Search for finding reports on the Data Table -->
            <v-card id="title-and-search" class="table-title">
              <div class="row">
                <div id="dateRange-display" class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <div class="section-title">{{ dateRange }}</div>
                </div>
                <div class="search col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div class="external-search--box">
                    <input type="text" placeholder="Search" class="search-box" v-model="search">
                    <i class="material-icons" aria-hidden="true">search</i>
                  </div>
                </div>
              </div>
            </v-card>

            <v-card>
              <v-data-table
                :headers="periodHeaders"
                :items="tableData"
                :loading="loading"
                :search="search"
                :rows-per-page-items="[50,100]"
                :custom-filter="customFilter"
                :expand="expand"
                item-key="toString"
              >
                <v-progress-linear slot="progress" color="success"/>
                <!-- <span class="section-title col-lg-6 col-md-6 col-sm-6">Orders Orverview for</span> -->
                <template v-slot:items="props">
                  <tr @click="props.expanded = !props.expanded && props.item.noOfOrders > 0">
                    <td class="text-xs-left col-md-5">{{ props.item.toString }}</td>
                    <td class="text-xs-left col-md-2">{{ props.item.noOfOrders }}</td>
                    <td class="text-xs-left col-md-4 embolden">{{ props.item.grandTotal | currency }}</td>
                    <td v-if="!props.expanded" class="table-cta" :class="props.item.noOfOrders < 1 ? 'disabled-toggle': ''">
                      Expand all
                      <i class="material-icons table-icon">arrow_drop_down</i>
                    </td>
                    <td v-else class="table-cta">
                      Collapse
                      <i class="material-icons table-icon">arrow_drop_up</i>
                    </td>
                  </tr>
                </template>
                <template v-slot:expand="props">
                  <v-card flat>
                    <v-data-table
                      :headers="rowHeaders"
                      :items="getRowData(props.item.toString)"
                      :rows-per-page-items="[50,100]"
                    >
                      <template slot="items" slot-scope="props">
                        <td class="text-xs-left col-md-3">{{ props.item.date }}</td>
                        <td class="text-xs-left col-md-3">{{ props.item.id }}</td>
                        <td class="text-xs-left col-md-3">{{ props.item.location }}</td>
                        <td class="text-xs-left col-md-3 embolden">{{ props.item.total | currency }}</td>
                        <td class="text-xs-left col-md-3 "><router-link :to="localizedRoute(`/orders/${props.item.id}`)" target="_blank" class="table-cta">View Order</router-link></td>
                      </template>
                    </v-data-table>
                  </v-card>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css'
import moment from 'moment'
import { mapGetters } from 'vuex'
import RevenueChart from 'core/components/RevenueChart'
import ShowLoader from 'theme/components/core/blocks/CompanySettings/ShowLoader'
import exportCSVFile from 'theme/helpers/objectToCsv.js'
import i18n from 'core/lib/i18n'

export default {
  name: 'SalesReportsByLocation',
  props: {
    parent: {
      type: String,
      default: ''
    }
  },
  components: {
    DateRangePicker,
    RevenueChart,
    ShowLoader
  },
  data () {
    return {
      expand: true,
      search: '',
      loading: true,
      startDate: '2019-01-01',
      endDate: moment().format('YYYY-MM-DD'),
      opens: 'right', // which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // format of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
        firstDay: 1, // ISO first day of week - see moment documenations for details
        showWeekNumbers: true // show week numbers on each row of the calendar,

      },
      ranges: { // default value for ranges object (if you set this to false ranges will no be rendered)
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        'Last 2 Years': [moment().subtract(2, 'years').startOf('year'), moment().subtract(2, 'years').endOf('year')]
      },
      dateRange: `${moment('2019-01-01').format('MMM D, YYYY')} - ${moment().format('MMM D, YYYY')}`,
      periodHeaders: [
        { text: 'Period', sortable: true, value: 'timestamp' },
        { text: 'No. of orders', sortable: true, value: 'noOfOrders' },
        { text: 'Grand total', sortable: true, value: 'grandTotal' },
        { text: 'Action', sortable: false, value: '' }
      ],
      tableData: [],
      chartData: {
        labels: [],
        datasets: []
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      filter: '',
      rowHeaders: [
        { text: 'Date', value: 'timestamp' },
        { text: 'Order #', value: 'id' },
        { text: 'Location', value: 'location' },
        { text: 'Order total', value: 'total' },
        { text: 'Action', value: '' }
      ],
      selectedLocations: [],
      ordersForLocation: [],
      exportFilter: 'overview',
      exportDetails: [],
      exportOptions: [{ id: 'overview', display: 'Overview' }, {
        id: 'details', display: 'Monthly Breakdown'
      } ],
      locations: [],
      isAllLocationsSelected: false
    }
  },
  created () {
    if (!this.hasPermission('view', 'spend analysis')) {
      this.$bus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push(this.localizedRoute('/'))
    }
  },
  mounted () {
    this.$store.dispatch('order/fetchCompletedOrders')
      .then(() => { this.loading = false })
      .catch(() => { this.loading = false })
    const locations = this.$store.state.userDetails.companyDetails.locations
    locations.sort((location1, location2) => {
      return location1.name < location2.name ? -1 : 1
    })
    this.locations = locations
  },
  computed: {
    ...mapGetters({
      allOrders: 'order/getCompletedOrders'
    })
  },
  watch: {
    allOrders: function (val) {
      this.applyFilter()
    },
    selectedLocations: 'applyFilter',
    startDate: 'applyFilter',
    endDate: 'applyFilter',
    filter: 'applyFilter',
    isAllLocationsSelected: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        if (newValue) {
          this.selectedLocations = [...this.locations]
        } else {
          this.clearSelectedLocations()
        }
      }
    }
  },
  methods: {
    clearSelectedLocations () {
      this.selectedLocations = []
      this.isAllLocationsSelected = false
    },
    exportAsCSV () {
      let data = []
      if (this.selectedLocations.length > 0) {
        let headers
        if (this.exportFilter === 'overview') {
          headers = {
            period: 'Period',
            orders: 'No of orders',
            total: 'Grand Total'
          }

          this.tableData.forEach(entry => {
            data.push({
              period: entry.toString.replace(',', ''),
              orders: entry.noOfOrders,
              total: entry.grandTotal
            })
          })
        } else if (this.exportFilter === 'details') {
          headers = {
            date: 'Date',
            total: 'Order Total',
            location: 'Location'
          }

          this.tableData.forEach(entry => {
            const tableData = this.getRowData(entry.toString.replace(',', ''))
            const sortedTableData = this.sortDataByTimeStamp(tableData)
            sortedTableData.forEach(row => {
              data.push({
                date: row.date.toString().replace(/(,)+/g, ''),
                total: row.total,
                location: row.location.toString().replace(/(,)+/g, ' ')
              })
            })
          })
        }

        const fileTitle = `Location_Reports_${this.exportFilter}${moment().format('X')}`
        exportCSVFile(headers, data, fileTitle)
      } else {
        console.log('No Report to print. Select a location to export report csv')
        this.$bus.$emit('notification', {
          type: 'warning',
          message: i18n.t('No Report to print. Select a location to export the products report by location csv'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
      }
    },
    sortDataByTimeStamp (tableData) {
      return tableData.sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return 0
        }
        return (b.timestamp < a.timestamp) ? 1 : -1
      })
    },
    customFilter (items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(i => (
        Object.keys(i).some(j => {
          return filter(i[j], search)
        })
      ))
    },
    getRowData (period) {
      let tableData = []
      let filterFormats = {
        day: 'DD MMMM, YYYY',
        dayOfWeek: 'dddd',
        month: 'MMMM YYYY',
        quarter: 'Q YYYY',
        year: 'YYYY',
        default: 'MMMM YYYY'
      }
      let appliedFormat = filterFormats[this.filter] || filterFormats['default']
      this.allOrders.forEach(order => {
        if (!(moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days')))) {
          return
        }
        const orderLocation = this.locations.find(location => location.id === order.addressInformation.deliveryLocation.id)
        if (this.filter === 'quarter') {
          period = period.replace('Q', '')
        }
        if (moment(order.createdAt).format(appliedFormat) === period) {
          const locationRowData = {
            date: moment(order.createdAt).format('DD MMMM, YYYY'),
            id: order.id,
            total: this.calculateOrderTotal(order),
            location: orderLocation.name,
            timestamp: moment(order.createdAt).format('X')
          }
          tableData.push(locationRowData)
        }
      })
      return tableData
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    calculateOrderTotal (order) {
      return order.products.reduce((total, product) => {
        return (product.price * product.qty) + total
      }, 0)
    },
    update (val) {
      this.startDate = moment(val.startDate).format('MMM D, YYYY')
      this.endDate = moment(val.endDate).format('MMM D, YYYY')
      this.dateRange = `${this.startDate} - ${this.endDate}`
    },
    applyFilter () {
      // this.selectedLocations = this.getHashMapOfSelectedLocations();
      const locationIds = this.selectedLocations.map(location => location.id)
      this.ordersForLocation = this.allOrders.filter(currentOrder => {
        if (currentOrder && currentOrder.addressInformation.deliveryLocation.id) {
          const orderLocationId = currentOrder.addressInformation.deliveryLocation.id
          return locationIds.includes(orderLocationId)
        }
      })

      let filterMethods = {
        'day': this.filterByDay,
        'dayOfWeek': this.filterByDayOfWeek,
        'month': this.filterByDateRange,
        'quarter': this.filterByQuarter,
        'year': this.filterByYear
      }
      if (this.filter === '') {
        this.filterByDateRange()
      } else {
        filterMethods[this.filter]()
      }
    },
    filterByDay () {
      let periods = []
      this.ordersForLocation.forEach(order => {
        if (!(moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days')))) {
          return
        }
        let existingPeriod = periods.find(entry => {
          return entry.toString === moment(order.createdAt).format('DD MMMM, YYYY')
        })
        if (existingPeriod) {
          existingPeriod.noOfOrders += 1
          existingPeriod.grandTotal += this.calculateOrderTotal(order)
        } else {
          periods.push({
            month: moment(order.createdAt).format('MMMM'),
            year: moment(order.createdAt).format('YYYY'),
            toString: `${moment(order.createdAt).format('DD')} ${moment(order.createdAt).format('MMMM')}, ${moment(order.createdAt).format('YYYY')}`,
            timestamp: moment(order.createdAt).format('X'),
            noOfOrders: 1,
            grandTotal: this.calculateOrderTotal(order)
          })
        }
      })

      this.generateChartData(periods)
    },
    filterByDayOfWeek () {
      let daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      let periods = []
      let ordersInRange = this.ordersForLocation.filter(order => {
        if (moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days'))) {
          return true
        }
      })
      daysOfWeek.forEach(day => {
        let dayOrders = ordersInRange.filter(order => {
          return day === moment(order.createdAt).format('dddd')
        })
        let noOfOrders = 0
        let grandTotal = 0
        dayOrders.forEach(order => {
          noOfOrders++
          grandTotal += this.calculateOrderTotal(order)
        })
        periods.push({
          toString: day,
          noOfOrders,
          grandTotal
        })
      })
      this.generateChartData(periods)
    },
    filterByMonth () {
      let periods = []
      this.ordersForLocation.forEach(order => {
        if (!(moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days')))) {
          return
        }
        let existingPeriod = periods.find(entry => {
          return entry.toString === moment(order.createdAt).format('MMMM YYYY')
        })
        if (existingPeriod) {
          existingPeriod.noOfOrders += 1
          existingPeriod.grandTotal += this.calculateOrderTotal(order)
        } else {
          periods.push({
            month: moment(order.createdAt).format('MMMM'),
            year: moment(order.createdAt).format('YYYY'),
            toString: `${moment(order.createdAt).format('MMMM YYYY')}`,
            timestamp: moment(order.createdAt).format('X'),
            noOfOrders: 1,
            grandTotal: this.calculateOrderTotal(order)
          })
        }
      })

      this.generateChartData(periods)
    },
    filterByYear () {
      let periods = []
      this.ordersForLocation.forEach(order => {
        if (!(moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days')))) {
          return
        }
        let existingPeriod = periods.find(entry => {
          return entry.toString === moment(order.createdAt).format('YYYY')
        })
        if (existingPeriod) {
          existingPeriod.noOfOrders += 1
          existingPeriod.grandTotal += this.calculateOrderTotal(order)
        } else {
          periods.push({
            year: moment(order.createdAt).format('YYYY'),
            toString: `${moment(order.createdAt).format('YYYY')}`,
            timestamp: moment(order.createdAt).format('X'),
            noOfOrders: 1,
            grandTotal: this.calculateOrderTotal(order)
          })
        }
      })

      this.generateChartData(periods)
    },
    filterByQuarter () {
      let periods = []
      this.ordersForLocation.forEach(order => {
        if (!(moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days')))) {
          return
        }
        let quarter = `Q${moment(order.createdAt).format('Q YYYY')}`
        let existingPeriod = periods.find(entry => {
          return entry.toString === quarter
        })
        if (existingPeriod) {
          existingPeriod.noOfOrders += 1
          existingPeriod.grandTotal += this.calculateOrderTotal(order)
        } else {
          periods.push({
            year: moment(order.createdAt).format('YYYY'),
            toString: quarter,
            timestamp: moment(order.createdAt).format('X'),
            noOfOrders: 1,
            grandTotal: this.calculateOrderTotal(order)
          })
        }
      })

      this.generateChartData(periods)
    },
    filterByDateRange () {
      let periods = []
      let monthsBetweenDates = moment(this.endDate).diff(moment(this.startDate), 'months', true)
      monthsBetweenDates = Math.floor(monthsBetweenDates)
      let startMonth = Number(moment(this.startDate).format('M'))
      let startYear = Number(moment(this.startDate).format('YYYY'))

      // generate the periods
      periods.push({
        month: this.months[startMonth - 1],
        year: startYear,
        toString: `${this.months[startMonth - 1]} ${startYear}`,
        timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X'),
        noOfOrders: 0,
        grandTotal: 0
      })

      const monthsInYear = 12
      for (let i = 1; i <= monthsBetweenDates; i++) {
        startMonth++
        if (startMonth > monthsInYear) {
          startMonth = 1
          startYear++
        }
        periods.push({
          month: this.months[startMonth - 1],
          year: startYear,
          toString: `${this.months[startMonth - 1]} ${startYear}`,
          timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X'),
          noOfOrders: 0,
          grandTotal: 0
        })
      }

      // calculate the number of orders and grand total for the periods
      periods.forEach(entry => {
        let entryDate = entry.toString
        this.ordersForLocation.forEach(order => {
          if (moment(order.createdAt).format('MMMM YYYY') === entryDate) {
            entry.noOfOrders += 1
            entry.grandTotal += this.calculateOrderTotal(order)
          }
        })
      })

      this.generateChartData(periods)
    },
    generateChartData (periods) {
      periods.sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return 0
        }
        return (b.timestamp < a.timestamp) ? 1 : -1
      })
      this.tableData = periods

      // generate chart data
      let data = []
      let labels = []
      periods.forEach(entry => {
        labels.push(entry.toString)
        data.push(entry.grandTotal)
      })

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Amount spent on Orders',
            backgroundColor: '#74df70',
            border: '0.5px solid',
            borderColor: '#bcb6ff',
            fill: false,
            data: data
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-white: color(white);
$color-search-border: color(gloo-search-border);
$color-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);
$color-success: color(gloo-success);
$color-silver: color(gloo-silver);

#controls {
  background-color: color(white);

  .daterange-box {
    display: inline-block;
    margin: 0 15px;
  }

  .period-filter {
    text-align: center;
  }
}

.export-btn {
  width: 200px;
  border: 1px solid lightgray;
  height: 50px;
  background: linear-gradient(173.96deg, #EEFAF4 0%, #A7CDA3 100%);
}

#chart {
  padding: 40px 0;
}
.pxy-5 {
  padding: .6rem !important;
}
#controls, #chart {
  margin: 0 0 50px !important;
}

#controls span.section-title, #title-and-search .section-title {
  padding-left: 20px;
  font-size: 21px;
  line-height: 25px;
  color: $color-gray;
  font-weight: 600;
}

#title-and-search .section-title {
  padding-left: 0;
}

.controls-wrapper {
  align-self: center;
}

.btn-group {
  .btn {
    padding: 25px 10px;
    color: #7D84A0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.13px;
    line-height: 19px;
    border-radius: 0;

    &:hover {
      color: $color-gloo-main;
    }
  }

  .active {
    color: $color-gloo-main;
    border-bottom: 1px solid $color-gloo-main;
    font-weight: 600;
  }
}

#tables>div {
  padding: 0;
}

#title-and-search>div {
  padding: 10px !important;

  #dateRange-display {
    align-self: center;
  }
}

// Style to properly align search icon in Search bar on Data-table
.search {
  margin: auto;
}

.external-search--box {
  text-align: left;
}

.search-box {
  width: 100%;
}

.v-card {
  // border-radius: 2px;
  box-shadow: 0 1px 3px 0 #d7d7e0 !important;
}

.card-title {
  font-size: 24px;
  line-height: 2em;
  display: inline-block;
}

.embolden {
  font-weight: 600 !important;
}
.table-cta {
  color: $color-gloo-main !important;
  margin-bottom: 0 !important;
  font-weight: 550 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  &:hover {
    cursor: pointer;
  }
}

.table-icon {
  font-size: 30px;
  vertical-align: middle;
}

tr>td:nth-child(1):hover {
  cursor: pointer;
}

input.daterange {
  background-image: linear-gradient(180deg, #FAFBFC 0%, $color-white 100%);
  border: 1.5px solid $color-search-border;
  border-radius: 3px;
  padding: 5px;
  padding-left: 35px;
}

.datepickericon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.disabled-toggle {
  color: $color-silver !important;
}

@media (min-width: 1200px) and (max-width:1485px) {
  .controls-wrapper {
    padding: 10px 7px;
  }
  .daterange-box {
    margin-top: 5px !important;
  }
}

@media (min-width: 1200px) and (max-width:1295px) {
  .period-filter .btn {
    padding: 25px 5px;
  }
}

@media (min-width: 1200px) {
  .period-filter {
    text-align: right !important;
  }
}

@media (max-width: 1199px) {
  #controls span.section-title {
    padding-left: 0;
  }
  .controls-wrapper {
    text-align: center;
    padding-top: 25px;
  }
  .daterange-box {
    margin-top: 15px !important;
  }
    .export-btn-container {
    padding-top: 1rem !important;
    .export-option {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 510px) {
  #dateRange-display, .search {
    width: 100%;
    padding: 7px;
    flex-basis: 100%;
    max-width: 100%;
  }
  #title-and-search .section-title {
    text-align: center;
  }
}
</style>
