<template>
  <div>
    <v-progress-linear color="success" :indeterminate="true" v-if="!selectedRole" />
    <div v-else>
      <div id="role-details" class="mb30">
        <div id="page-wrap" class="row pb20">
          <div id="role-name-holder" class="col-md-8 col-sm-6 col-xs-12 margin-auto">
            <h4 class="display-2 cl-gloo-gray">{{ selectedRole ? selectedRole.name : '' | ucfirst }}</h4>
            <span class="subheading cl-gloo-product-text">{{ selectedRole ? selectedRole.description : '' }}</span>
          </div>
        </div>
      </div>
      <v-tabs
        v-model="active"
        id="permissions-tab"
        slider-color="success"
      >
        <v-tab ripple class="tablink">
          Manage Permissions
        </v-tab>
        <v-tab ripple class="tablink">
          Assign Users
        </v-tab>
        <v-tab-item class="">
          <div class="">
            <div id="manage-permission" class="pb20">
              <AssignPermissionsToRole/>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="">
            <div id="manage-users" class="pb20">
              <AssignUsersToRole/>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>

import ShowLoader from './ShowLoader.vue'
import AssignPermissionsToRole from './AssignPermissionsToRole.vue'
import AssignUsersToRole from './AssignUsersToRole.vue'
import { permissionsGuard } from 'theme/route-guards'

export default {
  data () {
    return {
      tabs: null,
      text: 'Looks Okay',
      selectedRole: null,
      companyId: null,
      active: null
    }
  },
  mounted () {
    let selectedRoleId = this.$route.params['roleId']
    if (!selectedRoleId) {
      this.$router.push('/page-not-found')
    }

    let self = this
    this.companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : null
    if (this.companyId) {
      this.$store.dispatch('fetchCompanyRoles', this.companyId)
        .then(({ data }) => {
          if (data.code === 200) {
            let roles = data.result ? data.result.data.roles : []
            self.selectedRole = roles.find(obj => {
              return obj.id === selectedRoleId
            })
            if (!self.selectedRole) {
              this.$router.push('/page-not-found')
            }
          }
        })
        .catch(error => {
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while retrieving role',
              action1: { label: 'OK', action: 'close' }
            })
          }
          this.showLoader = false
        })
    }
  },
  components: {
    ShowLoader,
    AssignPermissionsToRole,
    AssignUsersToRole
  },
  beforeRouteEnter (to, from, next) {
    permissionsGuard(to, from, next)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);

.myHeading {
  margin-bottom: 20px
}

#role-name-holder .display-2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
}

.v-tabs__content { transition: none; }

.section-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 29px;
}

#manage-users {
  margin: 30px 0;
}
</style>
