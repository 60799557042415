import router from 'core/router'
// uncomment if you want to modify the router e.g. add before/after hooks
import Home from 'theme/pages/Home.vue'
import Category from 'theme/pages/Category.vue'
import Product from 'theme/pages/Product.vue'
import Static from 'theme/pages/Static.vue'
import Checkout from 'theme/pages/Checkout.vue'
import Dashboard from 'theme/pages/Dashboard.vue'
import Compare from 'theme/pages/Compare.vue'
import PageNotFound from 'theme/pages/PageNotFound.vue'
// import CompanySettings from 'theme/pages/CompanySettings.vue'
import Login from 'theme/pages/Login.vue'
import PasswordReset from 'theme/pages/PasswordReset.vue'
import FirstPasswordChange from 'theme/pages/FirstPasswordChange.vue'
import AddRole from 'theme/components/core/blocks/CompanySettings/AddRole.vue'
import ViewRoles from 'theme/components/core/blocks/CompanySettings/ViewRoles.vue'
import AssignUsersToRole from 'theme/components/core/blocks/CompanySettings/AssignUsersToRole.vue'
import AssignPermissionsToRole from 'theme/components/core/blocks/CompanySettings/AssignPermissionsToRole.vue'
import AribaResumeSession from 'theme/components/core/blocks/Punchout/AribaResumeSession.vue'
import CoupaResumeSession from 'theme/components/core/blocks/Punchout/CoupaResumeSession.vue'
import SAPSRMResumeSession from 'theme/components/core/blocks/Punchout/SAPSRMResumeSession.vue'
import CustomCmsPage from 'theme/pages/CustomCmsPage.vue'
import CmsData from 'src/extensions/cms/components/CmsData'
import ManageRole from 'theme/components/core/blocks/CompanySettings/ManageRole.vue'
import Holding from 'theme/pages/Holding.vue'
import ForgotPassword from 'theme/pages/ForgotPassword.vue'
import GoogleSSOResumeSession from 'theme/components/core/blocks/SSO/GoogleSSOResumeSession.vue'
import AlternativeProducts from 'theme/pages/AlternativeProducts.vue'
import CatalogRequest from 'theme/pages/CatalogRequest.vue'

import config from 'config'

// import store from 'core/store'

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'holding', path: '/holding', component: Holding },
  { path: '/ariba/shopping/session/:sessionId', component: AribaResumeSession },
  { path: '/coupa/shopping/session/:sessionId', component: CoupaResumeSession },
  { path: '/sap-srm/shopping/session/:sessionId', component: SAPSRMResumeSession },
  { path: '/sso/google/shopping/session/:sessionId', component: GoogleSSOResumeSession },
  { name: 'login', path: '/login', component: Login },
  { name: 'forgot-password', path: '/forgot-password', component: ForgotPassword },
  { name: 'password-reset', path: '/password-reset/:token', component: PasswordReset },
  { name: 'first-password-change', path: '/first-password-change', component: FirstPasswordChange },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'dashboard', path: '/dashboard', component: Dashboard, props: {activeBlock: 'Dashboard'} },
  { name: 'order-detail', path: '/orders/:orderId', component: Dashboard, props: {activeBlock: 'SingleOrder'} },
  { name: 'legal', path: '/legal', component: Static, props: {page: 'lorem', title: 'Legal Notice'}, meta: {title: 'Legal Notice', description: 'Legal Notice - example of description usage'} },
  { name: 'privacy', path: '/privacy', component: Static, props: {page: 'lorem', title: 'Privacy'} },
  { name: 'magazine', path: '/magazine', component: Static, props: {page: 'lorem', title: 'Magazine'} },
  { name: 'sale', path: '/sale', component: Static, props: {page: 'lorem', title: 'Sale'} },
  { name: 'order-tracking', path: '/order-tracking', component: Static, props: {page: 'lorem', title: 'Track my Order'} },
  { name: 'company-settings', path: '/company-settings', component: Dashboard, props: {activeBlock: 'GeneralSettings'} },
  { name: 'general-settings', path: '/company-settings/general-settings', component: Dashboard, props: {activeBlock: 'GeneralSettings'} },
  { name: 'location-settings', path: '/company-settings/location-settings', component: Dashboard, props: {activeBlock: 'LocationSettings'} },
  { name: 'add-location', path: '/company-settings/location-settings/add-location', component: Dashboard, props: {activeBlock: 'AddLocation'} },
  { name: 'single-location-settings', path: '/company-settings/location-settings/:locationId', component: Dashboard, props: {activeBlock: 'LocationDetails'} },
  { name: 'edit-location', path: '/company-settings/location-settings/:locationId/edit', component: Dashboard, props: {activeBlock: 'EditLocation'} },
  { name: 'catalog', path: '/company-settings/catalog', component: Dashboard, props: {activeBlock: 'Catalog'} },
  { name: 'catalog-request', path: '/catalog-request', component: CatalogRequest },
  { name: 'users', path: '/company-settings/users', component: Dashboard, props: {activeBlock: 'Users'} },
  {
    name: 'authorization-settings',
    path: '/company-settings/authorization-settings',
    component: Dashboard,
    props: {activeBlock: 'AuthorizationSettings'},
    children: [
      { path: 'roles/add', component: AddRole, props: {activeBlock: 'AuthorizationSettings'} },
      { name: 'view-roles', path: 'roles/', component: ViewRoles, props: {activeBlock: 'AuthorizationSettings'} },
      { path: 'roles/:roleId/assign-users', component: AssignUsersToRole, props: {activeBlock: 'AuthorizationSettings'} },
      { path: 'roles/:roleId/assign-permissions', component: AssignPermissionsToRole, props: {activeBlock: 'AuthorizationSettings'} },
      { path: 'roles/:roleId/manage', component: ManageRole, props: {activeBlock: 'AuthorizationSettings'} }
    ]
  },
  { name: 'single-user', path: '/company-settings/users/:userId', component: Dashboard, props: {activeBlock: 'SingleUser'} },
  { name: 'single-user-assign-permissions', path: '/company-settings/users/:userId/assign-permissions', component: Dashboard, props: {activeBlock: 'AssignSingleUserPermissions'} },
  { name: 'edit-user', path: '/company-settings/users/:userId/edit', component: Dashboard, props: {activeBlock: 'EditUser'} },
  { name: 'my-account', path: '/my-account', component: Dashboard, props: {activeBlock: 'MyAccount'} },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: Dashboard, props: {activeBlock: 'MyShippingDetails'} },
  { name: 'my-newsletter', path: '/my-account/newsletter', component: Dashboard, props: {activeBlock: 'MyNewsletter'} },
  { name: 'my-orders', path: '/my-account/orders', component: Dashboard, props: {activeBlock: 'MyOrders'} },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: Dashboard, props: {activeBlock: 'MyOrder'} },
  { name: 'order-approvals', path: '/my-account/order-approvals', component: Dashboard, props: {activeBlock: 'OrderApprovals'} },
  { name: 'about-us', path: '/about-us', component: Static, props: {page: 'about', title: 'About us'} },
  { name: 'customer-service', path: '/customer-service', component: Static, props: {page: 'lorem', title: 'Customer service'} },
  { name: 'store-locator', path: '/store-locator', component: Static, props: {page: 'lorem', title: 'Store locator'} },
  { name: 'size-guide', path: '/size-guide', component: Static, props: {page: 'lorem', title: 'Size guide'} },
  { name: 'gift-card', path: '/gift-card', component: Static, props: {page: 'lorem', title: 'Gift card'} },
  { name: 'delivery', path: '/delivery', component: Static, props: {page: 'lorem', title: 'Delivery'} },
  { name: 'returns', path: '/returns', component: Static, props: {page: 'lorem', title: 'Returns policy'} },
  { name: 'order-from-catalog', path: '/order-from-catalog', component: Static, props: {page: 'lorem', title: 'Order from catalog'} },
  { name: 'contact', path: '/contact', component: Static, props: {page: 'contact', title: 'Contact'} },
  { name: 'compare', path: '/compare', component: Compare, props: {title: 'Compare Products'} },
  { name: 'page-not-found', path: '/page-not-found', component: PageNotFound },
  { name: 'custom-cms-page', path: '/custom-cms-page', component: CustomCmsPage },
  { name: 'cms-page-sync', path: '/cms-page-sync', component: CmsData, props: {identifier: 'about-us', type: 'Page', sync: true} },
  { name: 'reports', path: '/reports', component: Dashboard, props: {activeBlock: 'Reports'} },
  { name: 'sales-reports', path: '/reports/sales', component: Dashboard, props: {activeBlock: 'SalesReports'} },
  { name: 'product-reports', path: '/reports/product', component: Dashboard, props: {activeBlock: 'ProductsReports'} },
  { name: 'company-orders', path: '/company/all-orders', component: Dashboard, props: { activeBlock: 'AllCompanyOrders' } },
  { name: 'list-approval-workflows', path: '/approval-workflows', component: Dashboard, props: { activeBlock: 'ListApprovalWorkflows' } },
  { name: 'add-approval-workflows', path: '/approval-workflows/add', component: Dashboard, props: { activeBlock: 'AddApprovalWorkflow' } },
  { path: '*', redirect: 'page-not-found' },
  { name: 'alternative-products', path: '/alt/:parentSku/:slug', component: AlternativeProducts }
]
if (!config.products.useShortCatalogUrls) {
  routes = routes.concat([{ name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'category', path: '/c/:slug', component: Category }])
} else {
  routes = routes.concat([{ name: 'virtual-product', path: '/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'bundle-product', path: '/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'simple-product', path: '/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'downloadable-product', path: '/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'grouped-product', path: '/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'configurable-product', path: '/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'product', path: '/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'category', path: '/:slug', component: Category }])
}

router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/login') > -1 ||
    to.path.indexOf('/holding') > -1 ||
    to.path.indexOf('/ariba/shopping/session') > -1 ||
    to.path.indexOf('/coupa/shopping/session') > -1 ||
    to.path.indexOf('/sap-srm/shopping/session') > -1 ||
    to.path.indexOf('/sso/google/shopping/session') > -1 ||
    to.path.indexOf('/password-reset') > -1 ||
    to.path.indexOf('/first-password-change') > -1 ||
    to.path.indexOf('/forgot-password') > -1) {
    next()
  } else {
    const usersCollection = global.$VS.db.usersCollection
    usersCollection.getItem('current-token', (err, token) => {
      setTimeout(() => {
        if (err || !token) {
          next({
            path: '/holding',
            query: {
              redirect: to.fullPath
            }
          })
        } else {
          // check if user has changed original password
          usersCollection.getItem('password-changed', (err, passwordChanged) => {
            setTimeout(() => {
              if (err || passwordChanged === false) {
                next({
                  path: '/holding'
                })
                return
              }
              next()
            })
          })
        }
      }, 10)
    })
  }
})

router.onError(err => {
  console.error('An error occured during navigation. ' + err.message)
})

export default routes
