<template>
  <div>
    <div id="submitted-orders" class="panel panel-default pad-panel">
      <div>
        <v-card-title class="table-title">
          <span class="section-title">{{ $t('My Submitted Orders') }}</span>
        </v-card-title>
        <v-card>
          <v-data-table
            v-if="nonApprovedOrders && nonApprovedOrders.length > 0"
            :headers="headers"
            :pagination.sync="pagination"
            :items="nonApprovedOrders"
            :loading="showLoader"
            :rows-per-page-items="[25, 50]"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left show-details" @click="showOrderDetails(props.item)">{{ props.item.id }}</td>
              <td class="text-xs-left">{{ parseDate(props.item.created_at) }}</td>
              <td class="text-xs-left">{{ props.item.addressInformation.deliveryLocation.name }}</td>
              <td class="text-xs-left">{{ props.item.approverId ? props.item.approverId.firstName : '' }} {{ props.item.approverId ? props.item.approverId.lastName : '' }}</td>
              <td v-if="props.item.status === 'pending'" class="text-xs-left order-status"><span class="order-status-chip" id="pending-order">{{ props.item.status }}</span></td>
              <td v-else-if="props.item.status === 'disapproved'" class="text-xs-left order-status"><span class="order-status-chip" id="disapproved-order">{{ props.item.status }}</span></td>
              <td v-else-if="props.item.status === 'resubmitted'" class="text-xs-left order-status"><span class="order-status-chip" id="resubmitted-order">{{ props.item.status }}</span></td>
              <td class="text-xs-left">
                <div v-if="hasPermission('create', 'order')">
                  <v-btn color="success" class="accept" v-if="props.item.status === 'disapproved'" @click="edit(props.item)">Edit Order</v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
          <div v-else class=" no-order">
            <div class="empty-history">
              <div class="no-order-text">
                <p class="cl-gloo-catalina-blue">You currently have no submitted orders.</p>
                <p class="cl-gloo-catalina-blue">Once you do, this is where you’ll track them.</p>
              </div>
              <div class="p15">
                <img src="/assets/no-recent-orders.svg" alt="Empty Order History" height="100px" class="img-order-history">
              </div>
              <button-outline color="gray" class="start p15" @click.native="browseCategories()">
                Start ordering
              </button-outline>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <!-- Edit Order Modal Component -->
    <edit-order-modal :order="orderToBeEdited" />
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from '../Form/BaseCheckbox.vue'
import moment from 'moment'
import ShowLoader from '../CompanySettings/ShowLoader.vue'
import EditOrderModal from './EditOrderModal'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { localizedRoute } from '@vue-storefront/store/lib/multistore'
// import { productsInCart } from 'core/api/cart'

export default {
  name: 'MySubmittedOrders',
  components: {
    ButtonFull,
    BaseCheckbox,
    ShowLoader,
    EditOrderModal,
    ButtonOutline
  },
  // mixins: [
  //   productsInCart
  // ],
  data () {
    return {
      myOrders: null,
      showLoader: true,
      pagination: {
        sortBy: 'created_at',
        descending: true
      },
      headers: [
        {
          text: 'Order ID',
          align: 'left',
          sortable: true,
          value: 'id'
        },
        {text: 'Date', sortable: true, value: 'created_at'},
        {text: 'Shipped To', sortable: true, value: 'addressInformation.deliveryLocation.name'},
        {text: 'Approver', sortable: true, value: 'approverId.firstName'},
        {text: 'Status', sortable: true, value: 'status'},
        {text: 'Actions', sortable: false, value: 'actions'}
      ],
      orderToBeEdited: {}
    }
  },
  methods: {
    edit (order) {
      if (this.productsInCart && this.productsInCart.length > 0) {
        this.orderToBeEdited = order
        this.$bus.$emit('modal-show', 'modal-edit-order-confirmation')
      } else {
        this.editOrder(order)
      }
    },
    fetchMyOrders () {
      // only fetch the orders if the state has initialized
      const jwt = this.$store.state.userDetails
        ? this.$store.state.userDetails.jwToken
        : null
      if (!jwt) {
        this.showLoader = false
        return
      }
      this.$store.dispatch('order/fetchMyOrders')
        .then(({ data }) => {
          this.showLoader = false
          if (data.code !== 200) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'Could not retrieve my orders',
              action1: { label: 'OK', action: 'close' }
            })
            return
          }
          this.myOrders = data.result.orders
        })
    },
    parseDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    editOrder (order) {
      this.$store.dispatch('order/saveLinkedOrder', order.id)
      const items = order.products
      items.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
    },
    showOrderDetails (order) {
      this.$router.push(this.localizedRoute('/orders/' + order.id))
    },
    browseCategories () {
      this.$router.push(localizedRoute('/#company-categories'))
    }
  },
  mounted () {
    this.fetchMyOrders()
  },
  computed: {
    nonApprovedOrders () {
      if (!this.myOrders) {
        return []
      }
      return this.myOrders.filter(item => {
        return ['approved', 'complete', 'closed', 'canceled', 'processing'].indexOf(item.status) === -1
      })
    },
    productsInCart () {
      return this.$store.state.cart.cartItems
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-success: color(gloo-success);
$color-pending: color(gloo-pending);
$color-error: color(gloo-danger);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-pend: color(gloo-pend);
$color-white: color(white);

.panel {
  background-color: $color-white;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px 0 #d7d7e0 !important;
}

#submitted-orders {
  margin: 30px 0;
  background-color: color(white);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #D7D7E0;
}

.table-title {
  border: 1px solid #e9ebf6 !important;
}

span.section-title {
  font-size: 24px;
  color: #253858;
  font-weight: 550;
  line-height: 29px;
  text-align: left;
}

table.v-table thead th, table.v-table tbody td, table.v-table tbody th {
  color: rgba(37, 56, 88, 0.7) !important;;
  font-weight: 400 !important;
  line-height: 18px !important;;
  width: 74px !important;
  text-align: left;
  font-size: 15px;
}

.show-details {
  cursor: pointer;
}

.order-status {
  text-transform: uppercase;
}

.order-status-chip {
  letter-spacing: 0.21px;
  line-height: 12px;
  width: 120px;
  text-align: center !important;
  align-items: center;
  border-radius: 28px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 30px;
  height: 32px;
  margin: auto;
  font-size: 13px !important;
  align-content: center;
}

#resubmitted-order {
  background-color: $color-completed;
  color: $color-success;
  padding: 0 15px;
}

#pending-order {
  background-color: $color-pend;
  color: $color-pending;
}

#disapproved-order {
  background-color: $color-failed;
  color: $color-error;
  padding: 0 15px;
}

.v-card {
  box-shadow: none !important;
}

.empty-history, .no-order, .no-order-text {
  margin: auto;
  text-align: center;
}

.empty-history {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.no-order {
  padding: 40px!important;
}

.no-order-text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-outline {
  padding: 10px;
  border-radius: 5px;
  height: 48px;
}
</style>
