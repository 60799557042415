<template>
  <v-progress-linear color="success" :indeterminate="true" v-if="!user" />
  <div id="user-details" v-else>
    <!-- Username and User Action Buttons Section -->
    <div>
      <div id="page-wrap" class="row pb20 px10">
        <div id="username-holder" class="col-md-8 col-sm-6 col-xs-12 margin-auto">
          <h3 id="username">{{ user && user.firstName }} {{ user && user.lastName }}</h3>
        </div>
        <div id="user-actions-holder" class="col-md-4 col-sm-6 col-xs-12 buttons">
          <v-btn large color="success" class="bg-cl-mine-shaft :bg-cl-th-secondary user-actions" @click="editUser">Edit Profile</v-btn>
          <p v-if="!usesPunchout" id="change-pwd-link" class="bg-cl-mine-shaft :bg-cl-th-secondary user-actions" @click.prevent="showPasswordChangeModal">Change Password</p>
        </div>
      </div>
    </div>

    <!-- Personal Details Section -->
    <v-card>
      <div id="details" class="panel panel-default pad-panel single-section">
        <v-card-title>
          <span class="section-title">Personal Details</span>
        </v-card-title>
        <div class="details-wrapper row">
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 col-xs-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Name</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ user && user.firstName }} {{ user && user.lastName }}</p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Job Title</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ userRole | capitalize }}</p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Location</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ userLocation && userLocation.name }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 col-xs-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Email</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ user && user.emailAddress }}</p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Phone Number</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p v-if="user && user.phoneNumber">{{ user && user.phoneNumber }}</p>
                <p v-else>User doesn't have a Phone Number registered</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <!-- User's Locations and Permissions Tabs Section -->
    <v-tabs
      id="user-tab"
      slider-color="success"
    >
      <v-tab ripple class="tablink">
        Locations
      </v-tab>
      <v-tab ripple class="tablink">
        Permissions
      </v-tab>

      <!-- User's Locations Tab Pane -->
      <v-tab-item>
        <div class="panel" id="user-locations--wrapper">
          <div id="user-locations">
            <div>
              <div class="pl0">
                <span class="section-title">Assigned Locations</span>
              </div>
            </div>
            <v-progress-linear :indeterminate="true" v-if="!deliveryLocations" color="success" />
            <div class="row pt20" v-else>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 delivery-address" v-for="location in deliveryLocations" :key="location.id">
                <h4>{{ location.name }}</h4>
                <p>{{ location && location.locationAddressId && location.locationAddressId.streetAddress }}</p>
                <p v-if="location && location.locationAddressId && location.locationAddressId.addressLine2">{{ location && location.locationAddressId && location.locationAddressId.addressLine2 }}</p>
                <p>
                  {{ location && location.locationAddressId && location.locationAddressId.city }}&nbsp;
                  {{ location && location.locationAddressId && location.locationAddressId.zipcode }}
                </p>
                <p>
                  {{ location && location.locationAddressId && location.locationAddressId.state }},&nbsp;
                  {{ location && location.locationAddressId && location.locationAddressId.country }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>

      <!-- User's Permissions Tab Pane -->
      <v-tab-item>
        <div class="panel" id="user-permissions--wrapper">
          <div id="user-permissions">
            <div>
              <div class="pl0">
                <span class="section-title">Permissions</span>
              </div>
            </div>
            <v-progress-linear :indeterminate="true" v-if="!userPermissions" color="success" />
            <div class="pt30" v-else-if="userPermissions.length > 0">
              <div class="row permissions">
                <p class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(permission, index) in userPermissions" :key="index" >{{ permission.name }}</p>
              </div>
            </div>
            <div class="col-xs pt20 pl0" v-else>
              <p class="details py15">No permission found</p>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>

    <!-- Edit User Component -->
    <edit-user :user="user" v-if="user" parent="my-account" />

    <!-- Change Password Modal -->
    <div id="change-password-modal">
      <modal name="change-password-modal" :width="600">
        <v-container grid-list-xl pwd-change>
          <header class="modal-header py25 px65 h3 weight-700">
            <i
              slot="close"
              class="modal-close material-icons p15 cl-bg-black"
              @click="cancelPasswordChange"
            >
              close
            </i>
            {{ 'Change Password' }}
          </header>
          <v-layout row wrap align-center justify-center>
            <v-flex xs10 class="center-form form">
              <form @submit.prevent="submit" novalidate>
                <v-text-field
                  class="mb35"
                  label="Old password"
                  type="password"
                  v-model="oldPassword"
                  outline
                  required
                />
                <v-text-field
                  class="mb35"
                  label="New password"
                  type="password"
                  v-model="password"
                  outline
                  required
                  :rules="passwordRules"
                />
                <v-text-field
                  class="mb35"
                  label="Confirm New password"
                  type="password"
                  v-model="confirmPassword"
                  outline
                  required
                  :rules="passwordRules"
                />
                <v-btn
                  block
                  type="submit"
                  style="height: 45px;"
                  id="change-pwd"
                >
                  Submit
                </v-btn>
              </form>
            </v-flex>
          </v-layout>
        </v-container>
      </modal>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import MyProfile from 'core/components/blocks/MyAccount/MyProfile'
import Modal from 'theme/components/core/Modal.vue'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import Tooltip from 'theme/components/core/Tooltip'
import EditUser from '../CompanySettings/EditUser'

export default {
  data () {
    return {
      userPermissions: Object.assign([], this.$store.state.userDetails.userPermissions),
      userId: this.$store.state.userDetails.userId,
      userRole: this.$store.state.userDetails.userRole,
      userLocation: this.$store.state.userDetails.locationDetails,
      deliveryLocations: this.$store.state.userDetails.deliveryLocations,
      password: '',
      confirmPassword: '',
      oldPassword: '',
      oldPasswordValid: '',
      passwordRules: [
        v => !!v.trim() || 'This field is required',
        (value) => {
          let matches = 0
          value = value.trim()
          if (value.match(/[A-Z]/g)) {
            matches++
          }
          if (value.match(/[a-z]/g)) {
            matches++
          }
          if (value.match(/[0-9]/g)) {
            matches++
          }
          if (value.match(/[^a-zA-Z0-9]/g)) {
            matches++
          }
          return (typeof value === 'string' && value.length >= 8 && matches >= 3) || 'Invalid password format. Password should contain lowercase, uppercase, number and special character'
        }
      ]
    }
  },
  components: {
    BaseCheckbox,
    BaseSelect,
    BaseInput,
    Tooltip,
    Modal,
    EditUser
  },
  mixins: [MyProfile],
  created () {
    let companyDetails = this.$store.getters.getCompanyDetails
    this.$store.dispatch('fetchCompanyUsers', companyDetails.id)
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    usesPunchout () {
      return this.$store.state.userDetails.companyDetails && this.$store.state.userDetails.companyDetails.punchoutActive
    },
    user () {
      return this.$store.getters.getUserDetails(this.userId)
    }
  },
  watch: {
    user: function (val) {
      if (val !== null) {
        this.fetchUpdatedUserDetails()
      }
    }
  },
  methods: {
    checkValidation () {
      if (this.changePassword && this.addCompany) {
        return this.$v.$invalid
      } else if (this.changePassword && !this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.password.$invalid || this.$v.rPassword.$invalid
      } else if (!this.changePassword && this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.userCompany.$invalid
      } else {
        return this.$v.currentUser.$invalid
      }
    },
    showPasswordChangeModal () {
      this.$bus.$emit('modal-show', 'change-password-modal')
    },
    cancelPasswordChange () {
      this.password = ''
      this.confirmPassword = ''
      this.oldPassword = ''
      this.oldPasswordValid = ''
      this.$bus.$emit('modal-hide', 'change-password-modal')
    },
    submit () {
      if (this.password !== this.confirmPassword) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Passwords do not match',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$bus.$emit('notification-progress-start', 'Saving new password...')
      this.$store.dispatch('changePassword', {
        oldPassword: this.oldPassword,
        newPassword: this.password
      })
        .then((response) => {
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('notification', {
            type: 'success',
            message: 'Password has been successfully changed',
            action1: { label: 'OK', action: 'close' }
          })
          this.cancelPasswordChange()
        })
    },
    editUser () {
      this.$bus.$emit('modal-show', 'modal-edit-user')
    },
    fetchUpdatedUserDetails () {
      if (this.user && this.user.emailAddress) {
        this.$store.dispatch('fetchSingleUserDetails', { userId: this.user.id, updateState: false })
          .then(({ data }) => {
            if (data.code === 200) {
              this.userLocation = data.result.data.user && data.result.data.user.location
              this.userRole = data.result.data.user && data.result.data.user.roleId && data.result.data.user.roleId.name
              this.userPermissions = data.result.data.user.permissions
              this.deliveryLocations = data.result.data.user.deliveryLocations
            }
          })
      }
    }
  },
  validations: {
    currentUser: {
      firstname: {
        required,
        minLength: minLength(3)
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      }
    },
    oldPassword: {
      required
    },
    password: {
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    userCompany: {
      company: {
        required
      },
      country: {
        required
      },
      street: {
        required
      },
      house: {
        required
      },
      postcode: {
        required,
        minLength: minLength(5)
      },
      city: {
        required
      },
      taxId: {
        required,
        minLength: minLength(3)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-gray: color(gray);
$color-gloo-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);
$color-gloo-text: color(gloo-text);
$color-success: color(gloo-success);
$color-error: color(gloo-danger);

.margin-auto {
  margin: auto;
}

#username {
  display: inline-block;
  font-size: 30px;
  font-weight: 550;
  line-height: 36px;
}

.user-actions.v-btn {
  font-size: 15px !important;
  font-weight: 600;
  line-height: 17px;
}

p.user-actions {
  margin: auto 15px;
  font-size: 15px;
  font-weight: 550;
  line-height: 17px;
  cursor: pointer;
}

p#change-pwd-link {
  color: $color-gloo-main;
}

.details-wrapper {
  padding: 10px 10px 0 !important;
}

#user-details {
  .v-card, .v-tabs {
    margin-top: 20px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  p span {
    position: relative;
    top: -8px;
    color: grey;
  }

  .details-values p {
    color: $color-gloo-text;
    position: relative;
    top: -13px;
    font-size: 16px;
    line-height: 32px;
  }
}

#details {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0 !important;
  margin-top: 10px !important;

  > .v-card__title {
    padding: 30px !important;
  }

  .section-title {
    font-size: 16px;
    font-weight: 550;
    line-height: 19px;
    text-transform: capitalize;
  }
}

#user-tab {
  .table-title {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 20px 25px !important;
  }
}

.single-section,
#user-permissions, #user-locations {
  margin: 50px 0 0;
  padding: 30px;
}

.details, .details-title, .permissions {
  color: $color-gloo-gray;
}

.delivery-address {
  margin-bottom: 20px;
  h4 {
    color: $color-gloo-text;
    margin-top: 10px;
    line-height: 2;
    font-weight: 550;
  }
  p {
    color: $color-gloo-text;
    line-height: 28px;
    margin-bottom: 0;
    font-size: 16px;
  }
}

#user-locations .delivery-address {
  padding: 0;
}

@media (max-width: 767px) {

  #username-holder {
    text-align: center;
  }

  #user-actions-holder {
    margin-top: 20px;
    justify-content: center !important;
  }

  #page-wrap {
    padding-top: 20px;
  }

}

.pwd-change {
  padding: 0 0 30px !important;
  margin: 70px auto !important;
}

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

.form {
  display: block;
  padding: 25px 0 !important;
}

#change-pwd.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
