<template>
  <div>
    <div class="mb20 mt20">
      <div class="col-xl-8 option-header">
        <h4 class="mb0 cl-gloo-gray section-header">Frequently Ordered Products</h4>
        <p class="mb0 hidden-xs sub-header cl-gloo-gray">Based on your most ordered products</p>
      </div>
      <hr >
    </div>
    <show-loader v-if="!frequentlyBoughtItems" />
    <div v-if="frequentlyBoughtItems && frequentlyBoughtItems.length === 0">
      <div class=" no-order">
        <div class="no-order-text">
          <p>Sorry, your company hasn’t made any orders yet.</p>
        </div>
      </div>
    </div>
    <div class="row align-center mb20">
      <div v-for="(frequent, index) in frequentlyBoughtItems" :key="index" :class="['col-lg-3', 'col-sm-6']">
        <div class="single-frequent">
          <product-tile :product="frequent" :identity="identity"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Theme local components
import ProductTile from 'theme/components/core/ProductTile'
import ShowLoader from '../CompanySettings/ShowLoader.vue'

export default {
  name: 'FrequentOrders',
  data () {
    return {
      identity: 'frequently-ordered'
    }
  },
  methods: {
  },
  components: {
    ProductTile,
    ShowLoader
  },
  computed: {
    categories () {
      return this.$store.state.category.list
    },
    companyId () {
      return this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : ''
    },
    frequentlyBoughtItems () {
      let bestSellingProducts = this.$store.getters.getCompanyFrequentlyPurchasedItems(this.companyId)
      if (bestSellingProducts) {
        bestSellingProducts = bestSellingProducts.slice(0, 8)
        bestSellingProducts.forEach(product => {
          product.qty = 0
        })
      }
      return bestSellingProducts
    }
  },
  created () {
  },
  mounted () {
    let companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : ''
    if (companyId) {
      this.$store.dispatch('fetchFrequentlyBoughtItems', { companyId })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gloo-secondary: color(gloo-secondary);
$hr: color(gloo-solitude);

  #home, h1, h4, p  {
    // font-family: Roboto !important;
  }
  .option-header h1 {
    line-height: 28px;
  }
  .option-header p {
    line-height: 21px;
  }
  .option-descr {
    position: relative;
  }
  .option-descr a {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $hr;
    margin: 1em 0;
    padding: 0;
    width: 100%;
  }

  .product.align-center {
    padding: 30px;
  }
  .single-frequent {
    // box-shadow: 0px 0.3px 2px 0px rgba(0,0,0,0.1);
    padding: 0 0.5em !important;
    margin: 1.5em 0.5em;
    // height: 425px;
  }
  .no-order {
    padding: 40px 10px;
    // width: 35%;
    margin: auto;
  }
  .no-order-text {
    // width: 70%;
    text-align: center;
    margin: auto;
    }
  .no-order-text p {
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section-header {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
    // line-spacing: 29px;
  }
  .sub-header {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  // .product, .add {
  //   padding: 3em;
  // }
  @media (max-width: 1280px) {
    .button-full {
      letter-spacing: 0;
    }
  .product, .add {
    padding: 0.8em !important;
  }
  }
</style>
