<template>
  <div id="login-wrapper">
    <header class="">
      <div class="header-img">
        <img src="/assets/gloopro-logo.png" alt="Gloopro">
      </div>
    </header>
    <div v-if="changeSuccessful === true" class="align-center modal-content change-successful">
      <div>
        <img src="/assets/checkmark.gif">
        <p class="cl-gloo-gray password-changed">Password successfully changed</p>
        <span class="cl-gloo-product-text back-to-login">Click here to <router-link class="cl-gloo-main" to="/login">login to your account</router-link></span>
      </div>
    </div>
    <div v-else class="modal-content pt30 pb60 px65 bg-cl-primary">
      <div class="header-text hidden-xs mb35" v-if="!changeSuccessful">
        <p class="cl-gloo-gray">Welcome</p>
        <span class="cl-gloo-product-text">This is your first time logging into this platform. <br>As a result, you are required to change your password before you proceed.<br>
          Please use the form below to do so</span>
      </div>
      <v-form @submit.prevent="submit" lazy-validation ref="form" v-if="!changeSuccessful">
        <v-text-field
          class="mb35 "
          label="Old password"
          v-model="oldPassword"
          :append-icon="showOldPassword ? 'visibility_off' : 'visibility'"
          :type="showOldPassword ? 'text' : 'password'"
          @click:append="showOldPassword = !showOldPassword"
          required
          outline
          :rules="[
            (v) => !!v.trim() || 'This field is required'
          ]"
        />
        <v-text-field
          class="mb35"
          label="New password"
          v-model="password"
          :append-icon="showNewPassword ? 'visibility_off' : 'visibility'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
          required
          outline
          :rules="passwordRules"
        />
        <v-text-field
          class="mb35"
          label="Confirm New password"
          type="password"
          v-model="confirmPassword"
          required
          outline
          :rules="[
            () => {
              return password === confirmPassword || 'Passwords do not match'
            }
          ]"
        />
        <button-full class="mb20" type="submit">
          <p class="p0 m0">Save Changes</p>
        </button-full>
      </v-form>
      <div v-else-if="oldPasswordValid === false">
        <v-alert
          :value="true"
          type="error"
        >
          Invalid password reset token
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'

export default {
  data () {
    return {
      showOldPassword: false,
      showNewPassword: false,
      password: '',
      confirmPassword: '',
      oldPassword: '',
      oldPasswordValid: '',
      changeSuccessful: null,
      passwordRules: [
        v => !!v.trim() || 'This field is required',
        (value) => {
          let matches = 0
          value = value.trim()
          if (value.match(/[A-Z]/g)) {
            matches++
          }
          if (value.match(/[a-z]/g)) {
            matches++
          }
          if (value.match(/[0-9]/g)) {
            matches++
          }
          if (value.match(/[^a-zA-Z0-9]/g)) {
            matches++
          }
          return (typeof value === 'string' && value.length >= 8 && matches >= 3) || 'Invalid password format. Password should contain lowercase, uppercase, number and special character'
        },
        () => {
          return this.password !== this.oldPassword || 'Old password should be different from new password'
        }
      ]
    }
  },
  created () {
    const userDetails = this.$store.state.userDetails
    if (userDetails.passwordChanged === true) {
      this.$router.push(this.localizedRoute('/'))
    }
  },
  methods: {
    submit () {
      if (!this.$refs.form.validate()) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'One or more fields is incorrect. Please fix the error displayed in the field(s).',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Passwords do not match',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      if (this.password === this.oldPassword) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Old password must be different from new password',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$bus.$emit('notification-progress-start', 'Saving new password...')
      this.$store.dispatch('changePassword', {
        oldPassword: this.oldPassword,
        newPassword: this.password
      })
        .then((response) => {
          this.$bus.$emit('notification-progress-stop')
          this.changeSuccessful = true
          this.$store.dispatch('clearUserDetailsOnLogout')
        })
    }
  },
  components: {
    ButtonFull
  }
}
</script>

 <style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $dark-border: color(secondary);
  $white: color(white);
  $black: color(black);
  $gloo-main: color(gloo-main);
  $gloo-danger: color(gloo-danger);

  img {
    height: auto;
    width: 30%;
  }
  #login-wrapper {
    padding-top: 5%;
    padding-bottom: 1%;
    font-size: 1.125em;
    width: 40%;
    margin: auto;
  }
  form {
  }
  .modal-content {
    padding: 3.5em;
    border-radius: 0.304em;
    box-shadow: 0 0 3px 0 #D7D7E0;
    text-align: center;
    margin: 3em
  }
  header {
    margin: auto;
    padding: 0;
    text-align: center;
  }
  .header-text p {
    font-size: 30px;
    font-weight: 550;
    letter-spacing: 0.1px;
    line-height: 29px;
    /* margin: 0.5em 0; */
    // padding-bottom: 1em;
    // color: #7D84A0;
  }
  .header-text span {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.07px;
    line-height: 21px;
  }
  p.password-changed {
    font-size: 24px;
    font-weight: 550;
    letter-spacing: 0.1px;
    line-height: 29px;
    padding: 1em 0 2em 0;
  }
  span.back-to-login {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  input[type=text] {
    /* border: none; */
    // border: 1px solid;
    // border-radius:  5px;
    background-color: #FFFFFF !important;
    border-radius: 5px !important;
    box-shadow: 0 0 3px 0 #D7D7E0 !important;
    // width: 534px;
    // height: 312px;
    border: 1px solid #DEE5E9 !important;
    border-radius: 4px !important;
  }
  base-checkbox div a, .remind {
    // color: #3d4094 !important;
  }
  base-checkbox {
    font-family: Roboto !important;
    font-size: 20.47px;
    line-height: 29px;
    letter-spacing: 1%;
  }
  .button-full {
    border-radius: 3px;
  }
  .back-to-gloopro {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: right;
  }
  .change-successful.modal-content {
    box-shadow: none ;
  }
  @media (min-width: 760px) and (max-width: 1280px) {
    #login-wrapper {
      padding-top: 25%;
      width: 70%;
    }
    // img {
    //   width: 120px;
    // }
  }
  @media (max-width: 360px) {
    #login-wrapper {
      padding-top: 40%;
      width: 100%;
      font-size: 1em;
      margin: auto;
    }
    // img {
    //   width: 96px;
    // }
    form {
      padding: 1.5em;
      box-shadow: none;
    }
    .modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    .h4 {
      font-size: 14px !important;
    }
    .remind {
      font-size: 12px;
    }
  }
  @media (max-width: 760px) {
    #login-wrapper {
      padding-top: 30%;
      width: 100%;
      font-size: 0.75em;
      margin: auto;
    }
    // img {
    //   width: 50px;
    // }
  }

</style>
