<template>
  <div id="edit-modal">
    <modal name="modal-edit-order-confirmation" :width="600">
      <header class="modal-header py25 px65 h3 weight-700">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Edit Order' }}
      </header>
      <v-container grid-list-xl confirm-order-edit>
        <div class="dialog-content">
          <v-card-title class="page-title confirmation-text--wrapper" style="display: block; text-align: center;">
            <p>An attempt to edit this order will result in removing previously added items from cart.</p>
            <p>Would you like to continue with this action?</p>
          </v-card-title>
          <v-card-actions style="display: block; text-align: center;">
            <v-btn large flat class="action-btn" @click="close">Close</v-btn>
            <v-btn large color="success" class="action-btn" @click="editOrder(order)" v-if="hasPermission('create', 'order')">Edit Order</v-btn>
          </v-card-actions>
        </div>
      </v-container>
    </modal>
  </div>
</template>

<script>
import Modal from 'theme/components/core/Modal'

export default {
  components: {
    Modal
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    editOrder () {
      // clear items already in cart
      this.productsInCart.forEach(item => {
        this.removeFromCart(item)
      })

      // add order items to cart to edit
      this.$store.dispatch('order/saveLinkedOrder', this.order.id)
      const items = this.order.products
      items.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
      this.close()
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-edit-order-confirmation')
    },
    removeFromCart (product) {
      this.$store.dispatch('cart/removeItem', product)
    }
  },
  computed: {
    productsInCart () {
      return this.$store.state.cart.cartItems
    }
  }
}
</script>

<style lang="scss" scoped>
header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}
.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}
.confirmation-text--wrapper {
  padding: 20px !important;
}
.confirm-order-edit {
  margin: auto !important;
}
.dialog-content {
  max-height: 400px;
  margin: auto;
  // padding: 80px 20px;
  .action-btn {
    margin: 10px;
  }
}
.page-title {
  h3 {
    margin: 0 0 24px 0;
  }
}
</style>
