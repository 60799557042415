<template>
  <div>
    <div id="all-reports">
      <div class="page-title">
        <v-card-title>
          <span class="section-title">Reports</span>
        </v-card-title>
      </div>
      <!-- Tab section holding Products Reports and Sales Reports components -->
      <div id="tab-section">
        <v-tabs
          id="reports-tab"
          slider-color="success"
        >
          <v-tab ripple class="tablink">
            Orders by Category
          </v-tab>

          <v-tab ripple class="tablink">
            Orders Overview
          </v-tab>
          <v-tab ripple class="tablink">
            Orders by Location
          </v-tab>
          <v-tab ripple class="tablink">
            Orders by Products
          </v-tab>

          <!-- Orders by Category Tab Pane -->
          <v-tab-item>
            <div class="" id="orders-by-category--wrapper">
              <div id="orders-by-category">
                <div class="pt20">
                  <div class="col-sm-12 orders-by-category">
                    <!-- Products Reports Component Here -->
                    <products-reports/>
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>

          <!-- Orders Overview Tab Pane -->
          <v-tab-item>
            <div class="" id="orders-overview--wrapper">
              <div id="orders-overview">
                <div class="pt20">
                  <div class="col-sm-12 orders-overviews">
                    <!-- Sales Reports Component Here -->
                    <sales-reports/>
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <!-- Orders by Location Tab Pane -->
          <v-tab-item>
            <div class="" id="orders-by-locations--wrapper">
              <div id="orders-by-locations">
                <div class="pt20">
                  <div class="col-sm-12 orders-by-locations">
                    <!-- Sales Reports by location Component Here -->
                    <sales-location />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <!-- Orders by Products Tab Pane -->
          <v-tab-item>
            <div class="" id="orders-by-products--wrapper">
              <div id="orders-by-products">
                <div class="pt20">
                  <div class="col-sm-12 orders-by-products">
                    <!-- Sales Reports by products Component Here -->
                    <sales-reports-by-products />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import SalesReports from '../Reports/SalesReports.vue'
import ProductsReports from '../Reports/ProductsReports.vue'
import SalesLocation from '../Reports/SalesReportsByLocation.vue'
import SalesReportsByProducts from '../Reports/SalesReportsByProducts.vue'

export default {
  components: {
    SalesReports,
    ProductsReports,
    SalesLocation,
    SalesReportsByProducts
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-gloo-gray: color(gloo-gray);
$color-gloo-text: color(gloo-text);

#reports-tab {
  .table-title {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 20px 25px !important;
  }
}

#orders-by-category, #orders-overview {
  margin: 30px 0 0;
//   padding: 30px;
}

.orders-by-category {
  color: $color-gloo-gray;
}

.orders-overviews {
  margin-bottom: 20px;
  h4 {
    color: $color-gloo-text;
    margin-top: 10px;
    line-height: 2;
    font-weight: 550;
  }
  p {
    color: $color-gloo-text;
    line-height: 28px;
    margin-bottom: 0;
    font-size: 16px;
  }
}

#orders-overview .orders-overviews {
  padding: 0;
}

@media (max-width: 767px) {

}
</style>
