import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'chartOptions'],
  mounted () {
    let options = { ...this.chartOptions }
    options.tooltips = {
      callbacks: {
        label: (item) => this.$options.filters.currency(item.yLabel)
      },
      backgroundColor: '#fcfdfe',
      titleFontColor: '#48446F',
      titleFontSize: 16,
      bodyFontColor: '#7d84a0',
      bodyFontSize: 14,
      xPadding: 20,
      yPadding: 20,
      caretSize: 12,
      caretPadding: 10,
      borderColor: '#d7d7e0',
      borderWidth: 2
    }
    options.scales = {
      yAxes: [{
        ticks: {
          callback: (value, index, values) => {
            return this.$options.filters.currency(value)
          }
        },
        scaleLabel: {
          display: true,
          labelString: 'Revenue'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        },
        gridLines: {
          display: false
        }
      }]
    }
    options.elements = {
      line: {
        tension: 0
      }
    }
    options.legend = {
      labels: {
        padding: 15
      }
    }
    this.renderChart(this.chartData, options)
  }
}
