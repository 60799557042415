// Add your own plugins in this folder with Vue.use()
import Vue from 'vue'

import VueProgressBar from 'vue-progressbar'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
// import VuetifyDaterangePicker from 'vuetify-daterange-picker'
// import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css'

Vue.use(VueProgressBar)
Vue.use(Vuetify)
// Vue.use(VuetifyDaterangePicker)
