<template>
  <component
    :is="link ? 'router-link' : 'button'"
    :to="localizedRoute(link)"
    class="button-outline no-outline py15 bg-cl-transparent h4 no-underline sans-serif fs-medium"
    :class="{
      light : color === 'light', 'brdr-white' : color === 'light', 'cl-white' : color === 'light',
      dark : color === 'dark', 'brdr-darkgray' : color === 'dark', 'cl-secondary' : color === 'dark',
      gray : color === 'gray', 'brdr-gray' : color === 'gray', 'cl-gloo-cornflower-blue' : color === 'gray',
      red : color === 'red', 'brdr-red' : color === 'red', 'cl-red' : color === 'red',
      px15 : link ? true : false,
      px40 : link ? false : true
    }"
  >
    <slot class="capitalize">
      Button
    </slot>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'

export default {
  name: 'ButtonOutline',
  directives: { focusClean },
  props: {
    color: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $dark-border: color(gloo-main);
  $light: color(gloo-background2);
  $dark: color(gloo-main);
  $gray: color(gloo-gray);
  $color-primary: color(primary, $colors-background);
  $gray-border: color(border-gray, $colors-border);

  .button-outline {
    border: 2px solid;
    min-width: 150px;
    text-transform: capitalize;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .dark {
    background: $dark;
    color:$light;
    text-transform: capitalize;
    border-color: $dark;
    &:hover,
    &:focus {
      color: $dark;
      background: $light;
      border-color: $dark;
    }
  }
  .light {
    background-color: $light;
    color:$dark;
    text-transform: capitalize;
    border-color: $dark;
    &:hover,
    &:focus {
      color: white;
      background: $dark;
      border-color: $dark-border;
    }
  }
  .gray {
    background-color: $color-primary;
    color: $gray;
    text-transform: capitalize;
    border-color: $gray-border;
    &:hover,
    &:focus {
      color: white;
      background: rgba(37, 56, 88, 0.7);
      border-color: white;
    }
  }
  .red {
  background-color: transparent !important;
  color: red;
  border-color: red;
  }
</style>
