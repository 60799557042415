<template>
  <div>
    <v-stepper v-model="stage">
      <v-stepper-header>
        <v-stepper-step :complete="stage > 1" step="1">Step 1</v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="stage > 2" step="2">Step 2</v-stepper-step>

        <v-divider />

        <v-stepper-step step="3">Step 3</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-5"
          >
            <v-layout row wrap align-center justify-center>
              <v-flex class="form">
                <v-form ref="formOne" @submit.prevent="goToStageTwo()" style="width: 300px" v-model="stageForms.validOne">
                  <v-text-field
                    label="Enter Workflow name"
                    outline
                    v-model="workflow.name"
                    required
                    :rules="workflowRules.name"
                  />
                  <v-text-field
                    label="Enter number of approval levels"
                    outline
                    type="number"
                    v-model="workflow.noOfLevels"
                    required
                    :rules="workflowRules.noOfLevels"
                  />
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>

          <v-btn
            color="primary"
            @click="goToStageTwo()"
            :disabled="!stageForms.validOne"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            class="mb-5"
          >
            <v-layout row wrap align-center justify-center>
              <v-flex class="form">
                <v-form ref="formTwo" @submit.prevent="goToStageThree()" lazy-validation v-model="stageForms.validTwo" class="w-100">
                  <v-layout row wrap v-for="(level, index) in workflow.levels" :key="index">
                    <v-flex xs12 sm3>
                      <v-text-field
                        label="Title of Level"
                        outline
                        v-model="level.name"
                        :rules="workflowLevelRules[index].name"
                      />
                    </v-flex>
                    <v-flex xs12 sm2>
                      <v-text-field
                        label="Minimum approvers"
                        outline
                        type="number"
                        @change="approversChanged(index)"
                        v-model="level.noOfRequiredApprovals"
                        :rules="workflowLevelRules[index].noOfRequiredApprovals"
                      />
                    </v-flex>
                    <v-flex xs12 sm2>
                      <v-text-field
                        label="Level"
                        outline
                        type="number"
                        disabled
                        v-model="level.level"
                      />
                    </v-flex>
                    <v-flex xs12 sm5>
                      <v-autocomplete
                        label="Select approvers"
                        outline
                        v-model="level.approvers"
                        :items="usersToSelectFrom[index]"
                        attach
                        chips
                        item-text="fullName"
                        return-object
                        multiple
                        solo
                        clearable
                        :error-messages="workflowLevelRules[index].approversMessages"
                        :error="workflowLevelRules[index].approversHasError"
                        @change="approversChanged(index)"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>

          <v-btn
            color="primary"
            @click="goToStageThree"
            :disabled="!stageForms.validTwo || !approversValid"
          >
            Continue
          </v-btn>

          <v-btn color="error" @click="stage = 1">Back</v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-5"
          >
            <div class="row mb-3">
              <h3 class="col-xs-12 mb-4">Summary</h3>
              <div class="col-sm-4">Name:</div>
              <div class="col-sm-8">{{ workflow.name }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4">No. of Levels:</div>
              <div class="col-sm-8">{{ workflow.noOfLevels }}</div>
            </div>
            <div class="row mb-3">
              <v-data-table
                :items="workflow.levels"
                :headers="headers"
                hide-actions
                style="width: 100%"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.name }}</td>
                  <td class="text-xs-left">{{ props.item.noOfRequiredApprovals }}</td>
                  <td class="text-xs-left">{{ props.item.level }}</td>
                  <td class="text-xs-left">
                    <ol>
                      <li class="mb-1" v-for="user in props.item.approvers" :key="user.id">{{ user.fullName }}</li>
                    </ol>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-card>
          <v-btn
            color="primary"
            @click.native="saveWorkflow"
          >
            Submit
          </v-btn>

          <v-btn color="error" @click="stage = 2">Back</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      stage: 0,
      workflow: {
        name: '',
        noOfLevels: 1,
        levels: []
      },
      stageForms: {
        validOne: null,
        validTwo: null
      },
      workflowRules: {
        name: [
          v => {
            return (v && v.trim().length > 2) || 'Must have at least 3 characters'
          }
        ],
        noOfLevels: [
          v => {
            return Number(v) > 0 || 'Value must be greater than 0'
          }
        ]
      },
      workflowLevelRules: [],
      headers: [
        { text: 'Title of Level', sortable: false, value: 'name' },
        { text: 'No. of Required Approvals', sortable: false, value: 'noOfRequiredApprovals' },
        { text: 'Level', sortable: false, value: 'level' },
        { text: 'Approvers', sortable: false, value: '' }
      ],
      usersToSelectFrom: {}
    }
  },
  methods: {
    goToStageTwo () {
      if (this.$refs.formOne.validate()) {
        if (this.workflow.noOfLevels !== this.workflow.levels.length) {
          this.workflow.levels = []
          this.workflowLevelRules = []
          for (let i = 0; i < this.workflow.noOfLevels; i++) {
            this.workflow.levels.push({
              name: '',
              approvers: [],
              noOfRequiredApprovals: 1,
              level: i + 1
            })
            this.workflowLevelRules.push({
              name: [
                v => (v && v.trim().length > 0) || 'This field is required'
              ],
              noOfRequiredApprovals: [
                v => (v && v > 0) || 'Value must be greater than zero'
              ],
              approversMessages: ['You need to select at least 1 approver'],
              approversHasError: true
            })
          }
        }
        this.stage = 2
      }
    },
    goToStageThree () {
      if (this.$refs.formTwo.validate()) {
        for (let level of this.workflow.levels) {
          if (level.noOfRequiredApprovals > level.approvers.length) {
            return
          }
        }
        this.stage = 3
      }
    },
    saveWorkflow () {
      this.$bus.$emit('notification-progress-start', 'Saving...')
      const data = {
        name: this.workflow.name,
        noOfLevels: this.workflow.noOfLevels
      }
      data.levels = JSON.parse(JSON.stringify(this.workflow.levels))
      data.levels.forEach((level) => {
        let approvers = []
        level.approvers.forEach(approver => {
          approvers.push({
            id: approver.id
          })
        })
        level.approvers = approvers
      })
      data.companyId = this.$store.state.userDetails.companyDetails && this.$store.state.userDetails.companyDetails.id
      this.$store.dispatch('saveApprovalWorkflow', data)
        .then(response => {
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('notification', {
            type: 'success',
            message: 'Workflow has been saved',
            action1: { label: 'OK', action: 'close' }
          })
          this.$router.push(this.localizedRoute('/approval-workflows'))
        })
        .catch(() => {
          this.$bus.$emit('notification-progress-stop')
        })
    },
    approversChanged (index) {
      this.validateApprovers(index)
      this.filterApprovers(index)
    },
    validateApprovers (index) {
      let level = this.workflow.levels[index]
      this.workflowLevelRules[index].approversMessages = []
      if (level.approvers.length < level.noOfRequiredApprovals) {
        const requiredCount = level.noOfRequiredApprovals - level.approvers.length
        let errorMessage = `You need to select at least ${requiredCount} more approver${requiredCount > 1 ? 's' : ''}`
        if (level.approvers.length === 0) {
          errorMessage = `You need to select at least ${requiredCount} approver${requiredCount > 1 ? 's' : ''}`
        }
        this.workflowLevelRules[index]
          .approversMessages
          .push(errorMessage)
        this.workflowLevelRules[index].approversHasError = true
      } else {
        this.workflowLevelRules[index].approversMessages = []
        this.workflowLevelRules[index].approversHasError = false
      }
    },
    filterApprovers () {
      for (let i = 0; i < this.workflow.noOfLevels; i++) {
        let approversInOtherLevels = new Set()
        for (let j = 0; j < this.workflow.noOfLevels; j++) {
          if (i !== j) {
            this.workflow.levels[j].approvers.forEach(approver => {
              approversInOtherLevels.add(approver.id)
            })
          }
        }
        this.usersToSelectFrom[i] = this.users.filter(user => {
          return !approversInOtherLevels.has(user.id)
        })
      }
    },
    populateUsersToSelectFrom () {
      for (let i = 0; i < this.workflow.noOfLevels; i++) {
        this.usersToSelectFrom[i] = this.users
      }
    }
  },
  computed: {
    users () {
      let users = this.$store.getters.getCompanyUsers
      users.forEach(function addFullnameToUser (user) {
        user.fullName = `${user.firstName} ${user.lastName}`
      })
      users.sort((user1, user2) => {
        if (user1.fullName > user2.fullName) {
          return 1
        } else if (user1.fullName < user2.fullName) {
          return -1
        }
        return 0
      })
      return users
    },
    approversValid () {
      for (let level of this.workflow.levels) {
        if (level.noOfRequiredApprovals > level.approvers.length) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    users: 'populateUsersToSelectFrom',
    'workflow.noOfLevels': 'populateUsersToSelectFrom'
  }
}
</script>

<style lang="scss">
.v-stepper__wrapper {
  height: 1000px;
}
</style>
