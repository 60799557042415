<template>
  <div>
    <div v-if="errorMessage">
      <p class="error-message">{{ errorMessage }}</p>
      <show-loader v-if="showLoader"/>
    </div>
    <div id="permissions">
      <div class="page-title">
        <v-card-title>
          <span class="section-title">Permissions</span>
        </v-card-title>
      </div>
      <div class="top-row row">
        <div class="caption col-sm-6 col-xs-6">
          <div class="external-search--box">
            <input type="text" placeholder="Search through roles" class="search-box" v-model="search">
            <i class="material-icons" aria-hidden="true">search</i>
          </div>
        </div>
        <div class="add-role col-sm-6 col-xs-6">
          <v-btn large color="success" @click.prevent="addNewRole"><i class="material-icons">add</i>Add role</v-btn>
        </div>
      </div>
      <div class="panel">
        <div>
          <v-card-title class="table-title">
            <h3 v-if="roles && roles.length > 0" class="no-of-roles">{{ roles && roles.length }} {{ roles && (roles.length > 1 || roles.length === 0) ? 'Roles' : 'Role' }}</h3>
          </v-card-title>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="roles"
              :search="search"
              :loading="roles.length<1"
              :rows-per-page-items="[25, 50]"
            >
              <v-progress-linear slot="progress" color="success" indeterminate/>
              <template slot="items" slot-scope="props">
                <td class="text-xs-left capitalize">{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.description }}</td>
                <td class="text-xs-center">{{ props.item.users.length }}</td>
                <td class="text-xs-center">
                  <p class="table-cta" @click="manageRole(props.item.id)">Manage role</p>
                </td>
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </div>
      </div>

      <!-- Add Role Component -->
      <add-role />
    </div>
  </div>
</template>

<script>
import ShowLoader from './ShowLoader.vue'
import { permissionsGuard } from 'theme/route-guards'
import AddRole from './AddRole'

export default {
  name: 'ViewRoles',
  components: {
    ShowLoader,
    AddRole
  },
  data () {
    return {
      // roles: [],
      showLoader: true,
      errorMessage: '',
      search: '',
      headers: [
        {
          text: 'Role Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        { text: 'Description', align: 'left', sortable: true, value: 'description' },
        { text: 'Users', align: 'center', sortable: true, value: 'users' },
        { text: 'Action', align: 'center', sortable: false, value: 'action' }
      ]
    }
  },
  methods: {
    addNewRole () {
      this.$bus.$emit('modal-show', 'modal-add-role')
    },
    assignUsers (id) {
      this.$router.push(`/company-settings/authorization-settings/roles/${id}/assign-users`)
    },
    assignPermissions (id) {
      this.$router.push(`/company-settings/authorization-settings/roles/${id}/assign-permissions`)
    },
    manageRole (id) {
      this.$router.push(`/company-settings/authorization-settings/roles/${id}/manage`)
    }
  },
  computed: {
    roles () {
      let companyExists = this.$store.state.userDetails.companyDetails
      if (companyExists) {
        return this.$store.getters.getCompanyRoles
      }
    }
  },
  mounted () {
    //  Check if user is assigned to a company
    let companyExists = this.$store.state.userDetails.companyDetails
    if (!companyExists) {
      this.errorMessage = 'You must have a company assigned to view roles'
      this.showLoader = false
    }
  },
  beforeRouteEnter (to, from, next) {
    permissionsGuard(to, from, next)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-gloo-gray: color(gloo-gray);

.no-of-roles {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

.panel {
  margin-top: 35px !important;
}

.add-role {
  text-align: end;
  margin: auto;
  @media (max-width: 767px) {
    text-align: center;
    margin: 16px auto 0;
  }

  .success {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
  }
}

.v-alert {
  color: red;
}

.primary {
  background-color:  $color-primary;
}

#delete-btn {
  background-color: #ed2020;
}

.v-btn {
  color: white
}

.capitalize {
  text-transform: capitalize;
}
</style>
