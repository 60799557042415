<template>
  <div id="checkout">
    <div class="container">
      <div class="" v-show="!orderPlaced">
        <div class="col-md-12">
          <div class="checkout-title py20 bottom-border cl-gloo-gray">
            <h2>
              {{ $t('Checkout') }}
            </h2>
          </div>
        </div>
        <div class="row py40">
          <cart-summary />
          <div class="col-lg-4 col-md-5 col-sm-12 p0" id="personal-details--block">
            <div class="checkout-card customer-details">
              <personal-details class="line relative" :is-active="activeSection.personalDetails"/>
              <location class="line relative" :is-active="activeSection.location"/>
              <delivery-method class="line relative" :delivery-location="deliveryLocation" :is-active="activeSection.deliveryMethod"/>
              <payment v-if="!punchoutActive" class="line relative" :is-active="activeSection.payment"/>

              <form method="post" :action="decodeURIComponent(url)" ref="aribaform">
                <input type="hidden" :value="base64String" name="cxml-base64" >
              </form>

              <form method="post" :action="decodeURIComponent(url)" ref="coupaform">
                <input type="hidden" :value="base64String" name="cxml-base64" >
              </form>

              <form method="post" :action="decodeURIComponent(srmData.HOOK_URL)" ref="sapsrmform" :target="srmData.target">
                <input type="hidden" name="~caller" :value="srmData.caller">
                <div v-for="(product, index) in order.products" :key="product.id">
                  <!-- Item {{ (index+1) }} -->
                  <input type="hidden" :name="`NEW_ITEM-DESCRIPTION[${index+1}]`" :value="product.name">
                  <input type="hidden" :name="`NEW_ITEM-QUANTITY[${index+1}]`" :value="product.qty">
                  <input type="hidden" :name="`NEW_ITEM-UNIT[${index+1}]`" :value="srmData.unitOfMeasure">
                  <input type="hidden" :name="`NEW_ITEM-PRICE[${index+1}]`" :value="product.price">
                  <input type="hidden" :name="`NEW_ITEM-PRICEUNIT[${index+1}]`" value="">
                  <input type="hidden" :name="`NEW_ITEM-CURRENCY[${index+1}]`" :value="currencyCode">
                  <input type="hidden" :name="`NEW_ITEM-LEADTIME[${index+1}]`" :value="srmData.leadTime">
                  <input type="hidden" :name="`NEW_ITEM-VENDORMAT[${index+1}]`" :value="product.sku">
                  <input type="hidden" :name="`NEW_ITEM-EXT_QUOTE_ID[${index+1}]`" :value="order.cart_id">
                  <input type="hidden" :name="`NEW_ITEM-EXT_PRODUCT_ID[${index+1}]`" :value="product.id">
                </div>
                <!-- add delivery fee as an item -->
                <template v-if="order.addressInformation">
                  <!-- Item {{ (lineItemsCount+1) }} -->
                  <input type="hidden" :name="`NEW_ITEM-DESCRIPTION[${lineItemsCount+1}]`" :value="deliveryMethod.name">
                  <input type="hidden" :name="`NEW_ITEM-QUANTITY[${lineItemsCount+1}]`" value="1">
                  <input type="hidden" :name="`NEW_ITEM-UNIT[${lineItemsCount+1}]`" :value="srmData.unitOfMeasure">
                  <input type="hidden" :name="`NEW_ITEM-PRICE[${lineItemsCount+1}]`" :value="deliveryMethod.deliveryFee">
                  <input type="hidden" :name="`NEW_ITEM-PRICEUNIT[${lineItemsCount+1}]`" value="">
                  <input type="hidden" :name="`NEW_ITEM-CURRENCY[${lineItemsCount+1}]`" :value="currencyCode">
                  <input type="hidden" :name="`NEW_ITEM-LEADTIME[${lineItemsCount+1}]`" :value="srmData.leadTime">
                  <input type="hidden" :name="`NEW_ITEM-VENDORMAT[${lineItemsCount+1}]`" :value="111111">
                  <input type="hidden" :name="`NEW_ITEM-EXT_QUOTE_ID[${lineItemsCount+1}]`" :value="order.cart_id">
                  <input type="hidden" :name="`NEW_ITEM-EXT_PRODUCT_ID[${lineItemsCount+1}]`" :value="111111">
                </template>
              </form>
            </div>
            <div class="my20 cart-btn-holder">
              <order-review class="line relative" :is-active="activeSection.orderReview" :is-valid="isValid" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <thank-you-page
      v-show="orderPlaced"
    />
  </div>
</template>

<script>
import config from 'config'
import Checkout from 'core/pages/Checkout'
import PersonalDetails from 'theme/components/core/blocks/Checkout/PersonalDetails'
import Shipping from 'theme/components/core/blocks/Checkout/Shipping'
import Payment from 'theme/components/core/blocks/Checkout/Payment'
import OrderReview from 'theme/components/core/blocks/Checkout/OrderReview'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
import Location from 'theme/components/core/blocks/Checkout/Location'
import DeliveryMethod from 'theme/components/core/blocks/Checkout/DeliveryMethod'

export default {
  comments: true,
  data () {
    return {
      stockCheckCompleted: false,
      stockCheckOK: false,
      orderPlaced: false,
      activeSection: {
        personalDetails: false,
        shipping: false,
        location: true,
        deliveryMethod: false,
        payment: false,
        orderReview: false
      },
      order: {},
      personalDetails: {},
      deliveryLocation: {},
      deliveryMethod: {},
      payment: {},
      orderReview: {},
      cartSummary: {},
      validationResults: {
        deliveryLocation: { $invalid: true },
        deliveryMethod: { $invalid: true },
        personalDetails: { $invalid: true },
        payment: { $invalid: true }
      },
      userId: null,
      currencySign: config.i18n.currencySign,
      base64String: null,
      url: null,
      srmData: {
        HOOK_URL: '',
        caller: '',
        target: ''
      },
      currencyCode: config.i18n.currencyCode,
      lineItemsCount: 0
    }
  },
  components: {
    PersonalDetails,
    Shipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage,
    Location,
    DeliveryMethod
  },
  mixins: [Checkout],
  created () {
    this.$bus.$on('checkout-after-locationDetails', this.onAfterLocationDetails)
    this.$bus.$on('checkout-after-deliveryMethod', this.onAfterDeliveryMethod)
    this.$bus.$on('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$on('checkout-before-sendToERP', this.onBeforeSendToERP)
  },
  methods: {
    activateHashSection () {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.$bus.$emit('notification', {
          type: 'warning',
          message: 'Shopping cart is empty. Please add some products before entering Checkout',
          action1: { label: 'OK', action: 'close' }
        })
        this.$router.push(this.localizedRoute('/'))
        return
      }
      if (typeof window !== 'undefined') {
        var urlStep = window.location.hash.replace('#', '')
        if (this.activeSection.hasOwnProperty(urlStep) && this.activeSection[urlStep] === false) {
          this.activateSection(urlStep)
        } else if (urlStep === '') {
          this.activateSection('location')
        }
      }
    },
    onBeforeSendToERP (receivedData, validationResult) {
      // check if the cart total is up to the minimum order amount for the location
      let deliveryLocation = this.$store.state.checkout.deliveryLocation
      let { minimumOrderAmount } = deliveryLocation

      if (!this.isUpToMinimumOrderAmount(minimumOrderAmount)) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: `The total cost of items in the cart is not up to the minimum order amount (${this.currencySign}${minimumOrderAmount}) for the location`,
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$bus.$emit('notification-progress-start', 'Sending to ERP...')
      const erpType = this.$store.getters['getErpType']
      if (erpType === 'ariba') {
        this.processAribaPunchout()
      }
      if (erpType === 'coupa') {
        this.processCoupaPunchout()
      }
      if (erpType === 'sap-srm') {
        this.processSAPSRMPunchout()
      }
    },
    processAribaPunchout () {
      this.$store.dispatch('processAribaPunchout', { order: this.prepareOrder() })
        .then(({ data }) => {
          if (data.code === 200) {
            const { url, base64String } = data.result
            this.url = url
            this.base64String = base64String
            // clears the cart locally and disables cart on magento before returning to ERP
            this.$store.dispatch('order/disableCart')
              .then(() => {
                this.$store.dispatch('clearCart')
                  .then(() => {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('after-punchout-action')
                    this.$refs.aribaform.submit()
                  })
              })
              .catch(error => {
                this.$bus.$emit('notification-progress-stop')
                this.$bus.$emit('notification', {
                  type: 'error',
                  message: error.message,
                  action1: { label: 'OK', action: 'close' }
                })
              })
          } else {
            this.$bus.$emit('notification-progress-stop')
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'Something went wrong',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = error.message
          if (error.response && error.response.data) {
            errorMessage = error.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    processCoupaPunchout () {
      this.$store.dispatch('processCoupaPunchout', { order: this.prepareOrder() })
        .then(({ data }) => {
          if (data.code === 200) {
            const { url, base64String } = data.result
            this.url = url
            this.base64String = base64String
            // clears the cart locally and disables cart on magento before returning to ERP
            this.$store.dispatch('order/disableCart')
              .then(() => {
                this.$store.dispatch('clearCart')
                  .then(() => {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('after-punchout-action')
                    this.$refs.coupaform.submit()
                  })
              })
              .catch(error => {
                this.$bus.$emit('notification-progress-stop')
                this.$bus.$emit('notification', {
                  type: 'error',
                  message: error.message,
                  action1: { label: 'OK', action: 'close' }
                })
              })
          } else {
            this.$bus.$emit('notification-progress-stop')
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'Something went wrong',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = error.message
          if (error.response && error.response.data) {
            errorMessage = error.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    processSAPSRMPunchout () {
      this.$store.dispatch('processSAPSRMPunchout', { order: this.prepareOrder() })
        .then(({ data }) => {
          if (data.code === 200) {
            const { order, srmData } = data.result
            this.srmData = srmData
            this.order = order
            this.lineItemsCount = order.products.length

            // clears the cart locally and disables cart on magento before returning to ERP
            this.$store.dispatch('order/disableCart')
              .then(() => {
                this.$store.dispatch('clearCart')
                  .then(() => {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('after-punchout-action')
                    this.$refs.sapsrmform.submit()
                  })
              })
              .catch(error => {
                this.$bus.$emit('notification-progress-stop')
                this.$bus.$emit('notification', {
                  type: 'error',
                  message: error.message,
                  action1: { label: 'OK', action: 'close' }
                })
              })
          } else {
            this.$bus.$emit('notification-progress-stop')
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'Something went wrong',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = error.message
          if (error.response && error.response.data) {
            errorMessage = error.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    onAfterPersonalDetails (receivedData, validationResult) {
      this.personalDetails = receivedData
      this.validationResults.personalDetails = validationResult
      this.activateSection('location')
      this.savePersonalDetails()
    },
    onAfterLocationDetails (receivedData, validationResult) {
      let userPhoneNumber = this.$store.state.userDetails.phoneNumber
      this.deliveryLocation = receivedData
      this.deliveryLocation.locationAddressId.phone = userPhoneNumber
      this.validationResults.deliveryLocation = validationResult
      this.activateSection('deliveryMethod')
      this.saveDeliveryLocation()
    },
    onAfterDeliveryMethod (receivedData, validationResult) {
      this.deliveryMethod = receivedData
      this.validationResults.deliveryMethod = validationResult
      if (this.punchoutActive) {
        this.activateSection('orderReview')
      } else {
        this.activateSection('payment')
      }
      this.saveDeliveryMethod()
    },
    saveDeliveryMethod () {
      this.$store.dispatch('checkout/saveDeliveryMethod', this.deliveryMethod)
    },
    saveDeliveryLocation () {
      this.$store.dispatch('checkout/saveDeliveryLocation', this.deliveryLocation)
    },
    onBeforePlaceOrder (userId) {
      if (userId) {
        this.userId = userId.toString()
      }
      this.placeOrder()
    },
    prepareOrder () {
      let street = [this.deliveryLocation.locationAddressId.streetAddress]
      if (this.deliveryLocation.locationAddressId.addressLine2) {
        street.push(this.deliveryLocation.locationAddressId.addressLine2)
      } else {
        street.push('n/a')
      }
      this.order = {
        companyId: this.$store.state.userDetails.companyDetails && this.$store.state.userDetails.companyDetails.id,
        linkedOrderId: this.$store.state.order.linkedOrderId,
        user_id: this.$store.state.user.current ? this.$store.state.user.current.id.toString() : (this.userId ? this.userId : ''),
        cart_id: this.$store.state.cart.cartServerToken ? this.$store.state.cart.cartServerToken : '',
        products: this.$store.state.cart.cartItems,
        addressInformation: {
          shippingAddress: {
            region: this.deliveryLocation.locationAddressId.state,
            region_id: 0,
            country_id: this.deliveryLocation.locationAddressId.country,
            street: street,
            company: '', // TODO: Fix me! https://github.com/DivanteLtd/vue-storefront/issues/224
            telephone: this.deliveryLocation.locationAddressId.phone.toString(),
            postcode: this.deliveryLocation.locationAddressId.zipcode.toString(),
            city: this.deliveryLocation.locationAddressId.city,
            firstname: this.personalDetails.firstName,
            lastname: this.personalDetails.lastName,
            email: this.personalDetails.emailAddress,
            region_code: ''
          },
          billingAddress: {
            region: this.deliveryLocation.locationAddressId.state,
            region_id: 0,
            country_id: this.deliveryLocation.locationAddressId.country,
            street: [this.deliveryLocation.locationAddressId.streetAddress, this.deliveryLocation.locationAddressId.addressLine2],
            company: '',
            telephone: this.deliveryLocation.locationAddressId.phone.toString(),
            postcode: this.deliveryLocation.locationAddressId.zipcode.toString(),
            city: this.deliveryLocation.locationAddressId.city,
            firstname: this.personalDetails.firstName,
            lastname: this.personalDetails.lastName,
            email: this.personalDetails.emailAddress,
            region_code: '',
            vat_id: this.payment.taxId
          },
          shipping_method_code: this.deliveryMethod.shippingMethodCode,
          shipping_carrier_code: this.deliveryMethod.shippingCarrierCode,
          payment_method_code: this.payment.code,
          payment_method_additional: this.payment.paymentMethodAdditional,
          shippingExtraFields: this.shipping.extraFields,
          deliveryLocation: this.deliveryLocation,
          deliveryMethod: this.deliveryMethod,
          paymentMethod: this.payment
        }
      }
      return this.order
    },
    placeOrder () {
      // check if the cart total is up to the minimum order amount for the location
      let { minimumOrderAmount } = this.deliveryLocation

      if (!this.isUpToMinimumOrderAmount(minimumOrderAmount)) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: `The total cost of items in the cart is not up to the minimum order amount (${this.currencySign}${minimumOrderAmount.toLocaleString()}) for the location`,
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.checkConnection({ online: typeof navigator !== 'undefined' ? navigator.onLine : true })
      if (this.checkStocks()) {
        this.$bus.$emit('notification-progress-start', 'Submitting order')
        this.$store.dispatch('checkout/placeOrder', { order: this.prepareOrder() })
      } else {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Some of the ordered products are not available!',
          action1: { label: 'OK', action: 'close' }
        })
      }
    },
    isUpToMinimumOrderAmount (minimumOrderAmount) {
      let cartItems = this.$store.state.cart.cartItems
      let total = 0.00
      cartItems.forEach(item => {
        total += item.price * item.qty
      })
      if (total < minimumOrderAmount) {
        return false
      }
      return true
    },
    onAfterPlaceOrder (order) {
      this.$bus.$emit('notification-progress-stop')
      if (!order) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'An error occured while submitting order',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.orderPlaced = true
    }
  },
  computed: {
    punchoutActive () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails
        ? this.$store.state.userDetails.companyDetails.punchoutActive
        : false
    },
    isValid () {
      if (this.punchoutActive) {
        return !!(this.deliveryLocation.id && this.deliveryMethod.id)
      } else {
        return !!(this.deliveryLocation.id && this.deliveryMethod.id && this.payment.id)
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~theme/css/base/text';
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $bg-secondary: color(secondary, $colors-background);
  $color-tertiary: color(tertiary);
  $color-secondary: color(secondary);
  $color-error: color(error);
  $color-white: color(white);
  $color-black: color(black);
  $gloo-main: color(gloo-main);

  #checkout {
    padding-bottom: 50px;

    .number-circle {
      width: 35px;
      height: 35px;

      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
    .radioStyled {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 4px;
        left: 4px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 3px solid $color-secondary;

        &:after {
          content: "";
          position: absolute;
          display: block;
          // background: #e0e0e0;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }

      input:checked ~ .checkmark:after {
        background: $bg-secondary;
      }

      input:checked ~ .checkmark {
        border: 3px solid $bg-secondary;
      }
    }

    .checkout-card {
      -webkit-box-shadow: 0 1px 3px 0 #D7D7E0;
      box-shadow: 0 1px 3px 0 #D7D7E0;
      overflow-y: auto;
      background-color: #ffffff;
      div.col-md-10 {
        h3 {
          // margin-bottom: 20px;
        }
      }

      label.radioStyled {
        margin-left: 0;
      }
    }

    div.px20 div.col-md-10 h3 {
      margin-left: 25px;
    }

    .vl {
      height: 40px;
      border-right: 1px solid black;
      width: 45px;
    }

    .material-icons {
      padding: 5px;
    }

    .grey {
      color: grey;
      background-color: transparent !important;
    }

    .circle {
      background-color: grey;
      color: white;
      border-radius: 50%;
    }

    input[type=radio]:checked {
      background-color: red;
    }

    .btn {
      padding: 15px 50px 15px 50px;
      border: 1px;
    }

    .btn-primary {
      background-color: #4F9221;
      color: white;
      font-weight: 700;
      font-size: 16px;
    }

    .text-primary {
      color: #4F9221;
    }

    label {
      margin-left: 25px;
    }

    .icon-active {
      background-color: #4F9221;
    }

    #cart-products {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 800px;
      box-shadow: 0 1px 3px 0 #D7D7E0;
    }

    #cart-products::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    #cart-products::-webkit-scrollbar-thumb {
      background-color: #919aa3;
      max-height: 300px;
    }

    #cart-products::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #f5f5f5;
    }

    .bottom-border {
      border-bottom: 1px solid #eaedef;
    }

    .top-border {
      border-top: 1px solid #eaedef;
    }
    .product-quantity {
      float: left;
      width: 53%;
    }

    .product-price {
      color: #4F9221;
      text-align: right;
      margin-right: 20px;
      width: 40%;
    }

    div.col-xs-12.col-sm-9.col-md-11 {
      padding-left: 0;

      div.col-xs-12.col-md-12 {
        padding-left: 0
      }
    }

    @media (max-width: 1225px) {
      .product-price {
        text-align: left;
      }
    }

    @media (max-width: 762px) {
      #cart-summary {
        margin-top: 30px;
      }
    }
  }

  .line {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 37px;
      z-index: -1;
      width: 1px;
      height: 100%;
      background-color: $bg-secondary;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .checkout-title {
    @media (max-width: 767px) {
      h1 {
        font-size: 36px;
        font-family: Roboto;
        line-height: 42px;
        text-align: left;
      }
    }
  }
  p, span {
    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
    }
  }

  .customer-details--title {
    color: #212B36;
    font-family: Larsseit;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    text-transform: uppercase;
    // padding-bottom: 20px;
  }
  button {
    border-radius: 5px;
  }
  select {
  border: 2px solid $bg-secondary;
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.btn.dropdown-btn {
  border: 1px solid $gloo-main !important;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 4px;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
  padding: 0 15px !important;
  height: 50px;
  position: relative;
}

i.caret.material-icons {
  color: #BBB;
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  font-size: 20px;
}

.dropdown-menu {
  display: none;
  text-align: left;
  border: 1px solid rgba(90, 122, 190, 0.08);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 10px 20px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
  padding: 0;
  margin-top: 5px;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-menu li {
  // color: black;
  color: #253858;
  padding: 16px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px;
  border: none;
  border-top: 0.5px solid #E3E8EE;
}

.is-visible {
  display: block;
}
.location-list {
  margin-bottom: unset;
}
.location-list:first-child {
  margin-bottom: 5px;
}
.dropdown-link .sort-options:hover {
  color: $gloo-main;
}
.button-full.save-changes {
  font-size: 14px;
  padding: 10px 0;
  @media (max-width: 767px) {
    padding: 8px 0;
    font-size: 12px;
  }
}
.customer-details--title--block {
  margin: auto 0;
}
.customer-details--section  {
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
<style scoped lang="scss">
  .customer-details, .cart-summary--block {
    /* padding: unset !important; */
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  #checkout .checkout-card {
    padding: 20px !important;
    @media (min-width: 767px) {
      padding: unset !important;
    }
  }
  @media (min-width: 1024px) {
    #personal-details--block {
      padding-left: 3%;
    }
  }
</style>
