<template>
  <div
    class="searchpanel fixed mw-100 bg-cl-white"
    :class="{ active: isOpen }"
    data-testid="searchPanel"
  >
    <div class="row">
      <div class="col-md-12 end-xs">
        <i
          class="material-icons p15 pointer cl-black"
          @click="closeSearchpanel"
          data-testid="closeSearchPanel"
        >
          close
        </i>
      </div>
    </div>
    <div class="col-md-12 end-xs">
      <label for="search" class="visually-hidden">
        {{ 'Search' }}
      </label>
      <input
        ref="search"
        id="search"
        v-model="search"
        class="py10 h4 cl-gloo-accent2 product-search brdr-bottom-1 border-line"
        :placeholder="'Type what you are looking for...'"
        type="text"
      >
      <span v-if="products.length > 0" class="product-count py10 start-xs"> {{ products.length }} {{ products.length > 1 ? 'Products' : 'Product' }} Found</span>
      <div class="col-md-12 end-xs brdr-none brdr-bottom-1 border-line"/>
    </div>
    <div class="col-md-12 product-listing pl35 pt20 row">
      <product-tile :key="product.id" v-for="product in products" :product="product"/>
      <transition name="fade" v-if="emptyResults">
        <div class="no-results relative center-xs">
          <h3>No product found</h3>
          <p>We searched far and wide and couldn’t find any <br> product matching your search.</p>
        </div>
      </transition>
      <transition name="fade" v-else-if="search.length < 1">
        <div class="no-results relative center-xs">
          <h3>Looking for a product?</h3>
          <p>Enter search text</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SearchPanel from 'core/components/blocks/SearchPanel/SearchPanel'
import ProductTile from 'theme/components/core/ProductTile'
import bodybuilder from 'bodybuilder'
import config from 'config'
import _ from 'lodash'

export default {
  components: {
    ProductTile
  },
  data () {
    return {
      search: ''
    }
  },
  mixins: [
    SearchPanel
  ],
  watch: {
    search: function (val) {
      if (this.searchText !== val) {
        this.$store.dispatch('setSearchText', val)
      }
      _.debounce(() => {
        this.makeSearch(val)
      }, 500)()
    },
    searchText: function (val) {
      this.search = val
    }
  },
  computed: {
    searchText () {
      return this.$store.getters.getSearchText
    }
  },
  methods: {
    closeSearchpanel () {
      this.$store.commit('ui/setSidebar', false)
      this.$store.commit('ui/setMicrocart', false)
      this.$store.commit('ui/setSearchpanel', false)
      this.$bus.$emit('search-status-changed', false)
    },
    makeSearch: function (val) {
      let queryText = val
      let start = 0
      let size = 18

      let query = bodybuilder()
        .query('range', 'visibility', { 'gte': 3, 'lte': 4 })
        .andQuery('range', 'status', { 'gte': 0, 'lt': 2 }/* 2 = disabled, 4 = out of stock */)

      if (config.products.listOutOfStockProducts === false) {
        query = query.andQuery('match', 'stock.is_in_stock', true)
      }

      query = query.andQuery('bool', b => b.orQuery('match_phrase_prefix', 'name', { query: queryText, boost: 3, slop: 2 })
        .orQuery('match_phrase', 'category.name', { query: queryText, boost: 1 })
        .orQuery('match_phrase', 'short_description', { query: queryText, boost: 1 })
        .orQuery('match_phrase', 'description', { query: queryText, boost: 1 })
        .orQuery('bool', b => b.orQuery('terms', 'sku', queryText.split('-'))
          .orQuery('terms', 'configurable_children.sku', queryText.split('-'))
          .orQuery('match_phrase', 'sku', { query: queryText, boost: 1 })
          .orQuery('match_phrase', 'configurable_children.sku', { query: queryText, boost: 1 }))
      )

      query = query.build()

      this.$store.dispatch('product/list', { query, start, size, updateState: false }).then((resp) => {
        if (resp.items) {
          let foundProducts = resp.items.filter(item => {
            return (!!item.stock.backorders)
          })
          this.products = foundProducts
          this.emptyResults = this.search.length > 0 && foundProducts.length < 1
        }
      }).catch(function (err) {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-solitude: color(gloo-solitude);
$color-gloo-gray: color(gloo-gray);

.searchpanel {
  height: 94vh;
  width: 900px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  transform: translateX(180%);
  transition: transform 300ms $motion-main;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 65px auto;

  &.active {
    transform: translateX(0)
  }

  .border-line {
    border-bottom-color: $color-gloo-solitude;
    margin: 0 20px;
  }

  #search {
    display: none;
  }

  .product-count {
    color: #637381;
    display: block;
    margin: 0 27px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: 13px;
  }

  .product {
    width: 31%;
    padding: 30px 15px;
    margin: 0.9em;;
  }

  input {
    width: calc(100% - 40px);

    &:focus {
      outline: none;
    }
  }

  .no-results {
    top: 120px;
    width: 100%;

    h3 {
      color:$color-gloo-gray;
      font-size: 20px;
      font-weight: 550;
      line-height: 27px;
      margin-bottom: 15px;
    }

    p {
      color: #637381;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }
}

i {
  opacity: 0.6;
}

i:hover {
  opacity: 1;
}

@media only screen and (max-width: 50em) {
  .searchpanel .product {
    width: 47%;
    box-sizing: border-box;
  }
  .product-listing {
    padding-left: 8px !important;
  }
}

@media (max-width: 767px) {
  .searchpanel #search {
    display: block;
  }
}

@media only screen and (max-width: 550px) {
  .searchpanel .product {
    width: 100%;
  }
  .product-listing {
    padding-left: 8px !important;
  }
}
</style>
