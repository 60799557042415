<template>
  <div id="location-settings">
    <div class="page-title">
      <v-card-title>
        <span class="section-title">{{ $t('Locations') }}</span>
      </v-card-title>
    </div>
    <div class="top-row row">
      <div class="col-sm-6 col-xs-6">
        <div class="external-search--box">
          <input type="text" placeholder="Search through locations" class="search-box" v-model="search">
          <i class="material-icons" aria-hidden="true">search</i>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 add-location">
        <v-btn large color="success" @click.prevent="addNewLocation"><i class="material-icons">add</i>Add Location</v-btn>
      </div>
    </div>
    <div class="panel">
      <div>
        <v-card-title class="table-title">
          <h3 v-if="result && result.length > 0" class="location-total">{{ result && result.length }} {{ result && (result.length >1 || result.length === 0) ? 'Locations' : 'Location' }}</h3>
        </v-card-title>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="result"
            :search="search"
            :loading="showLoader"
            :rows-per-page-items="[25, 50]"
          >
            <v-progress-linear slot="progress" color="success" indeterminate/>
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ getLocationAddress(props.item) }}</td>
              <td class="text-xs-left location-status"><span class="location-status-chip" :class="[props.item.isActive ? activeClass : inactiveClass]">{{ props.item.isActive ? 'Active' : 'Inactive' }}</span></td>
              <td class="text-xs-left">
                <p class="table-cta" @click="showLocation(props.item.id)">View Location</p>
              </td>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <add-location />
  </div>
</template>
<script>
import ShowLoader from './ShowLoader.vue'
import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import AddLocation from './AddLocation'

export default {
  name: 'LocationSettings',
  data () {
    return {
      activeClass: 'active',
      inactiveClass: 'inactive',
      showLoader: false,
      companyId: null,
      result: [],
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        { text: 'Address', align: 'left', sortable: true, value: 'address' },
        { text: 'Status', align: 'left', sortable: true, value: 'status' },
        { text: 'Action', align: 'left', sortable: false, value: 'action' }
      ]
    }
  },
  components: {
    ShowLoader,
    AddLocation
  },
  methods: {
    showLocation (locationId) {
      this.$router.push(`/company-settings/location-settings/${locationId}`)
    },
    addNewLocation () {
      // this.$router.push(this.localizedRoute('/company-settings/location-settings/add-location'))
      this.$bus.$emit('modal-show', 'modal-add-location')
    },
    getLocationAddress (location) {
      let maxStringLength = 80
      let ellipses = '.....'
      let locationAddress = location.locationAddressId.streetAddress + ', ' + location.locationAddressId.addressLine2 +
      ' ' + location.locationAddressId.city + ', ' + location.locationAddressId.state
      if (locationAddress.length > maxStringLength) {
        return locationAddress.substring(0, maxStringLength - ellipses.length) + ellipses
      }
      return locationAddress
    }
  },
  mounted () {
    this.result = this.$store.getters.getDeliveryLocations
    if (this.hasPermission('view all', 'location')) {
      this.result = this.$store.getters.getCompanyLocationDetails
    }
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('view', 'location')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
$color-success: color(gloo-success);
$color-error: color(gloo-danger);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-gloo-gray: color(gloo-gray);

.page-title {
  padding: 0px 0px 16px !important;
}

.page-title .v-card__title {
  padding: 16px 0px !important;
}

.page-title .section-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  color: $color-gloo-gray;
}
.panel {
  margin-top: 35px !important;
}

.location-total {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    text-align: left;
    padding: 20px;
    vertical-align: middle;

    @media (max-width: 1199px) {
      padding: 10px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.hide-on-xs {
      @media (max-width: 767px) {
        display: none;
      }
    }

  }

  i {
    vertical-align: middle;
  }

}

.add-location {
  text-align: end;
  margin: auto;

  .success {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
  }
}

.location-status-chip {
  letter-spacing: 0.21px;
  line-height: 12px;
  width: 120px;
  text-align: center !important;
  align-items: center;
  border-radius: 28px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 38px;
  height: 32px;
  margin: auto;
  align-content: center;
}

.active {
  background-color: $color-completed;
  color: $color-success;
}

.inactive {
  background-color: $color-failed;
  color: $color-error;
}

.table-cta {
  color: $color-success;
  margin-bottom: 0;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;

  &:hover {
    cursor: pointer;
  }
}
</style>
