<template>
  <div class="sidebar">
    <h4 class=" filter cl-gloo-gray">
      {{ $t('Filter by:') }}
    </h4>
    <div v-for="(filter, filterIndex) in filters"
         :key="filterIndex" v-if="filter.length"
         class="cl-gloo-footer-text">
      <h4 v-if="filterIndex==='price'">
        {{ $t(filterIndex + '_filter') }}
      </h4>

      <!-- <div v-if="filterIndex==='color'">
        <color-selector
          context="category"
          :attribute_code="color"
          code="color"
          v-for="(color, index) in filter"
          :key="index"
          :id="color.id"
          :label="color.label"
        />
      </div> -->
      <!-- <div v-else-if="filterIndex==='size'">
        <size-selector
          context="category"
          :attribute_code="size"
          code="size"
          class="size-select mr10 mb10"
          v-for="(size, index) in filter"
          :key="index"
          :id="size.id"
          :label="size.label"
        />
      </div> -->
      <div v-if="filterIndex==='price'" class="cl-gloo-gray">
        <price-selector
          context="category"
          :attribute_code="price"
          class="price-select mb10 block"
          code="price"
          v-for="(price, index) in filter"
          :key="index"
          :id="price.id"
          :from="price.from"
          :to="price.to"
          :content="price.label"
          :label="price.label"
        />
      </div>
      <!-- <div v-else>
        <generic-selector
          context="category"
          :attribute_code="filter.attribute_code"
          class="price-select mb10 block"
          :code="filterIndex"
          v-for="(option, index) in filter"
          :key="index"
          :id="option.id"
          :label="option.label"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import Sidebar from 'core/components/blocks/Category/Sidebar'

import ColorSelector from 'theme/components/core/ColorSelector'
import SizeSelector from 'theme/components/core/SizeSelector'
import PriceSelector from 'theme/components/core/PriceSelector'
import GenericSelector from 'theme/components/core/GenericSelector'

export default {
  components: {
    ColorSelector,
    SizeSelector,
    PriceSelector,
    GenericSelector
  },
  mixins: [Sidebar]
}
</script>

<style lang="scss" scoped>
  // h1, h2, h3, h4, h5, h6 {
  //   font-family: 'Roboto';
  // }
  .sidebar {
    // margin-top: 20px;
    // box-shadow: 0px 0.71px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    // background-color: #ffffff
  }
  .filter {
    font-size: 32px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    // color: #333333;
    // font-size: 20px;
    margin: 0 0 10px;
    // font-weight: normal;
  }
  h4 {
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin: 0 0 15px;
  }
  // .filter, h4 {
  //   margin: 0 0 10px;
  // }
  price-selector {
    font-size: 18px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
</style>
