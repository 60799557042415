<template>
  <div class="mb30 mt30">
    <div class="row mb30 mt30">
      <div class="col-md-4 col-xs-5 section-title">
        <span class="section-title">Assign Users</span>
      </div>
      <div class="external-search--box col-md-8 col-xs-7 align-right">
        <input type="text" placeholder="Search Users" class="search-box" v-model="search">
        <i class="material-icons" aria-hidden="true">search</i>
      </div>
    </div>
    <div class="panel">
      <v-card-title class="table-title p20">
        <div class="row w-100">
          <span class="headline col-md-6 col-xs-6">{{ selectedUsers.length }} {{ selectedUsers.length > 1 ? 'Users' : 'User' }} Assigned</span>
          <div class="col-md-6 col-xs-6 align-right">
            <v-btn @click="assignUsers()" color="success" dark class="mb-2">Save Changes</v-btn>
          </div>
        </div>
        <v-spacer/>
      </v-card-title>
      <v-card class="">
        <v-data-table
          :headers="headers"
          :items="usersData"
          :loading="users.length<1"
          :search="search"
          :rows-per-page-items="[25, 50]"
        >
          <v-progress-linear slot="progress" color="blue" indeterminate/>
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <v-checkbox
                  :value="props.item.id"
                  v-model="selectedUsers"
                  primary
                  hide-details
                />
              </td>
              <td>{{ props.item.fullName }}</td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            No Data.
          </v-alert>
        </v-data-table>
        <!-- <v-btn @click="assignUsers()" color="primary" dark class="mb-2">Save Changes</v-btn> -->
      </v-card>
    </div>
  </div>
</template>
<script>
import ShowLoader from './ShowLoader.vue'
import { permissionsGuard } from 'theme/route-guards'

export default {
  name: 'AssignUsersToRole',
  data () {
    return {
      selectedRole: null,
      users: [],
      showLoader: true,
      selectedUsers: [],
      companyId: null,
      search: '',
      headers: [
        { text: '', sortable: false, width: '100px' },
        { text: 'Users', sortable: true, value: 'fullName' }
      ]
    }
  },
  computed: {
    usersData () {
      return this.users.map((item, index) => {
        item.fullName = item.firstName + ' ' + item.lastName
        return item
      })
    }
  },
  methods: {
    inArray (array, item) {
      return array.find(obj => {
        return obj === item
      })
    },
    assignUsers () {
      this.$bus.$emit('notification-progress-start', 'Saving ...')
      let self = this
      let usersToBeUpdated = []
      this.users.forEach(user => {
        let data = {
          userId: user.id,
          assign: self.inArray(self.selectedUsers, user.id) ? 1 : 0
        }
        usersToBeUpdated.push(data)
      })
      this.$store.dispatch('assignUsersToRole', {
        userIds: usersToBeUpdated,
        companyId: this.companyId,
        roleId: this.selectedRole.id
      })
        .then(({data}) => {
          this.$bus.$emit('notification-progress-stop')
          if (data.code === 200) {
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'Operation successful',
              action1: { label: 'OK', action: 'close' }
            })
            // clear the user state variable as it may have changed to force a refetch the next time it is to be accessed
            this.$store.dispatch('setCompanyUsers', null)
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while saving details',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    populateSelectedUsers () {
      let usersWithSelectedRole = []
      let self = this
      self.users.forEach(user => {
        if (user.roleId === self.selectedRole.id) {
          usersWithSelectedRole.push(user.id)
        }
      })
      self.selectedUsers = usersWithSelectedRole
    }
  },
  mounted () {
    let selectedRoleId = this.$route.params['roleId']
    if (!selectedRoleId) {
      this.$router.push('/page-not-found')
    }

    let self = this
    this.companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : null
    if (this.companyId) {
      this.$store.dispatch('fetchCompanyRoles', this.companyId)
        .then(({ data }) => {
          if (data.code === 200) {
            let roles = data.result ? data.result.data.roles : []
            self.selectedRole = roles.find(obj => {
              return obj.id === selectedRoleId
            })
            if (!self.selectedRole) {
              this.$router.push('/page-not-found')
            }

            // get users in company
            // check state for presence of users first
            let users = this.$store.state.userDetails.companyUsers
            if (!users) {
              this.$store.dispatch('fetchCompanyUsers', self.companyId)
                .then(({ data }) => {
                  this.showLoader = false
                  if (data.code === 200) {
                    this.users = data.result ? data.result.data.users : []
                    this.$store.dispatch('setCompanyUsers', this.result)

                    // add the ids of users whose roleId corresponds to the selected role to the selectedUsers property
                    this.populateSelectedUsers()
                  }
                })
                .catch(error => {
                  this.showLoader = false
                  console.error(error)
                  if (error.response && error.response.data) {
                    this.$bus.$emit('notification', {
                      type: 'error',
                      message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
                      action1: { label: 'OK', action: 'close' }
                    })
                  } else {
                    this.$bus.$emit('notification', {
                      type: 'error',
                      message: 'An error occured while retrieving users',
                      action1: { label: 'OK', action: 'close' }
                    })
                  }
                })
            } else {
              this.users = users
              // add the ids of users whose roleId corresponds to the selected role to the selectedUsers property
              this.populateSelectedUsers()
              this.showLoader = false
            }
          }
        })
        .catch(error => {
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while retrieving role',
              action1: { label: 'OK', action: 'close' }
            })
          }
          this.showLoader = false
        })
    }
  },
  components: {
    ShowLoader
  },
  beforeRouteEnter (to, from, next) {
    permissionsGuard(to, from, next)
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    text-align: left;
    padding: 20px;

    @media (max-width: 1199px) {
      padding: 10px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.hide-on-xs {

      @media (max-width: 767px) {
        display: none;
      }

    }

  }

  i {
    vertical-align: middle;
  }

}

.panel {
  margin-top: 20px !important;
}

.section-title {
  margin: auto 0;
}

.external-search--box i {
  left: 30%;
}

@media (max-width: 900px) {
  .search-box {
    width: 100%;
  }

  .external-search--box i {
    left: 0;
  }

  .section-title {
    font-size: 20px;
  }

  .headline {
    font-size: 20px !important;
  }
}
</style>
