<template>
  <div class="dashboard">
    <div v-if="ordersHistory && ordersHistory.length < 1">
      <div class="no-history">
        <img src="/assets/transparent-chart.png">
        <h3>{{ $t('You have nothing to view on your dashboard!') }}</h3>
        <p>{{ $t('Place an order and watch this space transform to show your order history and purchase pattern.') }}</p>
        <v-btn color="success" large :to="localizedRoute('/#company-categories')">{{ $t('Browse Catalog') }}</v-btn>
        <v-btn color="success" large :to="localizedRoute('/#company-categories')">{{ $t('Pull Catalog') }}</v-btn>
      </div>
    </div>
    <div v-else>
      <div id="order-statistics" class="row panel table-title">
        <div id="report" class="pb20 col-xs-12 col-sm-12">
          <v-card-title class="table-title">
            <span class="section-title">{{ $t('Report') }}</span>
          </v-card-title>
          <aside>
            <div class="row">
              <div class="reports-block col-sm-4">
                <div class="report-icon">
                  <img src="/assets/total-amount-icon.svg" class="img-responsive">
                </div>
                <div class="total-amount">
                  <p v-if="ordersHistory && ordersHistory.length < 1" class="reports-block--value">₦0</p>
                  <p v-else class="reports-block--value">{{ totalOrdersAmount | number | currency }}</p>
                  <p class="reports-description">{{ $t('Total Amount') }}</p>
                </div>
              </div>
              <div class="reports-block col-sm-4">
                <div>
                  <p class="reports-block--value">{{ ordersHistory && ordersHistory.length }}</p>
                  <p class="reports-description">{{ $t('No of orders') }}</p>
                </div>
              </div>
              <div class="reports-block col-sm-4">
                <div>
                  <p v-if="ordersHistory && ordersHistory.length < 1" class="reports-block--value">₦0</p>
                  <p class="reports-block--value">{{ averageOrderValue | number | currency }}</p>
                  <p class="reports-description">{{ $t('Average order value') }}</p>
                </div>
              </div>
            </div>
          </aside>

          <!-- Orders Chart -->
          <div v-if="ordersHistory && ordersHistory.length > 0" id="purchase-graph">
            <orders-chart/>
          </div>
          <div v-else class="no-history">
            <img src="/assets/colorfulchart.jpg">
            <h3>{{ $t("Looks like you don't have any approved Orders yet!") }}</h3>
            <p>{{ $t('You can view your Purchase Pattern here, but before that, we need to create a chart with your Order History.') }}</p>
            <v-btn color="success" large :to="localizedRoute('/#company-categories')">{{ $t('Browse Catalog') }}</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Order Approval Component -->
    <OrderApprovals v-if="hasPermission('approve', 'order')" parent="dashboard"/>

    <!-- My Submitted Orders Component -->
    <MySubmittedOrders />

    <!-- Order History Data Table -->
    <div id="order-history" class="panel panel-default pad-panel">
      <div>
        <v-card-title class="table-title">
          <span class="section-title">{{ $t('Order History') }}</span>
        </v-card-title>
        <v-card>
          <v-data-table
            v-if="ordersHistory && ordersHistory.length > 0"
            :pagination.sync="pagination"
            :headers="headers"
            :items="ordersHistory"
            hide-actions
            :rows-per-page-items="[25, 50]"
          >
            <template slot="items" slot-scope="props">
              <td style="cursor: pointer" class="text-xs-left show-details" @click="showOrderDetails(props.item)">{{ props.item.increment_id }}</td>
              <td class="text-xs-center">{{ formatDate(props.item.created_at) }}</td>
              <td class="text-xs-right">{{ props.item.grand_total | number | currency }}</td>
              <td class="text-xs-center order-status"><span class="order-status-chip" :class="[orderStatus[props.item.status]]">{{ props.item.status }}</span></td>
            </template>
            <template slot="footer" v-if="ordersHistory && ordersHistory.length > 0">
              <td colspan="100%">
                <v-btn large color="success" @click="showMoreOrders">See More</v-btn>
              </td>
            </template>
          </v-data-table>
          <div v-else class="no-order">
            <div class="empty-history">
              <div class="no-order-text">
                <p class="cl-gloo-catalina-blue">You currently have no order history.</p>
                <p class="cl-gloo-catalina-blue">Once you do, this is where you’ll track them.</p>
              </div>
              <div class="p15">
                <img src="/assets/no-recent-orders.svg" alt="Empty Order History" height="100px" class="img-order-history">
              </div>
              <button-outline color="gray" class="start p15" @click.native="browseCategories()">
                Start ordering
              </button-outline>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// import OrderHistory from '../Dashboard/OrderHistory'
import MyOrders from 'core/components/blocks/MyAccount/MyOrders'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from '../Form/BaseCheckbox.vue'
import OrderApprovals from './OrderApprovals.vue'
import MySubmittedOrders from './MySubmittedOrders.vue'
import OrdersChart from '../Dashboard/OrdersChart.vue'
import moment from 'moment'
import config from 'config'
import { localizedRoute } from '@vue-storefront/store/lib/multistore'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  components: {
    // OrderHistory,
    ButtonFull,
    BaseCheckbox,
    OrderApprovals,
    MySubmittedOrders,
    OrdersChart,
    ButtonOutline
  },
  data () {
    return {
      stateOrdersHistory: Object.assign({}, this.$store.state.user.orders_history),
      ordersHistory: [],
      orderStatus: {
        'disapproved': 'disapproved-order',
        'resubmitted': 'resubmitted-order',
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order'
      },
      pagination: {
        sortBy: 'created_at',
        descending: true
      },
      headers: [
        {
          text: 'Order ID',
          align: 'left',
          sortable: true,
          value: 'increment_id'
        },
        {text: 'Date', align: 'center', sortable: true, value: 'created_at'},
        {text: 'Total Amount', align: 'right', sortable: true, value: 'grand_total'},
        {text: 'Status', align: 'center', sortable: true, value: 'status'}
      ]
    }
  },
  created () {
    if (this.stateOrdersHistory) {
      this.ordersHistory = this.stateOrdersHistory.items
    } else {
      return []
    }
    this.$store.dispatch('order/fetchAllCompanyOrders', () => {})
  },
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    showMoreOrders () {
      this.$router.push(`/my-account/orders`)
    },
    showOrderDetails (order) {
      this.$router.push(localizedRoute('/my-account/orders/' + order.increment_id))
    },
    browseCategories () {
      this.$router.push(localizedRoute('/#company-categories'))
    }
  },
  filters: {
    number: function (value) {
      if (!value) return ''
      value = value.toLocaleString(config.i18n.defaultLocale)
      return value
    },
    currency: function (value) {
      if (!value) return ''
      value = config.i18n.currencySign + value
      return value
    }
  },
  mixins: [MyOrders],
  computed: {
    totalOrdersAmount () {
      let totalOrdersAmount = 0
      if (!this.ordersHistory) {
        return 0
      }
      for (let i = 0; i < this.ordersHistory.length; i++) {
        totalOrdersAmount += this.ordersHistory[i].grand_total
      }
      return totalOrdersAmount
    },
    averageOrderValue () {
      if (!this.ordersHistory) {
        return 0
      }
      let averageOrderValue = this.totalOrdersAmount / this.ordersHistory.length
      return averageOrderValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-success: color(gloo-success);
// $color-pending: color(gloo-pending);
// $color-error: color(gloo-danger);
// $color-completed: color(gloo-completed);
// $color-failed: color(gloo-failed);
// $color-pend: color(gloo-pend);

.panel {
  background-color: $color-white;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px 0 #d7d7e0 !important;
}

#dashboard {
  background-color: #fafafa;
  padding: 10px 20px;
}

#report, #order-totals {
  padding-left: 0;
  padding-right: 0;
}

#pending-approval,
#order-history,
#order-statistics {
  background-color: color(white);
}

#pending-approval,
#order-history,
#submitted-orders {
  margin: 60px 0;
}

#order-statistics {
  margin: 0 0 60px 0 !important;
}

.table-title {
  border: 1px solid #e9ebf6;
}

span.section-title {
  font-size: 24px;
  color: #253858;
  font-weight: 550;
  line-height: 29px;
  text-align: left;
}

table.v-table thead th, table.v-table tbody td, table.v-table tbody th {
  color: rgba(37, 56, 88, 0.7) !important;;
  font-weight: 400 !important;
  line-height: 18px !important;;
  width: 74px !important;
  text-align: left;
  font-size: 15px;
}

aside {
  text-align: center;
  margin: 50px 0;
  // width: 80%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.no-history {
  text-align: center;
  img {
    width: 250px;
    height: 250px;
  }
}

#purchase-graph {
  margin: 30px 0;
}

.reports-block {
  display: inline-block;
  min-width: 140px;
  // padding: 25px 10px;
  // background-color: $color-primary;
  color: color(white);
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
    padding: 40px 0;
    margin: 15px 0px;
  }
}

.reports-block:nth-child(2) {
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #d7d7e0;
}

p.reports-block--value, p.reports-description {
  margin: 0;
  letter-spacing: 1px;
  line-height: 1;
  font-weight: 500;
}

p.reports-description {
  margin-bottom: 16px;
  color: #7d84a0;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

p.reports-block--value {
  color: #353F69;
  font-size: 32px;
  font-weight: 505;
  line-height: 38px;
}

.outer-layer {
  border: 0.5px solid transparent;
  border-radius: 100%;
  padding: 50px;
  background-color: #c4c4c4;
  height: 150px;
  // width: 70%;
}

.inner-layer {
  border: 0.5px solid transparent;
  border-radius: 100%;
  height: 150px;
  text-align: center;
  background-color: color(white);
  color: #4f4f4f;
  vertical-align: middle;
}

.layer-text-holder {
  margin: 50px 10px;
}

span.layer-text {
  font-size: 14px;
  &:first-child {
    font-size: 24px;
    font-weight: 600;
  }
  &:nth-child(2) {
    display: block;
  }
}

button.accept, button.rejection {
  padding: 0 15px;
  margin: 0 3px;
  @media (min-width: 1467px) {
    margin: 0 5px;
  }
  @media (min-width: 1270px) {
    margin: 0 4px;
  }
  @media (max-width: 1180px) {
    margin: 5px 10px;
    width: 100%;
  }
}

.rejection {
  background-color: #ed2020;
}

.v-btn {
  font-weight: 600;
  margin: 20px 0;
}

.v-card {
  box-shadow: none !important;
}

.empty-history, .no-order, .no-order-text {
  margin: auto;
  text-align: center;
}

.empty-history {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.no-order {
  padding: 40px!important;
}

.no-order-text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-outline {
  padding: 10px;
  border-radius: 5px;
  height: 48px;
}
</style>
