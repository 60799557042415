<template>
  <div class="panel ">
    <v-card-title class="">
      <span class="section-title">Manage Roles</span>
    </v-card-title>
    <div class="">
      <div class="row px20 py30">
        <div v-for="acos in acosData" :key="acos.id" class="col-md-3 col-xs-6">
          <div class="">
            <div class="mb10">
              <span class="permission-name cl-gloo-gray">{{ acos.id }}</span>
            </div>
            <div v-for="aco in acos.value" :key="aco.id">
              <v-checkbox
                v-model="selectedACOS"
                class="permission-action"
                :label= "`Can ${aco.action}`"
                :value="{acoId: aco.id}"
              />
            </div>
          </div>
        </div>
      </div>
      <v-card-title class="save-changes py30">
        <div>
          <v-btn @click="assignPermissions()" color="#48446F" outline class="px20">Save changes</v-btn>
        </div>
      </v-card-title>
    </div>
  </div>
</template>
<script>
import ShowLoader from './ShowLoader.vue'
import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'
import { permissionsGuard } from 'theme/route-guards'

export default {
  name: 'AssignPermissionsToRole',
  data () {
    return {
      selectedRole: null,
      acos: {},
      acoKeys: [],
      showLoader: true,
      selectedACOS: [],
      allPermissions: [],
      companyId: null,
      headers: [
        { text: 'Action Control Objects', sortable: false },
        { text: 'Actions', sortable: false }
      ]
    }
  },
  computed: {
    acosData () {
      let temp = this.acoKeys.map((item, index) => {
        let value = this.acos[item]
        let values = value.filter(entry => {
          let visibilityString = entry.visibility
          if (!visibilityString) {
            return false
          }
          let visibilityObject = JSON.parse(visibilityString)
          let visibilities = visibilityObject.visibilities
          let shouldShow = false
          visibilities.forEach(entry => {
            if (entry === 'store' || entry === 'all') {
              shouldShow = true
            }
          })
          return shouldShow
        })
        return {
          id: item,
          value: values
        }
      })
      return temp.filter(entry => {
        return entry.value.length > 0
      })
    }
  },
  methods: {
    inArray (array, item) {
      return array.find(obj => {
        return obj.acoId === item.id
      })
    },
    assignPermissions () {
      this.$bus.$emit('notification-progress-start', 'Saving ...')
      let self = this
      let permissionsToBeUpdated = []
      this.allPermissions.forEach(permission => {
        let data = {
          acoId: permission.id,
          assign: self.inArray(self.selectedACOS, permission) ? 1 : 0
        }
        permissionsToBeUpdated.push(data)
      })
      this.$store.dispatch('assignPermissionsToRole', {
        permissions: permissionsToBeUpdated,
        companyId: this.companyId,
        roleId: this.selectedRole.id
      })
        .then(({data}) => {
          this.$bus.$emit('notification-progress-stop')
          if (data.code === 200) {
            // force the list of users to be retrieved next time it is called
            // in order to update the users' permissions stored in the vuex store
            this.$store.dispatch('setCompanyUsers', null)
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'Operation successful',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while saving details',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    populateSelectedActions () {
      let selectedActs = []
      let promises = []
      if (this.selectedRole && this.selectedRole.permissions) {
        this.selectedRole.permissions.forEach(permission => {
          promises.push(new Promise(resolve => {
            selectedActs.push({ acoId: permission.acoId })
            resolve()
          }))
        })
        Promise.all(promises)
          .then(() => {
            this.selectedACOS = selectedActs
          })
      }
    }
  },
  created () {
    let selectedRoleId = this.$route.params['roleId']
    if (!selectedRoleId) {
      this.$router.push('/page-not-found')
    }

    let self = this
    this.companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : null
    if (this.companyId) {
      this.$store.dispatch('fetchCompanyRoles', this.companyId)
        .then(({ data }) => {
          if (data.code === 200) {
            this.showLoader = false
            let roles = data.result ? data.result.data.roles : []
            self.selectedRole = roles.find(obj => {
              return obj.id === selectedRoleId
            })
            if (!self.selectedRole) {
              this.$router.push('/page-not-found')
            }

            // fetch access control objects
            this.$store.dispatch('fetchAccessControlObjects')
              .then(({ data }) => {
                if (data.code === 200) {
                  this.showLoader = false
                  this.allPermissions = data.result ? data.result.data.acos : []
                  let tempSet = new Set()

                  for (let i = 0; i < this.allPermissions.length; i++) {
                    tempSet.add(this.allPermissions[i].model)
                    if (!this.acos[this.allPermissions[i].model]) {
                      this.acos[this.allPermissions[i].model] = []
                    }
                    this.acos[this.allPermissions[i].model].push(this.allPermissions[i])
                  }
                  this.acoKeys = [...tempSet]
                  this.populateSelectedActions()
                }
              })
              .catch(error => {
                console.error(error)
                if (error.response && error.response.data) {
                  this.$bus.$emit('notification', {
                    type: 'error',
                    message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
                    action1: { label: 'OK', action: 'close' }
                  })
                } else {
                  this.$bus.$emit('notification', {
                    type: 'error',
                    message: 'An error occured while retrieving access control objects',
                    action1: { label: 'OK', action: 'close' }
                  })
                }
                this.showLoader = false
              })
          }
        })
        .catch(error => {
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while retrieving role',
              action1: { label: 'OK', action: 'close' }
            })
          }
          this.showLoader = false
        })
    }
  },
  components: {
    ShowLoader,
    ButtonOutline
  },
  beforeRouteEnter (to, from, next) {
    permissionsGuard(to, from, next)
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);

.permission-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}

.permission-action {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-top: 0;
}

.save-changes {
  background-color: #F9FAFB;
  border: 1px solid #DFE3E8;
  border-radius: 0 0 3px 3px;
  height: 80px;
  button {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 3px;
  }
}

.v-card > div {
    padding: unset !important;
}

.panel {
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #D7D7E0;
}
</style>
