<template>
  <div class="sidebar-menu fixed mw-100 bg-cl-other cl-silver" :class="{ active: isOpen }">
    <div class="row brdr-bottom-1 brdr-cl-bg-secondary">
      <div class="col-xs bg-cl-primary" v-if="submenu.depth">
        <sub-btn type="back" class="bg-cl-transparent brdr-none" />
      </div>
      <div class="col-xs bg-cl-primary">
        <button
          type="button"
          :aria-label="$t('Close')"
          class="w-100 inline-flex end-xs bg-cl-transparent brdr-none p0 close-btn"
          @click="closeMenu"
        >
          <i class="material-icons p15">close</i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 h4 menu-links">
        <ul class="p0 m0 relative sidebar-menu__list" :style="mainListStyles">
          <li @click="closeMenu" class="brdr-bottom-1 brdr-cl-bg-secondary bg-cl-primary">
            <router-link
              class="block px25 py20 cl-gloo-catalina-blue no-underline"
              :to="localizedRoute('/')"
              exact
            >
              {{ $t('Home') }}
            </router-link>
          </li>
          <!-- <li
            class="brdr-bottom-1 brdr-cl-bg-secondary bg-cl-primary flex"
            :key="category.slug"
            @click="closeMenu"
            v-for="category in categories"
            v-if="category.product_count > 0 || category.children_data.length > 0"
          >
            <sub-btn
              class="bg-cl-transparent brdr-none fs-medium"
              :id="category.id"
              :name="category.name"
              v-if="category.children_data.length > 0"
            />
            <router-link
              v-else
              class="px25 py20 cl-accent no-underline col-xs"
              :to="localizedRoute({ name: 'category', params: { id: category.id, slug: category.slug }})"
            >
              {{ category.name }}
            </router-link>

            <sub-category
              :category-links="category.children_data"
              :id="category.id"
              :parent-slug="category.slug"z
            />
          </li> -->
          <li
            class="brdr-bottom-1 brdr-cl-bg-secondary bg-cl-primary flex"
            :key="item.id"
            @click="closeMenu"
            v-for="item in menuItems"
          >
            <router-link
              class="px25 py20 cl-gloo-catalina-blue no-underline col-xs"
              :to="localizedRoute(item.url)"
            >
              {{ item.label }}
            </router-link>
          </li>
          <li @click="closeMenu" class="brdr-bottom-1 brdr-cl-primary flex">
            <sub-btn
              v-if="currentUser"
              :name="$t('My account')"
              class="bg-cl-accent cl-accent2 brdr-none fs-medium-small"
            />
            <sub-category
              v-if="currentUser"
              :my-account-links="myAccountLinks"
              :id="'foo'"
            />
            <a
              v-if="!currentUser"
              href="#"
              @click.prevent="login"
              class="block w-100 px25 py20 cl-accent2 bg-cl-accent no-underline fs-medium-small"
            >
              {{ $t('My account') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import SidebarMenu from 'core/components/blocks/SidebarMenu/SidebarMenu'
import SubBtn from 'theme/components/core/blocks/SidebarMenu/SubBtn'
import SubCategory from 'theme/components/core/blocks/SidebarMenu/SubCategory'

export default {
  components: {
    SubCategory,
    SubBtn
  },
  mixins: [SidebarMenu],
  data () {
    return {
      myAccountLinks: [
        {
          id: 1,
          name: 'Dashboard',
          url: '/dashboard',
          show: true
        },
        {
          id: 2,
          name: 'My orders',
          url: '/my-account/orders',
          show: true
        },
        {
          id: 3,
          name: 'Pending Orders',
          url: '/my-account/order-approvals',
          show: this.hasPermission('approve', 'order')
        },
        {
          id: 4,
          name: 'Locations',
          url: '/company-settings/location-settings',
          show: this.hasPermission('view', 'location')
        },
        {
          id: 5,
          name: 'Users',
          url: '/company-settings/users',
          show: this.hasPermission('view', 'user')
        },
        {
          id: 6,
          name: 'Permissions',
          url: '/company-settings/authorization-settings/roles',
          show: this.hasPermission('manage', 'permission')
        },
        {
          id: 7,
          name: 'My Profile',
          url: '/my-account',
          show: true
        }
      ]
    }
  },
  computed: {
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      menuItems: 'getMenuItems'
    })
  },
  methods: {
    login () {
      this.$bus.$emit('modal-show', 'modal-signup')
      this.$store.commit('ui/setOpenMyAccount', true)
    }
  }
}
</script>

<style lang="scss">
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$bg-secondary: color(secondary, $colors-background);
$color-gainsboro: color(gainsboro);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);

.sidebar-menu {
  height: 100vh;
  width: 350px;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  z-index: 3;
  transition: transform $duration-main $motion-main;
  // background-color: rgba(242, 242, 242, 1) !important; //temporary fix for the transparent background issue on sidebar menu resulting from the theme's Secondary color variable value until colors are finally agreed upon

  @media (max-width: 767px) {
    width: 100vh;
  }

  &.active {
    transform: translateX(0);
  }

  &__list {
    transition: transform $duration-main $motion-main;
  }

  ul {
    list-style-type: none;
  }

  li {
    a {
      color: $color-mine-shaft;
    }
  }

  button {
    color: $color-mine-shaft;a {
      color: $color-mine-shaft;
    }
  }

  .close-btn {
    i {
      color: $color-gainsboro;
    }
    &:hover,
    &:focus {
      i {
        color: $color-matterhorn;
      }
    }
  }

  .menu-links {
    padding-left: 0;
    padding-right: 0;
  }

  .router-link-active {
    color: color(gloo-main)
  }
}
</style>
