<template>
  <div id="dashboard">
    <div class="">
      <div class="row pt0 page-wrapper">
        <div class="col-lg-2 col-md-1 col-sm-2 hidden-xs hidden-sm block">
          <nav class="static-menu h4 mb35">
            <ul class="m0 p0">
              <li class="companyAvatar logo-holder">
                <!-- <v-avatar color="blue">
                  <span class="white--text headline">{{ companyName.charAt(0) }}</span>
                </v-avatar>
                <span class="companyName">{{ companyName }}</span> -->
                <img src="../assets/gloopro-logo.png" class="dash-logo">
              </li>
              <li class="dashboard-link" v-for="(page, index) in navigation" :key="index">
                <template v-if="page.title === 'Permissions'">
                  <router-link v-if="hasPermission('manage', 'permission')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Users'">
                  <router-link v-if="hasPermission('view', 'user')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Locations'">
                  <router-link v-if="hasPermission('view', 'location')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Pending Orders'">
                  <router-link v-if="hasPermission('approve', 'order')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Company Orders'">
                  <router-link v-if="hasPermission('view all', 'order')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Spend Analysis'">
                  <router-link v-if="hasPermission('view', 'spend analysis')" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else-if="page.title === 'Approval Workflow'">
                  <router-link v-if="enableApprovalWorkflow" :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
                <template v-else>
                  <router-link :to="localizedRoute(page.link)" class="cl-accent"><i class="material-icons">{{ page.icon }}</i> {{ page.title }}</router-link>
                </template>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-lg-10 col-md-11 col-sm-10 col-xs-12 page-content">
          <!-- <p v-if="!isDashboardPage" @click="$router.go(-1)" class="cl-primary previous-link">
            <i class="material-icons">keyboard_backspace</i>Back
          </p> -->
          <v-app>
            <component :is="this.$props.activeBlock"/>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '../components/core/blocks/Dashboard/Dashboard.vue'
import MyAccount from '../components/core/blocks/MyAccount/MyProfile.vue'
import SingleOrder from '../components/core/blocks/Dashboard/SingleOrder.vue'
import MyOrders from '../components/core/blocks/MyAccount/MyOrders'
import MyOrder from '../components/core/blocks/MyAccount/MyOrder'
import OrderApprovals from '../components/core/blocks/Dashboard/OrderApprovals.vue'
import config from 'config'

// import CompanySettings from '../components/core/blocks/CompanySettings/GeneralSettings.vue'
import GeneralSettings from '../components/core/blocks/CompanySettings/GeneralSettings.vue'
import AuthorizationSettings from '../components/core/blocks/CompanySettings/AuthorizationSettings.vue'
import LocationSettings from '../components/core/blocks/CompanySettings/LocationSettings.vue'
import Catalog from '../components/core/blocks/CompanySettings/Catalog.vue'
import Users from '../components/core/blocks/CompanySettings/Users.vue'

import SingleUser from '../components/core/blocks/CompanySettings/SingleUser.vue'
import LocationDetails from '../components/core/blocks/CompanySettings/LocationDetails.vue'
import AddLocation from '../components/core/blocks/CompanySettings/AddLocation.vue'
import Reports from '../components/core/blocks/Reports/Reports.vue'
import AllCompanyOrders from '../components/core/blocks/CompanyOrders/AllCompanyOrders.vue'
import ListApprovalWorkflows from '../components/core/blocks/ApprovalWorkflow/ListApprovalWorkflows.vue'
import AddApprovalWorkflow from '../components/core/blocks/ApprovalWorkflow/AddApprovalWorkflow.vue'

export default {
  props: {
    activeBlock: {
      default: 'Dashboard',
      type: String
    }
  },
  data () {
    return {
      navigation: [
        { title: 'Dashboard', link: '/dashboard', icon: 'dashboard' },
        { title: 'My Orders', link: '/my-account/orders', icon: 'receipt' },
        { title: 'Pending Orders', link: '/my-account/order-approvals', icon: 'receipt' },
        // { title: 'Company Settings', link: '/company-settings', icon: 'settings' },
        // { title: 'Authorization Settings', link: '/company-settings/authorization-settings', icon: 'vpn_key' },
        { title: 'Locations', link: '/company-settings/location-settings', icon: 'location_on' },
        { title: 'Users', link: '/company-settings/users', icon: 'people' },
        { title: 'Permissions', link: '/company-settings/authorization-settings/roles', icon: 'vpn_key' },
        { title: 'My Profile', link: '/my-account', icon: 'person' },
        { title: 'Company Orders', link: '/company/all-orders', icon: '360' },
        { title: 'Approval Workflow', link: '/approval-workflows', icon: 'gavel' },
        { title: 'Spend Analysis', link: '/reports', icon: 'show_chart' }
      ],
      user: null,
      location: null,
      isDashboardPage: false,
      activeDashboardLinks: [
        this.localizedRoute('/dashboard'),
        this.localizedRoute('/my-account'),
        this.localizedRoute('/my-account/orders'),
        this.localizedRoute('/company-settings/location-settings'),
        this.localizedRoute('/company-settings/users'),
        this.localizedRoute('/company-settings/authorization-settings/roles')
      ],
      enableApprovalWorkflow: config.ecms.enableApprovalWorkflow
    }
  },
  // computed: {
  //   companyName () {
  //     return this.$store.state.userDetails && this.$store.state.userDetails.companyDetails
  //       ? this.$store.state.userDetails.companyDetails.name : null
  //   }
  // },
  components: {
    MyAccount,
    Dashboard,
    MyOrders,
    MyOrder,
    GeneralSettings,
    LocationSettings,
    Catalog,
    Users,
    AuthorizationSettings,
    SingleOrder,
    SingleUser,
    LocationDetails,
    AddLocation,
    OrderApprovals,
    ListApprovalWorkflows,
    AddApprovalWorkflow,
    Reports,
    AllCompanyOrders
  },
  created () {
    if (this.activeDashboardLinks.indexOf(this.$route.path) > -1) {
      this.isDashboardPage = true
    }
  },
  watch: {
    '$route.name': function () {
      if (this.activeDashboardLinks.indexOf(this.$route.path) > -1) {
        this.isDashboardPage = true
      } else {
        this.isDashboardPage = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-secondary: color(secondary);
$color-tertiary: color(tertiary);

#dashboard {
  background: transparent !important;
  select {
    @extend .h4;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid $color-tertiary;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    background-color: transparent;
  }
}

.previous-link {
  font-size: 18px;
  cursor: pointer;
  i {
    font-size: 16px;
    margin-right: 5px;
  }
}

li.companyAvatar.logo-holder {
  display: none;
  margin-left: 10px;
  // border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  // span.companyName{
  //   padding-left: 10px;
  // }
}

.dash-logo {
  width: 112px;
  height: 27px;
}

.page-content {
  background-color: #f8f9fd;
  margin: 15px auto;
  padding: 25px 50px;

  @media (max-width: 1485px) {
    padding: 25px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

#page-header {
  margin: 0 auto;
  padding: 0;
}

.block {
  padding: 50px 0;
  border-right: 1.5px solid rgba(0, 0, 0, 0.15);
  // box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 13px 0 rgba(0,0,0,.12);
  background-image: linear-gradient(173.96deg, #EEFAF4 0%, #A7CDA3 100%);
  // border-right: 1px solid #e9ebf6;
}

//To ensure that at smaller device widths, navigation links do not break into new lines, the width of the dashboard columns (col-md-2 and col-md-9) are increased repsectively
@media (min-width: 960px) and (max-width: 1485px) {
  .block {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .page-content {
    flex-basis: 66.66666667% !important;
    max-width: 66.66666667% !important;
  }
}

#location {
  font-weight: normal;
}

.page-wrapper {
  margin: 0;
  background-color: #fafafa;
}

h3, h4 {
  margin: 10px 0;
}

.static-menu {
  width: 100%;
  ul {
    list-style: none;
    position: relative;
  }

  li {
    &:hover, &:focus {
      a {
        color: $color-primary;
        // color: #4F9221;
        background-color: rgba(217, 237, 220, 0.8);
        border-radius: 0 17px 17px 0;
        font-weight: 600;
      }
    }
  }

  a {
    color: #353F69;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.13px;
    line-height: 16px;
    text-align: left;
    padding: 15px;
    width: 100%;
    display: block;
    &:after {
      content: "";
      display: none;
      position: unset;
      width: 100%;
      height: 1px;
      background: none;
    }

    &:hover,
    &.router-link-exact-active {
      color: $color-primary;
      // color: #4F9221;
      font-weight: 600;
      // background-color: $color-secondary;
      background-color: rgba(217, 237, 220, 0.8);
      border-radius: 0 17px 17px 0;
      &:after {
        opacity: 0;
      }
    }
  }

  i {
    margin-right: 10px;
    vertical-align: text-bottom;
    font-size: 20px;
  }
}
</style>
