var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"product align-center w-100 pb20 no-left-margin productTile bg-cl-gloo-solitude"},[_c('router-link',{staticClass:"no-underline product-link",attrs:{"to":_vm.localizedRoute({
      name: _vm.product.type_id + '-product',
      params: {
        parentSku: _vm.product.parentSku ? _vm.product.parentSku : _vm.product.sku,
        slug: _vm.product.slug,
        childSku: _vm.product.sku
      }
    }),"data-testid":"productLink"}},[_c('div',{staticClass:"product align-left"},[_c('div',{staticClass:"product-image relative align-center",class:[{ sale: _vm.labelsActive && _vm.isOnSale }, { new: _vm.labelsActive && _vm.isNew }]},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.thumbnailObj),expression:"thumbnailObj"}],attrs:{"alt":_vm.product.name,"src":_vm.thumbnailObj.loading,"height":"200"}})]),_vm._v(" "),_c('div',{staticClass:"product-details"},[_c('p',{staticClass:"mb10 mt5 product-name"},[_vm._v("\n          "+_vm._s(_vm.product.name)+"\n        ")]),_vm._v(" "),(parseFloat(_vm.product.price) > 0 && _vm.identity !== 'frequently-ordered')?_c('p',{staticClass:"lh40 price m0 mb5"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product.price))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.identity === 'frequently-ordered')?_c('div',{},[_c('button-outline',{staticClass:"light full-width",attrs:{"data-testid":"i"}},[_c('span',{staticStyle:{"marginLeft":"10px","marginRight":"10px"}},[_vm._v("View")])])],1):_vm._e()])]),_vm._v(" "),(_vm.identity === 'alternative-product')?_c('div',{},[_c('button-outline',{staticClass:"light full-width alternative-details",attrs:{"data-testid":"i"}},[_c('span',{staticStyle:{"marginLeft":"10px","marginRight":"10px"}},[_vm._v("View Details")])])],1):_vm._e()]),_vm._v(" "),_c('router-link',{staticClass:"no-underline product-link",attrs:{"to":{
      name: 'alternative-products',
      params: {
        parentSku: _vm.product.parentSku ? _vm.product.parentSku : _vm.product.sku,
        slug: _vm.product.slug,
        childSku: _vm.product.sku
      }
    },"data-testid":"productLink"}},[(_vm.product.product_stock_status === 'out-of-stock')?_c('div',{staticClass:"alt-class"},[_c('button-outline',{staticClass:"light unavailable",attrs:{"data-testid":"i"}},[_c('span',[_vm._v("Unavailable, View Alternatives")])])],1):_vm._e()]),_vm._v(" "),(_vm.hasPermission('create', 'order'))?_c('div',{staticClass:"add"},[((!_vm.isInCart || _vm.product.status === 0) && _vm.product.product_stock_status === 'in-stock')?_c('add-to-cart',{staticClass:"col-xs-12",attrs:{"product":_vm.product},nativeOn:{"click":function($event){return _vm.addToCart($event)}}},[_c('img',{attrs:{"src":"/assets/cart.png","alt":"cart"}}),_vm._v(" "),_c('span',[_vm._v("ADD TO CART")])]):_vm._e(),_vm._v(" "),(_vm.isInCart && _vm.product.status === 1 && _vm.product.product_stock_status === 'in-stock')?_c('div',{staticClass:"row col-xs-12 widget"},[_c('button-outline',{staticClass:"col-xs-4 decrement",attrs:{"color":"light"},nativeOn:{"click":function($event){return _vm.decrement()}}},[_c('i',{staticClass:"material-icons h4 p5"},[_vm._v("remove")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-4 widget-input cl-gloo-main"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qty),expression:"qty"}],staticClass:"h4",attrs:{"type":"number"},domProps:{"value":(_vm.qty)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing){ return; }_vm.qty=$event.target.value}}})]),_vm._v(" "),_c('button-outline',{staticClass:"col-xs-4 increment",attrs:{"color":"light"},nativeOn:{"click":function($event){return _vm.increment()}}},[_c('i',{staticClass:"material-icons h4"},[_vm._v("add")])])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }