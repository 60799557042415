const state = {
  searchText: ''
}

const getters = {
  getSearchText: (state) => {
    return state.searchText
  }
}

const actions = {
  setSearchText: ({ commit }, text) => {
    commit('saveSearchText', text)
  }
}

const mutations = {
  saveSearchText (state, text) {
    state.searchText = text
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
