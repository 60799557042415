<template>
  <div class="bottom-border py20 customer-details--section">
    <div class="row px20" v-if="!isActive && !isFilled">
      <div class="col-md-10 col-sm-10 col-xs-10 customer-details--title--block">
        <h3 class="customer-details--title p0">Location</h3>
      </div>
    </div>
    <div class="" v-else>
      <div class="">
        <template v-if="isActive">
          <div class="w-100">
            <div class="mb20">
              <div class="row">
                <div class="col-md-6 col-xs-8 p0 customer-details--title--block">
                  <h3 class="customer-details--title p0">Location</h3>
                </div>
                <div class=" col-md-6 col-xs-4 p0" v-if="isActive">
                  <button-full
                    data-testid="shippingSubmit"
                    class="save-changes"
                    @click.native="sendDataToCheckout"
                    :class="{ 'ripple': true, 'button-disabled' : $v.selectedDeliveryLocation.locationAddressId.$invalid}"
                  >
                    {{ 'Save Changes' }}
                  </button-full>
                </div>
              </div>
            </div>
            <div class="">
              <div class="dropdown open p0 w-100 mb20">
                <button class="btn dropdown-btn bg-cl-primary cl-gloo-gray w-100" id="dropdown-btn" @click="toggleDisplay()">
                  <span class="dropdown-btn cl-gloo-product-text">{{ selectedDeliveryLocation.name }}</span>
                  <i class="caret material-icons dropdown-btn">keyboard_arrow_down</i>
                </button>
                <ul class="dropdown-menu bg-cl-primary" role="menu" id="dropdown-list" :class="{ 'is-visible': isDropdownVisible }">
                  <a href="javascript:void(0)" class="dropdown-link">
                    <li class="active sort-options" role="presentation" v-for="location in userDeliveryLocations" :value="location" :key="location.id" @click="selectLocation(location)">
                      <p class="location-list cl-gloo-main mb5">{{ location.name }}</p>
                      <p class="location-list cl-gloo-product-text">{{ location.locationAddressId.streetAddress }}, {{ location.locationAddressId.city }}</p>
                    </li>
                  </a>
                </ul>
              </div>
              <span class="validation-error" v-if="$v.selectedDeliveryLocation.locationAddressId.$error && !$v.selectedDeliveryLocation.locationAddressId.required">
                {{ $t('Field is required') }}
              </span>
            </div>
            <div v-if="!isDropdownVisible">
              <p class="cl-gloo-gray mb10 weight-600">{{ selectedDeliveryLocation && selectedDeliveryLocation.name }}</p>
              <p class="m0 cl-gloo-text mb5">{{ selectedDeliveryLocation && selectedDeliveryLocation.locationAddressId ? selectedDeliveryLocation.locationAddressId.streetAddress : '' }}</p>
              <p class="m0 cl-gloo-text mb5">{{ selectedDeliveryLocation && selectedDeliveryLocation.locationAddressId ? selectedDeliveryLocation.locationAddressId.city : '' }} {{ selectedDeliveryLocation && selectedDeliveryLocation.locationAddressId ? selectedDeliveryLocation.locationAddressId.zipcode : '' }}</p>
              <p class="m0 cl-gloo-text mb5">
                <span v-if="selectedDeliveryLocation && selectedDeliveryLocation.locationAddressId && selectedDeliveryLocation.locationAddressId.state">{{ selectedDeliveryLocation.locationAddressId.state }}, </span>
                <span>{{ selectedDeliveryLocation && selectedDeliveryLocation.locationAddressId ? selectedDeliveryLocation.locationAddressId.country : '' }}</span>
              </p>
            </div>
          </div>
        </template>
        <template v-else-if="isFilled && !isActive">
          <div class="mb20">
            <div class="row">
              <div class="col-md-10 col-xs-9 p0 customer-details--title--block">
                <h3 class="customer-details--title p0">Location</h3>
              </div>
              <div class="col-md-2 col-xs-3 p0 align-right">
                <a href="javascript:void(0)" @click.prevent="edit" class="cl-gloo-main">
                  Change
                </a>
              </div>
            </div>
          </div>
          <p class="m0 cl-gloo-text mb5">{{ selectedDeliveryLocation.locationAddressId.streetAddress }}</p>
          <p class="m0 cl-gloo-text mb5">{{ selectedDeliveryLocation.locationAddressId.city }} {{ selectedDeliveryLocation.locationAddressId.zipcode }}</p>
          <p class="m0 cl-gloo-text mb5">
            <span v-if="selectedDeliveryLocation.locationAddressId.state">{{ selectedDeliveryLocation.locationAddressId.state }}, </span>
            <span>{{ selectedDeliveryLocation.locationAddressId.country }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
import cloneDeep from 'lodash-es/cloneDeep'
export default {
  name: 'Location',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selectedDeliveryLocation: {},
      userDeliveryLocations: null,
      isFilled: false,
      previousDeliveryLocation: {},
      isDropdownVisible: false
    }
  },
  validations: {
    selectedDeliveryLocation: {
      locationAddressId: {
        required
      }
    }
  },
  methods: {
    sendDataToCheckout () {
      this.previousDeliveryLocation = cloneDeep(this.selectedDeliveryLocation)
      this.$bus.$emit('checkout-after-locationDetails', this.selectedDeliveryLocation, this.$v)
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'location')
      }
    },
    toggleDisplay () {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    selectLocation (location) {
      this.selectedDeliveryLocation = location
      this.isDropdownVisible = false
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  },
  computed: {
    userLocation () {
      let location = this.$store.getters.getLocationDetails
      return location
    },
    savedDeliveryLocation () {
      return this.$store.getters['checkout/getDeliveryLocation']
    }
  },
  created () {
    this.userDeliveryLocations = this.$store.getters.getDeliveryLocations
    this.userDeliveryLocations.forEach(location => {
      if (this.userLocation && location.id === this.userLocation.id) {
        this.selectedDeliveryLocation = location
      }
    })
  },
  mounted () {
    this.previousDeliveryLocation = cloneDeep(this.selectedDeliveryLocation)
    document.documentElement.onclick = (event) => {
      if (!event.target.matches('.dropdown-btn')) {
        if (this.isDropdownVisible) {
          this.isDropdownVisible = false
        }
      }
    }
    if (Object.keys(this.selectedDeliveryLocation).length > 0) {
      this.sendDataToCheckout()
    }
    if (Object.keys(this.savedDeliveryLocation).length > 0) {
      this.selectedDeliveryLocation = this.savedDeliveryLocation
      this.sendDataToCheckout()
      return
    }
    if (this.userDeliveryLocations.length === 1) {
      this.selectedDeliveryLocation = this.userDeliveryLocations[0]
      this.sendDataToCheckout()
    }
  },
  watch: {
    isActive: function (val) {
      this.selectedDeliveryLocation = this.previousDeliveryLocation
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$gloo-main: color(gloo-main);
 .weight-600 {
   font-weight: 600;
 }
</style>
