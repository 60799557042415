<template>
  <div id="delivery-method" class="bottom-border customer-details--section py20">
    <div class="row" v-if="!isActive && !isFilled">
      <div class="col-md-10 col-sm-10 col-xs-10 p0 customer-details--title--block">
        <h3 class="customer-details--title p0">Delivery Method</h3>
      </div>
    </div>
    <div class="" v-else>
      <div class="" >
        <template v-if="isActive">
          <div class="w-100">
            <div class="mb20">
              <div class="row">
                <div class="col-md-6 col-xs-8 p0 customer-details--title--block">
                  <h3 class="customer-details--title p0">Delivery Method</h3>
                </div>
                <div class="col-md-6 col-xs-4 p0" v-if="isActive">
                  <button-full
                    v-if="paymentMethods && paymentMethods.length !== 1"
                    data-testid="deliveryMethodSubmit"
                    @click.native="sendDataToCheckout"
                    class="save-changes"
                    :class="{ 'ripple': true, 'button-disabled' : ($v.selectedDeliveryMethod.$invalid || $v.selectedDeliveryMethod.deliveryFee.$invalid)}"
                  >
                    <!-- {{ punchoutActive ? 'Proceed to Review' : 'Select Payment Method' }} -->
                    Save Changes
                  </button-full>

                  <button-full
                    v-if="paymentMethods && paymentMethods.length === 1"
                    data-testid="deliveryMethodSubmit"
                    @click.native="sendDataToCheckout"
                    class="save-changes"
                    :class="{ 'ripple': true, 'button-disabled' : ($v.selectedDeliveryMethod.$invalid || $v.selectedDeliveryMethod.deliveryFee.$invalid)}"
                  >
                    Save Changes
                  </button-full>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="dropdown open p0 w-100 mb20">
                <button class="btn dropdown-btn bg-cl-primary cl-gloo-gray w-100" id="dropdown-btn" @click="toggleDisplay()">
                  <span class="dropdown-btn cl-gloo-product-text">{{ selectedDeliveryMethod.name }}</span>
                  <i class="caret material-icons dropdown-btn">keyboard_arrow_down</i>
                </button>
                <ul class="dropdown-menu bg-cl-primary" role="menu" id="dropdown-list" :class="{ 'is-visible': isDropdownVisible }">
                  <a href="javascript:void(0)" class="dropdown-link">
                    <li class="active sort-options" role="presentation" v-for="method in Object.keys(deliveryMethods)" :value="deliveryMethods[method]" :key="method" @click="selectDeliveryMethod(deliveryMethods[method])">
                      <p class="location-list cl-gloo-main mb5">{{ deliveryMethods[method].name }}</p>
                      <p class="location-list cl-gloo-product-text">{{ deliveryMethods[method].deliveryFee | currency }}</p>
                    </li>
                  </a>
                </ul>
              </div>
              <span class="validation-error" v-if="$v.selectedDeliveryMethod.$error && !$v.selectedDeliveryMethod.required">
                {{ $t('Field is required') }}
              </span>
            </div>
            <div v-if="!isDropdownVisible">
              <p class="m0 cl-gloo-text mb5">Delivery method: {{ selectedDeliveryMethod ? selectedDeliveryMethod.name : '' }}</p>
              <p class="m0 cl-gloo-text mb5">Delivery Fee: {{ ( selectedDeliveryMethod ? selectedDeliveryMethod.deliveryFee : '' ) | currency }}</p>
            </div>
          </div>
        </template>
        <template v-else-if="isFilled && !isActive">
          <div class="mb20">
            <div class="row">
              <div class="col-md-10 col-xs-9 p0 customer-details--title--block">
                <h3 class="customer-details--title p0">Delivery Method</h3>
              </div>
              <div class="col-md-2 col-xs-3 p0 align-right">
                <a href="javascript:void(0)" @click.prevent="edit" class="cl-gloo-main">
                  Change
                </a>
              </div>
            </div>
          </div>
          <div>
            <p class="m0 cl-gloo-text mb5">Delivery method: {{ selectedDeliveryMethod.name }}</p>
            <p class="m0 cl-gloo-text mb5">Delivery Fee: {{ selectedDeliveryMethod.deliveryFee | currency }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import { required } from 'vuelidate/lib/validators'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
import Tooltip from 'theme/components/core/Tooltip'
import { cloneDeep } from 'lodash-es'

export default {
  name: 'DeliveryMethod',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      deliveryMethods: {},
      deliveryLocation: null,
      selectedDeliveryMethod: {
        name: 'Select Delivery Method'
      },
      previousDeliveryLocation: {},
      currencySign: config.i18n.currencySign,
      isFilled: false,
      isDropdownVisible: false
    }
  },
  validations: {
    selectedDeliveryMethod: {
      deliveryFee: {
        required
      }
    }
  },
  computed: {
    punchoutActive () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails
        ? this.$store.state.userDetails.companyDetails.punchoutActive
        : false
    },
    paymentMethods () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails &&
        this.$store.state.userDetails.companyDetails.paymentMethods
        ? this.$store.state.userDetails.companyDetails.paymentMethods
        : []
    },
    savedDeliveryMethod () {
      return this.$store.getters['checkout/getDeliveryMethod']
    }
  },
  mounted () {
    this.previousDeliveryMethod = cloneDeep(this.selectedDeliveryMethod)
    document.documentElement.onclick = (event) => {
      if (!event.target.matches('.dropdown-btn')) {
        if (this.isDropdownVisible) {
          this.isDropdownVisible = false
        }
      }
    }
    if (Object.keys(this.savedDeliveryMethod).length > 0) {
      this.selectedDeliveryMethod = this.savedDeliveryMethod
      this.sendDataToCheckout()
      return
    }

    if (Object.keys(this.deliveryMethods).length === 1) {
      this.selectedDeliveryMethod = Object.values(this.deliveryMethods)[0]
      this.sendDataToCheckout()
    }
  },
  methods: {
    sendDataToCheckout () {
      this.previousDeliveryMethod = cloneDeep(this.selectedDeliveryMethod)
      this.$bus.$emit('checkout-after-deliveryMethod', this.selectedDeliveryMethod, this.$v)
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'deliveryMethod')
      }
    },
    onAfterLocationDetails (receivedData, validationResult) {
      this.deliveryLocation = receivedData
      let locationDeliveryAttributes = this.deliveryLocation.locationDeliveryAttributes

      if (this.$store.state.userDetails.companyDetails) {
        let availableMethods = this.$store.state.userDetails.companyDetails.deliveryMethods
        let deliveryMethods = {}
        availableMethods.forEach(item => {
          deliveryMethods[item.id] = item
        })
        this.deliveryMethods = deliveryMethods
      }
      let applicableDeliveryMethods = {}
      locationDeliveryAttributes.forEach(attribute => {
        if (this.deliveryMethods[attribute.deliveryMethodsId]) {
          this.deliveryMethods[attribute.deliveryMethodsId].deliveryFee = attribute.deliveryFee
          applicableDeliveryMethods[attribute.deliveryMethodsId] = this.deliveryMethods[attribute.deliveryMethodsId]
        }
      })
      this.deliveryMethods = applicableDeliveryMethods
      if (Object.keys(this.deliveryMethods).length > 0) {
        this.selectedDeliveryMethod = this.deliveryMethods[Object.keys(this.deliveryMethods)[0]]
      }
    },
    toggleDisplay () {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    selectDeliveryMethod (deliveryMethod) {
      this.selectedDeliveryMethod = deliveryMethod
      this.isDropdownVisible = false
    }
  },
  components: {
    ButtonFull,
    Tooltip,
    BaseCheckbox,
    BaseInput
  },
  created () {
    this.$bus.$on('checkout-after-locationDetails', this.onAfterLocationDetails)
  },
  watch: {
    isActive: function (val) {
      this.selectedDeliveryLocation = this.previousDeliveryMethod
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
