<template>
  <div>
    <div class="page-title">
      <v-card-title>
        <span class="section-title">My Orders</span>
      </v-card-title>
    </div>
    <div class="top-row row">
      <div class="caption col-md-6 col-sm-12 col-xs-12">
        <div class="external-search--box">
          <input type="text" placeholder="Search through orders" class="search-box" v-model="search">
          <i class="material-icons" aria-hidden="true">search</i>
        </div>
      </div>
      <!-- <div class="export-print col-sm-6 col-xs-6">
        <v-btn color="success" class="export-print--btn">Export as<i class="material-icons">arrow_drop_down</i></v-btn>
      </div> -->
    </div>
    <div class="panel">
      <div>
        <v-card-title class="table-title">
          <h3 v-if="ordersHistory && ordersHistory.length > 0" class="order-total cl-gloo-product-text">{{ ordersHistory && ordersHistory.length }} Placed {{ ordersHistory && ordersHistory.length > 1 ? 'Orders' : 'Order' }}</h3>
          <v-spacer/>
        </v-card-title>
        <v-card>
          <v-data-table
            v-if="ordersHistory && ordersHistory.length > 0"
            :pagination.sync="pagination"
            :headers="headers"
            :search="search"
            :items="ordersHistory"
            :rows-per-page-items="[25, 50]"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.increment_id }}</td>
              <td class="text-xs-left">{{ props.item.date }}</td>
              <td class="text-xs-left">{{ props.item.grand_total | number | currency }}</td>
              <td class="text-xs-left order-status"><span class="order-status-chip" :class="[orderStatus[props.item.status]]">{{ props.item.status }}</span></td>
              <td class="text-xs-right no-right-padding">
                <p class="table-cta" @click="showOrderDetails(props.item)">View order</p>
              </td>
              <td class="text-xs-left no-left-padding">
                <v-btn
                  class="re-order"
                  @click.prevent="remakeOrder(skipGrouped(props.item.items))"
                  v-if="hasPermission('create', 'order')"
                >Re-order</v-btn>
              </td>
            </template>
          </v-data-table>
          <div v-else class=" no-order">
            <div class="empty-history">
              <div class="no-order-text">
                <p class="cl-gloo-catalina-blue">You currently have no orders.</p>
                <p class="cl-gloo-catalina-blue">Once you do, this is where you’ll track them.</p>
              </div>
              <div class="p15">
                <img src="/assets/no-recent-orders.svg" alt="Empty Order History" height="100px" class="img-order-history">
              </div>
              <button-outline color="gray" class="start p15" @click.native="browseCategories()">
                Start ordering
              </button-outline>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MyOrders from 'core/components/blocks/MyAccount/MyOrders'
import config from 'config'
import moment from 'moment'
import { localizedRoute } from '@vue-storefront/store/lib/multistore'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  components: {
    ButtonOutline
  },
  mixins: [MyOrders],
  data () {
    return {
      search: '',
      orderStatus: {
        'disapproved': 'disapproved-order',
        'resubmitted': 'resubmitted-order',
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order'
      },
      pagination: {
        sortBy: 'entity_id',
        descending: true
      },
      headers: [
        {
          text: 'Order ID',
          align: 'left',
          sortable: true,
          value: 'increment_id'
        },
        {text: 'Date and Time', align: 'left', sortable: true, value: 'date'},
        {text: 'Total Amount', align: 'left', sortable: true, value: 'grand_total'},
        {text: 'Status', align: 'left', sortable: true, value: 'status'},
        {text: 'Actions', align: 'right', sortable: false, value: 'actions'},
        {text: '', sortable: false, value: ''}
      ]
    }
  },
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY - hh:mm A')
    },
    showOrderDetails (order) {
      this.$router.push(localizedRoute('/my-account/orders/' + order.increment_id))
    },
    browseCategories () {
      this.$router.push(localizedRoute('/#company-categories'))
    },
    getOrdersHistory () {
      if (this.stateOrdersHistory) {
        let orders = this.stateOrdersHistory.items
        if (orders) {
          orders.forEach(order => {
            let date = this.formatDate(order.created_at)
            order.date = date
          })
          return orders
        }
      } else {
        return []
      }
    }
  },
  filters: {
    number: function (value) {
      if (!value) return ''
      value = value.toLocaleString(config.i18n.defaultLocale)
      return value
    },
    currency: function (value) {
      if (!value) return ''
      value = config.i18n.currencySign + value
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon-hover: color(secondary, $colors-background);
$color-white: color(white);
$color-gloo-gray: color(gloo-gray);

.order-total {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.v-btn {
  font-weight: 600;
}

// .export-print {
//   text-align: end;
//   margin: auto;

//   .success {
//     font-size: 14px;
//     font-weight: 550;
//     line-height: 17px;
//   }
// }

// .export-print--btn {
//   text-transform: none;

//   i {
//     margin: 5px;
//   }
// }

.panel {
  margin-top: 35px !important;
}

.re-order {
  text-transform: none;
  display: inline-block !important;
  background-color: $color-white !important;
  border-radius: 3px;
  box-shadow: 0 1px 3px 1px rgba(127, 131, 155, 0.15), 0 1px 2px 0 rgba(127, 131, 155, 0.3) !important;
}

.table-cta {
  display: inline;
  margin: 0 8px 0 0;
  line-height: 18px !important;
}

.re-order, .table-cta {
  font-size: 15px !important;
  font-weight: 550;
}

.no-right-padding {
  padding-right: 0 !important;

  @media (max-width: 1485px) {
    text-align: center !important;
    padding-left: 0 !important;
  }
}
.no-left-padding {
  padding-left: 0 !important;
}

.empty-history, .no-order, .no-order-text {
  margin: auto;
  text-align: center;
}

.empty-history {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.no-order {
  padding: 40px!important;
}

.no-order-text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-outline {
  padding: 10px;
  border-radius: 5px;
  height: 48px;
}

@media (max-width: 1485px) {
  .search-box {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .search-box {
    width: 70%;
  }
}
@media (max-width: 550px) {
  .search-box {
    width: 100%;
  }
}
</style>
