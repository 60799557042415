import Vue from 'vue'

const extensionList = []
if (!Vue.prototype.$isServer) { // extensions that are not required in the SSR mode
  // extensionList.push(require('@vue-storefront/extension-droppoint-shipping/index.js'))
  // extensionList.push(require('@vue-storefront/extension-google-analytics/index.js'))
  // extensionList.push(require('@vue-storefront/extension-magento2-cms/index.js'))
  // extensionList.push(require('@vue-storefront/extension-mailchimp-subscribe/index.js'))
  // extensionList.push(require('src/extensions/cms/index.js'))
}
// extensionList.push(require('@vue-storefront/extension-payment-backend-methods/index.js'))
// extensionList.push(require('@vue-storefront/extension-payment-cash-on-delivery/index.js'))
// extensionList.push(require('vsf-payment-stripe/index.js'))
// extensionList.push(require('@vue-storefront/extension-template/index.js'))
export default extensionList
