import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'

export const permissionsGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('manage', 'permission')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const viewLocationsGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('view', 'location')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const editLocationsGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('edit', 'location')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const viewUsersGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('view', 'user')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const editUsersGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('edit', 'user')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const createUsersGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('create', 'user')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const viewAllOrdersGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('view all', 'order')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}

export const createOrderGuard = (to, from, next) => {
  if (!acl.isUserAllowedPermission('create', 'order')) {
    EventBus.$emit('notification', {
      type: 'error',
      message: 'You are not authorized to access this page',
      action1: { label: 'OK', action: 'close' }
    })
    next({
      path: '/'
    })
  } else {
    next()
  }
}
