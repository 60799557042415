<template>
  <div>
    <div class="thank-you-content">
      <div class="container">
        <div class="align-center" id="order-summary--heading">
          <div>
            <img src="/assets/order-success.svg" >
          </div>
          <div class="py40">
            <p class="cl-gloo-gray user">Thank you for your order, {{ user }}</p>
            <p class="cl-gloo-product-text order" v-if="hasPermission('approve', 'order')">
              Your order with order id {{ orderId }} has been placed. You can view the status of your order by visiting <router-link :to="'my-account/orders/'+orderId">here</router-link>.
            </p>
            <p class="cl-gloo-product-text order" v-if="!hasPermission('approve', 'order')">
              Your order has been placed. We will let you know once your order has been approved and items dispatched. You can view the status of your order by visiting <router-link :to="'/orders/'+submittedOrder.id">here</router-link>.
            </p>
          </div>
        </div>
        <div class="row" id="order-summary--body">
          <div class="col-md-6 col-sm-12 order-summary--body--products">
            <div class="checkout-card checkout-card--order-summary mb20 py20">
              <div class="row" id="cart-title">
                <div class="col-md-12 order-summary--body--text">
                  <h3 class="cl-gloo-gray">Order Summary</h3>
                  <div class="row cl-gloo-main align-center">
                    <p class="m0">{{ submittedOrder && submittedOrder.products && submittedOrder.products.length }} {{ submittedOrder && submittedOrder.products && (submittedOrder.products.length > 1 || submittedOrder.products.length === 0) ? 'Items' : 'Item' }}</p>
                    <button @click="toggleDisplay()">
                      <i class="material-icons" :class="{ 'is-visible': !isProductListVisible }">keyboard_arrow_up</i>
                      <i class="material-icons" :class="{ 'is-visible': isProductListVisible }">keyboard_arrow_down</i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" id="cart-products" :class="{ 'is-visible': !isProductListVisible }">
                <div class="col-md-12">
                  <product v-for="product in submittedOrder.products" :key="product.sku" :product="product" />
                </div>
              </div>
            </div>
            <div class="cart-totals">
              <div class="row">
                <div class="col-md-12 p0">
                  <div class="col-md-8 col-md-offset-4 col-sm-12 p0">
                    <p>
                      <span class="cart-total cl-gloo-product-text" v-if="inclusiveVAT">Subtotal incl. tax: </span>
                      <span class="cart-total cl-gloo-product-text" v-else>Subtotal</span>
                      <span style="float: right" class="cart-total cl-gloo-gray">{{ parseFloat(subTotal) | currency }}</span>
                    </p>
                    <p v-if="!inclusiveVAT">
                      <span class="cart-total cl-gloo-product-text">Tax: </span>
                      <span style="float: right" class="cart-total cl-gloo-gray">{{ parseFloat(subTotal * vat) | currency }}</span>
                    </p>
                    <p>
                      <span class="cart-total cl-gloo-product-text">Shipping: </span>
                      <span style="float: right" class="cart-total cl-gloo-gray">{{ (deliveryFee ) | currency }}</span>
                    </p>

                    <p>
                      <span class="cart-total cl-gloo-product-text">Total: </span>
                      <span class="cart-total cart-grand-total cl-gloo-main">{{ parseFloat(total) | currency }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 order-summary--body--delivery">
            <div class="row checkout-card py20">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <p class="cl-gloo-product-text">Your delivery option: </p>
                <p><strong class="cl-gloo-gray">{{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryMethod.name }}</strong></p>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <p class="cl-gloo-product-text">Your order will be sent to </p>
                <p class="cl-gloo-gray"><strong>{{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.streetAddress }} {{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.addressLine2 }}</strong></p>
                <p class="cl-gloo-gray"><strong>{{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.city }} {{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.zipcode }}</strong></p>
                <p class="cl-gloo-gray"><strong>{{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.state }} {{ submittedOrder.addressInformation && submittedOrder.addressInformation.deliveryLocation.locationAddressId.country }}</strong></p>
              </div>
            </div>
            <v-card-title class="py30 tab-btn-holder">
              <div class="align-center w-100">
                <v-btn v-if="hasPermission('approve', 'order')" color="#48446F" outline class="px30 view-order-status" :to="localizedRoute('my-account/orders/'+orderId)">View Order Status</v-btn>
                <v-btn v-if="!hasPermission('approve', 'order')" color="#48446F" outline class="px30 view-order-status" :to="localizedRoute('/orders/'+submittedOrder.id)">View Order Status</v-btn>
              </div>
            </v-card-title>
            <div class="my20 cart-btn-holder">
              <div class="col-md-12 p0 my20">
                <router-link :to="localizedRoute('/#company-categories')" class="cl-accent">
                  <button-full
                  >
                    {{ 'Continue Shopping' }}
                  </button-full>
                </router-link>
              </div>
              <div class="col-md-12 p0 my20">
                <router-link to="/dashboard" class="cl-accent">
                  <button-outline color="light" class="w-100">Go to Dashboard</button-outline>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import Product from './Product'
import config from 'config'

export default {
  name: 'ThankYouPage',
  components: {
    ButtonFull,
    ButtonOutline,
    CartSummary,
    Product
  },
  data () {
    return {
      submittedOrder: {},
      orderId: null,
      isProductListVisible: false
    }
  },
  computed: {
    subTotal () {
      if (!this.submittedOrder.products) {
        return 0
      }
      return this.submittedOrder.products.reduce((total, product) => {
        return (product.price * product.qty) + total
      }, 0)
    },
    deliveryFee () {
      return this.submittedOrder.addressInformation ? this.submittedOrder.addressInformation.deliveryMethod.deliveryFee : ''
    },
    total () {
      let VAT = config.ecms.VAT
      if (!VAT || isNaN(VAT)) {
        VAT = 0.075
      }
      return this.inclusiveVAT
        ? (this.subTotal + this.deliveryFee)
        : (this.subTotal * (1 + VAT) + this.deliveryFee)
    },
    inclusiveVAT () {
      let { state } = this.$store
      return state.userDetails.companyDetails.inclusiveVAT
    },
    user () {
      return (this.$store.state.user && this.$store.state.user.current) ? this.$store.state.user.current.firstname : ''
    },
    vat () {
      let VAT = config.ecms.VAT
      if (!VAT || isNaN(VAT)) {
        VAT = 0.075
      }
      return VAT
    }
  },
  created () {
    this.$bus.$on('order-after-placed', (order) => {
      this.submittedOrder = order.order
      this.orderId = order.magentoOrder ? order.magentoOrder.magentoOrderId : order.order.id
    })
  },
  methods: {
    continueShopping () {
      this.$router.push(this.localizedRoute('/'))
    },
    toggleDisplay () {
      this.isProductListVisible = !this.isProductListVisible
    }
  }
}
</script>

<style lang="scss" scoped>
  .thank-you-content {
    padding-left: 0;

    p {
      line-height: 25px
    }

    @media (min-width: 64em) {
      h4 {
        font-size: 24px;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }
  .card-section {
    p {
      margin-bottom: 5px;
    }
  }

  .summary-title {
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  .cart-totals, .tab-btn-holder, .cart-btn-holder {
    @media (max-width: 767px) {
      margin-left: 3% !important;
      margin-right: 3% !important;
    }
  }
  .cart-total {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
    }
  }
  .cart-grand-total {
    float: right;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
    }
  }
  .link-span {
    position: relative;
    top: -7px;
  }
  a {
    color: #4F9221;
  }
  .tab-btn-holder {
    border: 1px solid #dfe3e8;
    background-color: #f9fafb;
    div {
      margin: 0 auto;
      button {
        width: 80%;
        @media (min-width: 767px) {
          width: 50%;
          font-size: 18px;
        }
        @media (min-width: 360px) {
          font-size: 16px;
        }
        // padding: 0 50px;
      }
    }
  }
  #order-summary--heading {
    margin: 0 auto;
    width: 80%;
    @media (min-width: 767px) {
      width: 70%;
    }
  }
  #order-summary--body {
    margin: 0 auto !important;
    @media (min-width: 1280px) {
      margin: 0 10% !important;
    }
  }
  .user {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 29px;
  }
  .order {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .order-summary--body--text h3 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    line-height: 22px;
  }
  .order-summary--body {
    @media (max-width: 1024px) {
      display: grid;
    }
  }
  .order-summary--body--products {
    @media (max-width: 1024px) {
      order: 2;
    }
  }
  .order-summary--body--delivery {
    @media (max-width: 1024px) {
      order: 1;
    }
  }
  .checkout-card--order-summary, .cart-totals {
    @media (min-width: 1024px) {
      margin-right: 10%;
    }
  }
  .checkout-card--order-summary {
    padding: unset !important;
  }
  .checkout .checkout-card {
    padding: 20px 40px;
    @media (max-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      margin-left: 3% !important;
      margin-right: 3% !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .order-summary--body--text {
    padding: 20px 40px;
    @media (max-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .is-visible {
    display: none;
  }
  .light {
    background-color: #fff;
  }
  button {
    border-radius: 5px;
    height: 50px;
  }
  .view-order-status {
    padding: 0 10%;
  }
</style>
