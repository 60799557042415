// Here you can place theme-specific stores that will be added to the core stores.
// Everything that you will export here will be treated as a Vuex module
// The good practice is to keep all modulase separated in this folder.

// Below you can find commented exaple for additional theme store
import userDetails from './modules/userdetails'
import ui from './ui-store'
import punchout from './modules/punchout'
import menu from './modules/menu'
import cities from './modules/cities'
import sync from './sync-store'
import order from './order-store'
import home from './modules/home'
import cart from './cart-store'
import checkout from './checkout-store'
import category from './category_store'
import product from './product_store'
import user from './user_store'
import search from './modules/search'
import sso from './modules/sso'
import approvalWorkflow from './modules/approval-workflow'
import catalogReq from './modules/catalog-request'

// const exampleThemeStore = {
//   namespaced: true,
//   state: {},
//   mutations: {},
//   actions: {}
// }

export default {
  // exampleThemeStore,
  userDetails,
  ui,
  product,
  punchout,
  menu,
  sync,
  order,
  home,
  cart,
  cities,
  checkout,
  category,
  user,
  search,
  sso,
  approvalWorkflow,
  catalogReq
}
