var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.link ? 'router-link' : 'button',{tag:"component",staticClass:"button-outline no-outline py15 bg-cl-transparent h4 no-underline sans-serif fs-medium",class:{
    light : _vm.color === 'light', 'brdr-white' : _vm.color === 'light', 'cl-white' : _vm.color === 'light',
    dark : _vm.color === 'dark', 'brdr-darkgray' : _vm.color === 'dark', 'cl-secondary' : _vm.color === 'dark',
    gray : _vm.color === 'gray', 'brdr-gray' : _vm.color === 'gray', 'cl-gloo-cornflower-blue' : _vm.color === 'gray',
    red : _vm.color === 'red', 'brdr-red' : _vm.color === 'red', 'cl-red' : _vm.color === 'red',
    px15 : _vm.link ? true : false,
    px40 : _vm.link ? false : true
  },attrs:{"to":_vm.localizedRoute(_vm.link)}},[_vm._t("default",[_vm._v("\n    Button\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }