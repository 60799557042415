<template>
  <transition name="fade" appear>

    <li class="row py10 listed-product">
      <div class="row col-md-6 col-sm-5 col-xs-3">
        <div class="col-md-3 col-sm-3 hidden-xs img-holder align-center ">
          <div class="">
            <img class="image" v-lazy="thumbnail" alt="" >
          </div>
        </div>
        <div class="start-xs between-sm details col-md-9 col-sm-9 -xs-12 pl0 pr0">
          <div class="row h4 name cl-gloo-product-text">
            {{ product.name | htmlDecode }}
          </div>
          <div class="row links">
            <div class="mt5" @click="removeItem">
              <remove-button />
            </div>
          </div>
        </div>
      </div>
      <div class="h5 cl-accent lh25 qty col-md-2 col-sm-2 col-xs-3 p0">
        <add-to-cart-widget :product="product"/>
      </div>
      <div class="qty-total col-md-2 col-sm-2 col-xs-3 cl-secondary p0">
        <div class="h4 price cl-gloo-text p0">
          <span>{{ product.price | currency }}</span>
        </div>
        <div class="h6 cl-bg-tertiary options p0" v-if="product.totals && product.totals.options">
          <div v-for="opt in product.totals.options" :key="opt.label" class="p0">
            <span class="opn">{{ opt.label }}: </span>
            <span class="opn">{{ opt.price | currency }}:</span>
            <span class="opv" v-html="opt.value" />
          </div>
        </div>
        <div class="h6 cl-bg-tertiary options p0" v-else-if="product.options">
          <div v-for="opt in product.options" :key="opt.label" class="p0">
            <span class="opn">{{ opt.label }}: </span>
            <span class="opv" v-html="opt.value" />
          </div>
        </div>
        <div class="h6 cl-error p0" v-if="product.errors && Object.keys(product.errors).length > 0">
          {{ product.errors | formatProductMessages }}
        </div>
      </div>
      <div class="align-left col-md-2 col-sm-3 col-xs-3 cl-gloo-main p0">
        <div class="h4 price cl-gloo-main p0">
          <span>{{ product.price * qty | currency }}</span>
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import Product from 'core/components/blocks/Microcart/Product'
import config from 'config'
import RemoveButton from './RemoveButton'
import AddToCartWidget from 'theme/components/core/AddToCartWidget'

export default {
  components: {
    AddToCartWidget,
    RemoveButton
  },
  mixins: [Product],
  data () {
    return {
      currency: config.i18n.currencySign
    }
  },
  created () {
    this.$bus.$on('cart-after-update', ({ product }) => {
      if (product.sku === this.product.sku) {
        this.qty = parseInt(product.qty)
      }
    })
    this.$bus.$on('cart-after-itemchanged', (event) => {
      if (event.item.sku === this.product.sku) {
        this.qty = event.item.qty
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .image {
    mix-blend-mode: multiply;
    vertical-align: top;
    width: 60px;
    @media (max-width: 767px) {
      width: 70px;
    }
    @media (max-width: 991px) {
      width: 40px;
    }
  }

  .details {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .name {
    @media (min-width: 767px) and (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
    font-size: 16px;
    text-align: left;
    padding-bottom: 0.6rem;
  }

  .price {
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  .options, .sku {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .qty {
    @media (max-width: 767px) {
      font-size: 12px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
    }
    text-align: center;
  }
  .qty-total {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 3%;
  }

  .actions {
    flex-direction: column;
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
    .links {
      @media (max-width: 991px) {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        margin-top: 12px;
      }
    }
  }

  .price-special {
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .price-original {
    text-decoration: line-through;
  }

  .price-regular {
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
      // color: #3d4094;
  }

  input {
    border: solid 1.5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 70%;
    margin: 1px 0;
    height: 33px;
    padding: 0;
    @media (max-width: 767px) {
      width: 80%;
      height: 30px;
      font-size: 12px;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button {
    vertical-align: middle;
  }

  button i {
    padding: 0;
  }

  .listed-product {
    border-bottom: solid 1px #e0e0e0;
    align-items: center;
  }

  .listed-product:last-child {
    border-bottom: none;
  }

  .widget {
    width: 80%;
    @media (max-width: 767px) {
      width: 90%;
    }
  }

  i {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }
</style>
