<template>
  <div id="home" class="bg-cl-gloo">
    <main-slider />
    <div class="container m30">
      <recent-orders />
      <modal name="modal-terms" static-data="terms">
        <p slot="header">{{ $t('Terms and conditions') }} </p>
      </modal>
      <popular-categories />
      <frequent-orders />
    </div>
  </div>
</template>

<script>
// 3rd party dependecies
import builder from 'bodybuilder'

// Core dependecies
import config from 'config'

// Core pages
import Home from 'core/pages/Home'

// Theme core components
import MainSlider from 'theme/components/core/blocks/MainSlider/MainSlider'
import Modal from 'theme/components/core/Modal'

// Theme local components
import Collection from 'theme/components/theme/blocks/Collection/Collection'
import PromotedOffers from 'theme/components/theme/blocks/PromotedOffers/PromotedOffers'
import TileLinks from 'theme/components/theme/blocks/TileLinks/TileLinks'
import RecentOrders from 'theme/components/core/blocks/Home/RecentOrders.vue'
import FrequentOrders from 'theme/components/core/blocks/Home/FrequentOrders.vue'
import PopularCategories from 'theme/components/core/blocks/Home/PopularCategories.vue'

export default {
  data () {
    return {
      // user: null,
      showModal: false
    }
  },
  methods: {
  },
  mixins: [
    Home
  ],
  components: {
    Modal,
    Collection,
    MainSlider,
    PromotedOffers,
    TileLinks,
    RecentOrders,
    FrequentOrders,
    PopularCategories
  },
  beforeMount () {
    if (global.$VS.__DEMO_MODE__) {
      this.$store.dispatch('claims/check', { claimCode: 'onboardingAccepted' }).then((onboardingClaim) => {
        if (!onboardingClaim) { // show onboarding info
          this.$bus.$emit('modal-toggle', 'modal-onboard')
          this.$store.dispatch('claims/set', { claimCode: 'onboardingAccepted', value: true })
        }
      })
    }
  },
  asyncData ({ store, route }) { // this is for SSR purposes to prefetch data
    return new Promise((resolve, reject) => {
      console.log('Entering asyncData for Home ' + new Date())
      let newProductsQuery = builder().query('match', 'category.name', 'Tees').andFilter('range', 'visibility', { 'gte': 2, 'lte': 4 }/** Magento visibility in search & categories */).build()
      let coolBagsQuery = builder().query('match', 'category.name', 'Women').andFilter('range', 'visibility', { 'gte': 2, 'lte': 4 }/** Magento visibility in search & categories */).build()
      store.dispatch('category/list', { includeFields: config.entities.optimize ? config.entities.category.includeFields : null }).then((categories) => {
        store.dispatch('product/list', {
          query: newProductsQuery,
          size: 8,
          sort: 'created_at:desc',
          includeFields: config.entities.optimize ? config.entities.productList.includeFields : []
        }).then(function (res) {
          if (res) {
            store.state.homepage.new_collection = res.items
          }

          store.dispatch('product/list', {
            query: coolBagsQuery,
            size: 4,
            sort: 'created_at:desc',
            includeFields: config.entities.optimize ? config.entities.productList.includeFields : []
          }).then(function (res) {
            if (res) {
              store.state.homepage.coolbags_collection = res.items
            }
            return resolve()
          })
        })
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gloo-secondary: color(gloo-secondary);
  #home, h1, h4, p  {
    // font-family: Larsseit !important;
  }
.container {
  min-width: 90% !important;
}
</style>
