<template>
  <div>
    <div id="sales-product-reports">
      <div v-if="parent === 'single page'" class="page-title">
        <v-card-title>
          <span class="section-title">Product Sales Report By Location</span>
        </v-card-title>
      </div>
      <div id="products-reports">
        <!-- Date range selector and View by date/day-of-the-week/month/year -->
        <div id="controls" class="row panel panel-default pad-panel">
          <div class="col-lg-6 col-md-12 col-sm-12 controls-wrapper">
            <div class="form-group filter-title">
              <span class="section-title">Orders Products for</span>
              <div class="daterange-box">
                <date-range-picker
                  :locale-data="locale"
                  :opens="opens"
                  @update="update"
                  :auto-apply="true"
                  :ranges="ranges"
                >
                  <!--Optional scope for the input displaying the dates -->
                  <div slot="input" slot-scope="picker">
                    <img src="/assets/calendar.svg" class="img-responsive datepickericon">
                    <input class="daterange" type="text" v-model="dateRange">
                  </div>
                </date-range-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row panel panel-default pad-panel mb-5">
          <div class="col-md-12 pxy-5">
            <div class="row">
              <v-autocomplete
                v-model="selectedCompanyLocations"
                :items="companyLocations"
                attach
                chips
                class="col-lg-6 col-md-12 col-sm-12 d-flex align-center location-drop-down"
                label="Locations"
                hide-details
                @click:clear="clearSelectedLocations"
                item-value="id"
                item-text="name"
                return-object
                menu-props="{ disableKeys: false}"
                multiple
                solo
                clearable
              >
                <template v-slot:prepend-item>
                  <v-checkbox
                    v-model="isAllLocationsSelected"
                    class="px-3 text-primary"
                    label="Select all Locations"
                  />
                </template>
              </v-autocomplete>
              <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-center export-btn-container">
                <button @click="exportAsCSV"
                        :disabled="selectedCompanyLocations.length === 0"
                        class="export-btn col-lg-3 col-md-6 mr-3">Export as CSV</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Reports Chart -->
        <div id="chart" class="row panel panel-default pad-panel" v-if="false">
          <show-loader v-if="loading" />
          <div v-else class="col-md-12" style="position: relative;">
            <revenue-chart
              :chart-data="chartData"
              :height="180"
              :chart-options="chartOptions"
            />
          </div>
        </div>

        <!-- Data Table showing Reports -->
        <div id="tables" class="row panel panel-default pad-panel">
          <div class="col-md-12">
            <!-- Search for finding reports on the Data Table -->
            <v-card id="title-and-search" class="table-title">
              <div class="row">
                <div id="dateRange-display" class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <div class="section-title">{{ dateRange }}</div>
                </div>
                <div class="search col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div class="external-search--box">
                    <input type="text" placeholder="Search" class="search-box" v-model="search">
                    <i class="material-icons" aria-hidden="true">search</i>
                  </div>
                </div>
              </div>
            </v-card>
            <v-card>
              <v-data-table
                :headers="periodHeaders"
                :items="tableData"
                :loading="loading"
                :search="search"
                :rows-per-page-items="[50, 100]"
                :custom-filter="customFilter"
              >
                <v-progress-linear slot="progress" color="success"/>
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left col-md-6">{{ props.item.period }}</td>
                  <td class="text-xs-left col-md-2">{{ props.item.itemName }}</td>
                  <template v-if="selectedCompanyLocations.length > 0">
                    <td class="text-xs-left col-md-2" v-for="location in selectedCompanyLocations" :key="location.id">{{ props.item[location.id] | currency }}</td>
                  </template>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css'
import moment from 'moment'
import { mapGetters } from 'vuex'
import RevenueChart from 'core/components/RevenueChart'
import ShowLoader from 'theme/components/core/blocks/CompanySettings/ShowLoader'
import exportCSVFile from 'theme/helpers/objectToCsv.js'
import i18n from 'core/lib/i18n'

export default {
  name: 'SalesReportsByProducts',
  props: {
    parent: {
      type: String,
      default: ''
    }
  },
  components: {
    DateRangePicker,
    RevenueChart,
    ShowLoader
  },
  data () {
    return {
      pagination: {
        sortBy: 'timestamp'
      },
      search: '',
      loading: true,
      startDate: '2019-01-01',
      endDate: moment().format('YYYY-MM-DD'),
      opens: 'right', // which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // format of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
        firstDay: 1, // ISO first day of week - see moment documenations for details
        showWeekNumbers: true // show week numbers on each row of the calendar
      },
      ranges: { // default value for ranges object (if you set this to false ranges will no be rendered)
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        'Last 2 Years': [moment().subtract(2, 'years').startOf('year'), moment().subtract(2, 'years').endOf('year')]
      },
      dateRange: `${moment('2019-01-01').format('MMM D, YYYY')} - ${moment().format('MMM D, YYYY')}`,

      tableData: [],
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        title: {
          display: true,
          text: 'Orders By Category'
        }
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      filter: '',
      productCategories: {},
      categoriesWithProducts: [],
      categoryColours: {},
      companyLocations: [],
      selectedCompanyLocations: [],
      orderCompanyLocations: [],
      isAllLocationsSelected: false

    }
  },
  mounted () {
    Promise.all([
      new Promise(resolve => {
        this.$store.dispatch('order/fetchCompletedOrders')
          .then(() => { resolve() })
          .catch(() => { resolve() })
      }).then(() => {
        this.loading = false
      })
    ])
    const locations = this.$store.state.userDetails.companyDetails.locations
    locations.sort((location1, location2) => {
      return location1.name < location2.name ? -1 : 1
    })
    this.companyLocations = locations
  },
  created () {
    if (!this.hasPermission('view', 'spend analysis')) {
      this.$bus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push(this.localizedRoute('/'))
    }
  },
  computed: {
    periodHeaders () {
      const headers = [
        { text: 'Period', sortable: true, value: 'timestamp' },
        { text: 'Items', sortable: true, value: 'itemName' }
      ]
      this.selectedCompanyLocations.forEach(location => {
        const locationId = location.id
        const dynamicHeader = {
          text: location.name, sortable: true, value: locationId
        }
        headers.push(dynamicHeader)
      })
      return headers
    },
    ...mapGetters({
      allOrders: 'order/getCompletedOrders'
    })
  },
  watch: {
    startDate: 'applyFilter',
    endDate: 'applyFilter',
    selectedCompanyLocations: 'applyFilter',
    isAllLocationsSelected: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        if (newValue) {
          this.selectedCompanyLocations = [...this.companyLocations]
        } else {
          this.clearSelectedLocations()
        }
      }
    }
  },
  methods: {
    clearSelectedLocations () {
      this.selectedCompanyLocations = []
      this.isAllLocationsSelected = false
    },
    exportAsCSV () {
      let data = []
      if (this.selectedCompanyLocations.length > 0) {
        let headers = {
          period: 'Period',
          itemName: 'Item'
        }
        this.selectedCompanyLocations.forEach(({ id, name }) => {
          headers[id] = name.replace(/(,)+/g, ' ')
        })
        this.tableData.forEach(entry => {
          const productRowInExcel = {
            period: entry.period,
            itemName: entry.itemName.replace(/(,)+/g, ' ')
          }
          this.selectedCompanyLocations.forEach(location => {
            productRowInExcel[location.id] = entry[location.id]
          })
          data.push(productRowInExcel)
        })
        const fileTitle = `Products_Location_Reports_${moment().format('X')}`
        exportCSVFile(headers, data, fileTitle)
      } else {
        console.log('No Report to print. Select a location to export the products report by location csv')
        this.$bus.$emit('notification', {
          type: 'warning',
          message: i18n.t('No Report to print. Select a location to export the products report by location csv'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
      }
    },
    customFilter (items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(i => (
        Object.keys(i).some(j => {
          return filter(i[j], search)
        })
      ))
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    calculateOrderTotal (order) {
      return order.products.reduce((total, product) => {
        return (product.price * product.qty) + total
      }, 0)
    },
    update (val) {
      this.startDate = moment(val.startDate).format('MMM D, YYYY')
      this.endDate = moment(val.endDate).format('MMM D, YYYY')
      this.dateRange = `${this.startDate} - ${this.endDate}`
    },
    applyFilter () {
      if (this.selectedCompanyLocations && this.selectedCompanyLocations.length > 0) {
        const locationIds = this.selectedCompanyLocations.map(location => location.id)
        this.orderCompanyLocations = this.allOrders.filter(currentOrder => {
          if (currentOrder.addressInformation.deliveryLocation.id) {
            const orderLocationId = currentOrder.addressInformation.deliveryLocation.id
            return locationIds.includes(orderLocationId)
          }
        })

        this.filterByDateRange()
      } else {
        this.tableData = []
      }
    },

    filterByDateRange () {
      let periods = []
      let monthsBetweenDates = moment(this.endDate).diff(moment(this.startDate), 'months', true)
      monthsBetweenDates = Math.floor(monthsBetweenDates)
      let startMonth = Number(moment(this.startDate).format('M'))
      let startYear = Number(moment(this.startDate).format('YYYY'))

      // generate the periods
      periods.push({
        toString: `${this.months[startMonth - 1]} ${startYear}`,
        timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X')
      })

      const monthsInYear = 12
      for (let i = 1; i <= monthsBetweenDates; i++) {
        startMonth++
        if (startMonth > monthsInYear) {
          startMonth = 1
          startYear++
        }
        periods.push({
          toString: `${this.months[startMonth - 1]} ${startYear}`,
          timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X')
        })
      }

      let tableData = []
      const objectResult = {}

      periods.forEach(entry => {
        let entryDate = entry.toString
        this.orderCompanyLocations.forEach(order => {
          if (moment(order.createdAt).format('MMMM YYYY') === entryDate) {
            if (!objectResult[entryDate]) {
              objectResult[entryDate] = {}
            }
            const products = order.products
            products.forEach(product => {
              const productToPush = {
                locationId: order.addressInformation.deliveryLocation.id,
                price: Number(product.price) * Number(product.qty),
                id: product.id,
                timestamp: moment(order.createdAt).format('X')
              }

              if (objectResult[entryDate][product.name]) {
                const locationsForProduct = objectResult[entryDate][product.name]
                const locationExists = locationsForProduct.find(location => location.locationId === productToPush.locationId)
                if (locationExists) {
                  const locationIndex = locationsForProduct.map(loc => loc.locationId).indexOf(locationExists.locationId)
                  const price = locationExists.price + productToPush.price
                  locationsForProduct[locationIndex] = {
                    ...locationExists,
                    price
                  }
                } else {
                  objectResult[entryDate][product.name].push(productToPush)
                }
              } else {
                objectResult[entryDate][product.name] = [productToPush]
              }
            })
          }
        })
      })

      for (const key in objectResult) {
        const period = key
        const productObject = objectResult[key]
        for (const productName in productObject) {
          const productPriceValues = productObject[productName]
          let tableDataObject = {
            period,
            itemName: productName
          }
          productPriceValues.forEach(priceValue => {
            tableDataObject[priceValue.locationId] = priceValue.price
            tableDataObject['timestamp'] = priceValue.timestamp
          })

          this.selectedCompanyLocations.forEach(location => {
            if (!tableDataObject.hasOwnProperty(location.id)) {
              tableDataObject[location.id] = 0
            }
          })
          tableData.push(tableDataObject)
        }
      }
      this.generateChartData(tableData)
    },
    generateChartData (tableData) {
      tableData.sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return 0
        }
        return (b.timestamp < a.timestamp) ? 1 : -1
      })
      this.tableData = tableData
    },
    generateColoursForCategory () {
      let colours = {
        fillColor: this.randomColour(),
        strokeColor: this.randomColour(),
        highlightFill: this.randomColour(),
        highlightStroke: this.randomColour(),
        borderColor: this.randomColour(),
        color: this.randomColour()
      }
      return colours
    },
    randomColour () {
      let o = Math.round
      let r = Math.random
      const s = 255
      return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-white: color(white);
$color-search-border: color(gloo-search-border);
$color-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);
$color-success: color(gloo-success);

 .filter-title {
   padding: 15px 5px;
 }

#controls {
  background-color: color(white);

  .daterange-box {
    display: inline-block;
    margin: 0 15px;
  }

  .period-filter {
    text-align: center;
  }
}
.export-btn {
  width: 200px;
  border: 1px solid lightgray;
  height: 50px;
  background: linear-gradient(173.96deg, #EEFAF4 0%, #A7CDA3 100%);
}

#chart {
  padding: 40px 0;
}

.pxy-5 {
  padding: .6rem !important;
}

#controls, #chart {
  margin: 0 0 50px !important;
}

#controls span.section-title, #title-and-search .section-title {
  padding-left: 20px;
  font-size: 21px;
  line-height: 25px;
  color: $color-gray;
  font-weight: 600;
}

#title-and-search .section-title {
  padding-left: 0;
}

.controls-wrapper {
  align-self: center;
}

.location-drop-down .v-text-field__details {
  display: none !important;
}

.btn-group {
  .btn {
    padding: 25px 10px;
    color: #7D84A0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.13px;
    line-height: 19px;
    border-radius: 0;

    &:hover {
      color: $color-gloo-main;
    }
  }

  .active {
    color: $color-gloo-main;
    border-bottom: 1px solid $color-gloo-main;
    font-weight: 600;
  }
}

input.daterange {
  background-image: linear-gradient(180deg, #FAFBFC 0%, $color-white 100%);
  border: 1.5px solid $color-search-border;
  border-radius: 3px;
  padding: 5px;
  padding-left: 35px;
}

.datepickericon {
  position: absolute;
  top: 10px;
  left: 10px;
}

#tables>div {
  padding: 0;
}

#title-and-search>div {
  padding: 10px !important;

  #dateRange-display {
    align-self: center;
  }
}

// Style to properly align search icon in Search bar on Data-table
.search {
  margin: auto;
}

.external-search--box {
  text-align: left;
}

.search-box {
  width: 100%;
}

.v-card {
  // border-radius: 2px;
  box-shadow: 0 1px 3px 0 #d7d7e0 !important;
}

.card-title {
  font-size: 24px;
  line-height: 2em;
  display: inline-block;
}

.embolden {
  font-weight: 600 !important;
}

@media (min-width: 1200px) and (max-width:1291px) {
  .period-filter .btn {
    padding: 25px 5px;
  }
}

@media (min-width: 1200px) {
  .period-filter {
    text-align: right !important;
  }
}

@media (max-width: 1199px) {
  #controls span.section-title {
    padding-left: 0;
  }
  .controls-wrapper {
    text-align: center;
    padding-top: 25px;
  }
  .daterange-box {
    margin-top: 15px !important;
  }
    .export-btn-container {
    padding-top: 1rem !important;
  }
}

@media (max-width: 510px) {
  #dateRange-display, .search {
    width: 100%;
    padding: 7px;
    flex-basis: 100%;
    max-width: 100%;
  }
  #title-and-search .section-title {
    text-align: center;
  }

}
</style>
