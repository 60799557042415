<template>
  <button class="brdr-none bg-cl-transparent p0 middle-xs inline-flex cl-secondary">
    <slot>
      <i class="material-icons h4 p5 pr0">keyboard_arrow_down</i>
    </slot>
  </button>
</template>

<script>
export default {
  name: 'DecrementButton',
  methods: {
    decrement (data) {
      data--
    }
  }
}
</script>

<style scoped>
  i {
    font-weight: bold;
    font-size: 21px;
    padding: 0;
  }
</style>
