<template>
  <div>
    <button
      type="button"
      :aria-label="$t('Open search panel')"
      class="bg-cl-transparent brdr-none inline-flex"
      data-testid="openSearchPanel"
      id="search-btn"
      @click="toggleSearchPanel(true)"
    >
      <img src="/assets/Search-Icon.svg">
    </button>
    <div class="external-search--box">
      <input
        ref="search"
        id="search"
        v-model="search"
        class="py10 h4 cl-gloo-accent2 product-search"
        :placeholder="'Type what you are looking for...'"
        type="text"
        data-testid="searchPanel"
        @focus="validateSearch"
      >
      <!-- <i class="material-icons" aria-hidden="true">search</i> -->
      <img src="/assets/Search-Icon.svg">
    </div>
    <search-panel/>
  </div>
</template>

<script>
import SearchIcon from 'core/components/blocks/Header/SearchIcon'
import SearchPanel from '../SearchPanel/SearchPanel.vue'
import _ from 'lodash'

export default {
  components: {
    SearchPanel
  },
  mixins: [
    SearchIcon
  ],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    searchText () {
      return this.$store.getters.getSearchText
    }
  },
  watch: {
    search: function (newVal, oldVal) {
      if (this.searchText !== newVal) {
        this.$store.dispatch('setSearchText', newVal)
        this.toggleSearchPanel(true)
      }
      if (this.searchText.length < 1) {
        this.toggleSearchPanel(false)
      }
    },
    searchText: function (val) {
      this.search = val
    },
    '$route': function (val) {
      if (val.name !== 'home') {
        this.toggleSearchPanel(false)
      }
    }
  },
  methods: {
    toggleSearchPanel (status) {
      this.$store.commit('ui/setSearchpanel', status)
      this.$bus.$emit('search-status-changed', status)
    },
    debounceSearch () {
      _.debounce(() => {
        this.$store.dispatch('setSearchText', this.search)
      }, 500)()
    },
    validateSearch () {
      if (this.search.length > 0) {
        this.toggleSearchPanel(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-white: color(white);
$color-primary: color(primary);

#search-btn {
  display: none;
}

.external-search--box img {
  position: absolute;
  left: 0;
  top: 0px;
  padding: 10px 15px 13px;
  height: 50px;
}

.external-search--box input[type="text"] {
  padding-left: 60px;
}

.product-search {
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 1px rgba(127, 131, 155, 0.15), 0 1px 2px 0 rgba(127, 131, 155, 0.3);
  width: 500px;

  @media (max-width: 1680px) {
    width: 450px;
  }
  @media (max-width: 1499px) {
    width: 400px;
  }
  @media (max-width: 1369px) {
    width: 380px;
  }
  @media (max-width: 1260px) {
    width: 350px;
  }
  @media (max-width: 1023px) {
    max-width: 300px;
  }
  @media (max-width: 839px) {
    max-width: 280px;
  }
  @media (max-width: 799px) {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #search-btn {
    display: block;
  }
  .external-search--box {
    display: none;
  }
}
</style>
