<template>
  <p>{{ message }}</p>
</template>

<script>

export default {
  data () {
    return {
      message: ''
    }
  },
  created () {
    let sessionId = this.$route.params.sessionId
    if (!sessionId) {
      this.$router.push('page-not-found')
    }

    this.$bus.$emit('notification-progress-start', 'Initialization in progress ...')
    setTimeout(() => {
      this.$store.dispatch('resumeSAPSRMSession', sessionId)
        .then(response => {
          const srmData = response.data
          if (srmData.code === 200) {
            global.$VS.userTokenInvalidateLock = 0
            this.$store.dispatch('fetchSingleUserDetails', { userId: srmData.result.userId, updateState: true })
              .then(({data}) => {
                if (data.code === 200) {
                  // check if user is active i.e. not disabled/suspended
                  const userStatus = data.result.data.user.isActive
                  if (!userStatus) {
                    this.$bus.$emit('notification-progress-stop')
                    alert('Your account has been suspended')
                    this.$bus.$emit('user-before-logout')
                    window.history.go(-1)
                  } else {
                    this.$bus.$emit('notification-progress-stop')
                    let companyStoreViewCode = this.$store.state.userDetails.companyDetails.companyCatalog
                      ? this.$store.state.userDetails.companyDetails.companyCatalog.storeId
                      : ''

                    this.$bus.$emit('prepare-storeview', companyStoreViewCode)
                    this.$bus.$emit('on-reinitialize-storage', '') // this is to ensure that the cache ignores the concept of multistore

                    this.$store.dispatch('user/changeUserToken', { newToken: srmData.result.result, meta: srmData.result.meta })

                    this.$router.push('/')
                  }
                }
              })
          } else {
            this.$bus.$emit('notification-progress-stop')
            this.message = 'An error occured'
          }
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = error.message
          if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message
          }
          if (error.response && error.response.data && error.response.data.result) {
            errorMessage = error.response.data.result
          }
          this.message = `An error occured. ${errorMessage}`
        })
    }, 500)
  }
}
</script>
