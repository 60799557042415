<template>
  <div id="edit-user--permissions-wrapper" class="panel">
    <div id="edit-user--permissions">
      <div>
        <div class="pl0">
          <span class="section-title">Permissions</span>
        </div>
      </div>
      <div class="permissions row pt30">
        <div v-for="acos in acosData" :key="acos.id" class="col-lg-3 col-md-3 col-sm-6 col-xs-12 py15">
          <div class="pb20">
            <span class="permission-name">{{ acos.id }}</span>
          </div>
          <div v-for="aco in acos.value" :key="aco.id">
            <v-checkbox
              v-model="selectedACOS"
              class="permission-action"
              :label= "`Can ${aco.action}`"
              :value="{acoId: aco.id}"
              color="success"
            />
          </div>
        </div>
      </div>
    </div>
    <v-card-title class="tab-btn-holder">
      <div v-if="acosData && hasPermission('edit', 'user')">
        <v-btn large outline color="gray" class="edit" @click="assignPermissions()">Update Permissions</v-btn>
        <v-btn large outline color="gray" @click="toggleEditPermissions(false)" class="previous-link">
          <i class="material-icons">keyboard_backspace</i>Go Back
        </v-btn>
      </div>
    </v-card-title>
  </div>
</template>

<script>
import ShowLoader from './ShowLoader.vue'

export default {
  name: 'AssignSingleUserPermissions',
  data () {
    return {
      acos: {},
      acoKeys: [],
      showLoader: true,
      selectedACOS: [],
      allPermissions: [],
      companyId: null
    }
  },
  computed: {
    acosData () {
      let temp = this.acoKeys.map((item, index) => {
        let value = this.acos[item]
        let values = value.filter(entry => {
          let visibilityString = entry.visibility
          if (!visibilityString) {
            return false
          }
          let visibilityObject = JSON.parse(visibilityString)
          let visibilities = visibilityObject.visibilities
          let shouldShow = false
          visibilities.forEach(entry => {
            if (entry === 'store' || entry === 'all') {
              shouldShow = true
            }
          })
          return shouldShow
        })
        return {
          id: item,
          value: values
        }
      })
      return temp.filter(entry => {
        return entry.value.length > 0
      })
    },
    selectedUser () {
      let userId = this.$route.params['userId']
      return this.$store.getters.getUserDetails(userId)
    }
  },
  methods: {
    inArray (array, item) {
      return array.find(obj => {
        return obj.acoId === item.id
      })
    },
    toggleEditPermissions (status) {
      this.$bus.$emit('editing-permissions', status)
    },
    assignPermissions () {
      this.$bus.$emit('notification-progress-start', 'Saving ...')
      let self = this
      let permissionsToBeUpdated = []
      this.allPermissions.forEach(permission => {
        let data = {
          acoId: permission.id,
          assign: self.inArray(self.selectedACOS, permission) ? 1 : 0
        }
        permissionsToBeUpdated.push(data)
      })
      this.$store.dispatch('updateUserPermissions', {
        permissions: permissionsToBeUpdated,
        userId: this.selectedUser.id
      })
        .then(({data}) => {
          this.$bus.$emit('notification-progress-stop')
          if (data.code === 200) {
            // force the list of users to be retrieved next time it is called
            // in order to update the users' permissions stored in the vuex store
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'Operation successful',
              action1: { label: 'OK', action: 'close' }
            })
          }
          this.toggleEditPermissions(false)
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while saving details',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    populateSelectedActions () {
      let selectedActs = []
      if (this.selectedUser && this.selectedUser.permissions) {
        this.selectedUser.permissions.forEach(permission => {
          selectedActs.push({ acoId: permission.acoId.id })
        })
        this.selectedACOS = selectedActs
      }
    }
  },
  mounted () {
    let userId = this.$route.params['userId']
    if (!userId) {
      this.$router.push('page-not-found')
    }
    this.companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : null
    if (this.companyId) {
      // fetch access control objects
      this.$store.dispatch('fetchAccessControlObjects')
        .then(({ data }) => {
          if (data.code === 200) {
            this.showLoader = false
            this.allPermissions = data.result ? data.result.data.acos : []
            let tempSet = new Set()

            for (let i = 0; i < this.allPermissions.length; i++) {
              tempSet.add(this.allPermissions[i].model)
              if (!this.acos[this.allPermissions[i].model]) {
                this.acos[this.allPermissions[i].model] = []
              }
              this.acos[this.allPermissions[i].model].push(this.allPermissions[i])
            }
            this.acoKeys = [...tempSet]
            this.populateSelectedActions()
          }
        })
        .catch(error => {
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while retrieving access control objects',
              action1: { label: 'OK', action: 'close' }
            })
          }
          this.showLoader = false
        })
    }
  },
  components: {
    ShowLoader
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
$color-gloo-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);

.permission-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}

.permission-action {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-top: 0;
}

.permissions {
  color: $color-gloo-gray;
}

#edit-user--permissions {
  margin: 50px 0 0 !important;
  padding: 30px;
}

.tab-btn-holder {
  border: 1px solid #dfe3e8;
  background-color: #f9fafb;
}

.v-btn.edit {
  margin: 0;
}

.previous-link {
  i {
    margin-right: 5px;
    font-size: 14px;
  }
}

#edit-user--permissions-wrapper .tab-btn-holder .v-btn--large {
  @media (max-width: 449px) {
    padding: 0 16px !important;
  }
  @media (max-width: 384px) {
    padding: 0 10px !important;
  }
  @media (max-width: 360px) {
    // padding: 0 5px !important;
    display: block;
    margin: 10px 0;
  }
}
</style>
