// You can extend core UI store here
// The good practise is to keep all ui-related states in this file

import Vue from 'vue'
import localForage from 'localforage'
import UniversalStorage from 'core/store/lib/storage'
import config from 'config'
import * as types from 'core/store/mutation-types'
import store from 'core/store'
import EventBus from 'core/store/lib/event-bus'
import router from 'core/router'
import apiService from 'theme/services/api_service'

EventBus.$on('user-before-logout', () => {
  store.dispatch('clearUserDetailsOnLogout')
})

EventBus.$on('on-app-load', () => {
  initializeStorage()
  global.$VS.db.companyDetailsCollection.getItem('company-details')
    .then(response => {
      store.commit('setCompanyDetails', response)
    })
  global.$VS.db.companyDetailsCollection.getItem('location-details')
    .then(response => {
      store.commit('setLocationDetails', response)
    })
  global.$VS.db.usersCollection.getItem('jwToken')
    .then(response => {
      store.commit('setUserToken', response)
      if (!config.ecms.allowMultipleUserSessions) {
        store.dispatch('validateUserSession')
      }
    })
  global.$VS.db.usersCollection.getItem('current-storecode')
    .then(response => {
      store.state.user.current_storecode = response
      global.$VS.storeView = prepareStoreView(response)
    })
  global.$VS.db.usersCollection.getItem('user-permissions')
    .then(response => {
      store.commit('setUserPermissions', response)
    })
  global.$VS.db.usersCollection.getItem('user-role')
    .then(response => {
      store.commit('setUserRole', response)
    })
  global.$VS.db.usersCollection.getItem('delivery-locations')
    .then(response => {
      store.commit('setDeliveryLocations', response)
    })
  global.$VS.db.usersCollection.getItem('userId')
    .then(response => {
      store.commit('setUserId', response)
    })
  global.$VS.db.companyDetailsCollection.getItem('company-roles')
    .then(response => {
      store.commit('setCompanyRoles', response)
    })
  global.$VS.db.companyDetailsCollection.getItem('company-users')
    .then(response => {
      store.commit('setCompanyUsers', response)
    })
  global.$VS.db.usersCollection.getItem('user-phone-number')
    .then(response => {
      store.commit('setUserPhoneNumber', response)
    })
  global.$VS.db.usersCollection.getItem('password-changed')
    .then(response => {
      store.commit('setUserPasswordChanged', response)
    })
  global.$VS.db.usersCollection.getItem('phone-number-changed')
    .then(response => {
      store.commit('setUserPhoneNumberChanged', response)
    })
  global.$VS.db.companyDetailsCollection.getItem('all-company-orders')
    .then(response => {
      store.commit('setAllCompanyOrders', response)
    })
  global.$VS.db.usersCollection.getItem('punchout-session-id')
    .then(response => {
      store.commit('setSessionId', response)
    })
  global.$VS.db.usersCollection.getItem('punchout-erp-type')
    .then(response => {
      store.commit('setErpType', response)
    })
})

EventBus.$on('on-reinitialize-storage', (storeCode) => {
  initializeStorage(storeCode)
})

EventBus.$on('prepare-storeview', (storeCode) => {
  prepareStoreView(storeCode)
})

function prepareStoreView (storeCode) {
  let storeView = { // current, default store
    tax: config.tax,
    i18n: config.i18n,
    elasticsearch: config.elasticsearch,
    storeCode: '',
    storeId: 0
  }
  if (storeCode) { // current store code
    if ((storeView = config.storeViews[storeCode])) {
      storeView.storeCode = storeCode
      store.state.user.current_storecode = storeCode
      global.$VS.db.usersCollection.setItem('current-storecode', storeCode)
    }
  } else {
    storeView.storeCode = config.defaultStoreCode || ''
    store.state.user.current_storecode = config.defaultStoreCode || ''
    global.$VS.db.usersCollection.setItem('current-storecode', config.defaultStoreCode || '')
  }
  if (!global.$VS.storeView || global.$VS.storeView.storeCode !== storeCode) {
    global.$VS.storeView = storeView
    global.$VS.i18n.locale = storeView.i18n.defaultLocale
  }
  return storeView
}

function initializeStorage (storeCode = '') {
  const dbNamePrefix = storeCode ? storeCode + '-' : ''
  Vue.prototype.$db = {
    ordersCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'orders',
      driver: localForage[config.localForage.defaultDrivers['orders']]
    })),
    categoriesCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'categories',
      driver: localForage[config.localForage.defaultDrivers['categories']]
    })),
    attributesCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'attributes',
      driver: localForage[config.localForage.defaultDrivers['attributes']]
    })),
    cartsCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'carts',
      driver: localForage[config.localForage.defaultDrivers['carts']]
    })),
    elasticCacheCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'elasticCache',
      driver: localForage[config.localForage.defaultDrivers['elasticCache']]
    })),
    productsCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'products',
      driver: localForage[config.localForage.defaultDrivers['products']]
    })),
    claimsCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'claims',
      driver: localForage[config.localForage.defaultDrivers['claims']]
    })),
    wishlistCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'wishlist',
      driver: localForage[config.localForage.defaultDrivers['wishlist']]
    })),
    compareCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'compare',
      driver: localForage[config.localForage.defaultDrivers['compare']]
    })),
    usersCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'user',
      driver: localForage[config.localForage.defaultDrivers['user']]
    })),
    syncTaskCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'syncTasks',
      driver: localForage[config.localForage.defaultDrivers['syncTasks']]
    })),
    checkoutFieldsCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'checkoutFieldValues',
      driver: localForage[config.localForage.defaultDrivers['checkoutFieldValues']]
    })),
    newsletterPreferencesCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'newsletterPreferences',
      driver: localForage[config.localForage.defaultDrivers['newsletterPreferences']]
    })),
    ordersHistoryCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'ordersHistory',
      driver: localForage[config.localForage.defaultDrivers['ordersHistory']]
    })),
    cmsData: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'cms'
    })),
    companyDetailsCollection: new UniversalStorage(localForage.createInstance({
      name: dbNamePrefix + 'shop',
      storeName: 'companyDetails',
      driver: localForage[config.localForage.defaultDrivers['companyDetails']]
    }))
  }
  global.$VS.db = Vue.prototype.$db // localForage instance
}

const state = {
  companyDetails: null,
  locationDetails: null,
  companyRoles: null,
  companyUsers: null,
  jwToken: null,
  userPermissions: null,
  userRole: null,
  deliveryLocations: [],
  userId: null,
  frequentlyBoughtItems: {},
  bestSellingProducts: [],
  phoneNumber: null,
  passwordChanged: null,
  allCompanyOrders: [],
  phoneNumberChanged: null,
  totalOrders: null
}

const getters = {
  getCompanyDetails: (state) => {
    return state.companyDetails
  },
  getLocationDetails: (state) => {
    return state.locationDetails
  },
  getCompanyUsers: (state) => {
    return state.companyUsers
  },
  getDeliveryLocations: state => {
    return state.deliveryLocations
  },
  getCompanyLocationDetails: (state) => {
    return state.companyDetails ? state.companyDetails.locations : null
  },
  getCompanyFrequentlyPurchasedItems: (state) => (companyId) => {
    return state.frequentlyBoughtItems[companyId]
  },
  getBestSellingProducts: state => {
    return state.bestSellingProducts
  },
  getUserDetails: state => userId => {
    if (!state.companyUsers) {
      return null
    }
    return state.companyUsers.find(user => {
      return userId === user.id
    })
  },
  getCompanyRoles: state => {
    return state.companyRoles || []
  },
  getAllCompanyOrders: state => {
    return state.allCompanyOrders
  },
  getTotalOrders: state => state.totalOrders
}

const actions = {
  createUser: function ({rootState}, userData) {
    let endpoint = config.ecms.createUser_endpoint
    return apiService.send(endpoint, 'post', userData)
  },
  fetchCompanyDetails: function (context, companyId) {
    let endpoint = config.ecms.getCompanyDetails_endpoint
    endpoint = endpoint.replace('{{companyId}}', companyId)
    return apiService.send(endpoint, 'get')
      .then(({ data }) => {
        if (data.code === 200) {
          context.commit('setCompanyDetails', data.result.company)
          context.commit('setLocationDetails', data.result.data.user.location)
        }
        return data
      })
      .catch(error => {
        console.log(error)
      })
  },
  fetchCompanyUsers: function (context, companyId) {
    let endpoint = config.ecms.getCompanyUsers_endpoint
    endpoint = endpoint.replace('{{companyId}}', companyId)
    return apiService.send(endpoint, 'get')
      .then(response => {
        let data = response.data
        if (data.code === 200) {
          context.commit('setCompanyUsers', data.result.data.users)
          EventBus.$emit('new-user-added', response)
        }
        return response
      })
  },
  setCompanyUsers: function ({ commit }, users) {
    commit('setCompanyUsers', users)
  },
  fetchAllCompanyOrders: function (context, data) {
    let companyId = this.state.userDetails.companyDetails.id
    const {page, sortBy, direction, perPage, searchCriteria} = data
    let endpoint = `${config.ecms.orders_endpoint}?filter=on&filter_by=company&companyId=${companyId}`
    if (page && sortBy && direction && perPage) {
      endpoint = `${config.ecms.orders_endpoint}?filter=on&filter_by=company&companyId=${companyId}&page=${page}&sort=${sortBy}&direction=${direction}&perPage=${perPage}`
    }
    if (searchCriteria) {
      endpoint = `${config.ecms.orders_endpoint}?filter=on&filter_by=company&companyId=${companyId}&page=${page}&perPage=${perPage}&search=${searchCriteria}&sort=${sortBy}&direction=${direction}`
    }
    return apiService.send(`${endpoint}`, 'get')
      .then((response) => {
        let data = response.data
        let orders = data.result.orders
        let totalOrder = data.result.totalOrders
        if (data.code === 200) {
          context.commit('setAllCompanyOrders', orders)
          context.commit('setOrderTotal', totalOrder)
        }
        return response
      })
      .catch(err => {
        console.log(err)
      })
  },
  fetchSingleUserDetails: function ({ commit }, { userId, updateState = false }) {
    let endpoint = config.ecms.user_endpoint
    return apiService.send(`${endpoint}/${userId}`, 'get')
      .then(response => {
        const { data } = response
        if (data.code === 200) {
          if (updateState) {
            commit('setUserId', data.result.data.user.id)
            commit('setCompanyDetails', data.result.data.user.company)
            commit('setLocationDetails', data.result.data.user.location)
            commit('setUserPermissions', data.result.data.user.permissions)
            commit('setUserRole', data.result.data.user.roleId ? data.result.data.user.roleId.name : null)
            commit('setDeliveryLocations', data.result.data.user.deliveryLocations)
            // fetch company menu
            if (data.result.data.user.company) {
              store.dispatch('getMenu', data.result.data.user.company.id)
              store.dispatch('fetchCompanyRoles')
            }
            commit('setUserPhoneNumber', data.result.data.user.phoneNumber)
            commit('setUserPasswordChanged', data.result.data.user.passwordChanged)
            commit('setUserPhoneNumberChanged', data.result.data.user.phoneNumberChanged)
          }
        }
        return response
      })
  },
  addRole: function ({ commit }, { roleName, roleDescription }) {
    let endpoint = config.ecms.role_endpoint
    let data = {
      name: roleName,
      companyId: this.state.userDetails.companyDetails.id,
      description: roleDescription
    }
    return apiService.send(endpoint, 'post', data)
  },
  fetchCompanyRoles: function (context) {
    let endpoint = `${config.ecms.role_endpoint}?filter=on&companyId=${this.state.userDetails.companyDetails.id}`
    return apiService.send(endpoint, 'get')
      .then((response) => {
        let data = response.data
        if (data.code === 200) {
          context.commit('setCompanyRoles', data.result.data.roles)
        }
        return response
      })
      .catch(error => {
        console.log(error)
      })
  },
  assignUsersToRole: function ({ commit }, { userIds, companyId, roleId }) {
    let endpoint = `${config.ecms.role_endpoint}/${roleId}`
    let data = {
      companyId,
      userIds
    }
    return apiService.send(endpoint, 'put', data)
  },
  assignPermissionsToRole: function ({ commit }, { companyId, roleId, permissions }) {
    let endpoint = `${config.ecms.role_endpoint}/${roleId}`
    let data = {
      companyId,
      permissions
    }
    return apiService.send(endpoint, 'put', data)
  },
  updateUserPermissions: function ({ dispatch, state }, { userId, permissions }) {
    let endpoint = `${config.ecms.user_endpoint}/${userId}`
    let data = {
      permissions
    }
    return apiService.send(endpoint, 'put', data)
      .then(result => {
        if (result.status === 200) {
          dispatch('fetchCompanyUsers', state.companyDetails.id)
        }
        return result
      })
      .catch(err => {
        throw err
      })
  },
  updateUserRole: function ({ commit }, { userId, roleId }) {
    let endpoint = `${config.ecms.user_endpoint}/${userId}`
    let data = {
      roleId
    }
    return apiService.send(endpoint, 'put', data)
  },
  fetchActions: function ({ commit }) {
    let endpoint = `${config.ecms.actions_endpoint}`
    return apiService.send(endpoint, 'get')
  },
  fetchAccessControlObjects: function ({ commit }) {
    let endpoint = `${config.ecms.accessControlObjects_endpoint}`
    return apiService.send(endpoint, 'get')
  },
  setUserToken: function ({ commit, rootState }, { newToken, meta }) {
    store.commit(types.USER_TOKEN_CHANGED, { newToken, meta }, { root: true })
  },
  fetchMenu: function (context, {companyId, menuId}) {
    let endpoint = `${config.menu.endpoint}`
    endpoint = endpoint + '?companyid=' + companyId
    return apiService.send(endpoint, 'get')
  },
  clearUserDetailsOnLogout: function (context) {
    context.commit('setCompanyDetails', null)
    context.commit('setLocationDetails', null)
    context.commit('setCompanyRoles', null)
    context.commit('setCompanyUsers', null)
    context.commit('setUserToken', null)
    context.commit('setCurrentStoreCode', null)
    context.commit('setUserPermissions', null)
    context.commit('setUserRole', null)
    context.commit('setUserId', null)
    context.commit('setDeliveryLocations', null)
    context.commit('setUserPhoneNumber', null)
    context.commit('setUserPasswordChanged', null)
    context.commit('setUserPhoneNumberChanged', null)
    context.commit('clearCurrentUserOnCache', null)
    context.commit('setAllCompanyOrders', null)
  },
  loginUserOnEcmsCore: function (context, { emailAddress, password }) {
    let endpoint = `${config.ecms.ecmsLogin_endpoint}`
    return apiService.send(endpoint, 'post', { emailAddress, password })
      .then((response) => {
        if (response.data.code === 200) {
          context.commit('setUserToken', response.data.result.data ? response.data.result.data.token : null)
          return response
        }
      })
  },
  fetchLocationDetails: function (context, locationId) {
    let endpoint = config.ecms.locations_endpoint
    return apiService.send(`${endpoint}/${locationId}`, 'get')
      .then(response => {
        return response
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateLocation: function (context, { data, locationId }) {
    let endpoint = config.ecms.locations_endpoint
    return apiService.send(`${endpoint}/${locationId}`, 'put', data)
  },
  updateUser: function ({ commit }, data) {
    let { userId } = data
    let endpoint = `${config.ecms.user_endpoint}/${userId}`
    return apiService.send(endpoint, 'put', data)
  },
  validateUserSession: function (context) {
    let jwt = store.state.userDetails.jwToken
    if (router.currentRoute.path.indexOf('password-reset') > -1 ||
        router.currentRoute.path.indexOf('forgot-password') > -1) {
      return
    }
    if (router.currentRoute.path.indexOf('shopping/session') > -1) {
      store.dispatch('clearUserDetailsOnLogout')
      return
    }
    if (!jwt) {
      store.dispatch('clearUserDetailsOnLogout')
      router.push('/login')
      return
    }
    let endpoint = config.ecms.validateSession_endpoint
    return apiService.send(endpoint, 'post', {})
      .then(response => {
        if (response.status === 200) {
          let data = response.data
          if (!data.result.valid) {
            EventBus.$emit('notification', {
              type: 'error',
              message: 'Session expired. Please log in again',
              action1: { label: 'OK', action: 'close' }
            })
            EventBus.$emit('user-before-logout', { silent: true })
            store.dispatch('clearUserDetailsOnLogout')
            router.push('/login')
          } else {
            store.dispatch('cart/load')
          }
        }
        return response
      })
      .catch(() => {})
  },
  fetchFrequentlyBoughtItems: (context, { companyId }) => {
    let endpoint = `${config.ecms.frequentlyBoughtItems_endpoint}?filter_by=company&companyId=${companyId}`

    return apiService.send(endpoint, 'get')
      .then(response => {
        if (response.status === 200) {
          let products = response.data.result.products
          context.commit('setFrequentlyBoughtItems', { companyId, products })
          return response
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  fetchBestSellingProducts: (context) => {
    const endpoint = `/frequently-bought-items`
    return apiService.send(endpoint, 'get')
      .then(response => {
        if (response.code === 200) {
          let products = response.data.result.products
          context.commit('setBestSellingProducts', products)
          return response
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  validatePasswordResetToken: (context, token) => {
    let endpoint = `${config.ecms.user_endpoint}/validate-password-reset-token`
    return apiService.send(endpoint, 'post', {
      passwordResetToken: token
    })
  },
  resetPassword: (context, data) => {
    let endpoint = `${config.ecms.user_endpoint}/reset-password`
    return apiService.send(endpoint, 'post', data)
  },
  changePassword: (context, data) => {
    let endpoint = `${config.ecms.user_endpoint}/change-password`
    return apiService.send(endpoint, 'post', data)
  },
  forgotPassword: (context, { email }) => {
    let endpoint = `${config.ecms.user_endpoint}/forgot-password`
    return apiService.send(endpoint, 'post', { email })
  }
}

const mutations = {
  setUserId: (state, id) => {
    state.userId = id
    global.$VS.db.usersCollection.setItem('userId', id)
  },
  setCompanyDetails: (state, newState) => {
    state.companyDetails = newState
    global.$VS.db.companyDetailsCollection.setItem('company-details', newState)
  },
  setLocationDetails: (state, newState) => {
    state.locationDetails = newState
    global.$VS.db.companyDetailsCollection.setItem('location-details', newState)
  },
  setCompanyUsers: (state, users) => {
    state.companyUsers = users
    global.$VS.db.companyDetailsCollection.setItem('company-users', users)
  },
  setAllCompanyOrders: (state, orders) => {
    state.allCompanyOrders = orders
    global.$VS.db.companyDetailsCollection.setItem('all-company-orders', orders)
  },
  setOrderTotal (state, totalOrders) {
    state.totalOrders = totalOrders
  },
  setCompanyRoles: (state, roles) => {
    state.companyRoles = roles
    global.$VS.db.companyDetailsCollection.setItem('company-roles', roles)
  },
  setUserToken: (state, token) => {
    state.jwToken = token
    global.$VS.db.usersCollection.setItem('jwToken', token)
  },
  setCurrentStoreCode: (state, code) => {
    store.state.user.current_storecode = code
    global.$VS.db.usersCollection.setItem('current-storecode', code)
  },
  setUserPermissions: (state, permissions) => {
    state.userPermissions = permissions
    global.$VS.db.usersCollection.setItem('user-permissions', permissions)
  },
  setUserRole: (state, role) => {
    state.userRole = role
    global.$VS.db.usersCollection.setItem('user-role', role)
  },
  setDeliveryLocations: (state, deliveryLocations) => {
    state.deliveryLocations = deliveryLocations
    global.$VS.db.usersCollection.setItem('delivery-locations', deliveryLocations)
  },
  addLocation: (state, location) => {
    let companyDetails = state.companyDetails
    companyDetails.locations = [ ...companyDetails.locations, location ]
    state.companyDetails = companyDetails
  },
  setFrequentlyBoughtItems: (state, { products, companyId }) => {
    state.frequentlyBoughtItems = { ...state.frequentlyBoughtItems, [companyId]: products }
  },
  setFrequentlyBoughtItemProps: (state, { product, companyId }) => {
    const frequentlyBoughtItems = { ...state.frequentlyBoughtItems[companyId] }
    const frequentlyBoughtItemsArray = Object.values(frequentlyBoughtItems)
    const itemIndex = frequentlyBoughtItemsArray.findIndex(p => p.sku === product.sku)
    if (itemIndex >= 0) {
      let item = frequentlyBoughtItemsArray[itemIndex]
      item = Object.assign(item, product)
      frequentlyBoughtItems[itemIndex] = item
      state.frequentlyBoughtItems = { ...state.frequentlyBoughtItems, [companyId]: frequentlyBoughtItems }
    }
  },
  setBestSellingProducts: (state, products) => {
    state.bestSellingProducts = products
  },
  setUserPhoneNumber: (state, phoneNumber) => {
    state.phoneNumber = phoneNumber
    global.$VS.db.usersCollection.setItem('user-phone-number', phoneNumber)
  },
  setUserPasswordChanged: (state, passwordChanged) => {
    state.passwordChanged = passwordChanged
    global.$VS.db.usersCollection.setItem('password-changed', passwordChanged)
  },
  setUserPhoneNumberChanged: (state, value) => {
    state.phoneNumberChanged = value
    global.$VS.db.usersCollection.setItem('phone-number-changed', value)
  },
  clearCurrentUserOnCache: (state) => {
    global.$VS.db.usersCollection.setItem('current-user', null)
    global.$VS.db.usersCollection.setItem('current-token', null)
    global.$VS.db.checkoutFieldsCollection.setItem('personal-details', null)
    global.$VS.db.cartsCollection.setItem('current-cart-token', null)
    global.$VS.db.companyDetailsCollection.setItem('company-menu-items', null)
    global.$VS.db.usersCollection.setItem('current-refresh-token', null)
  },
  setCompanyUserActiveStatus: (state, { userId, status }) => {
    let oldState = state.companyUsers
    let user = oldState.find(user => {
      return user.id === userId
    })
    if (user) {
      user.isActive = status
    }
    state.companyUsers = oldState
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
