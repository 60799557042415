import axios from 'axios'
import store from 'core/store'
import _ from 'lodash'
import config from 'config'
import EventBus from 'core/store/lib/event-bus'
import router from 'core/router'

const api = (endpoint, requestType, requestData, params) => {
  // this is useful in preventing multiple logged out messages caused by multiple requests failing due to expired jwt
  service.sent = false

  requestType = requestType.toLowerCase()
  const axiosConfig = {
    url: endpoint,
    method: requestType,
    baseURL: config.ecms.base_url,
    data: requestData
  }
  if (store.state.userDetails) {
    axiosConfig['headers'] = {
      Authorization: 'Bearer ' + store.state.userDetails.jwToken
    }
  }
  return processRequest(axiosConfig, params)
    .then(response => {
      return processResponse(response)
    })
    .catch(err => {
      EventBus.$emit('notification-progress-stop')
      if (!err.response) {
        EventBus.$emit('notification', {
          type: 'error',
          message: 'Connection to server could not be established. Please try again',
          action1: { label: 'OK', action: 'close' }
        })
      }
      logoutOnTokenExpiration(err)
      throw err
    })
}
const processRequest = (config, params = null) => {
  if (params !== null) {
    _.each(params, (value, key) => {
      config[key] = value
    })
  }
  return axios(config)
}

const processResponse = (response) => {
  return response
}

const logoutOnTokenExpiration = (err) => {
  const response = err.response
  let message = response.data ? response.data.result : err.message
  if (response.data.result && response.data.result.message) {
    message = response.data.result.message
  }
  if (message === 'jwt expired' || message === 'jwt malformed') {
    if (service.sent === true) {
      return
    }
    EventBus.$emit('notification', {
      type: 'error',
      message: 'Session expired. Please log in again',
      action1: { label: 'OK', action: 'close' }
    })
    EventBus.$emit('user-before-logout', { silent: true })
    store.dispatch('clearUserDetailsOnLogout')
    router.push('/login')
    service.sent = true
  } else {
    EventBus.$emit('notification', {
      type: 'error',
      message: message,
      action1: { label: 'OK', action: 'close' }
    })
  }
}

const service = (function () {
  let apiService

  function initialize () {
    apiService = {
      sent: false,
      send: api
    }
  }

  if (!apiService) {
    initialize()
  }
  return apiService
})()

export default service
