<template>
  <div>
    <div id="reports">
      <div v-if="parent === 'single page'" class="page-title">
        <v-card-title>
          <span class="section-title">Orders By Category</span>
        </v-card-title>
      </div>
      <div id="products-reports">
        <!-- Date range selector and View by date/day-of-the-week/month/year -->
        <div id="controls" class="row panel panel-default pad-panel">
          <div class="col-lg-8 col-md-12 col-sm-12 controls-wrapper">
            <span class="section-title">Orders By Category for</span>
            <div class="form-group daterange-box">
              <date-range-picker
                :locale-data="locale"
                :opens="opens"
                @update="update"
                :auto-apply="true"
                :ranges="ranges"
              >
                <!--Optional scope for the input displaying the dates -->
                <div slot="input" slot-scope="picker">
                  <img src="/assets/calendar.svg" class="img-responsive datepickericon">
                  <input class="daterange" type="text" v-model="dateRange">
                </div>
              </date-range-picker>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 period-filter">
            <div class="btn-group">
              <button type="button" class="btn" :class="{ active: filter === 'day' }" @click="filter = 'day'">Day</button>
              <button type="button" class="btn" :class="{ active: filter === 'month' }" @click="filter='month'">Month</button>
              <button type="button" class="btn" :class="{ active: filter === 'quarter' }" @click="filter = 'quarter'">Quarter</button>
              <button type="button" class="btn" :class="{ active: filter === 'year' }" @click="filter = 'year'">Year</button>
            </div>
          </div>
        </div>

        <!-- Reports Chart -->
        <div id="chart" class="row panel panel-default pad-panel">
          <show-loader v-if="loading" />
          <div v-else class="col-md-12" style="position: relative;">
            <revenue-chart
              :chart-data="chartData"
              :height="180"
              :chart-options="chartOptions"
            />
          </div>
        </div>

        <!-- Data Table showing Reports -->
        <div id="tables" class="row panel panel-default pad-panel">
          <div class="col-md-12">
            <!-- Search for finding reports on the Data Table -->
            <v-card id="title-and-search" class="table-title">
              <div class="row">
                <div id="dateRange-display" class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <div class="section-title">{{ dateRange }}</div>
                </div>
                <div class="search col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div class="external-search--box">
                    <input type="text" placeholder="Search" class="search-box" v-model="search">
                    <i class="material-icons" aria-hidden="true">search</i>
                  </div>
                </div>
              </div>
            </v-card>
            <v-card>
              <v-data-table
                :headers="periodHeaders"
                :items="tableData"
                :loading="loading"
                :search="search"
                :rows-per-page-items="[100]"
                :custom-filter="customFilter"
              >
                <v-progress-linear slot="progress" color="success" indeterminate />
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left col-md-6">{{ props.item.period }}</td>
                  <td class="text-xs-left col-md-2">{{ props.item.category }}</td>
                  <td class="text-xs-left col-md-4 embolden">{{ props.item.amount | currency }}</td>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css'
import moment from 'moment'
import { mapGetters } from 'vuex'
import RevenueChart from 'core/components/RevenueChart'
import builder from 'bodybuilder'
import cloneDeep from 'lodash/cloneDeep'
import ShowLoader from 'theme/components/core/blocks/CompanySettings/ShowLoader'

export default {
  name: 'ProductsReports',
  props: {
    parent: {
      type: String,
      default: ''
    }
  },
  components: {
    DateRangePicker,
    RevenueChart,
    ShowLoader
  },
  data () {
    return {
      pagination: {
        sortBy: 'timestamp'
      },
      search: '',
      loading: true,
      startDate: '2019-07-01',
      endDate: moment().format('YYYY-MM-DD'),
      opens: 'right', // which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // format of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
        firstDay: 1, // ISO first day of week - see moment documenations for details
        showWeekNumbers: true // show week numbers on each row of the calendar
      },
      ranges: { // default value for ranges object (if you set this to false ranges will no be rendered)
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        'Last 2 Years': [moment().subtract(2, 'years').startOf('year'), moment().subtract(2, 'years').endOf('year')]
      },
      dateRange: `${moment('2019-07-01').format('MMM D, YYYY')} - ${moment().format('MMM D, YYYY')}`,
      periodHeaders: [
        { text: 'Period', sortable: true, value: 'timestamp' },
        { text: 'Category', sortable: true, value: 'category' },
        { text: 'Amount', sortable: true, value: 'amount' }
      ],
      tableData: [],
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        title: {
          display: true,
          text: 'Orders By Category'
        }
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      filter: '',
      productCategories: {},
      categoriesWithProducts: [],
      categoryColours: {}
    }
  },
  mounted () {
    Promise.all([
      new Promise(resolve => {
        this.$store.dispatch('order/fetchCompletedOrders')
          .then(() => { resolve() })
          .catch(() => { resolve() })
      }),
      new Promise(resolve => {
        this.$store.dispatch('category/list', {})
          .then(() => {
            this.getCategoryProducts(this.categories)
              .then(() => {
                this.loading = false
              })
          })
      })
    ])
      .then(() => {
        this.loading = false
      })
  },
  created () {
    if (!this.hasPermission('view', 'spend analysis')) {
      this.$bus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push(this.localizedRoute('/'))
    }
  },
  computed: {
    ...mapGetters({
      allOrders: 'order/getCompletedOrders'
    }),
    categories () {
      return this.$store.getters['category/list']
    }
  },
  watch: {
    allOrders: 'applyFilter',
    startDate: 'applyFilter',
    endDate: 'applyFilter',
    filter: 'applyFilter',
    categories: 'getCategoryProducts'
  },
  methods: {
    customFilter (items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(i => (
        Object.keys(i).some(j => {
          return filter(i[j], search)
        })
      ))
    },
    getCategoryProducts (val) {
      let promises = []
      val.forEach(category => {
        promises.push(new Promise((resolve, reject) => {
          let productsInCategoryQuery = builder().query('terms', 'category_ids', [category.id]).build()
          this.$store.dispatch('product/list', {
            query: productsInCategoryQuery,
            size: 4000
          })
            .then(res => {
              let categoryWithProduct = cloneDeep(category)
              categoryWithProduct.products = res.items
              this.categoryColours[category.name] = this.generateColoursForCategory()
              resolve(categoryWithProduct)
            })
            .catch(err => {
              reject(err)
            })
        }))
      })
      return Promise.all(promises)
        .then((categoriesWithProducts) => {
          this.categoriesWithProducts = categoriesWithProducts
          this.sortProductsIntoCategories()
        })
        .catch(err => {
          console.error(err)
        })
    },
    sortProductsIntoCategories () {
      let productsObject = {}
      this.categoriesWithProducts.forEach((category) => {
        category.products.forEach(product => {
          if (!productsObject[product.sku]) {
            productsObject[product.sku] = new Set()
          }
          if (category.level !== 1) { // ignore the root category
            productsObject[product.sku].add(category.name)
          }
        })
      })
      this.productCategories = productsObject
      this.applyFilter()
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    calculateOrderTotal (order) {
      return order.products.reduce((total, product) => {
        return (product.price * product.qty) + total
      }, 0)
    },
    update (val) {
      this.startDate = moment(val.startDate).format('MMM D, YYYY')
      this.endDate = moment(val.endDate).format('MMM D, YYYY')
      this.dateRange = `${this.startDate} - ${this.endDate}`
    },
    applyFilter () {
      let filterMethods = {
        'day': this.filterByDay,
        'month': this.filterByMonth,
        'quarter': this.filterByQuarter,
        'year': this.filterByYear
      }
      if (this.filter === '') {
        this.filterByDateRange()
      } else {
        filterMethods[this.filter]()
      }
    },
    filterByDay () {
      let periods = []

      let filteredOrders = this.allOrders.filter(order => {
        if (moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days'))) {
          return true
        }
      })

      filteredOrders.forEach(order => {
        order.products.forEach(product => {
          product.category = this.productCategories[product.sku]
        })
      })

      filteredOrders.forEach(order => {
        let existingPeriod = periods.find(entry => {
          return entry === moment(order.createdAt).format('DD MMMM, YYYY')
        })
        if (!existingPeriod) {
          periods.push(moment(order.createdAt).format('DD MMMM, YYYY'))
        }
      })

      let tableData = []
      periods.forEach(entry => {
        this.categoriesWithProducts.forEach(category => {
          if (category.level !== 1) {
            let categoryTotalAmount = 0
            filteredOrders.forEach(order => {
              if (entry === moment(order.createdAt).format('DD MMMM, YYYY')) {
                order.products.forEach(product => {
                  if (product.category && product.category.has(category.name)) {
                    categoryTotalAmount += product.qty * product.price
                  }
                })
              }
            })
            tableData.push({
              period: entry,
              category: category.name,
              colours: category.colours,
              amount: categoryTotalAmount,
              timestamp: moment(entry, 'DD MMMM YYYY').format('X')
            })
          }
        })
      })

      this.generateChartData(tableData)
    },
    filterByMonth () {
      let periods = []

      let filteredOrders = this.allOrders.filter(order => {
        if (moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days'))) {
          return true
        }
      })

      filteredOrders.forEach(order => {
        order.products.forEach(product => {
          product.category = this.productCategories[product.sku]
        })
      })

      filteredOrders.forEach(order => {
        let existingPeriod = periods.find(entry => {
          return entry === moment(order.createdAt).format('MMMM YYYY')
        })
        if (!existingPeriod) {
          periods.push(moment(order.createdAt).format('MMMM YYYY'))
        }
      })

      let tableData = []
      periods.forEach(entry => {
        this.categoriesWithProducts.forEach(category => {
          if (category.level !== 1) {
            let categoryTotalAmount = 0
            filteredOrders.forEach(order => {
              if (entry === moment(order.createdAt).format('MMMM YYYY')) {
                order.products.forEach(product => {
                  if (product.category && product.category.has(category.name)) {
                    categoryTotalAmount += product.qty * product.price
                  }
                })
              }
            })
            tableData.push({
              period: entry,
              category: category.name,
              colours: category.colours,
              amount: categoryTotalAmount,
              timestamp: moment(entry, 'MMMM YYYY').format('X')
            })
          }
        })
      })

      this.generateChartData(tableData)
    },
    filterByYear () {
      let periods = []

      let filteredOrders = this.allOrders.filter(order => {
        if (moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days'))) {
          return true
        }
      })

      filteredOrders.forEach(order => {
        order.products.forEach(product => {
          product.category = this.productCategories[product.sku]
        })
      })

      filteredOrders.forEach(order => {
        let existingPeriod = periods.find(entry => {
          return entry === moment(order.createdAt).format('YYYY')
        })
        if (!existingPeriod) {
          periods.push(moment(order.createdAt).format('YYYY'))
        }
      })

      let tableData = []
      periods.forEach(entry => {
        this.categoriesWithProducts.forEach(category => {
          if (category.level !== 1) {
            let categoryTotalAmount = 0
            filteredOrders.forEach(order => {
              if (entry === moment(order.createdAt).format('YYYY')) {
                order.products.forEach(product => {
                  if (product.category && product.category.has(category.name)) {
                    categoryTotalAmount += product.qty * product.price
                  }
                })
              }
            })
            tableData.push({
              period: entry,
              category: category.name,
              colours: category.colours,
              amount: categoryTotalAmount,
              timestamp: moment(entry, 'YYYY').format('X')
            })
          }
        })
      })

      this.generateChartData(tableData)
    },
    filterByQuarter () {
      let periods = []

      let filteredOrders = this.allOrders.filter(order => {
        if (moment(order.createdAt).isSameOrAfter(moment(this.startDate)) &&
          moment(order.createdAt).isSameOrBefore(moment(this.endDate).add(1, 'days'))) {
          return true
        }
      })

      filteredOrders.forEach(order => {
        order.products.forEach(product => {
          product.category = this.productCategories[product.sku]
        })
      })

      filteredOrders.forEach(order => {
        let existingPeriod = periods.find(entry => {
          return entry === 'Q' + moment(order.createdAt).format('Q YYYY')
        })
        if (!existingPeriod) {
          periods.push('Q' + moment(order.createdAt).format('Q YYYY'))
        }
      })

      let tableData = []
      periods.forEach(entry => {
        this.categoriesWithProducts.forEach(category => {
          if (category.level !== 1) {
            let categoryTotalAmount = 0
            filteredOrders.forEach(order => {
              if (entry === 'Q' + moment(order.createdAt).format('Q YYYY')) {
                order.products.forEach(product => {
                  if (product.category && product.category.has(category.name)) {
                    categoryTotalAmount += product.qty * product.price
                  }
                })
              }
            })
            tableData.push({
              period: entry,
              category: category.name,
              colours: category.colours,
              amount: categoryTotalAmount,
              timestamp: moment(entry, 'Q YYYY').format('X')
            })
          }
        })
      })

      this.generateChartData(tableData)
    },
    filterByDateRange () {
      let periods = []
      let monthsBetweenDates = moment(this.endDate).diff(moment(this.startDate), 'months', true)
      monthsBetweenDates = Math.floor(monthsBetweenDates)
      let startMonth = Number(moment(this.startDate).format('M'))
      let startYear = Number(moment(this.startDate).format('YYYY'))

      // generate the periods
      periods.push({
        toString: `${this.months[startMonth - 1]} ${startYear}`,
        timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X'),
        noOfOrders: 0,
        grandTotal: 0
      })

      const monthsInYear = 12
      for (let i = 1; i <= monthsBetweenDates; i++) {
        startMonth++
        if (startMonth > monthsInYear) {
          startMonth = 1
          startYear++
        }
        periods.push({
          toString: `${this.months[startMonth - 1]} ${startYear}`,
          timestamp: moment(`${this.months[startMonth - 1]} ${startYear}`).format('X'),
          noOfOrders: 0,
          grandTotal: 0
        })
      }

      this.allOrders.forEach(order => {
        order.products.forEach(product => {
          product.category = this.productCategories[product.sku]
        })
      })

      let tableData = []
      periods.forEach(entry => {
        this.categoriesWithProducts.forEach(category => {
          if (category.level !== 1) {
            let categoryTotalAmount = 0
            this.allOrders.forEach(order => {
              if (entry.toString === moment(order.createdAt).format('MMMM YYYY')) {
                order.products.forEach(product => {
                  if (product.category && product.category.has(category.name)) {
                    categoryTotalAmount += product.qty * product.price
                  }
                })
              }
            })
            tableData.push({
              period: entry.toString,
              category: category.name,
              colours: category.colours,
              amount: categoryTotalAmount,
              timestamp: moment(entry.toString, 'MMMM YYYY').format('X')
            })
          }
        })
      })

      this.generateChartData(tableData)
    },
    generateChartData (tableData) {
      tableData.sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return 0
        }
        return (b.timestamp < a.timestamp) ? 1 : -1
      })
      this.tableData = tableData

      // generate chart data
      let labels = []
      tableData.forEach(entry => {
        if (labels.indexOf(entry.period) < 0) {
          labels.push(entry.period)
        }
      })

      let categories = {}
      tableData.forEach(entry => {
        if (!categories[entry.category]) {
          categories[entry.category] = []
        }
        categories[entry.category].push(entry.amount)
      })

      let datasets = []
      for (let category in categories) {
        let {
          fillColor,
          strokeColor,
          highlightFill,
          highlightStroke,
          borderColor,
          color
        } = this.categoryColours[category]
        datasets.push({
          label: category,
          data: categories[category],
          fillColor,
          strokeColor,
          highlightFill,
          highlightStroke,
          borderColor,
          backgroundColor: borderColor,
          color,
          fill: false,
          border: '1px solid'
        })
      }

      this.chartData = {
        labels: labels,
        datasets: datasets,
        title: {
          display: true,
          text: 'Orders By Category'
        }
      }
    },
    generateColoursForCategory () {
      let colours = {
        fillColor: this.randomColour(),
        strokeColor: this.randomColour(),
        highlightFill: this.randomColour(),
        highlightStroke: this.randomColour(),
        borderColor: this.randomColour(),
        color: this.randomColour()
      }
      return colours
    },
    randomColour () {
      let o = Math.round
      let r = Math.random
      const s = 255
      return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-white: color(white);
$color-search-border: color(gloo-search-border);
$color-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);
$color-success: color(gloo-success);

#controls {
  background-color: color(white);

  .daterange-box {
    display: inline-block;
    margin: 0 15px;
  }

  .period-filter {
    text-align: center;
  }
}

#chart {
  padding: 40px 0;
}

#controls, #chart {
  margin: 0 0 50px !important;
}

#controls span.section-title, #title-and-search .section-title {
  padding-left: 20px;
  font-size: 21px;
  line-height: 25px;
  color: $color-gray;
  font-weight: 600;
}

#title-and-search .section-title {
  padding-left: 0;
}

.controls-wrapper {
  align-self: center;
}

.btn-group {
  .btn {
    padding: 25px 10px;
    color: #7D84A0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.13px;
    line-height: 19px;
    border-radius: 0;

    &:hover {
      color: $color-gloo-main;
    }
  }

  .active {
    color: $color-gloo-main;
    border-bottom: 1px solid $color-gloo-main;
    font-weight: 600;
  }
}

input.daterange {
  background-image: linear-gradient(180deg, #FAFBFC 0%, $color-white 100%);
  border: 1.5px solid $color-search-border;
  border-radius: 3px;
  padding: 5px;
  padding-left: 35px;
}

.datepickericon {
  position: absolute;
  top: 10px;
  left: 10px;
}

#tables>div {
  padding: 0;
}

#title-and-search>div {
  padding: 10px !important;

  #dateRange-display {
    align-self: center;
  }
}

// Style to properly align search icon in Search bar on Data-table
.search {
  margin: auto;
}

.external-search--box {
  text-align: left;
}

.search-box {
  width: 100%;
}

.v-card {
  // border-radius: 2px;
  box-shadow: 0 1px 3px 0 #d7d7e0 !important;
}

.card-title {
  font-size: 24px;
  line-height: 2em;
  display: inline-block;
}

.embolden {
  font-weight: 600 !important;
}

@media (min-width: 1200px) and (max-width:1291px) {
  .period-filter .btn {
    padding: 25px 5px;
  }
}

@media (min-width: 1200px) {
  .period-filter {
    text-align: right !important;
  }
}

@media (max-width: 1199px) {
  #controls span.section-title {
    padding-left: 0;
  }
  .controls-wrapper {
    text-align: center;
    padding-top: 25px;
  }
  .daterange-box {
    margin-top: 15px !important;
  }
}

@media (max-width: 510px) {
  #dateRange-display, .search {
    width: 100%;
    padding: 7px;
    flex-basis: 100%;
    max-width: 100%;
  }
  #title-and-search .section-title {
    text-align: center;
  }
}
</style>
