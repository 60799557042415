<template>
  <div class="col-lg-8 col-md-7 col-sm-12 p0" id="cart-summary--block">
    <div class="mb15" id="cart-title">
      <div class="row">
        <div class="col-md-12 cl-gloo-product-text p0">
          <h3 class="pt0">Order Summary ({{ productsInCart && productsInCart.length }} {{ productsInCart && (productsInCart.length > 1 || productsInCart.length === 0) ? 'Items' : 'Item' }})</h3>
        </div>
      </div>
    </div>
    <div class="mb15">
      <div class="row align-left cart-summary--title mb15 cl-gloo-product-text">
        <span class="col-md-6 col-sm-6 col-xs-4 cart-summary p0">Item</span>
        <span class="col-md-2 col-sm-2 hidden-xs cart-summary p0">Quantity</span>
        <span class="hidden-md col-xs-1 cart-summary p0">Qty</span>
        <span class="col-md-2 col-sm-2 hidden-xs cart-summary p0">Unit Price</span>
        <span class="hidden-md col-xs-3 cart-summary">Price</span>
        <span class="col-md-2 col-sm-2 hidden-xs cart-summary p0">Subtotal</span>
        <span class="hidden-md col-xs-4 cart-summary p0">Total</span>
      </div>
    </div>
    <div class=" mb15" id="cart-products">
      <div class="checkout-card cart-summary py20 row">
        <div class="col-md-12">
          <checkout-product-list v-for="product in productsInCart" :key="product.sku" :product="product" />
        </div>
      </div>
    </div>
    <div class="row" v-if="totalsItems.length > 0">
      <div class="col-md-6 col-md-offset-5 col-sm-offset-3 col-sm-8 p0 p25 pt0 pb0" v-if="productsInCart && productsInCart.length">
        <p v-if="inclusiveVAT" class="cl-gloo-product-text line relative weight-400 align-right">***All Prices Are Inclusive of VAT</p>
        <p class="cl-gloo-product-text row" v-for="(segment, index) in totalsItems" :key="index" v-if="segment.code !== 'grand_total'">
          <span class="col-md-6 align-right mb5">{{ segment.title }}: </span>
          <span v-if="segment.value != null" class="col-md-6 align-right mb5">{{ segment.value | currency }}</span>
        </p>

        <p class="cl-gloo-product-text row" v-for="(segment, index) in totalsItems" :key="index" v-if="segment.code === 'grand_total'">
          <span class="col-md-6 align-right mb5">Total: </span>
          <span v-if="segment.value != null" class="cart-total col-md-6 align-right mb5">{{ segment.value | currency }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CartSummary from 'core/components/blocks/Checkout/CartSummary'
import CheckoutProductList from './CheckoutProductList'
import i18n from 'core/store/lib/i18n'
import sumBy from 'lodash-es/sumBy'
import config from 'config'

export default {
  data () {
    return {
      totalsItems: []
    }
  },
  components: {
    CheckoutProductList
  },
  mixins: [CartSummary],
  computed: {
    inclusiveVAT () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails
        ? this.$store.state.userDetails.companyDetails.inclusiveVAT
        : false
    },
    deliveryMethod () {
      return this.$store.getters['checkout/getDeliveryMethod']
    }
  },
  watch: {
    deliveryMethod: function (val) {
      this.getCartTotalsWithDeliveryFee()
    }
  },
  created () {
    this.getCartTotalsWithDeliveryFee()
    this.$bus.$on('recompute-cart-totals', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('cart-after-update', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('cart-after-itemchanged', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('servercart-after-totals', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('servercart-after-pulled', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('servercart-after-diff', () => {
      this.getCartTotalsWithDeliveryFee()
    })
    this.$bus.$on('cart-after-delete', () => {
      this.getCartTotalsWithDeliveryFee()
    })
  },
  methods: {
    getCartTotalsWithDeliveryFee () {
      let { state } = this.$store
      console.log('companyDetails', JSON.stringify(state, null, 4))
      let deliveryMethod = state.checkout.deliveryMethod
      if (Object.keys(deliveryMethod).length > 0) {
        if (!(state.userDetails && state.userDetails.companyDetails)) {
          return
        }
        const priceIncludesVAT = state.userDetails.companyDetails.inclusiveVAT
        let cartTotal = sumBy(state.cart.cartItems, (p) => {
          return p.qty * p.price
        })
        const deliveryLocationState = localStorage.getItem('session/locationState')
        if (deliveryLocationState && deliveryLocationState !== 'Lagos') {
          // deliveryMethod.deliveryFee = cartTotal * 0.1
          deliveryMethod.deliveryFee = 0
        }
        const isExonnMobile = state.userDetails.companyDetails.companyCatalog.storeId.includes('exxonmobil')
        const isSepnuUser = state.userDetails.companyDetails.companyCatalog.storeId.includes('sepnu')
        const sepnuUserDeliveryLocation = state.userDetails.locationDetails.locationAddressId.state
        console.log('userDetails', JSON.stringify(state.userDetails, null, 4))
        if (sepnuUserDeliveryLocation && isSepnuUser && sepnuUserDeliveryLocation !== 'Lagos') {
          deliveryMethod.deliveryFee = cartTotal * 0.1
        }

        // for exxonmobile delivery on agreement
        if (deliveryLocationState && isExonnMobile) {
          deliveryMethod.deliveryFee = 0
        }
        if (priceIncludesVAT) {
          let subTotalTitle = 'Subtotal incl. tax'
          this.totalsItems = [
            {
              code: 'subtotalInclTax',
              title: subTotalTitle,
              value: cartTotal
            },
            {
              code: 'shipping',
              title: `${isExonnMobile || isSepnuUser ? deliveryMethod.name + ' (On Agreement)' : deliveryMethod.name}`,
              value: deliveryMethod.deliveryFee
            },
            {
              code: 'grand_total',
              title: i18n.t('Grand total'),
              value: cartTotal + deliveryMethod.deliveryFee
            }
          ]
        } else {
          let subTotalTitle = 'Subtotal'
          let VAT = config.ecms.VAT
          if (!VAT || isNaN(VAT)) {
            VAT = 0.075
          }
          const tax = VAT * cartTotal
          this.totalsItems = [
            {
              code: 'subtotalInclTax',
              title: subTotalTitle,
              value: cartTotal
            },
            {
              code: 'tax',
              title: 'Tax',
              value: tax
            },
            {
              code: 'shipping',
              title: `${isExonnMobile || isSepnuUser ? deliveryMethod.name + ' (On Agreement)' : deliveryMethod.name}`,
              value: deliveryMethod.deliveryFee
            },
            {
              code: 'grand_total',
              title: i18n.t('Grand total'),
              value: cartTotal + tax + deliveryMethod.deliveryFee
            }
          ]
        }
      } else {
        this.totalsItems = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .summary-title {
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  .cart-total {
    color: #4F9221;
    float: right;
    font-size: 20px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  #cart-title {
    h3  {
      // margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  .cart-summary--title {
    text-transform: uppercase;
    span {
      font-size: 16px;
      @media (max-width: 767px) {
        font-size: 14px
      }
    }
  }
  @media (max-width: 767px) {
    #cart-title {
      h3 {
        font-size: 20px;
      }
    }
  }
</style>
