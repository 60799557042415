<template>
  <button class="brdr-none bg-cl-transparent p0 middle-xs inline-flex cl-secondary" @click.native="increment(quantity)">
    <!-- <i class="mdi mdi-chevron-up"/> -->
    <slot>
      <i class="material-icons h4 p5 pr0">keyboard_arrow_up</i>
    </slot>
  </button>
</template>

<script>
export default {
  name: 'IncrementButton',
  methods: {
    increment (data) {
      data++
    }
  }
}
</script>

<style scoped>
  i {
    font-weight: bold;
    font-size: 21px;
    padding: 0;
  }
</style>
