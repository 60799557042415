
<template>
  <div>
    <div v-if="parent !== 'dashboard'" class="page-title">
      <v-card-title>
        <span class="section-title">Pending Orders</span>
      </v-card-title>
    </div>
    <div v-if="parent !== 'dashboard'" class="top-row row">
      <div class="caption col-md-6 col-sm-12 col-xs-12">
        <div class="external-search--box">
          <input type="text" placeholder="Search through pending orders" class="search-box" v-model="search">
          <i class="material-icons" aria-hidden="true">search</i>
        </div>
      </div>
      <!-- <div class="export-print col-sm-6 col-xs-6">
        <v-btn color="success" class="export-print--btn">Export as<i class="material-icons">arrow_drop_down</i></v-btn>
      </div> -->
    </div>
    <div id="pending-approval" class="panel panel-default pad-panel">
      <div>
        <v-card-title v-if="parent !== 'dashboard'" class="table-title">
          <h3 v-if="ordersAwaitingApproval && ordersAwaitingApproval.length > 0" class="order-total">
            {{ ordersAwaitingApproval && ordersAwaitingApproval.length }}
            {{ ordersAwaitingApproval && ordersAwaitingApproval.length > 1 ? 'Orders' : 'Order' }}
            {{ 'Pending Approval' }}
          </h3>
        </v-card-title>
        <v-card-title v-else class="table-title">
          <span class="section-title">Orders Pending Approval</span>
        </v-card-title>
        <v-card>
          <v-data-table
            v-if="ordersAwaitingApproval && ordersAwaitingApproval.length > 0"
            :headers="headers"
            :pagination.sync="pagination"
            :loading="showloader"
            :search="search"
            :items="ordersAwaitingApproval"
            :rows-per-page-items="[25, 50]"
          >
            <v-progress-linear slot="progress" color="success" indeterminate/>
            <template
              slot="items"
              slot-scope="props"
            >
              <td class="text-xs-left show-details" @click="showOrderDetails(props.item)">{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.parsedDate }}</td>
              <td class="text-xs-left">{{ props.item.addressInformation.deliveryLocation.name }}</td>
              <td class="text-xs-left">{{ props.item.requesterId.firstName }} {{ props.item.requesterId.lastName }}</td>
              <td class="text-xs-left order-status"><span class="order-status-chip pending-order">{{ props.item.status }}</span></td>
              <td class="text-xs-right no-right-padding">
                <v-btn color="success" class="bg-cl-mine-shaft :bg-cl-th-secondary accept" @click="approveOrder(props.item.id)">Accept</v-btn>
              </td>
              <td class="text-xs-left no-left-padding">
                <v-btn color="error" @click="showDisapproveModal(props.item.id)">Decline</v-btn>
              </td>
            </template>
          </v-data-table>
          <div v-else class=" no-order">
            <div class="empty-history">
              <div class="no-order-text">
                <p class="cl-gloo-catalina-blue">You currently have no orders pending approval.</p>
                <p class="cl-gloo-catalina-blue">Once you do, this is where you’ll track them.</p>
              </div>
              <div class="p15">
                <img src="/assets/no-recent-orders.svg" alt="Empty Order History" height="100px" class="img-order-history">
              </div>
              <button-outline color="gray" class="start p15" @click.native="browseCategories()">
                Start ordering
              </button-outline>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <div id="disapprove-modal">
      <modal name="order-decline-confirm">
        <div class="disapprove-modal-content">
          <v-card>
            <v-card-title>
              <span class="headline">Decline Order?</span>
            </v-card-title>
            <v-card-title>
              <p>Are you sure you want to decline this order?</p>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-textarea v-model="declineReason" label="State your reason(s)" required/>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn large color="error" class="rejection" @click="disapproveOrder()">Decline Order</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from '../Form/BaseCheckbox.vue'
import moment from 'moment'
import Modal from 'theme/components/core/Modal.vue'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import { mapGetters } from 'vuex'
import { localizedRoute } from '@vue-storefront/store/lib/multistore'

export default {
  name: 'OrderApprovals',
  props: {
    parent: {
      type: String,
      default: ''
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    Modal,
    ButtonOutline
  },
  data () {
    return {
      search: '',
      orderIdToBeDisapproved: null,
      showloader: true,
      declineReason: null,
      pagination: {
        sortBy: 'created_at',
        descending: true
      },
      headers: [
        {
          text: 'Order ID',
          align: 'left',
          sortable: true,
          value: 'id'
        },
        {text: 'Date', sortable: true, value: 'parsedDate'},
        {text: 'Shipped to', sortable: true, value: 'addressInformation.deliveryLocation.name'},
        {text: 'Requester', sortable: true, value: 'requesterId.firstName'},
        {text: 'Status', sortable: true, value: 'status'},
        {text: 'Actions', sortable: false, value: 'actions', align: 'right'},
        {text: '', sortable: false, value: ''}
      ]
    }
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('approve', 'order')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  },
  methods: {
    showDisapproveModal (orderId) {
      this.orderIdToBeDisapproved = orderId
      this.$bus.$emit('modal-show', 'order-decline-confirm')
    },
    fetchOrdersForApproval () {
      // only fetch the orders if the state has initialized
      const jwt = this.$store.state.userDetails
        ? this.$store.state.userDetails.jwToken
        : null
      if (!jwt) {
        return
      }
      this.$store.dispatch('order/fetchOrdersForApproval')
        .then(({ data }) => {
          this.showloader = false
          if (data.code !== 200 && (this.ordersAwaitingApproval || this.ordersAwaitingApproval.length === 0)) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'Could not retrieve orders needing approval',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
        .catch(() => {
          this.showloader = false
        })
    },
    parseDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    updateOrder (orderId, inputData) {
      this.$bus.$emit('notification-progress-start', 'Updating order status')
      this.$store.dispatch('order/approveOrder', { orderId, data: inputData })
        .then(({ data }) => {
          this.$bus.$emit('notification-progress-stop')
          if (data.code !== 200) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while updating order status',
              action1: { label: 'OK', action: 'close' }
            })
            return
          }
          let message = inputData.status === 'approved'
            ? 'Order has been approved'
            : 'Order has been disapproved'
          this.$bus.$emit('notification', {
            type: 'success',
            message: message,
            action1: { label: 'OK', action: 'close' }
          })
          this.declineReason = null
          this.$bus.$emit('modal-hide', 'order-decline-confirm')
          this.fetchOrdersForApproval()
        })
        .catch(err => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = err.message
          if (err.response && err.response.data.result) {
            errorMessage = err.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    approveOrder (orderId) {
      const data = {
        status: 'approved',
        approvalDate: moment().format()
      }
      this.updateOrder(orderId, data)
    },
    disapproveOrder () {
      const data = {
        status: 'disapproved',
        comment: this.declineReason,
        approvalDate: moment().format()
      }
      this.updateOrder(this.orderIdToBeDisapproved, data)
    },
    showOrderDetails (order) {
      this.$router.push(this.localizedRoute('/orders/' + order.id))
    },
    browseCategories () {
      this.$router.push(localizedRoute('/#company-categories'))
    }
  },
  mounted () {
    this.$bus.$emit('modal-hide', 'order-decline-confirm')
    this.fetchOrdersForApproval()
  },
  computed: {
    ...mapGetters({
      ordersAwaitingApproval: 'order/getPendingOrders'
    })
  },
  watch: {
    ordersAwaitingApproval: function (val) {
      val.forEach(order => {
        order.parsedDate = this.parseDate(order.created_at)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-pending: color(gloo-pending);
$color-pend: color(gloo-pend);
$color-white: color(white);
$color-gloo-gray: color(gloo-gray);

.order-total {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

.panel {
  margin-top: 35px !important;
}

#pending-approval {
  background-color: color(white);
}

#pending-approval {
  margin: 30px 0;
}

.table-title {
  border: 1px solid #e9ebf6 !important;
}

span.section-title {
  font-size: 24px;
  color: #253858;
  font-weight: 550;
  line-height: 29px;
  text-align: left;
}

.order-status {
  text-transform: uppercase;
}

.order-status-chip {
  letter-spacing: 0.21px;
  line-height: 12px;
  width: 120px;
  text-align: center !important;
  align-items: center;
  border-radius: 28px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 30px;
  height: 32px;
  margin: auto;
  font-size: 13px !important;
  align-content: center;
}

.disapprove-modal-content {
  padding: 20px;
  overflow-y: auto;
  .v-card {
    box-shadow: none;
  }
  .rejection {
    margin: 10px auto;
  }
}

.show-details {
  cursor: pointer;
}

.v-btn {
  font-weight: 600;
}

.no-right-padding {
  padding-right: 0 !important;
}
.no-left-padding {
  padding-left: 0 !important;
}

.v-card {
  box-shadow: none !important;
}

.empty-history, .no-order, .no-order-text {
  margin: auto;
  text-align: center;
}

.empty-history {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.no-order {
  padding: 40px!important;
}

.no-order-text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-outline {
  padding: 10px;
  border-radius: 5px;
  height: 48px;
}

@media (max-width: 1485px) {
  .search-box {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .search-box {
    width: 70%;
  }
}
@media (max-width: 550px) {
  .search-box {
    width: 100%;
  }
}
</style>
