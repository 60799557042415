import coreStore from '@vue-storefront/store/modules/category'
import { extendStore } from 'core/lib/themes'
import bodybuilder from 'bodybuilder'
import * as types from 'core/store/mutation-types'
import { quickSearchByQuery } from 'core/store/lib/search'
import EventBus from 'core/store/lib/event-bus'
import config from 'config'

const actions = {
  list (context, { parent = null, onlyActive = true, onlyNotEmpty = false, size = 4000, start = 0, sort = 'position:asc', includeFields = config.entities.optimize ? config.entities.category.includeFields : null }) {
    const commit = context.commit
    let qrObj = bodybuilder()
    if (parent && typeof parent !== 'undefined') {
      qrObj = qrObj.filter('term', 'parent_id', parent.id)
    }

    if (onlyActive === true) {
      qrObj = qrObj.andFilter('term', 'is_active', true) // show only active cateogires
    }

    if (onlyNotEmpty === true) {
      // qrObj = qrObj.andFilter('range', 'product_count', {'gt': 0}) // show only active cateogires
    }

    if (!context.state.list | context.state.list.length === 0) {
      return quickSearchByQuery({ entityType: 'category', query: qrObj.build(), sort: sort, size: size, start: start, includeFields: includeFields }).then(function (resp) {
        commit(types.CATEGORY_UPD_CATEGORIES, resp)
        EventBus.$emit('category-after-list', { query: qrObj, sort: sort, size: size, start: start, list: resp })
        return resp
      }).catch(function (err) {
        console.error(err)
      })
    } else {
      return new Promise((resolve, reject) => {
        let resp = { items: context.state.list, total: context.state.list.length }
        EventBus.$emit('category-after-list', { query: qrObj, sort: sort, size: size, start: start, list: resp })
        resolve(resp)
      })
    }
  }
}

export default extendStore(coreStore, {
  actions
})
