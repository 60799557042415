<template>
  <div id="category">
    <header class="pt35">
      <div class="container">
        <div class="row middle-sm">
          <div class="col-md-9 col-sm-12">
            <breadcrumbs :routes="breadcrumbs.routes" :active-route="category.name" class="col-sm-12 breadcrumb pl0" />
            <h1 class="col-sm-9 category-title pl0 cl-gloo-gray"> {{ category.name }} </h1>
          </div>
          <div class="col-md-3 col-sm-12 align-right sorting">
            <p v-if="productsTotal <= 1" class="products-total pl0 cl-gloo-gray">{{ productsTotal }} Product</p>
            <p v-else class="products-total pl0 cl-gloo-gray">{{ productsTotal }} Products</p>
            <div class="dropdown open p0">
              <button class="btn dropdown-btn bg-cl-primary cl-gloo-gray" id="dropdown-btn" @click="toggleDisplay()">
                <span id="key-span" class="dropdown-btn">{{ sortBy }}</span><span id="sort-key" class="cl-gloo-product-text dropdown-btn">{{ sortKey }}</span>
                <i class="caret material-icons dropdown-btn">keyboard_arrow_down</i>
              </button>
              <ul class="dropdown-menu bg-cl-primary" role="menu" id="dropdown-list" :class="{ 'is-visible': isDropdownVisible }">
                <a href="#" class="dropdown-link">
                  <li class="active sort-options" role="presentation" v-for="(option, label) in sortByAttribute" :value="option" :key="label" @click="applySort(label)">{{ label }}</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container pb60">
      <div class="row m0 pt15">
        <p v-if="productsTotal <= 1" class="col-xs-12 hidden-md m0 px20 cl-secondary">{{ productsTotal }} Product</p>
        <p v-else class="col-xs-12 hidden-md m0 px20 cl-secondary">{{ productsTotal }} Products</p>
        <div class="col-md-12 pt0 p0 border-box products-list">
          <div v-if="isCategoryEmpty" class="hidden-xs">
            <h4 data-testid="noProductsInfo">{{ $t('No products found!') }}</h4>
            <p>{{ $t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!') }}</p>
          </div>
          <product-listing columns="3" :products="products" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from 'core/pages/Category'
import Sidebar from '../components/core/blocks/Category/Sidebar.vue'
import ProductListing from '../components/core/ProductListing.vue'
import Breadcrumbs from '../components/core/Breadcrumbs.vue'
import SortBy from 'core/components/SortBy'
import config from 'config'
import i18n from 'core/lib/i18n'
import { buildFilterProductsQuery } from '@vue-storefront/store/helpers'

export default {
  components: {
    ProductListing,
    Breadcrumbs,
    Sidebar
  },
  data () {
    return {
      mobileFilters: false,
      sortBy: 'Sort By',
      sortKey: null,
      isDropdownVisible: false
    }
  },
  asyncData ({ store, route }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    return new Promise((resolve, reject) => {
      store.state.category.current_product_query = Object.assign(store.state.category.current_product_query, { // this is just an example how can you modify the search criteria in child components
        sort: 'updated_at:desc'
      })
      resolve()
    })
  },
  mounted () {
    window.onclick = (event) => {
      if (!event.target.matches('.dropdown-btn')) {
        if (this.isDropdownVisible) {
          this.isDropdownVisible = false
        }
      }
    }
    if (!config.products.defaultSort) {
      return
    }
    this.applySort(config.products.defaultSort)
  },
  methods: {
    openFilters () {
      this.mobileFilters = true
    },
    closeFilters () {
      this.mobileFilters = false
    },
    toggleDisplay () {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    applySort (key) {
      if (key === 'Lowest Price' || key === 'Oldest') {
        this.direction = 'asc'
      } else if (key === 'Highest Price' || key === 'Latest') {
        this.direction = 'desc'
      }
      this.sortBy = `Sort By: `
      this.sortKey = key
      this.$bus.$emit('list-change-sort', { attribute: this.sortByAttribute[key], direction: this.direction })
      this.isDropdownVisible = false
    },
    bottomVisible () {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight - 2
      return bottomOfPage || pageHeight < visible
    },
    onSortOrderChanged (param) {
      if (param.attribute) {
        let filterQr = buildFilterProductsQuery(this.category, this.filters.chosen)
        this.$store.state.category.current_product_query = Object.assign(this.$store.state.category.current_product_query, {
          sort: param.attribute + ':' + param.direction,
          searchProductQuery: filterQr,
          append: false,
          current: 0
        })
        this.$store.dispatch('category/products', this.$store.state.category.current_product_query).then((res) => {
        })
      } else {
        this.$bus.$emit('notification', {
          type: 'error',
          message: i18n.t('Please select the field which You like to sort by'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
      }
    }
  },
  mixins: [SortBy, Category],
  computed: {
    products () {
      if (this.$store.state.product.list.items) {
        let products = this.$store.state.product.list.items.filter(item => {
          return (!!item.stock.backorders)
        })
        return products
      }
      return this.$store.state.product.list.items
    },
    productsCounter () {
      return this.products.length
    },
    productsTotal () {
      return this.$store.state.product.list.total
    },
    sortByAttribute () {
      delete config.products.sortByAttributes['Price']
      return config.products.sortByAttributes
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$background: color(mainslider-background);
$gloo-main: color(gloo-main);

  header {
    background-color: #dff0e3;
    @media (min-width: 900px) {
      height: 260px;
    }
  }

  .container {
    min-width: unset !important;
    max-width: 85% !important;
  }
  .category-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    letter-spacing: 0.05em;
    margin-bottom: 0.3em;
  }

  .breadcrumb {
    margin: 0 0 10px 0;
  }

  .products-total {
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    text-align: right;
    letter-spacing: 1.5px;
  }

  li.sort-options {
    text-align: -webkit-match-parent;
    list-style: none;
  }

  .dropdown {
    position: relative;
  }

  .dropdown .btn {
    border: 0.5px solid #E3E8EE;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius: 4px;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    padding: 0 15px;
    width: 12.5em;
    height: 2.5em;
    position: relative;
  }

  i.caret.material-icons {
    color: #BBB;
    position: absolute;
    right: 10px;
    display: inline-block;
    font-size: 15px;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    text-align: left;
    border: 1px solid rgba(90, 122, 190, 0.08);
    border-radius: 0 0 4px 4px;
    box-shadow: 0 10px 20px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
    width: 10em;
    padding: 0;
  }

  .dropdown-menu li {
    // color: black;
    color: #253858;
    padding: 16px;
    text-decoration: none;
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 16px;
    border: none;
    border-top: 0.5px solid #E3E8EE;
  }

  .dropdown-link .sort-options:hover {
    color: $gloo-main;
  }

  .is-visible {
    display: block;
  }

  @media (max-width: 900px) {

    .category-title {
      letter-spacing: normal;
      font-size: 22px;
      line-height: 20px;
    }

    .sorting {
      text-align: left;
    }

    .products-total {
      text-align: left;
      font-size: 10px;
      letter-spacing: normal;
    }

    header {
      padding-top: 10px;
    }
  }
  @media (max-width: 760px) {

    .products-list {
      width: 100%;
      max-width: none;
    }

    .category-filters {
      display: none;
    }

    .product-listing {
      justify-content: center;;
    }
  }

  .close-container {
    left: 0;
  }

  .close {
    margin-left: auto;
  }

  .product.align-center {
    margin: 0 1.2em 1.2em !important;
  }
</style>
