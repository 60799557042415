<template>
  <div class="relative">
    <div class="relative">
      <input
        class="
         py10 px10 w-100 border-box h4
       "
        :class="{pr30: type === 'password', empty: value === ''}"
        :type="type === 'password' ? passType : type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :readonly="readonly"
        :ref="focus ? name : false"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      >
      <label>{{ placeholder }}</label>
    </div>
    <button
      v-if="iconActive"
      type="button"
      class="
        icon material-icons absolute brdr-none no-outline
        p0 bg-cl-transparent pointer
      "
      @click="togglePassType()"
      :aria-label="'Toggle password visibility'"
    >
      <i class="material-icons">{{ icon }}</i>
    </button>
    <template v-if="validation">
      <span class="block cl-error h6" v-if="validation.condition">
        {{ validation.text }}
      </span>
    </template>
    <template v-else-if="validations">
      <span
        v-for="(validation, index) in validations"
        :key="index"
        v-if="validation.condition"
        class="block cl-error h6"
      >
        {{ validation.text }}
      </span>
    </template>
  </div>
</template>

<script>
import baseInput from 'core/components/blocks/Form/BaseInput'

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mixins: [baseInput]
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-puerto-rico: color(puerto-rico);
  $color-hover: color(gloo-main);
  $gloo-main: color(gloo-main);
  $color-border: color(primary, $colors-border);
  $color-gray: color(secondary, $colors-theme);

  form {
    border-radius: 0.304em;
  }
  input {
    // background: inherit;
    // border: none;
    // border-bottom: 2px solid $color-gray;
    // border-color: rgba(130, 130, 130, 0.5);
    // font-size: 1em;
  background-color: #FFFFFF !important;
  border-radius: 5px !important;
  box-shadow: 0 0 3px 0 #D7D7E0 !important;
  // width: 534px;
  height: 60px;
  border: 1px solid #DEE5E9;
  border-radius: 4px !important;
    &:hover,
    &:focus {
      outline: none;
      border-color: $color-hover;
    }

    &:disabled,
    &:disabled + label {
      opacity: 0;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  label {
    color:#999;
    position:absolute;
    pointer-events:none;
    user-select: none;
    left:10px;
    top: 20px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }
  input:focus ~ label, input:not(.empty) ~ label{
    top: 0px;
    left: 10px;
    // font-size: 17px;
    color:$gloo-main;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    // text-align: left;
  }

  .icon {
    right: 6px;
    top: 10px;
    &:hover,
    &:focus {
      color: $gloo-main;
    }
  }
</style>
