
<template>
  <div class="inline-flex relative dropdown py5 h-100">
    <button
      type="button"
      class="bg-cl-transparent icon brdr-none p0"
      @click="goToAccount();"
      data-testid="accountButton"
      :aria-label="$t('Open my account')"
    >
      <i class="material-icons h6 account">keyboard_arrow_down</i>
    </button>
    <div v-if="currentUser" class="dropdown-content bg-cl-primary align-left weight-400">
      <div class="">
        <div v-for="(page, index) in navigation" :key="index" @click="notify(page.title)">
          <router-link class="no-underline block px10 py10 bottom-border" :to="localizedRoute(page.link)">
            <div class="row">
              <div class="col-sm-2">
                <img :src="`/assets/${page.icon}`">
              </div>
              <div class="col-sm-10">
                {{ page.title }}
              </div>
            </div>
          </router-link>
          <!-- <div class="bottom-border" /> -->
        </div>
      </div>
      <div class="brdr-cl-gloo-primary">
        <a href="#" class="no-underline block py10 px10" @click.prevent="logout">
          <div class="row">
            <div class="col-sm-2">
              <img src="/assets/logout.svg">
            </div>
            <div class="col-sm-10">
              {{ 'Logout' }}
            </div>
          </div>
        </a>
        <!-- <div class="bottom-border" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import i18n from 'core/lib/i18n'
import AccountIcon from 'core/components/blocks/Header/AccountIcon'

export default {
  mixins: [AccountIcon],
  data () {
    return {
      navigation: [
        { title: 'Dashboard', link: '/dashboard', icon: 'binocoulars.svg' },
        { title: i18n.t('My Account'), link: '/my-account', icon: 'profile-icon.svg' },
        { title: 'My Orders', link: '/my-account/orders', icon: 'my-orders-nav.svg' },
        { title: 'Catalog Request', link: '/catalog-request', icon: 'my-orders-nav.svg' }
      ]
    }
  },
  methods: {
    logout () {
      this.$bus.$emit('user-before-logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon-hover: color(secondary, $colors-background);
$color-primary: color(primary);
$color-secondary: color(secondary);
$gloo-main: color(gloo-main);
$color-primary: color(primary);
$color-secondary: color(gloo-section-header-text);
$gloo-main: color(gloo-main);
$background: color(gloo-background2);
$gloo-solitude: color(gloo-solitude);
$icon: color(gloo-catalina-blue);

.dropdown {
  opacity: 1 !important;
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 200px;
    z-index: 1;
    border: 1px solid rgba(90, 122, 190, 0.08);
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
  }

  a {
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      letter-spacing: 0.25px;
      line-height: 19px;
      // border-bottom: 1px solid $gloo-solitude;
      color: $color-secondary;
      // width: 80%;
      margin: auto;
    &:hover,
    &:focus {
      border: none;
      width: 100%;
      background-color: $background;
    }

  }
   a:hover, a:hover + div {
    border: none;
  }
  .bottom-border {
    width:100%;
    border-bottom: 1px solid $gloo-solitude;
    margin: 0 auto;
  }
  .icon {
    color: $icon;
    &:hover {
      background-color: #f2f2f233 !important;
      color: $color-primary;
    }
  }
  .account {
    font-size: 25px;
    padding: 0;
    font-weight: bold;
  }
  button {
    padding: 0 !important;
  }
  @media (min-width: 768px) {
    &:hover .dropdown-content {
      display: block;
    }
  }
}
</style>
