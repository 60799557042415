import extensionStore from './store'
import extensionRoutes from './router'

const EXTENSION_KEY = 'theme_gloong-theme'

export default function (app, router, store, config) {
  app.$on('application-after-init', () => {
    console.log('Hello from custom theme extension')
  })
  router.addRoutes(extensionRoutes) // add custom routes
  store.registerModule(EXTENSION_KEY, extensionStore) // add custom store
  console.log('ECMS extension registered')
  return { EXTENSION_KEY, extensionRoutes: null, extensionStore: null }
}
