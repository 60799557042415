<template>
  <div class="all-orders-dashboard">
    <div class="page-title">
      <v-card-title>
        <span class="section-title">Company Orders</span>
      </v-card-title>
    </div>
    <!-- All Orders Search Field -->
    <div class="top-row row">
      <div class="caption col-md-6 col-sm-12 col-xs-12">
        <div class="external-search--box">
          <input
            type="text"
            placeholder="Search through company orders"
            class="search-box"
            v-model="search"
            @keypress="loading = true"
            @keyup="searchOrders"
          >
          <i class="material-icons" aria-hidden="true">search</i>
        </div>
      </div>
    </div>
    <!-- All Orders Component -->
    <div id="all-orders" class="panel panel-default pad-panel">
      <div>
        <v-card-title class="table-title">
          <h3 v-if="totalItems && totalItems > 0" class="order-total">
            {{ totalItems?totalItems:null }}
            {{ 'Company ' + (totalItems && totalItems > 1 ? 'Orders' : 'Order') }}
          </h3>
        </v-card-title>
        <v-card>
          <v-data-table
            v-if="loading || (companyOrders && companyOrders.length > 0)"
            :headers="headers"
            :loading="loading"
            :pagination.sync="pagination"
            :total-items="totalItems"
            :items="companyOrders"
            :custom-filter="customFilter"
            :rows-per-page-items="[25, 50]"
          >
            <v-progress-linear slot="progress" color="success" indeterminate />
            <template slot="items" slot-scope="props">
              <td class="text-xs-center">{{ props.item.date }}</td>
              <td class="text-xs-center">{{ props.item.totalAmount | number | currency }}</td>
              <td class="text-xs-center order-status">
                <span
                  class="order-status-chip"
                  :class="[orderStatus[props.item.status]]"
                >{{ props.item.status }}</span>
              </td>
              <td class="text-xs-center">{{ props.item.locationName }}</td>
              <td class="text-xs-center">{{ props.item.requesterName }}</td>
              <td
                class="text-xs-center"
              >{{ props.item.approverName ? props.item.approverName : 'Pending' }}</td>
              <td
                class="text-xs-center"
              >{{ props.item.approvalDate ? props.item.formattedApprovalDate : 'Pending' }}</td>
              <td class="text-xs-center">
                <p class="table-cta" @click="viewOrderDetails(props.item.id)">View Order</p>
              </td>
            </template>
          </v-data-table>
          <div v-else class="no-order">
            <div class="empty-history">
              <div class="no-order-text">
                <p class="cl-gloo-catalina-blue">Your company currently has no orders.</p>
                <p class="cl-gloo-catalina-blue">Once there is, this is where you’ll track them.</p>
              </div>
              <div class="p15">
                <img
                  src="/assets/no-recent-orders.svg"
                  alt="Empty Order History"
                  height="100px"
                  class="img-order-history"
                >
              </div>
              <button-outline
                color="gray"
                class="start p15"
                @click.native="browseCategories()"
              >Start ordering</button-outline>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import moment from 'moment'
import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import _ from 'lodash'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { localizedRoute } from '@vue-storefront/store/lib/multistore'

export default {
  name: 'CompanyOrders',
  components: {
    ButtonOutline
  },
  data () {
    return {
      search: '',
      pagination: {},
      headers: [
        {text: 'Date Created', sortable: true, value: 'created_at'},
        {text: 'Order Amount', sortable: true, value: 'totalAmount'},
        {text: 'Status', sortable: true, value: 'status', align: 'center'},
        {text: 'Location', sortable: true, value: 'locationName'},
        {text: 'Requester', sortable: true, value: 'requesterName'},
        {text: 'Approver', sortable: true, value: 'approverName'},
        {text: 'Date Approved', sortable: true, value: 'approvalDate'},
        {text: 'Actions', sortable: false, value: 'actions'}
      ],
      orderStatus: {
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order',
        'approved': 'approved-order',
        'resubmitted': 'resubmitted-order',
        'disapproved': 'disapproved-order'
      },
      companyId: this.$store.getters.getCompanyDetails.id,
      companyName: this.$store.getters.getCompanyDetails.name,
      loading: false
    }
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('view all', 'order')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  },
  created () {
    this.loading = true
    this.populatePagination()
  },
  watch: {
    pagination: {
      handler () {
        this.loading = true
        this.populatePagination()
      },
      deep: true
    }
  },
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    viewOrderDetails (orderId) {
      this.$router.push(this.localizedRoute('/orders/' + orderId))
    },
    browseCategories () {
      this.$router.push(localizedRoute('/#company-categories'))
    },
    customFilter (items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(i => (
        Object.keys(i).some(j => {
          return filter(i[j], search)
        })
      ))
    },
    populatePagination () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      let direction = 'ASC'
      if (descending) {
        direction = 'DESC'
      }

      let data = {
        companyId: this.companyId,
        sortBy: sortBy,
        page: page,
        perPage: rowsPerPage,
        direction: direction,
        searchCriteria: this.search
      }

      this.$store.dispatch('fetchAllCompanyOrders', data).then(() => {
        this.loading = false
      }).catch(() => { this.loading = false })
    },
    searchOrders: _.debounce(function () {
      this.populatePagination()
    }, 1000)
  },
  computed: {
    companyOrders () {
      let orders = this.$store.getters.getAllCompanyOrders
      if (orders) {
        orders.forEach(order => {
          if (order.requesterId) {
            order.requesterName = order.requesterId.firstName + ' ' + order.requesterId.lastName
          }
          if (order.approverId) {
            order.approverName = order.approverId.firstName + ' ' + order.approverId.lastName
          }
          let total = _.sumBy(order.products, product => {
            return product.qty * product.price
          })
          order.totalAmount = total
          // modify created_at & approvalDate formats and Order location
          let date, formattedApprovalDate, locationName
          date = this.formatDate(order.created_at)
          formattedApprovalDate = this.formatDate(order.approvalDate)
          locationName = order.addressInformation.deliveryLocation.name
          // then assign new properties 'date', 'formattedApprovalDate', and 'locationName' to the Order property
          // to allow date and location search on the Vuetify Data Table
          order.date = date
          order.formattedApprovalDate = formattedApprovalDate
          order.locationName = locationName
        })
        return orders
      }
    },
    totalItems () {
      if (!this.$store.getters.getTotalOrders) {
        return 0
      }
      return this.$store.getters.getTotalOrders
    }
  },
  filters: {
    number: function (value) {
      if (!value) return ''
      value = value.toLocaleString(config.i18n.defaultLocale)
      return value
    },
    currency: function (value) {
      if (!value) return ''
      value = config.i18n.currencySign + value
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-pending: color(gloo-pending);
$color-pend: color(gloo-pend);
$color-white: color(white);
$color-success: color(gloo-success);
$color-gloo-gray: color(gloo-gray);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-gloo-text: color(gloo-text);
$color-footer-text: color(gloo-footer-text);
$color-error: color(gloo-danger);

.order-total {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

.panel {
  margin-top: 35px !important;
}

#all-orders {
  background-color: color(white);
}

#all-orders {
  margin: 30px 0;
}

.table-title {
  border: 1px solid #e9ebf6 !important;
}

span.section-title {
  font-size: 24px;
  color: #253858;
  font-weight: 550;
  line-height: 29px;
  text-align: left;
}

.order-status {
  text-transform: uppercase;
}

.order-status-chip {
  letter-spacing: 0.21px;
  line-height: 12px;
  width: 120px;
  text-align: center !important;
  align-items: center;
  border-radius: 28px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 30px;
  height: 32px;
  margin: auto;
  font-size: 13px !important;
  align-content: center;
}

//Order status classes and styles
.pending-order {
  background-color: $color-pend;
  color: $color-pending !important;
}
.resubmitted-order {
  background-color: $color-completed;
  color: $color-success !important;
  padding: 0 17px !important;
}
.approved-order {
  background-color: #008000;
  color: $color-white;
  padding: 0 27px !important;
}
.disapproved-order {
  background-color: $color-failed;
  color: $color-error !important;
  padding: 0 15px !important;
}

.table-cta {
  color: $color-success;
  margin-bottom: 0;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;

  &:hover {
    cursor: pointer;
  }
}

.v-btn {
  font-weight: 600;
}

.v-card {
  box-shadow: none !important;
}

.empty-history,
.no-order,
.no-order-text {
  margin: auto;
  text-align: center;
}

.empty-history {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.no-order {
  padding: 40px !important;
}

.no-order-text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-outline {
  padding: 10px;
  border-radius: 5px;
  height: 48px;
}

// table.v-table tbody td:not(:first-child), table.v-table thead th:not(:first-child), table.v-table thead th:not(:first-child) {
//   padding: 0 5px !important;
// }

table.v-table tbody td:first-child,
table.v-table tbody td:not(:first-child),
table.v-table tbody th:first-child,
table.v-table tbody th:not(:first-child),
table.v-table thead td:first-child,
table.v-table thead td:not(:first-child),
table.v-table thead th:first-child,
table.v-table thead th:not(:first-child) {
  padding: 0 5px !important;
}

@media (max-width: 1485px) {
  .search-box {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .search-box {
    width: 70%;
  }
}
@media (max-width: 550px) {
  .search-box {
    width: 100%;
  }
}
</style>
