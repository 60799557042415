<template>
  <div id="app">
    <loader/>
    <notification/>
    <overlay v-show="overlayActive"/>
    <router-view v-if="!authenticated || !passwordChanged"/>
    <div v-else>
      <div id="viewport" class="w-100 relative">
        <microcart/>
        <!-- <search-panel/> -->
        <sidebar-menu/>
        <main-header/>
        <edit-phone-number />
        <router-view/>
        <main-footer />
        <!-- <newsletter-popup/> -->
        <!-- <cookie-notification/> -->
        <offline-badge/>
      </div>
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MainHeader from './components/core/blocks/Header/Header.vue'
import MainFooter from './components/core/blocks/Footer/Footer.vue'

import Microcart from './components/core/blocks/Microcart/Microcart.vue'
import SidebarMenu from './components/core/blocks/SidebarMenu/SidebarMenu.vue'
// import SearchPanel from './components/core/blocks/SearchPanel/SearchPanel.vue'

import Overlay from './components/core/Overlay.vue'
import Loader from './components/core/Loader.vue'
import Login from './components/core/blocks/Auth/Login.vue'
import Modal from './components/core/Modal.vue'
import Notification from './components/core/Notification.vue'
import OfflineBadge from './components/core/OfflineBadge.vue'
import ModalSwitcher from './components/core/blocks/Switcher/Language.vue'
import EditPhoneNumber from 'theme/components/core/blocks/Home/EditPhoneNumber.vue'
import Head from 'theme/resource/head'

export default {
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      authenticated: state => state.user.current !== null && !!state.userDetails.jwToken,
      passwordChanged: state => state.userDetails.passwordChanged === true
    })
  },
  beforeMount () {
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      this.$bus.$emit('notification-progress-start', 'Loading')
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$bus.$emit('notification-progress-stop')
      this.$Progress.finish()
    })
    this.$bus.$emit('on-app-load')
  },
  beforeDestroy () {
  },
  metaInfo: Head,
  components: {
    MainHeader,
    MainFooter,
    Microcart,
    // SearchPanel,
    SidebarMenu,
    Overlay,
    Loader,
    Login,
    Notification,
    Modal,
    OfflineBadge,
    ModalSwitcher,
    EditPhoneNumber
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== 'number') return value
      const formatter = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN'
      })
      return formatter.format(value)
    }
  }
}
</script>

<style lang="scss" src="./css/main.scss">
