<template>
  <div>
    <div id="permissions">
      <div class="page-title">
        <v-card-title>
          <span class="section-title">Approval Workflows</span>
        </v-card-title>
      </div>
      <div class="top-row row">
        <div class="caption col-sm-6 col-xs-6">
          <div class="external-search--box">
            <input type="text" placeholder="Search through workflows" class="search-box" v-model="search">
            <i class="material-icons" aria-hidden="true">search</i>
          </div>
        </div>
        <div class="add-workflow col-sm-6 col-xs-6">
          <v-btn large color="success" :to="'/approval-workflows/add'"><i class="material-icons">add</i>Add Workflow</v-btn>
        </div>
      </div>
      <div class="panel">
        <div>
          <v-card-title class="table-title">
            <h3 v-if="workflows && workflows.length > 0" class="no-of-workflows">{{ workflows && workflows.length }} {{ workflows && (workflows.length > 1 || workflows.length === 0) ? 'workflows' : 'workflow' }}</h3>
          </v-card-title>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="workflows"
              :search="search"
              :loading="loading"
              :rows-per-page-items="[25, 50]"
            >
              <v-progress-linear slot="progress" color="success" indeterminate/>
              <template slot="items" slot-scope="props">
                <td class="text-xs-left capitalize">{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.noOfLevels }}</td>
                <td class="text-xs-center">{{ props.item.status }}</td>
                <td class="text-xs-center">
                  <p class="table-cta">View</p>
                </td>
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      search: '',
      headers: [
        {
          text: 'Workflow Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        { text: 'No of Levels', align: 'left', sortable: true, value: 'noOfLevels' },
        { text: 'Status', align: 'center', sortable: true, value: 'status' },
        { text: 'Actions', align: 'center', sortable: true, value: '' }
      ]
    }
  },
  methods: {
  },
  created () {
    this.$store.dispatch('fetchApprovalWorkflows')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  },
  computed: {
    workflows () {
      let workflows = this.$store.getters.getApprovalWorkflows
      workflows.forEach(entry => {
        entry.noOfLevels = entry.levels.length
        entry.status = entry.isActive ? 'Active' : 'Inactive'
      })
      return workflows
    }
  }
}
</script>

<style lang="scss">
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-gloo-gray: color(gloo-gray);

.no-of-workflows {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

.panel {
  margin-top: 35px !important;
}

.add-workflow {
  text-align: end;
  margin: auto;
  @media (max-width: 767px) {
    text-align: center;
    margin: 16px auto 0;
  }

  .success {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
  }
}

.v-alert {
  color: red;
}

.primary {
  background-color:  $color-primary;
}

#delete-btn {
  background-color: #ed2020;
}

.v-btn {
  color: white
}

.capitalize {
  text-transform: capitalize;
}
</style>
