<template>
  <modal name="modal-add-role" :width="600">
    <v-container grid-list-xl create-role>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Add Role' }}
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <v-form ref="form" lazy-validation>
            <div>
              <v-text-field
                label="Role name"
                v-model="roleName"
                :rules="rules.roleName"
                outline
                required
              />
              <v-text-field
                label="Description"
                hint="Briefly describe this role"
                v-model="roleDescription"
                outline
              />
            </div>
            <v-btn
              id="submit-btn"
              block
              @click="submit()"
              style="height: 45px"
            >
              Submit
            </v-btn>
            <!-- <v-btn id="cancel-btn" large outline flat @click="clear()">Cancel</v-btn> -->
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>
<script>
import Modal from 'theme/components/core/Modal'
import { permissionsGuard } from 'theme/route-guards'

export default {
  name: 'AddRole',
  components: {
    Modal
  },
  data () {
    return {
      roleName: '',
      roleDescription: '',
      rules: {
        roleName: [
          v => v.trim().length > 0 || 'Role name is required'
        ]
      }
    }
  },
  mounted () {
    let companyId = this.$store.state.userDetails.companyDetails.id
    this.companyId = companyId
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        if (!this.roleName) {
          return this.$bus.$emit('notification', {
            type: 'error',
            message: 'Role Name is required',
            action1: { label: 'OK', action: 'close' }
          })
        }

        // check if user is assigned to a company
        let company = this.$store.state.userDetails.companyDetails
        if (!company) {
          return this.$bus.$emit('notification', {
            type: 'error',
            message: 'You must have a company assigned to add a role',
            action1: { label: 'OK', action: 'close' }
          })
        }

        // save details
        this.$bus.$emit('notification-progress-start', 'Saving role ...')
        this.$store.dispatch('addRole', {
          roleName: this.roleName,
          roleDescription: this.roleDescription
        })
          .then(({ data }) => {
            this.$bus.$emit('notification-progress-stop')
            if (data.code === 201) {
              this.$bus.$emit('notification', {
                type: 'success',
                message: 'Role has been created successfully!',
                action1: { label: 'OK', action: 'close' }
              })
              this.close()
              this.$store.dispatch('fetchCompanyRoles', this.companyId)
            }
          })
      }
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-add-role')
    }
  },
  beforeRouteEnter (to, from, next) {
    permissionsGuard(to, from, next)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-gloo-main: color(gloo-main);

.create-role {
  padding: 0 0 30px !important;
  margin: 70px auto !important;
}

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
// #cancel-btn {
//   background-color: transparent;
//   border-color: $color-primary;
// }

</style>
