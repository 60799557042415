<template>
  <div class="inline-flex relative dropdown p5 h-100">
    <button
      type="button"
      class="bg-cl-transparent brdr-none inline-flex hamburger-icon"
      @click="openSidebarMenu"
      :aria-label="$t('Open menu')"
      data-testid="menuButton"
    >
      <i class="material-icons">dehaze</i>
    </button>
    <div class="arrow-down-icon">
      <button
        type="button"
        class="bg-cl-transparent brdr-none inline-flex"
        :aria-label="$t('Open menu')"
        data-testid="menuButton"
      >
        <i class="material-icons h6 categories-list">keyboard_arrow_down</i>
      </button>
      <div class="dropdown-content bg-cl-primary">
        <div v-for="item in menuItems" :key="item.id" class="cl-gloo-secondary dropdown-content-links">
          <router-link
            class="no-underline col-xs block px20 py20"
            :to="localizedRoute(item.url)"
          >
            {{ item.label }}
          </router-link>
          <div class="bottom-border" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HamburgerIcon from 'core/components/blocks/Header/HamburgerIcon'
import { mapGetters } from 'vuex'

export default {
  mixins: [HamburgerIcon],
  computed: {
    ...mapGetters({
      menuItems: 'getMenuItems'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-secondary: color(gloo-section-header-text);
$gloo-main: color(gloo-main);
$background: color(gloo-background2);
$gloo-solitude: color(gloo-solitude);

  .dropdown {
    opacity: 1 !important;
    .dropdown-content {
      display: none;
      position: absolute;
      right: -150%;
      top: 100%;
      min-width: 250px;
      z-index: 1;
      border: 1px solid rgba(90, 122, 190, 0.08);
      border-radius: 4px;
      box-shadow: 0 10px 20px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
      // background-color: #ffffff;
    }
    a {
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      letter-spacing: 0.25px;
      line-height: 19px;
      // border-bottom: 1px solid $gloo-solitude;
      color: $color-secondary;
      // width: 80%;
      margin: auto;
      // padding-left: 0;
      &:hover,
      &:focus {
        // color: white;
        border: none;
        width: 100%;
        background-color: $background;
      }

    }
    a:hover, a:hover + div {
      border: none;
    }
    .bottom-border {
      width:85%;
      border-bottom: 1px solid $gloo-solitude;
      margin: 0 auto;
    }
    .icon {
      color: $color-secondary;
      &:hover {
        color: $gloo-main;
      }
    }
    .account {
      font-size: 25px;
      padding: 0;
      font-weight: bold;
    }
    .categories-list {
      font-size: 25px;
      padding: 0;
      color: #4e5767;
    }
    .arrow-down-icon {
      display: inline-flex !important;
    }
    .hamburger-icon {
      display: none !important;
    }
    @media (max-width: 1160px) {
      .hamburger-icon {
        display: inline-flex !important;
      }
      .arrow-down-icon {
        display: none !important;
      }
    }
    @media (min-width: 768px) {
      &:hover .dropdown-content {
        display: block;
      }
      .dropdown-content:before {
        border-bottom-color: #fff;  /* arrow color */

        /* positioning */
        position: absolute;
        top: -19px;
        left: 255px;
        z-index: 2;
      }
      .dropdown-content:after {
        border-bottom-color: #333;  /* arrow color */

        /* positioning */
        position: absolute;
        top: -24px;
        left: 255px;
        z-index: 1;
      }
      .dropdown-content:before, .dropdown-content:after {
        content: ' ';
        height: 0;
        position: absolute;
        width: 0;
        border: 10px solid transparent;
      }
    }
    @media (max-width: 450px) {
      .hamburger-icon {
      padding-right: 0px;
      }
      img {
        height: 20px !important;
      }
    }
  }
</style>
