import { keyBy } from 'lodash-es'
import config from 'config'
import store from 'core/store'
import EventBus from 'core/store/lib/event-bus'

import apiService from 'theme/services/api_service'

EventBus.$on('on-app-load', () => {
  global.$VS.db.companyDetailsCollection.getItem('cities')
    .then(response => {
      store.commit('setCities', response)
    })
})

const state = {
  cities: {}
}

const getters = {
  getCities: state => {
    let cities = Object.values(state.cities)
    cities.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    return cities
  }
}

const actions = {
  fetchCities: (context) => {
    let endpoint = config.ecms.cities_endpoint
    return apiService.send(endpoint, 'get')
      .then(response => {
        if (response.data.code === 200) {
          let result = response.data.result
          let cities = result.cities
          context.commit('setCities', cities)
        }
        return response
      })
      .catch(error => {
        throw error
      })
  },
  saveLocation: (context, location) => {
    let endpoint = config.ecms.locations_endpoint
    return apiService.send(endpoint, 'post', location)
      .then(response => {
        if (response.data.code === 201) {
          let location = response.data.result.location
          store.commit('addLocation', location)
          return response
        }
      })
      .catch(err => {
        throw err
      })
  }
}

const mutations = {
  setCities: (state, cities) => {
    state.cities = keyBy(cities, city => {
      return city.id
    })
    global.$VS.db.companyDetailsCollection.setItem('cities', state.cities)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
