import store from 'core/store'
import _ from 'lodash'

const acl = {}

acl.isUserAllowedPermission = (action, resourceName) => {
  const userPermissions = store.state.userDetails.userPermissions
  const found = _.find(userPermissions, (o) => {
    return o.acoId.model === resourceName && o.acoId.action === action
  })
  return !!found
}

export default acl
