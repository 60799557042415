<template>
  <line-chart
    :chart-data="chartData"
    :options="{responsive: true, maintainAspectRatio: false}"
    :width="400"
    :height="120"
  />
</template>

<script>
import LineChart from 'core/components/LineChart'
import moment from 'moment'
import each from 'lodash-es/each'

export default {
  components: {
    LineChart
  },
  data: () => ({
    chartData: null,
    ordersHistory: [],
    stateOrdersHistory: {}
  }),
  methods: {
    getOrdersHistory () {
      if (this.stateOrdersHistory) {
        return this.stateOrdersHistory.items
      } else {
        return []
      }
    },
    parseDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY - HH : MM')
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    }
  },
  mounted () {
    this.stateOrdersHistory = Object.assign({}, this.$store.state.user.orders_history)
    this.ordersHistory = this.getOrdersHistory()
    let dataForChart = []
    let labels = []
    let dataObject = {}
    each(this.ordersHistory, order => {
      if (dataObject[this.formatDate(order.created_at)]) {
        dataObject[this.formatDate(order.created_at)] += order.grand_total
      } else {
        dataObject[this.formatDate(order.created_at)] = order.grand_total
      }
    })
    labels = Object.keys(dataObject)
    dataForChart = Object.values(dataObject)
    labels.reverse()
    dataForChart.reverse()
    this.chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Amount spent on Orders',
          backgroundColor: 'transparent',
          // border: '1px solid #BCB6FF',
          color: '#bcb6ff',
          data: dataForChart
        }
      ]
    }
  }
}
</script>
