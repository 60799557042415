import store from 'core/store'
import { quickSearchByQuery } from 'core/store/lib/search'
import builder from 'bodybuilder'

const state = {
  recentOrders: []
}

const actions = {
  loadRecentOrders: ({commit}, orders) => {
    let recentOrders = []
    let promises = []
    for (let i = 0; i < orders.length && i <= 3; i++) {
      let recentOrder = orders[i]
      let products = recentOrder.items
      // console.log(products)
      for (let j = 0; j < products.length && j <= 11; j++) {
        let product = products[j]
        const qrObj = builder().query('bool', 'must', [{'match': { 'sku': product.sku }}])
        promises.push(new Promise((resolve, reject) => {
          quickSearchByQuery({ query: qrObj.build() }).then((resp) => {
            if (resp.items && resp.items.length) {
              product.image = resp.items[0].image
            }
            resolve()
          })
        }))
      }
      recentOrders.push(recentOrder)
    }
    return Promise.all(promises).then(() => {
      store.commit('saveRecentOrders', recentOrders)
    })
  }
}

const mutations = {
  saveRecentOrders: (state, recentOrders) => {
    state.recentOrders = recentOrders
  }
}

const getters = {
  getRecentOrders: (state) => {
    return state.recentOrders
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
