<template>
  <modal name="modal-adduser" :width="600">
    <v-container grid-list-xl create-user>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Add User' }}
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <v-form @submit.prevent="createUser" ref="form" lazy-validation>
            <v-text-field
              label="First Name"
              v-model="user.firstName"
              outline
              type="text"
              :rules="firstNameRules"
              required
            />
            <v-text-field
              label="Middle Name"
              v-model="user.otherNames"
              outline
              type="text"
              :rules="middleNameRules"
            />
            <v-text-field
              label="Last Name"
              v-model="user.lastName"
              outline
              type="text"
              :rules="lastNameRules"
              required
            />
            <v-text-field
              label="Email"
              v-model="user.emailAddress"
              outline
              type="email"
              :rules="emailRules"
              required
            />
            <v-text-field
              label="Password"
              v-model="user.password"
              outline
              type="text"
              :rules="passwordRules"
              required
            />
            <v-text-field
              label="Phone Number"
              v-model="user.phoneNumber"
              outline
              :rules="phoneRules"
              type="text"
              required
            />
            <v-select
              v-if="roles.length > 0"
              :items="roles"
              v-model="user.roleId"
              outline
              label="Select Role for this user"
              item-text="name"
              item-value="id"
              :rules="[() => user.roleId.length > 0 || 'Select a role for this user']"
            />
            <v-select
              :items="locations"
              v-model="user.locationId"
              outline
              label="Select a Location for this user"
              item-text="name"
              item-value="id"
              :rules="[() => user.locationId.length > 0 || 'Select a location for this user']"
            />
            <v-btn
              block
              type="submit"
              style="height: 45px;"
            >Create user</v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>
<script>
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'
import i18n from 'core/lib/i18n'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { isNaN } from 'lodash-es'

export default {
  name: 'CreateUsers',
  components: {
    Modal,
    ButtonFull
  },
  data () {
    return {
      user: {
        firstName: '',
        otherNames: '',
        lastName: '',
        emailAddress: '',
        password: '',
        phoneNumber: '',
        roleId: '',
        locationId: '',
        companyId: ''
      },
      firstNameRules: [
        (v) => v.trim().length > 0 || 'First Name is required',
        (v) => {
          if (v && v.trim().length < 3) {
            return 'First name should have at least 3 characters'
          }
          return true
        }
      ],
      middleNameRules: [
        (v) => {
          if (v && v.trim().length < 3) {
            return 'Middle name should have at least 3 characters'
          }
          return true
        }
      ],
      lastNameRules: [
        (v) => v.trim().length > 0 || 'Last Name is required',
        (v) => {
          if (v && v.trim().length < 3) {
            return 'Last name should have at least 3 characters'
          }
          return true
        }
      ],
      emailRules: [

        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        (v) => {
          if (v.trim() === '' || v.length < 1) {
            return 'Phone Number is required'
          }
          return true
        },
        (v) => this.validatePhoneNumber(v) || 'Phone number must be a valid number in your country'
      ],
      passwordRules: [
        v => !!v.trim() || 'This field is required',
        (value) => {
          let matches = 0
          value = value.trim()
          if (value.match(/[A-Z]/g)) {
            matches++
          }
          if (value.match(/[a-z]/g)) {
            matches++
          }
          if (value.match(/[0-9]/g)) {
            matches++
          }
          if (value.match(/[^a-zA-Z0-9]/g)) {
            matches++
          }
          return (typeof value === 'string' && value.length >= 8 && matches >= 3) || 'Invalid password format. Password should contain lowercase, uppercase, number and special character'
        }
      ],
      roles: [],
      locations: []
    }
  },
  mounted () {
    // this.$store.dispatch('fetchCompanyRoles').then((result) => {
    let userDetails = this.$store.state.userDetails
    let companyDetails = this.$store.state.userDetails.companyDetails
    this.roles = userDetails.companyRoles
    this.locations = (userDetails && companyDetails) ? companyDetails.locations : null
    this.user.companyId = this.$store.state.userDetails.companyDetails.id
  },
  watch: {
    'user.lastName': function () {
      this.user.password = (this.user.firstName.trim() + '.' + this.user.lastName.trim() + '123').toLowerCase()
    }
  },
  methods: {
    validatePhoneNumber (number) {
      number = number.trim()
      if (!isNaN(number)) {
        let userCountryCode = this.$store.state.userDetails.locationDetails.locationAddressId.country
        let phoneEntry = parsePhoneNumberFromString(number, userCountryCode)
        if (!phoneEntry) {
          return 'Entry must be a number'
        }
        let formattedPhoneNumber = phoneEntry.number
        let phoneIsValid = phoneEntry.isValid()
        let countryCode = phoneEntry.country
        if (phoneIsValid && countryCode === userCountryCode) {
          this.user.phoneNumber = formattedPhoneNumber
          return true
        }
      }
    },
    createUser () {
      if (this.$refs.form.validate()) {
        let userData = this.user
        this.$bus.$emit('notification-progress-start', 'Creating User Account ...')
        this.$store.dispatch('createUser', userData)
          .then((result) => {
            this.$bus.$emit('notification-progress-stop')
            if (result.data.code === 200) {
              this.$bus.$emit('notification', {
                type: 'success',
                message: i18n.t('User Account Created'),
                action1: { label: i18n.t('OK'), action: 'close' }
              })
              this.close()
              this.$store.dispatch('fetchCompanyUsers', this.user.companyId)
            } else {
              this.$bus.$emit('notification', {
                type: 'error',
                message: i18n.t(result.data.result.message),
                action1: { label: i18n.t('OK'), action: 'close' }
              })
            }
          }).catch(err => {
            this.$bus.$emit('notification-progress-stop')
            console.error(err)
            let errorMessage = err.message
            if (err.response && err.response.data.result) {
              errorMessage = err.response.data.result
            }
            this.$bus.$emit('notification', {
              type: 'error',
              message: errorMessage,
              action1: { label: 'OK', action: 'close' }
            })
          })
      }
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-adduser')
    },
    createPassword (userData) {

    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);
$color-white: color(white);

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

input::placeholder {
  color: rgba(130,130,130,0.25);
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.create-user {
  margin: 70px auto !important;
  padding: 0 0 30px !important;
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
