
export function truncate (longText, length) {
  let ellipses = '...'
  let truncatedText = longText

  if (longText && longText.length > length) {
    truncatedText = longText.substring(0, length - ellipses.length) + ellipses
  }
  return truncatedText
}
