<template>
  <modal name="modal-edit-user" :width="600">
    <v-container grid-list-xl create-user>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        <span v-if="parent !== 'my-account'">{{ 'Edit User' }}</span>
        <span v-else>{{ 'Edit Profile' }}</span>
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <v-form @submit.prevent="editUser" ref="form" lazy-validation>
            <v-text-field
              label="First name"
              v-model="user.firstName"
              outline
              type="text"
              :rules="firstNameRules"
              required
            />
            <v-text-field
              label="Middle name"
              v-model="user.otherNames"
              outline
              type="text"
              :rules="middleNameRules"
            />
            <v-text-field
              label="Last name"
              v-model="user.lastName"
              outline
              type="text"
              :rules="lastNameRules"
              required
            />
            <v-text-field
              v-if="hasPermission('edit', 'user')"
              label="Email"
              v-model="user.emailAddress"
              outline
              type="email"
              :rules="emailRules"
              required
            />
            <v-text-field
              label="Phone number"
              v-model="user.phoneNumber"
              outline
              :rules="phoneRules"
              type="tel"
            />
            <v-select
              v-if="roles.length > 0 && hasPermission('edit', 'user')"
              :items="roles"
              v-model="user.roleId"
              label="Role of user"
              outline
              item-text="name"
              item-value="id"
              :rules="[() => user.roleId.length > 0 || 'Select a role for this user']"
            />
            <v-select
              v-if="hasPermission('edit', 'user')"
              :items="locations"
              v-model="user.locationId"
              label="Location of user"
              outline
              item-text="name"
              item-value="id"
              :rules="[() => user.locationId.length > 0 || 'Select a location for this user']"
            />
            <v-btn
              block
              type="submit"
              style="height: 45px;"
            >Save user changes</v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>
<script>
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'
import i18n from 'core/lib/i18n'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

export default {
  name: 'EditUser',
  components: {
    Modal,
    ButtonFull
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      firstNameRules: [
        (v) => v.trim().length > 0 || 'First Name is required',
        (v) => {
          if (v && v.trim().length < 3) {
            return 'First name should have at least 3 characters'
          }
          return true
        }
      ],
      middleNameRules: [
        (v) => {
          if (v && v.trim().length < 3) {
            return 'Middle name should have at least 3 characters'
          }
          return true
        }
      ],
      lastNameRules: [
        (v) => v.trim().length > 0 || 'Last Name is required',
        (v) => {
          if (v && v.trim().length < 3) {
            return 'Last name should have at least 3 characters'
          }
          return true
        }
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        (v) => {
          if (v.trim() === '' || v.length < 1) {
            return 'Phone Number is required'
          }
          return true
        },
        (v) => this.validatePhoneNumber(v) || 'Phone number must be a valid number in your country'
      ]
    }
  },
  computed: {
    roles () {
      return this.$store.getters.getCompanyRoles
    },
    locations () {
      let locations = this.$store.getters.getCompanyLocationDetails
      if (!locations) {
        return []
      }
      // filter out disabled locations
      let enabledLocations = locations.filter(location => {
        return location.isActive
      })
      return enabledLocations
    },
    companyId () {
      let companyDetails = this.$store.getters.getCompanyDetails
      return companyDetails && companyDetails.id
    }
  },
  methods: {
    validatePhoneNumber (number) {
      number = number.trim()
      if (!isNaN(number)) {
        let userCountryCode = this.$store.state.userDetails.locationDetails.locationAddressId.country
        let phoneEntry = parsePhoneNumberFromString(number, userCountryCode)
        if (!phoneEntry) {
          return 'Entry must be a number'
        }
        let formattedPhoneNumber = phoneEntry.number
        let phoneIsValid = phoneEntry.isValid()
        let countryCode = phoneEntry.country
        if (phoneIsValid && countryCode === userCountryCode) {
          this.user.phoneNumber = formattedPhoneNumber
          return true
        }
      }
    },
    editUser () {
      if (this.$refs.form.validate()) {
        let { isActive, ...rest } = this.user
        let userData = rest
        userData.userId = this.user.id
        this.$bus.$emit('notification-progress-start', 'Updating user details ...')
        this.$store.dispatch('updateUser', userData)
          .then((result) => {
            this.$bus.$emit('notification-progress-stop')
            if (result.data.code === 200) {
              this.$bus.$emit('notification', {
                type: 'success',
                message: i18n.t('User updated successfully'),
                action1: { label: i18n.t('OK'), action: 'close' }
              })
              this.close()
              this.$bus.$emit('user-updated')
              this.$store.dispatch('fetchCompanyUsers', this.user.companyId)
            } else {
              this.$bus.$emit('notification', {
                type: 'error',
                message: i18n.t(result.data.result.message),
                action1: { label: i18n.t('OK'), action: 'close' }
              })
            }
          }).catch(() => {
            this.$bus.$emit('notification-progress-stop')
          })
      }
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-edit-user')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);
$color-white: color(white);

// .user-edit {
//   padding: 0 0 30px !important;
// }

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

input::placeholder {
  color: rgba(130,130,130,0.25);
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.create-user {
  margin: 70px auto !important;
  padding: 0 0 30px !important;
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
