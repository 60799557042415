<template>
  <div>
    <div class="">
      <div class="row">
        <div class="w-100" v-if="!punchoutActive">
          <button-full
            class="btn w-100"
            :class="{ 'button-disabled' : !isValid }"
            @click.native="placeOrder"
            data-testid="orderReviewSubmit"
            v-if="hasPermission('create', 'order')"
          >
            <span v-if="hasPermission('approve', 'order')">
              Submit Order
            </span>
            <span v-else>
              Submit Order for Approval
            </span>
          </button-full>
        </div>
        <div class="w-100" v-else>
          <button-full
            class="btn w-100"
            :class="{ 'button-disabled' : !isValid }"
            @click.native="proceedToPunchout"
            data-testid="orderReviewSubmit"
            v-if="hasPermission('create', 'order')"
          >
            Send to ERP
          </button-full>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import Composite from 'core/mixins/composite'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import ButtonFull from 'theme/components/theme/ButtonFull'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import Modal from 'theme/components/core/Modal'
import OrderReview from 'core/components/blocks/Checkout/OrderReview'
import ValidationError from 'theme/components/core/ValidationError'

export default {
  data () {
    return {
      base64String: null,
      url: null,
      currencySign: config.i18n.currencySign
    }
  },
  props: {
    isValid: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BaseCheckbox,
    ButtonFull,
    ButtonOutline,
    CartSummary,
    Modal,
    ValidationError
  },
  mixins: [OrderReview, Composite],
  computed: {
    punchoutActive () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails
        ? this.$store.state.userDetails.companyDetails.punchoutActive
        : false
    }
  },
  methods: {
    proceedToPunchout () {
      this.$bus.$emit('checkout-before-sendToERP')
    },
    placeOrder () {
      this.$bus.$emit('checkout-before-placeOrder')
    }
  }
}
</script>

<style lang="scss" scoped>
  .link {
    text-decoration: underline;
  }

  .cartsummary-wrapper {
    @media (min-width: 767px) {
      display: none;
    }
  }
  button {
    border-radius: 5px;
  }
</style>
