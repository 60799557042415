<template>
  <div class="breadcrumbs cl-gray">
    <span v-for="link in routes" :key="link.route_link">
      <router-link :to="localizedRoute(link.route_link)">
        {{ link.name | htmlDecode }}
      </router-link> >
    </span>
    <span class="cl-gloo-main">
      <!-- <span class="cl-primary"> -->
      {{ activeRoute | htmlDecode }}
    </span>
  </div>
</template>

<script>
import Breadcrumbs from 'core/components/Breadcrumbs'

export default {
  mixins: [Breadcrumbs]
}
</script>
<style lang="scss" scoped>
  a, span {
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    letter-spacing: 0.05em;
  }
  @media (max-width: 900px) {
    a, span {
      letter-spacing: normal;
      font-size: 12px;
      line-height: 14px;
    }
  }
</style>
