<template>
  <modal name="modal-add-location" :width="600">
    <v-container grid-list-xl create-location>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Add Location' }}
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <div>
            <v-text-field
              label="Location name"
              v-model="location.name"
              outline
              :rules="rules.locationName"
              required
            />
            <v-text-field
              label="Location code"
              v-model="location.code"
              outline
              :rules="rules.locationCode"
              required
            />
          </div>
          <div>
            <v-card-title class="section-caption">
              Location Address Information
            </v-card-title>
            <v-text-field
              label="Street Address"
              v-model="location.streetAddress"
              outline
              :rules="rules.streetAddress"
              required
            />
            <v-text-field
              label="Address Line 2"
              v-model="location.addressLine2"
              outline
            />
            <v-select
              label="Country of location"
              :items="countries"
              item-text="name"
              item-value="code"
              v-model="location.country"
              :rules="rules.country"
              required
              outline
            />
            <v-select
              label="State of location"
              :items="filteredStates"
              item-text="name"
              item-value="name"
              v-model="location.state"
              :rules="rules.state"
              required
              outline
            />
            <v-select
              :items="filteredCities"
              :item-text="getCityText"
              :return-object="true"
              label="City of location"
              v-model="selectedCity"
              :rules="rules.city"
              required
              outline
            />
            <v-text-field
              label="Zip code"
              v-model="location.zipcode"
              outline
            />
          </div>

          <div>
            <v-card-title class="section-caption">
              Location Delivery Details
            </v-card-title>
            <v-text-field
              label="Minimum order amount"
              v-model="location.minimumOrderAmount"
              outline
              type="number"
              :rules="rules.minimumOrderAmount"
              :error-messages="minimumOrderAmountError.messages"
              :error="minimumOrderAmountError.hasError"
            />
            <!-- Delivery Methods -->
            <div class="row delivery-methods" v-for="deliveryMethod in deliveryMethods" :key="deliveryMethod.id">
              <div class="col-md-6 col-xs-12">
                <v-text-field
                  label="Delivery Method"
                  type="text"
                  outline
                  :disabled="true"
                  v-model="deliveryMethod.name"
                />
              </div>
              <div class="col-md-6 col-xs-12">
                <v-text-field
                  label="Delivery Fee"
                  :error-messages="deliveryMethodsErrors[deliveryMethod.id].messages"
                  :error="deliveryMethodsErrors[deliveryMethod.id].hasError"
                  type="number"
                  :disabled="true"
                  v-model="location.deliveryMethods[deliveryMethod.id].deliveryFee"
                  outline
                />
              </div>
              <!-- <div class="col-md-4 col-xs-12">
                <v-text-field
                  label="Delivery Days"
                  v-model="location.deliveryMethods[deliveryMethod.id].deliveryDays"
                />
              </div> -->
            </div>
          </div>
          <v-btn
            block
            @click="submit"
            :disabled="formValid() === false"
            style="height: 45px"
          >
            Submit
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal'
import { mapGetters } from 'vuex'
import { keyBy } from 'lodash-es'

export default {
  name: 'AddLocation',
  components: {
    Modal
  },
  data () {
    return {
      location: {
        name: '',
        code: '',
        companyId: this.$store.state.userDetails.companyDetails.id,
        minimumOrderAmount: '',
        streetAddress: '',
        addressLine2: '',
        state: '',
        city: '',
        country: '',
        phone: '',
        zipcode: '',
        deliveryMethods: {}
      },
      selectedCity: null,
      rules: {
        locationName: [
          v => !!v || 'Location name is required'
        ],
        locationCode: [
          v => !!v || 'Location code is required'
        ],
        streetAddress: [
          v => !!v || 'Street Address is required'
        ],
        city: [
          v => !!v || 'City is required'
        ],
        state: [
          v => !!v || 'State is required'
        ],
        country: [
          v => !!v || 'Country is required'
        ],
        minimumOrderAmount: [
          v => !!v || 'Minimum order amount is required'
        ]
      },
      deliveryMethodsErrors: {},
      minimumOrderAmountError: {
        hasError: false,
        messages: []
      },
      filteredStates: [],
      filteredCities: []
    }
  },
  created () {
    this.$store.dispatch('fetchCities')
      .then(() => {})
      .catch(err => {
        let errorMessage = err.message
        if (err.response && err.response.data.result) {
          errorMessage = err.response.data.result
        }
        this.$bus.$emit('notification', {
          type: 'error',
          message: errorMessage,
          action1: { label: 'OK', action: 'close' }
        })
      })
  },
  computed: {
    ...mapGetters({
      cities: 'getCities'
    }),
    deliveryMethods () {
      return this.selectedCity ? this.selectedCity.deliveryMethods : []
    },
    states () {
      let statesSet = new Set()
      let states = []
      if (!(this.cities && Array.isArray(this.cities))) {
        return states
      }
      this.cities.forEach(city => {
        let state = city.state
        if (!statesSet.has(state.id)) {
          statesSet.add(state.id)
          states.push(state)
        }
      })
      return states
    },
    countries () {
      let countriesSet = new Set()
      let countries = []
      if (!Array.isArray(this.states)) {
        return countries
      }
      this.states.forEach(state => {
        let country = state.countryId
        if (!countriesSet.has(country.id)) {
          countriesSet.add(country.id)
          countries.push(country)
        }
      })
      return countries
    }
  },
  watch: {
    selectedCity: function (val) {
      this.location.city = val.name
      this.location.country = val.state.countryId.code
      this.location.state = val.state.name
      this.location.minimumOrderAmount = val.minimumOrderAmount
      let temp = keyBy(val.deliveryMethods, method => {
        return method.id
      })
      this.location.deliveryMethods = JSON.parse(JSON.stringify(temp))
      val.deliveryMethods.forEach(method => {
        this.deliveryMethodsErrors[method.id] = {
          hasError: false,
          messages: []
        }
      })
    },
    countries: function (val) {
      this.location.country = val.length === 1 ? val[0].code : ''
    },
    states: function (val) {
      this.filteredStates = val
    },
    cities: function (val) {
      this.filteredCities = val
    },
    'location.country': function (val) {
      this.filteredStates = this.states.filter(state => {
        return state.countryId.code === val
      })
      this.location.state = ''
    },
    'location.state': function (val) {
      this.filteredCities = this.cities.filter(city => {
        return city.state.name === val || (!val && city.state.countryId.code === this.location.country)
      })
      this.location.city = ''
    },
    'location.name': function (val) {
      let companyCode = this.$store.state.userDetails.companyDetails.code
      let code = this.location.name.toLowerCase().trim().replace(/(\s)+/g, '_')
      this.location.code = `${companyCode}_${code}`
    },
    'location.city': function (val) {
      let locationDeliveryMethods = Object.values(this.location.deliveryMethods)
      locationDeliveryMethods.forEach(method => {
        // get corresponding default delivery fee
        let defaultDeliveryMethod = this.deliveryMethods.find(entry => {
          return entry.id === method.id
        })
        if (!defaultDeliveryMethod) {
          return
        }
        if (Number(method.deliveryFee) < Number(defaultDeliveryMethod.deliveryFee)) {
          this.deliveryMethodsErrors[method.id].hasError = true
          this.deliveryMethodsErrors[method.id].messages = []
          this.deliveryMethodsErrors[method.id].messages.push('Delivery Fee cannot be less than ' + this.$options.filters.currency(defaultDeliveryMethod.deliveryFee))
        } else {
          this.deliveryMethodsErrors[method.id].hasError = false
          this.deliveryMethodsErrors[method.id].messages = []
        }
      })

      // check if the minimum order amount is less than the default for the city
      if (!this.selectedCity) {
        return
      }
      if (Number(this.location.minimumOrderAmount) < Number(this.selectedCity.minimumOrderAmount)) {
        this.minimumOrderAmountError.hasError = true
        this.minimumOrderAmountError.messages = []
        this.minimumOrderAmountError.messages.push('Minimum order amount cannot be less than ' + this.$options.filters.currency(this.selectedCity.minimumOrderAmount))
      } else {
        this.minimumOrderAmountError.hasError = false
        this.minimumOrderAmountError.messages = []
      }
    }
  },
  methods: {
    formValid () {
      const { name, code, minimumOrderAmount, streetAddress, state, city, country } = this.location
      if (!(name && code && minimumOrderAmount && streetAddress && state && city && country)) {
        return false
      }
      // check if an error exists in the minimum order amount or delivery fee
      if (this.minimumOrderAmountError.hasError) {
        return false
      }
      let hasError = false
      let deliveryMethodsErrors = Object.values(this.deliveryMethodsErrors)
      deliveryMethodsErrors.forEach(error => {
        if (error.hasError) {
          hasError = true
        }
      })
      return !hasError
    },
    submit () {
      this.$bus.$emit('notification-progress-start', 'Saving location...')
      this.$store.dispatch('saveLocation', this.location)
        .then(response => {
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('notification', {
            type: 'success',
            message: 'Location saved successfully',
            action1: { label: 'OK', action: 'close' }
          })
          this.close()
          let locationId = response.data.result.location.id
          this.$router.push(this.localizedRoute(`/company-settings/location-settings/${locationId}`))
        })
        .catch(err => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = err.message
          if (err.response && err.response.data.result) {
            errorMessage = err.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    getCityText (city) {
      return `${city.name}`
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-add-location')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);
$color-white: color(white);

.create-location {
  padding: 0 0 30px !important;
  margin: 70px auto !important;
}

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.v-messages .error--text {
  color: red;
}

.section-caption {
  padding: 10px 0 !important;
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
