const state = {
  fields: [
    {
      name: '',
      image: null,
      imageUrl: '',
      description: ''
    }
  ],
  similarProducts: []
}

// Getters
const getters = {
  // Getter to return fields
  getFields: (state) => state.fields,

  getSimilarProducts: (state) => state.similarProducts
}

// Actions
const actions = {
  // Action to add a new field
  addField ({ commit }) {
    commit('ADD_FIELD')
  },

  // Action to remove a field
  removeField ({ commit }, index) {
    commit('REMOVE_FIELD', index)
  },

  saveSimilarProducts ({ commit }, products) {
    commit('SET_SIMILAR_PRODUCTS', products)
  },

  // fetch similar products
  getSimilarProducts ({ commit }, productName, email) {
    return fetch(
      `https://ic.gloopro.com/api/catalog-requests/company-product-exists?user_email=${email}&product_name=${productName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        commit('SET_SIMILAR_PRODUCTS', data.similar_products)
        return data
      })
      .catch((error) => {
        console.log(error)
      })
  },

  // Action to handle form submission
  submitForm: function ({ commit }, data) {
    return fetch('https://ic.gloopro.com/api/catalog-requests/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: data
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData
      })
      .catch((error) => {
        return error.message
      })
  }
}

const mutations = {
  ADD_FIELD (state) {
    state.fields.push({
      name: '',
      image: null,
      imageUrl: '',
      description: ''
    })
  },

  REMOVE_FIELD (state, index) {
    state.fields.splice(index, 1)
  },

  // Reset the form after successful submission
  RESET_FIELDS (state) {
    state.fields = [
      {
        name: '',
        image: null,
        imageUrl: '',
        description: ''
      }
    ]
  },

  // Mutation to handle image changes
  UPDATE_IMAGE (state, { index, image, imageUrl }) {
    state.fields[index].image = image
    state.fields[index].imageUrl = imageUrl
  },

  SET_SIMILAR_PRODUCTS (state, products) {
    state.similarProducts = products
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
