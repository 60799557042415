<template>
  <div class="card-wrapper">
    <div class="card">
      <h2>Catalog Request</h2>
      <form @submit.prevent="submitForm" novalidate>
        <div v-for="(item, index) in fields" :key="index" class="field-card">
          <base-input
            v-if="item"
            class="mb35 login-input"
            type="text"
            name="product_name"
            focus
            v-model="item.product_name"
            :placeholder="$t('Product Name *')"
            :validation="{
              condition: $v.fields.$each[index] && $v.fields.$each[index].product_name.$error && !$v.fields.$each[index].product_name.required,
              text: $t('Product Name is required')
            }"
            @input="debouncedCheckProductName(item.product_name, index)"
          />
          <!-- Similar Product Suggestions -->
          <div class="similar-products">
            <span class="similar-products-title" v-if="similarProducts[index] && similarProducts[index].length">Similar Products Exist:</span>
            <div class="similar-products-list">
              <div v-for="product in similarProducts[index]" :key="product.id" class="product-card">
                <router-link
                  class="block no-underline product-link"
                  :to="localizedRoute({
                    name: product.type_id + '-product',
                    params: {
                      parentSku: product.parentSku ? product.parentSku : product.sku,
                      slug: product.slug,
                      childSku: product.sku
                    }
                  })"
                  data-testid="productLink"
                >
                  <span class="product-name">{{ truncateProductName(product.name, 30) }}</span>
                </router-link>
              </div>
            </div>
            <!-- <router-link v-if="similarProducts[index].length > 5"
                class="block no-underline product-link"
                :to="localizedRoute('/catalog-request/existing-catalog-request')"
                data-testid="productLink"
              >
              <button class="see-all">See All</button>
            </router-link> -->
          </div>

          <base-input
            class="mb35 login-input"
            type="text"
            name="product_brand"
            focus
            v-model="item.product_brand"
            :placeholder="$t('Product Brand')"
          />
          <base-input
            class="mb35 login-input"
            type="text"
            name="product_category"
            focus
            v-model="item.product_category"
            :placeholder="$t('Product Category')"
            :validations="[]"
          />
          <div class="">
            <base-textarea
              class="mb25 login-input description"
              type="text"
              name="body"
              value=""
              v-model="item.description"
              :placeholder="$t('Description *')"
              :autofocus="true"
              :validation="{
                condition: $v.fields.$each[index] && $v.fields.$each[index].description.$error && !$v.fields.$each[index].description.required,
                text: $t('Description is required')
              }"
            />
          </div>
          <div class="form-group-file">
            <label for="image" class="custom-file-label">Select Image:</label>
            <div class="custom-file-input-wrapper">
              <div class="custom-file-input">
                <input type="file" id="image" @change="handleImageChange($event, index)" accept="image/*">
                <label for="image" class="file-label">
                  <span v-if="!item.image">Choose a file</span>
                  <span v-else>{{ item.image.name }}</span>
                </label>
              </div>
              <div class="clear" v-if="item.image" @click="handleClearFile(index,'product_image')">
                <i data-v-7f3b1c20="" class="material-icons p1 cl-gloo-gra">close</i>
              </div>
            </div>
          </div>
          <div v-if="item.image" class="image-preview-container" style="margin-bottom:20px;">
            <img :src="item.imageUrl" alt="Preview" class="image-preview" >
          </div>
          <div class="form-group-file">
            <label for="image" class="custom-file-label">Select Fact Sheet:</label>
            <div class="custom-file-input-wrapper">
              <div class="custom-file-input">
                <input type="file" id="image" @change="handleFactSheetChange($event, index)" accept=".pdf">
                <label for="image" class="file-label">
                  <span v-if="!item.factSheet">Choose a file</span>
                  <span v-else>{{ item.factSheet.name }}</span>
                </label>
              </div>
              <div class="clear" v-if="item.factSheet" @click="handleClearFile(index,'factsheet')">
                <i data-v-7f3b1c20="" class="material-icons p1 cl-gloo-gra">close</i>
              </div>
            </div>
          </div>
          <div v-if="item.factSheet" class="image-preview-container" style="margin-bottom:20px;">
            <iframe
              :src="fields[0].factSheetUrl"
              type="application/pdf"
              width="80%"
              height="250px"
              class="fact-sheet-frame"
            />
          </div>
          <!-- :validation="{
              condition: $v.fields.$each[index] && $v.fields.$each[index].product_brand.$error && !$v.fields.$each[index].product_brand.required,
              text: $t('Product Brand is required')
            }" -->
          <!-- <span class="error" v-if="$v.fields.$each[index] && $v.fields.$each[index].image.$error && !$v.fields.$each[index].image.required">Image field is required</span> -->
          <!-- <span class="error">Max size 2048kb</span> -->
        </div>
        <button-full class="mb20 bg-cl-gloo-main" type="submit">
          <p class="p0 m0">Submit</p>
        </button-full>
      </form>
    </div>
  </div>
</template>

<script>
import BaseInput from '../components/core/blocks/Form/BaseInput.vue'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseTextarea from '../components/core/blocks/Form/BaseTextarea.vue'
import { required } from 'vuelidate/lib/validators'
import i18n from 'core/lib/i18n'

export default {
  components: {
    BaseInput,
    BaseTextarea,
    ButtonFull
  },
  data () {
    return {
      userId: this.$store.state.userDetails.userId,
      fields: [
        {
          product_name: '',
          product_category: '',
          product_brand: '',
          image: null,
          imageUrl: '',
          factSheet: null,
          factSheetUrl: '',
          description: ''
        }
      ],
      similarProducts: [[]]
    }
  },
  validations: {
    fields: {
      $each: {
        product_name: { required },
        // product_brand: { required },
        description: { required }
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUserDetails(this.userId)
    }
  },
  created () {
    this.debouncedCheckProductName = this.debounce(this.checkProductName, 500)
  },
  methods: {
    log (data) {
      console.log(data)
    },
    handleImageChange (event, index) {
      const file = event.target.files[0]
      if (file) {
        this.fields[index].image = file
        this.fields[index].imageUrl = URL.createObjectURL(file)
      }
    },
    debounce (fn, delay) {
      let timeout
      return function (...args) {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          fn.apply(this, args)
        }, delay)
      }
    },
    debouncedCheckProductName (productName, index) {
      this.debouncedCheckProductName(productName, index)
    },
    checkProductName (productName, index) {
      if (!productName) {
        this.$set(this.similarProducts, index, [])
        return
      }
      const user = this.$store.state.user
      const email = user.current.email
      this.$store.dispatch('getSimilarProducts', productName, email).then((res) => {
        if (res.status === 200) {
          this.$set(this.similarProducts, index, res.data)
        } else {
          this.$set(this.similarProducts, index, [])
        }
      })
    },
    handleFactSheetChange (event, index) {
      const file = event.target.files[0]
      if (file) {
        this.fields[index].factSheet = file
        if (file.type.includes('image')) {
          this.fields[index].factSheetUrl = URL.createObjectURL(file)
        } else if (file.type === 'application/pdf') {
          this.fields[index].factSheetUrl = URL.createObjectURL(file)
        }
      }
    },
    handleClearFile (index, fieldName) {
      if (fieldName === 'factsheet') {
        this.fields[index].factSheet = null
        this.fields[index].factSheetUrl = ''
      }
      if (fieldName === 'product_image') {
        this.fields[index].image = null
        this.fields[index].imageUrl = ''
      }
    },
    submitForm () {
      const user = this.$store.state.user
      // const userDetails = this.$store.state.userDetails

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$bus.$emit('notification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
        return
      }
      const formData = new FormData()
      const data = this.fields[0]
      formData.append(`product_name`, data.product_name)
      formData.append(`product_brand`, data.product_brand)
      formData.append(`product_category`, data.product_category)
      formData.append(`user_email`, user.current.email)
      formData.append(`product_description`, data.description)
      if (data.image) {
        formData.append(`product_image`, data.image)
      }
      if (data.factSheet) {
        formData.append(`fact_sheet`, data.factSheet)
      }

      this.$bus.$emit('notification-progress-start', i18n.t('Processing catalog request...'))
      this.$store.dispatch('submitForm', formData).then((res) => {
        if (res.status !== 200) {
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('notification', {
            type: 'error',
            message: i18n.t(res.message || 'Something went wrong!'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        } else {
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('notification', {
            type: 'success',
            message: i18n.t(res.message),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
          this.$v.$reset()
          this.resetFields()
        }
      })
    },
    truncateProductName (name, maxLength) {
      if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...'
      }
      return name
    },
    resetFields () {
      this.fields = [{
        product_name: '',
        product_category: '',
        product_brand: '',
        image: null,
        imageUrl: '',
        factSheet: null,
        factSheetUrl: '',
        description: ''
      }]
      this.similarProducts = [[]]
    }
  }
}
</script>

<style scoped>
.card-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card {
  width: 100%;
  max-width: 40em;
  padding: 2em 5em;
  border-radius: 0.304em;
  box-shadow: 0 0 3px 0 #D7D7E0;
  background: #fff;
  text-align: center;
  margin: 3em
}

.field-card {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 10px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
}

input[type="file"] {
  margin-top: 4px;
}

.description {
  padding: 4px;
  box-shadow: 0 0 3px 0 #D7D7E0 !important;
}

.image-preview {
  margin-top: 10px;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.add-btn,
.remove-btn,
.submit-btn {
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
}

.remove-btn {
  background-color: #dc3545;
}

.submit-btn {
  background-color: #007bff;
}

.add-btn:hover {
  background-color: #218838;
}

.remove-btn:hover {
  background-color: #c82333;
}

.submit-btn:hover {
  background-color: #0056b3;
}
.similar-products {
  margin-top: -1.5em;
  margin-bottom: 1.5em;
  text-align: left;
}

.similar-products-title {
  font-weight: bold;
}

.similar-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.product-card {
  background: #caf7c2;
  border: 1px solid #68a25e;
  border-radius: 8px;
  padding: 0.5em;
  margin-top: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex: 1 1 150px;
  transition: transform 0.2s;
  cursor: pointer;
}

.product-card:hover {
  transform: scale(1.02);
}

.product-name {
  font-size: 14px;
}

.form-group-file {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-bottom: 1.5rem;
}

.custom-file-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
}

.custom-file-input-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.custom-file-input {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.clear{
  background: #c82333;
  color: white;
  border-radius:50%;
  padding: 2px;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  display: flex;
  height: fit-content;
  align-items: center;
}

.cl-gloo-gra{
  font-size: 12px;
}

.custom-file-input input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; /* Hide the default file input */
  cursor: pointer;
}

.file-label {
  display: inline-block;
  width:100%;
  padding: 0.5rem 1rem;
  background-color: #ccc;
  color: #000;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.image-preview-container {
  margin-top: 0.5rem;
}

.image-preview {
  max-width: 100%;
  max-height: 200px; /* Adjust as needed */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.error{
  font-size: 14px;
  color: #c82333;
}
.see-all{
  margin-top: 10px;
  width:200px;
  color:#68a25e;
}
.see-all:hover{
  text-decoration: underline;
  color:#7acf6b;
}
</style>
