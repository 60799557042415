<template>
  <div id="product">
    <div class="top-section container">
      <div class="breadcrumb-wrapper">
        <breadcrumbs :routes="breadcrumbs.routes" :active-route="product.name" class="col-sm-12 breadcrumb pl0" />
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-6 col-xs-12 center-xs middle-xs image">
            <product-gallery
              :gallery="gallery"
              :offline="offlineImage"
              :configuration="configuration"
            />
          </div>
          <div class="col-md-5 col-xs-12 data" id="product-name">
            <div class="on-sale mt30">
              <p v-if="product.special_price"><span class="sale">ON SALE</span></p>
            </div>
            <h3 class="product-name">{{ product.name | htmlDecode }}</h3>
            <p class="my10 sku">SKU: {{ product.sku }}</p>

            <div class="price-container my30" v-if="product.type_id !== 'grouped'">
              <div
                class="cl-secondary"
                v-if="product.special_price && product.price && product.originalPriceInclTax"
              >
                <span class="cl-mine-shaft price">{{ product.price | currency }}</span>&nbsp;
                <span class="price-original price">{{ product.originalPriceInclTax | currency }}</span>
              </div>
              <div
                class="price"
                v-if="!product.special_price && product.price"
              >
                <span class="price">{{ product.price | currency }}</span>
              </div>
            </div>
            <p v-html="product.short_description" class="mb30 product-description" />

            <!-- Add To Cart Widget -->
            <div class="row m0" v-if="hasPermission('create', 'order')">
              <add-to-cart
                :product="product"
                class="add-to-cart"
                @click.native="addToCart"
                v-if="(!isInCart || product.status === 0) && product.product_stock_status === 'in-stock'"
              >
                <img src="/assets/cart.png" alt="cart">
                <span>ADD TO CART</span>
              </add-to-cart>

              <!-- Increment & Decrement Button -->
              <div class="row widget cl-gloo-gray" v-if="isInCart && product.status === 1 && product.product_stock_status === 'in-stock'">
                <button-outline color="light" class="col-xs-4 decrement" @click.native="decrement()">
                  <i class="material-icons h4 p5">remove</i>
                </button-outline>
                <div class="col-xs-4 widget-input cl-gloo-main">
                  <input type="number" class="h4" v-model="qty">
                </div>
                <button-outline color="light" class="col-xs-4 increment" @click.native="increment()">
                  <i class="material-icons h4">add</i>
                </button-outline>
              </div>
            </div>

            <div v-if="product.product_stock_status === 'out-of-stock'" @click="scrollToAlternatives">
              <button-outline class="light unavailable" data-testid="i">
                <span>Unavailable, View Alternatives</span>
              </button-outline>
            </div>

            <!-- face sheet view and download -->
            <div v-if="isPdfFile" class="face_sheet">
              <div class="btn btn-light-gray" @click="viewFaceSheet">
                <span>View Fact Sheet</span>
                <img class="eye-icon" src="/assets/eye.svg" alt="Eye Icon">
              </div>
              <!-- <div class="btn btn-light-gray" @click="downloadFactSheet">
                <span>Download Fact Sheet</span>
                <img class="eye-icon" src="/assets/download-arrow-icon.svg" alt="Download">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-description">
      <div class="container product-description-inner">
        <div class="px80 product-description-wrapper">
          <h3 class="product-detail-caption">Product Details</h3>
          <div class="bottom-border mb15 mt15"/>
          <p v-html="product.description" class="product-description" />
        </div>
        <div class="px80 product-description-wrapper mt-5" v-if="product.product_filtered_alternatives.length && product.product_stock_status === 'out-of-stock'" ref="alternativeProductsSection">
          <h3 class="product-detail-caption" >Alternative Products</h3>
          <product-listing columns="3" :products="product.product_filtered_alternatives" :identity="productType"/>
        </div>

        <div class="px120 product-description-wrapper mt-5" v-if="product.price_comparison_1 || product.price_comparison_2">
          <h3 class="product-detail-caption">Compare Prices</h3>
          <div class="bottom-border mb15 mt15"/>
          <div>
            <p>To show that our prices are competitive, you can check the price of this product on other online stores via the link(s) below</p>
            <a :href="product.price_comparison_1" target="_blank" v-if="product.price_comparison_1">{{ product.price_comparison_1 }}</a><br>
            <a :href="product.price_comparison_2" target="_blank" v-if="product.price_comparison_2">{{ product.price_comparison_2 }}</a>
          </div>
        </div>

        <!-- Related Products -->
        <!-- <div class="similiar-products-wrapper">
          <h3 class="product-detail-caption">Similar Products</h3>
          <p class="mb5 sub-caption">Products related to this item</p>
          <div class="bottom-border mb15 mt15"/>
          <related-products type="related"/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Product from 'core/pages/Product'

import RelatedProducts from 'theme/components/core/blocks/Product/Related.vue'
import AddToCart from 'theme/components/core/AddToCart.vue'
import GenericSelector from 'theme/components/core/GenericSelector'
import ColorSelector from 'theme/components/core/ColorSelector.vue'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue'
import ProductAttribute from 'theme/components/core/ProductAttribute.vue'
import ProductTile from 'theme/components/core/ProductTile.vue'
import ProductLinks from 'theme/components/core/ProductLinks.vue'
import ProductCustomOptions from 'theme/components/core/ProductCustomOptions.vue'
import ProductBundleOptions from 'theme/components/core/ProductBundleOptions.vue'
import ProductGallery from 'theme/components/core/ProductGallery'
import PromotedOffers from 'theme/components/theme/blocks/PromotedOffers/PromotedOffers'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'
import IncrementButton from 'theme/components/core/blocks/Microcart/IncrementButton'
import DecrementButton from 'theme/components/core/blocks/Microcart/DecrementButton'
import i18n from 'core/lib/i18n'
import ProductListing from 'theme/components/core/ProductListing'

export default {
  components: {
    AddToCart,
    Breadcrumbs,
    ColorSelector,
    GenericSelector,
    ProductAttribute,
    ProductBundleOptions,
    ProductCustomOptions,
    ProductGallery,
    ProductLinks,
    ProductTile,
    RelatedProducts,
    SizeSelector,
    ButtonOutline,
    IncrementButton,
    DecrementButton,
    PromotedOffers,
    ProductListing
  },
  mixins: [Product],
  data () {
    return {
      detailsOpen: false,
      qty: 0,
      isInCart: false,
      productType: 'alternative-product'
    }
  },
  watch: {
    qty: function (val) {
      this.qty = parseInt(val)
      if (this.qty < 0) {
        this.qty = 0
      }
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: parseInt(this.qty) })
    },
    'product.sku': function (val) {
      let cartItems = this.$store.state.cart ? this.$store.state.cart.cartItems : []
      let prod = cartItems.find(item => {
        return item.sku === this.product.sku
      })
      if (!prod) {
        this.qty = 0
        this.isInCart = false
      } else {
        this.isInCart = true
        this.qty = prod.qty
      }
    }
  },
  methods: {
    scrollToAlternatives () {
      const alternativeSection = this.$refs.alternativeProductsSection
      if (alternativeSection) {
        alternativeSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    validateRoute () {
      let inst = this
      if (!inst.loading) {
        inst.loading = true
        inst.$store.dispatch('product/fetchAsync', { parentSku: inst.$route.params.parentSku, childSku: inst.$route && inst.$route.params && inst.$route.params.childSku ? inst.$route.params.childSku : null }).then((res) => {
          inst.loading = false
          inst.defaultOfflineImage = inst.product.image
          this.onStateCheck()
          this.$bus.$on('filter-changed-product', this.onAfterFilterChanged)
        }).catch((err) => {
          inst.loading = false
          console.error(err)
          this.$bus.$emit('notification', {
            type: 'error',
            message: i18n.t('The product is out of stock and cannot be added to the cart!'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
          this.$router.back()
        })
      } else {
        this.$router.go(0)
        console.error('Error with loading = true in Product.vue; Reload page')
      }
    },
    increment () {
      this.qty++
    },
    decrement () {
      this.qty--
    },
    addToCart () {
      if (!this.product.status) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'The selected product is not available',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$store.dispatch('cart/addItem', { productToAdd: this.product })
      this.qty = 1
      this.isInCart = true
    },
    showDetails (event) {
      this.detailsOpen = true
      event.target.classList.add('hidden')
    },
    viewFaceSheet () {
      if (this.product.fact_sheet && this.isPdfFile) {
        const link = document.createElement('a')
        link.href = this.product.fact_sheet
        link.download = this.product.fact_sheet.split('/').pop()
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.parentNode.removeChild(link)
      } else {
        this.$bus.$emit('notification', {
          type: 'warning',
          message: 'No fact sheet available for this product',
          action1: { label: 'OK', action: 'close' }
        })
      }
    }
  },
  created () {
    // close the search panel
    this.$store.commit('ui/setSidebar', false)
    this.$store.commit('ui/setMicrocart', false)
    this.$store.commit('ui/setSearchpanel', false)

    this.$bus.$on('product-after-priceupdate', (product) => {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    })
    let cartItems = this.$store.state.cart ? this.$store.state.cart.cartItems : []
    let prod = cartItems.find(item => {
      return item.sku === this.product.sku
    })
    if (!prod) {
      this.qty = 0
    } else {
      this.isInCart = true
      this.qty = prod.qty
    }
    this.$bus.$on('cart-after-update', ({ product }) => {
      if (product.sku === this.product.sku) {
        this.qty = parseInt(product.qty)
        if (this.qty === 0) {
          this.isInCart = false
          this.$store.dispatch('cart/removeItem', product)
        }
      }
    })
  },
  directives: { focusClean },
  computed: {
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border'
    },
    isPdfFile () {
      return this.product.fact_sheet && this.product.fact_sheet.toLowerCase().endsWith('.pdf')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-secondary: color(secondary);
$color-white: color(white);
$bg-secondary: color(secondary, $colors-background);
$color-solitude: color(gloo-solitude);
$color-section-text: color(gloo-section-header-text);
$color-gloo-gray: color(gloo-gray);

#product {
  background: #f8f9fd;
  .top-section {
    margin: 60px 60px 0;
    .breadcrumb-wrapper {
      margin: 0 50px 30px;
      padding: 15px 0;
      border-bottom: 1px solid $color-solitude;
      .breadcrumb {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: $color-gloo-gray;
      }
    }
  }
  .media-gallery {
    background-color: transparent !important;
  }
  .product-description {
    // background-color: #fafafa;
    color: $color-gloo-gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    .bottom-border {
      border-bottom: 2px solid $color-solitude;
    }
    .product-description-inner {
      padding-top: 50px !important;
    }
  }
  .px120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .px80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .product-description-wrapper {
    margin: 0 50px;
    //offset the extra width created by the .px120 class on this element to cause it to start at normal width on the browser
    .bottom-border {
      margin-left: -120px;
      margin-right: -120px;

      @media (max-width: 1023px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .similiar-products-wrapper {
    margin: 70px 50px;
    .sub-section {
      color: $color-gloo-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px
    }
  }
   div#product-name, div.image {
    padding: 0 50px;
  }
  div#product-name {
    padding-left: 0;
    margin-top: 20px;
    .sale {
      border: 1px solid orange;
      padding: 5px;
      border-radius: 20px;
    }
  }
  .product-name, .product-detail-caption {
    font-size: 24px;
    font-weight: 550;
    line-height: 29px;
    color: $color-section-text;
  }
  .sku {
    color: rgba(37, 56, 88, 0.7);
    font-size: 17px;
    font-weight: 400;
    line-height: 19px;
  }
  .price-container {
    border-top: 1px solid $color-solitude;
    border-bottom: 1px solid $color-solitude;
    padding: 20px 0;
  }
  .price {
    color: $color-section-text;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
  .price-original {
    text-decoration: line-through;
    color: red;
  }
  .add-to-cart {
    width: 310px !important;
    height: 42px !important;
    color: $color-white;
    border-radius: 3px;
    width: 70%;
    background-color: $color-primary;
    padding: 5px 10px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    &:hover {
      background-color: $color-white;
      outline: $color-primary;
      color: $color-primary;
    }
  }
  .widget, .widget div {
    padding: 0 !important;
    margin: 0 !important;
  }
  .widget {
    border: 2px solid $color-primary;
    border-radius: 4px;
    text-align: center;
    width: 310px !important;
    height: 40px;
  }
  .widget-input {
    width: 100%;
  }
  .widget-input input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 31px;
    padding: 0;
  }
  .widget-input input:focus {
    outline: none;
  }
  i {
    font-size: 20px;
    line-height: 31px;
    font-weight: 300;
  }
  button {
    padding: 0 !important;
    height: 40px;
  }
  .widget button {
    height: 100%;
    border: none;
    min-width: unset;
  }
  button.decrement {
    border-right: 2px solid;
    border-radius: unset;
  }
  button.increment {
    border-left: 2px solid;
    border-radius: unset;
  }
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .face_sheet{
    display: flex;
    width:100%;
    flex-direction: row;
    margin-top: 20px;
    gap: 10px;
    box-sizing: border-box;
  }
  .btn {
    display: flex;
    width:310px;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .btn-light-gray {
    background-color: #d3d3d3;
    color: black;
  }
  .btn-light-gray:hover {
    background-color: #b0b0b0;
  }
  .eye-icon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
  .unavailable {
    width: 250px;
    margin-top: 20px;
    font-size: 17px;
  }

  /* Responsive Design Style for mobile screens */
  @media (max-width: 1023px) {
    .breadcrumb-wrapper {
      margin: 0 0 30px !important;
    }
    .px120, .px80 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .product-description-wrapper, div#product-name, div.image {
      margin: 0;
    }
    div.image {
      padding: 0;
    }
    .similiar-products-wrapper {
      margin: 70px 0 !important;
    }
    .unavailable {
      width: 100%;
      margin-top: 20px;
      font-size: 17px;
    }
  }
}

.light {
  background-color: #fff;
}

</style>
