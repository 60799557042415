<template>
  <div />
</template>

<script>
import store from 'core/store'

export default {
  name: 'Holding',
  mounted () {
    this.$bus.$emit('notification-progress-start', 'Loading...')
    let url = this.$route.query.redirect
    const usersCollection = global.$VS.db.usersCollection
    usersCollection.getItem('current-token', (err, token) => {
      if (!token) {
        token = store.state.user.token
      }
      this.$bus.$emit('notification-progress-stop')
      if (err || !token) {
        let destination = url ? '?redirect=' + url : ''
        this.$router.push('/login' + destination)
      } else {
        // check if user has changed original password
        usersCollection.getItem('password-changed', (err, passwordChanged) => {
          if (!passwordChanged) {
            passwordChanged = store.state.userDetails.passwordChanged
          }
          if (err || passwordChanged === false) {
            this.$router.push('/first-password-change')
            return
          }
          this.$router.push(url)
        })
      }
    })
  }
}
</script>
