<template>
  <div :class="['media-gallery', { 'open bg-cl-primary': isZoomOpen }]">
    <div v-show="OfflineOnly" class="image-view offline-img-wrapper">
      <transition name="fade" appear>
        <img class="offline-image" v-lazy="offline" :src="offline.src" ref="offline" alt="">
      </transition>
    </div>
    <i
      v-if="isZoomOpen"
      v-show="OnlineOnly"
      class="material-icons modal-close p15 cl-bg-tertiary pointer"
      @click="toggleZoom"
    >close</i>
    <div
      v-show="OnlineOnly"
      :class="{ 'product-zoom py40': isZoomOpen }"
    >
      <div :class="['relative', { row: isZoomOpen }]" class="images-container">
        <div class="scroll col-md-2 col-sm-12 p0" v-if="isZoomOpen">
          <div class="thumbnails">
            <div
              class="bg-cl-primary"
              v-for="(images, key) in gallery"
              :key="images.src">
              <transition name="fade" appear>
                <img
                  v-lazy="images"
                  class="mw-100 pointer" ref="images" @click="navigate(key)"
                  :class="{ 'selected': activeThumbnail === key }"
                  alt=""
                >
              </transition>
            </div>
          </div>
        </div>
        <div v-if="gallery.length === 1">
          <transition name="fade" appear>
            <img
              :src="defaultImage.src"
              v-lazy="defaultImage"
              class="mw-100 pointer"
              ref="defaultImage"
              alt=""
            >
          </transition>
        </div>
        <div v-else :class="{ 'col-md-10 col-sm-12 col-xs-12 image-view' : isZoomOpen}">
          <no-ssr>
            <carousel
              class="image-carousel"
              :per-page="1"
              :mouse-drag="false"
              ref="carousel"
            >
              <slide
                v-for="images in gallery"
                :key="images.src">
                <div class="bg-cl-primary">
                  <img
                    class="product-image inline-flex pointer mw-100"
                    v-lazy="images"
                    ref="images"
                    @dblclick="toggleZoom"
                    alt=""
                    data-testid="productGalleryImage"
                  >
                </div>
              </slide>
            </carousel>
          </no-ssr>
          <div class="col-xs-12">
            <div />
          </div>
          <i
            v-if="isZoomOpen === false"
            class="zoom-in material-icons p15 cl-bgs-tertiary pointer"
            @click="toggleZoom"
          >zoom_in</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductGallery from 'core/components/ProductGallery'
import NoSSR from 'vue-no-ssr'
import VueOfflineMixin from 'vue-offline/mixin'

export default {
  components: {
    'no-ssr': NoSSR
  },
  mixins: [ProductGallery, VueOfflineMixin],
  watch: {
    '$route': 'validateRoute'
  },
  data () {
    return {
      loaded: true,
      isZoomOpen: true,
      activeThumbnail: 0
    }
  },
  methods: {
    validateRoute () {
      this.$forceUpdate()
    },
    navigate (index) {
      if (this.$refs.carousel) {
        this.activeThumbnail = index
        this.$refs.carousel.goToPage(index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);

.images-container {
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}
.media-gallery {
  text-align: center;
}
.product-zoom {
  max-width: 750px;
}
.zoom-in {
  position: absolute;
  bottom: 0;
  right: 0;
}
img {
  opacity: 0.9;
  mix-blend-mode: multiply;
  vertical-align: top;
  &:hover {
    opacity: 1;
  }
}
img[lazy=error] {
  width: 100%;
}
img[lazy=loading] {
  width: 100%;
}
.scroll {
  max-height: 400px;
  // margin-top: 80px;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    padding: 50px 0 0;
  }
}

.thumbnails {
  display: flex;
  flex-direction: row;

  div {
    margin: 0 20px 20px 0;
  }
  @media (min-width: 1025px) {
    flex-direction: column;
  }
  img.pointer.selected {
    // padding: 3px;
    border: 1px solid $color-gloo-main;
  }
}
</style>
<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-solitude: color(gloo-solitude);
$color-white: color(white);

.media-gallery {
  .image-view, .thumbnails .bg-cl-primary {
    border: 1px solid $color-solitude;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 #d7d7e0;
    padding: 0;
  }
  .thumbnails .bg-cl-primary {
    max-width: 84px;
    max-height: 100px;
  }
  .thumbnails .bg-cl-primary img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .thumbnails img.pointer {
    padding: 12px;
  }
  .carousel-image {
    width: 100%;

    img {
      width: 100%;
    }

  }
  .VueCarousel-wrapper, .offline-img-wrapper {
    background-color: $color-white;
    max-height: 400px;
    padding: 20px;
  }
  .VueCarousel-pagination {
    position: absolute;
    bottom: 15px;
    display: none;
  }
  .VueCarousel-navigation-button {
    margin: 0;
    transform: translateY(-50%) !important;
  }
  .VueCarousel-slide {
    backface-visibility: unset;
  }
  .VueCarousel-navigation {
    opacity: 0;
    &--disabled {
      opacity: 0.3;
    }
  }
  .VueCarousel-dot {
    padding: 8px !important;
    button {
      border: 2px solid #828282;
    }
  }
  &:hover {
    .VueCarousel-navigation {
      opacity: .9;
    }
    .VueCarousel-navigation-button {
      transition: opacity 3s;
      &:after {
        background-color: transparent;
      }
    }
  }
  .modal-close {
    display: none;
  }

  /* Control the height of images in the Vue Carousel and the offline-image */
  img.product-image.inline-flex.pointer.mw-100, img.offline-image {
    max-height: 320px;
  }
}
</style>
