import coreStore from '@vue-storefront/store/modules/user'
import { extendStore } from 'core/lib/themes'
import config from 'core/store/lib/config'
import EventBus from 'core/store/lib/event-bus'
import { ValidationError } from 'core/store/lib/exceptions'
import { adjustMultistoreApiUrl } from 'core/store/lib/multistore'
import * as types from 'core/store/mutation-types'
import Ajv from 'ajv' // json validator
import router from 'core/router'
import rootStore from 'core/store'

const actions = {
  changeUserToken (context, { newToken, meta }) {
    context.commit(types.USER_TOKEN_CHANGED, { newToken, meta })
    setTimeout(() => {
      context.dispatch('me', { refresh: true, useCache: false })
        .then(() => {
          rootStore.dispatch('cart/serverCreate', { guestCart: false }, { root: true })
          context.dispatch('getOrdersHistory', { refresh: true, useCache: false }).then(() => {})
        })
        .catch(() => {
          EventBus.$emit('notification', {
            type: 'error',
            message: 'An error occured during authentication. Please log back in and try again',
            action1: { label: 'OK', action: 'close' }
          })
          EventBus.$emit('user-before-logout')
          router.go()
        })
    }, 500)
  },
  update (context, userData) {
    const ajv = new Ajv()
    const validate = ajv.compile(require('./userProfile.schema.json'))

    if (!validate(userData)) { // schema validation of user profile data
      EventBus.$emit('notification', {
        type: 'error',
        message: 'Internal validation error. Please check if all required fields are filled in. Please contact us on contributors@vuestorefront.io',
        action1: { label: 'OK', action: 'close' }
      })
      throw new ValidationError(validate.errors)
    } else {
      return new Promise((resolve, reject) => {
        context.dispatch('sync/queue', { url: config.users.me_endpoint,
          payload: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-store' },
            mode: 'cors',
            body: JSON.stringify(userData)
          },
          callback_event: 'user-after-update'
        }, { root: true }).then(task => {
          resolve()
        })
      })
    }
  },
  /**
   * Login user and return user profile and current token
   */
  login (context, { username, password }) {
    return new Promise((resolve) => {
      let url = config.users.login_endpoint
      if (config.storeViews.multistore) {
        url = adjustMultistoreApiUrl(url)
      }
      fetch(url, { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store'
        },
        body: JSON.stringify({ username: username, password: password })
      }).then(resp => { return resp.json() })
        .then((resp) => {
          if (resp.code === 200) {
            global.$VS.userTokenInvalidateLock = 0
            context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header
            context.dispatch('getOrdersHistory', { refresh: true, useCache: false }).then(result => {})
            context.dispatch('me', { refresh: true, useCache: false }).then(result => {
              resolve(resp)
            })
          } else {
            resolve(resp)
          }
        })
    })
  },

  /**
   * Load current user profile
   */
  me (context, { refresh = true, useCache = true }) {
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        console.debug('No User token, user unathorized')
        return reject(new Error('An error occured during authentication. Please try again'))
      }
      const cache = global.$VS.db.usersCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('current-user', (err, res) => {
          if (err) {
            console.error(err)
            return
          }

          if (res) {
            context.commit(types.USER_INFO_LOADED, res)
            EventBus.$emit('user-after-loggedin', res)

            resolve(res)
            resolvedFromCache = true
            console.log('Current user served from cache')
          }
        })
      }

      if (refresh) {
        return context.dispatch('sync/execute', { url: config.users.me_endpoint,
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'Cache-Control': 'no-store'
            }
          }
        }, { root: true })
          .then((resp) => {
            if (resp.resultCode === 200) {
              context.commit(types.USER_INFO_LOADED, resp.result) // this also stores the current user to localForage
            }
            if (!resolvedFromCache && resp.resultCode === 200) {
              EventBus.$emit('user-after-loggedin', resp.result)
              resolve(resp)
            } else {
              resolve(null)
            }
            return resp
          })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  }
}

export default extendStore(coreStore, {
  actions
})
