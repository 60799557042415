var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login-wrapper"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"modal-content bg-cl-primary"},[(!_vm.sent)?_c('div',[_vm._m(1),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword($event)}}},[_c('p',{staticClass:"text-gray"},[_vm._v("Enter your email to receive instructions on how to reset your password")]),_vm._v(" "),_c('base-input',{staticClass:"mb35 login-input",attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
            {
              condition: !_vm.$v.email.required && _vm.$v.email.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.email.email && _vm.$v.email.$error,
              text: _vm.$t('Please provide valid e-mail address.')
            }
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('button-full',{staticClass:"mb20 bg-cl-gloo-main",attrs:{"type":"submit"}},[_c('p',{staticClass:"p0 m0"},[_vm._v("Reset Password")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-12 mb35 flex middle-xs pr0"},[_c('router-link',{staticClass:"text-gray align-center",attrs:{"to":"/login"}},[_vm._v("Return to login")])],1)],1)]):_c('div',[_c('p',[_vm._v("You will receive an email with instructions to reset your password if you have an account with us.")])])]),_vm._v(" "),_vm._m(2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{},[_c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://gloopro.com"}},[_c('img',{staticClass:"logo-tagline",attrs:{"src":"/assets/logo-tagline.png","alt":"Gloopro"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-text hidden-xs"},[_c('p',[_vm._v("Reset Password")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-center"},[_c('span',{staticClass:"back-to-gloopro cl-gloo-footer-text"},[_vm._v("Go back to "),_c('a',{staticClass:"cl-gloo-main",attrs:{"href":"https://gloopro.com"}},[_vm._v("Gloopro.com")])])])}]

export { render, staticRenderFns }