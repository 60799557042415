<template>
  <footer :class="{ 'brdr-top-1 brdr-cl-primary': isCheckout }" class="m0 p0 bg-cl-primary">
    <div class="container align-center p0 mt0">
      <div class="row">
        <div class="col-md-8">
          <div class="footer-links">
            <ul class="links p0 align-center upper-links">
              <li class="inline-flex">
                <!-- <router-link
                  class="cl-gloo-footer-text px20"
                  to="/about-us"
                  exact
                >
                  About Us
                </router-link>
              </li>
              <li class="inline-flex">
                <router-link
                  class="cl-gloo-footer-text px20"
                  to="/legal"
                  exact
                >
                  Contact us
                </router-link>
              </li>
              <li class="inline-flex">
                <router-link
                  class="cl-gloo-footer-text px20"
                  to="/privacy"
                  exact
                >
                  Privacy
                </router-link>
              </li>
              <li class="inline-flex">
                <router-link
                  class="cl-gloo-footer-text px20"
                  to="/legal"
                  exact
                >
                  Legal
                </router-link>
              </li>
              <li class="inline-flex">
                <router-link
                  class="cl-gloo-footer-text px20"
                  to="/legal"
                  exact
                >
                  Return Policy
                </router-link> -->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 align-right gloo">
          <div class="col-xs col-sm-12 col-xl-2">
            <ul class="links p0 align-center inline-flex">
              <li class="inline-flex">
                <router-link
                  class="cl-gloo-footer-text copyright-link"
                  to="/"
                  exact
                >
                  &copy;Gloopro 2019
                </router-link>
              </li>
              <li class="inline-flex cl-gloo-footer-text px10">
                <span>|</span>
              </li>
              <li class="inline-flex cl-gloo-footer-text">
                All Rights Reserved
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LanguageSwitcher from '../../LanguageSwitcher.vue'
import Footer from 'core/components/blocks/Footer/Footer'
import Newsletter from 'theme/components/core/blocks/Footer/Newsletter'

export default {
  components: {
    Newsletter,
    LanguageSwitcher
  },
  computed: {
    categories () {
      return this.$store.state.category.list.filter((op) => {
        return op.level === 2 // display only the root level (level =1 => Default Category)
      })
    }
  },
  mixins: [Footer],
  data () {
    return {
      isCheckout: false
    }
  },
  created () {
    if (this.$route.path === '/checkout') {
      this.isCheckout = true
    }
  },
  watch: {
    '$route.path': function () {
      if (this.$route.path === '/checkout') {
        this.isCheckout = true
      } else {
        this.isCheckout = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-secondary: color(secondary);
$color-white: color(white);
$gloo-secondary: color(gloo-secondary);
$footer-text: color(gloo-footer-text);

footer {
  // background-color: #1b1f3b;
  border-top: 1px solid #DAE1E9;
  padding: 20px;
  // max-height: 64px;
}

.icon {
  transition: 0.3s all;
}

img {
  max-height: 28px;
}

.social-icon {
  width: 40px;
  height: 40px;
  &:hover,
  &:focus,
  &:active {
    .icon {
      fill: $color-secondary;
    }
  }
}

.links {
  li {
    vertical-align: middle;
    color: $footer-text;
  }
}
.links:not(:first-child) {
  list-style-type: bullets;
}
.footer-links, .gloo {
//   @media (max-width: 767px) {
//     padding: 0;
//   }
// font-family: Larsseit;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 16px;
  // text-align: left;
}
.footer-links {
  text-align: left;
}
.gloo {
  opacity: 0.5;
  @media (max-width: 900px) {
    text-align: center;
  }
}

//  hr {
//   display: block;
//   height: 1px;
//   border: 0;
//   border-top: 2px solid $gloo-secondary;
//   margin: 1em 10px;
//   padding: 0;
//   // width: 100%;
// }

a:hover {
  text-decoration: none;
}

.copyright-link {
  text-transform: lowercase;
}

a.underline:after, a:not(.no-underline):hover:after {
  background-color: transparent !important;
}

.underline {
  &:hover {
    color: $color-secondary;
    &:after {
      background-color: $color-secondary;
    }
  }
}

.legal-entity-link {
  text-align: center;
  @media (min-width: 768px) {
    display: none;
  }
}

.privacy-policy-link {
  text-align: right;
  @media (min-width: 768px) {
    display: none;
  }
}

@media (max-width: 595px) {
  .no-mobile {
    display: none;
  }

  .social {
    margin-top: 0;
  }

  // .footer-links {
  //   padding-bottom: 30px;
  // }
}
</style>
