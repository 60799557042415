
<template>
  <div class="mt20">
    <div class="row mb20 mt20">
      <div class="col-md-8 option-header">
        <h4 class="mb0 cl-gloo-gray section-header">Recent Purchases</h4>
        <p class="mb0 hidden-xs sub-header cl-gloo-gray">Check out your recent orders and reorder items</p>
      </div>
      <div class="col-md-4 option-descr mb0 p0">
        <router-link to="/my-account/orders">
          <p class="m0 hidden-xs cl-gloo-main">See all your orders</p>
          <p class="m0 hidden-md">View All orders</p>
        </router-link>
      </div>
      <hr >
    </div>
    <show-loader v-if="showLoader && recentOrders.length === 0" />
    <div v-if="!showLoader && recentOrders && recentOrders.length === 0">
      <div class=" no-order">
        <div class="empty-history">
          <div class="no-order-text">
            <p class="cl-gloo-catalina-blue">You currently have no orders yet.</p>
            <p class="cl-gloo-catalina-blue">Please order items and your recent orders will appear here</p>
          </div>
          <div class="p15">
            <img src="/assets/no-recent-orders.svg" alt="Empty Order History" height="100px" class="img-order-history">
          </div>
          <button-outline color="gray" class="start p15" @click.native="scrollToElement('#company-categories')">
            Start Ordering
          </button-outline>
        </div>
      </div>
    </div>
    <div v-else class="mb20 mt20">
      <div class="row align-center mb20 mt20">
        <div v-for="order in recentOrders" :key="order.entity_id" class="col-md-3 col-sm-6 col-xs-12">
          <div class="recent py10 bg-cl-primary">
            <div class="single-recent">
              <div class="order-details align-left row px40 pb10 align-center">
                <i class="material-icons pr-0 icon order-status-icon" :class="[orderStatus[order.status]]">check_circle_outline</i>
                <p class="col-md-10 col-xs-10 align-left cl-gloo-text p0">Order No. #{{ order.increment_id }}</p>
              </div>
              <div class="row align-left re-order px40">
                <div class="col-xs-12 align-left amount">
                  <p class="cl-gloo-text">{{ order.grand_total | currency }}</p>
                  <p class="cl-gloo-product-text">{{ parseDate(order.created_at) }}</p>
                  <p>
                    <span class="cl-gloo-text">{{ order && order.total_qty_ordered }} {{ order && (order.total_qty_ordered > 1 || order.total_qty_ordered === 0) ? 'Items' : 'Item' }}</span>
                    <span class="cl-gloo-product-text">(From {{ order && order.items && order.items.length }} {{ order && order.items && (order.items.length > 1 || order.items.length === 0) ? 'Products' : 'Product' }})</span>
                  </p>
                </div>
              </div>
              <div class="row recent-order--img--block p0 px40 align-center">
                <div class="col-md-2 col-xs-2 col-sm-2 p0" v-for="item in order.items.slice(0,5)" :key="item.item_id">
                  <div class="recent-order--img p0 ml0">
                    <img class="blend img-responsive" v-lazy="thumbnailObj(item.image)" alt="item.name">
                  </div>
                </div>
                <div class="col-md-2 col-xs-2 col-sm-2 align-center p0 products-count" @click="toggleDisplay(order.increment_id)" v-if="order.items.length > 4">
                  <div class="recent-order--img align-center p0">
                    <p class="align-center">+{{ order && order.items && order.items.length > 4 ? order.items.length - 4 : '' }}</p>
                  </div>
                </div>
              </div>
              <div class="pt10">
                <hr class="bottom-hr" >
                <div class="dropdown open p0 w-100 mb20">
                  <ul class="px40 dropdown-menu bg-cl-primary" role="menu" id="dropdown-list" :class="{ 'is-visible': isDropdownVisible[order.increment_id] }">
                    <li class="active sort-options" role="presentation" v-for="item in order.items" :key="item.item_id">
                      <div class="row align-center">
                        <div class="col-md-2 col-xs-2 col-sm-2 p0">
                          <div class="recent-order--img p0 ml0">
                            <img class="blend img-responsive" v-lazy="thumbnailObj(item.image)" alt="item.name">
                          </div>
                        </div>
                        <div class="col-md-10 pr5 align-left">
                          <p class="cl-gloo-product-text mb5">{{ item && item.name }}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="re-order px40">
                  <div class="row w-100 reorder-button">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 p0 align-left">
                      <button-outline
                        class="col-xs-12 col-sm-12 col-md-12"
                        color="light"
                        @click.native="remakeOrder (order.items)"
                      >
                        <span>Re-order</span>
                      </button-outline>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 view-details p0">
                      <router-link :to="localizedRoute('/my-account/orders/' + order.increment_id)" class="align-right">
                        <span class="cl-gloo-main">View details</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 3rd party dependecies
import moment from 'moment'

// Theme core components
import ShowLoader from '../CompanySettings/ShowLoader.vue'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { thumbnail } from 'core/mixins/thumbnail/index.js'

import { mapGetters } from 'vuex'

export default {
  mixins: [thumbnail],
  name: 'RecentOrders',
  data () {
    return {
      orderStatus: {
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order'
      },
      orderHistory: [],
      showLoader: true,
      placeholder: '/assets/placeholder.jpg',
      isDropdownVisible: {}
    }
  },
  methods: {
    parseDate (dateString) {
      return moment(dateString).format('Do MMM, YYYY')
    },
    reorderImage (productLength) {

    },
    remakeOrder (items) {
      items.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty_ordered
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
    },
    scrollToElement (element) {
      document.querySelector(element).scrollIntoView()
    },
    toggleDisplay (orderId) {
      this.isDropdownVisible = { ...this.isDropdownVisible, [orderId]: !this.isDropdownVisible[orderId] }
    },
    thumbnailObj (image) {
      const thumbnail = this.getThumbnail(image, 100, 100)
      return {
        src: thumbnail,
        loading: this.placeholder,
        error: this.placeholder
      }
    }
  },
  components: {
    ButtonOutline,
    ShowLoader
  },
  mounted () {
    let userId = this.$store.state.userDetails.userId
    if (!userId) {
      return
    }
    this.$store.dispatch('user/getOrdersHistory', { refresh: true, useCache: false }).then((result) => {
      this.orderHistory = result.result.items
      this.$store.dispatch('loadRecentOrders', this.orderHistory).then(res => {
        this.showLoader = false
      })
    })
  },
  computed: {
    ...mapGetters({
      recentOrders: 'getRecentOrders'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gloo-secondary: color(gloo-secondary);
$hr: color(gloo-solitude);
$white: color(white);
  #home, h1, h4, p  {
    font-family: Roboto !important;
  }
  .new-collection {
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }
  .option-header h1 {
    line-height: 28px;
  }
  .option-header p {
    line-height: 21px;
  }
  .option-descr {
    position: relative;
  }
  .option-descr a {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  user {
    padding: 0;
    h1 {
      line-height: 56px;
    }
    p {
      line-height: 21px;
    }
  }
  hr {
    display: block;
    height: 1px;
    border: none;
    border-top: 1px solid  rgba(206,211,230, 0.3942522321428572);
    padding: 0;
    width: 100%;
  }
  .recent {
    box-shadow: 0px 0.3px 2px 0px rgba(0,0,0,0.1);
    margin: 1em;
  }
  .single-recent, .empty-history{
    margin:  5% 0;
  }
  .single-recent .order-details p:first-of-type, .single-recent .order-details p:nth-child(2) {
    letter-spacing: 1px;
    margin: 0;
  }
  .single-recent .order-details p:first-of-type {
    font-size: 16px;
    line-height: 19px;
    font-weight: bolder;
    margin-bottom: 0;
  }
  .re-order p:nth-child(1) {
    font-size: 28px;
    line-height: 34px;
    font-weight: bolder;
    margin-bottom: 5px;
  }
  .re-order p:nth-child(2), .re-order p:nth-child(3) {
    font-size: 18px;
    line-height: 22px;
  }
  .re-order p span:nth-child(1) {
    font-weight: bolder;
  }
  .amount, .cart {
    padding: 0;
  }
  .amount {
    padding-top: 1%;
  }
  .amount p {
    line-height:  20px;
    letter-spacing: 1px;
  }
  button {
    padding: 10px 0 !important;
  }
  .bottom-hr {
    border-top: 1px solid rgba(206,211,230, 0.3942522321428572);
  }
  .view-details {
    margin: auto;
  }
  .view-details span {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
    display: block;
  }
  .cart button {
    padding: 15px 0 15px 0 !important;
    outline: none;
    cursor: pointer;
  }
  .product-image {
      max-height: 240px;
  }
  .empty-history, .no-order {
    margin: auto;
    text-align: center;
  }
  .empty-history {
    margin-left: 5px;
    margin-right: 5px;
  }
  .no-order {
    padding: 40px 30px 10px 30px;
    width: 50%;
  }
  .no-order-text {
    text-align: center;
    margin: auto;
    }
  .no-order-text p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .section-header {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
  }
  .sub-header {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .button-outline {
    padding: 20px 30px;
  }
  .button-outline {
    border-radius: 5px;
  }
  @media (max-width: 900px) {
    .button-outline {
      letter-spacing: 0;
      min-width: 120px;
    }
    .empty-history {
      padding: 10px;
    }
    .empty-history, .no-order {
      margin-left: 5px;
      margin-right: 5px;
    }
    .order-details {
      text-align: left;
    }
    .order-details img, .order-details p {
      padding: 0;
    }
    .user .button-outline {
      font-size: 16px;
    }
  }
  @media(min-width: 1200px) {
    .order-details, .re-order, .recent-order--img--block, .dropdown.open {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media(min-width: 1300px) {
    .order-details, .re-order, .recent-order--img--block, .dropdown.open {
      padding-left: 40px;
      padding-right: 20px;
    }
  }
  @media(max-width: 1650px) {

  }
  @media(min-width: 1024px) and (max-width: 1640px) {
    .single-recent .order-details p:first-of-type {
      font-size: 12px;
    }
    .re-order p:nth-child(1) {
      font-size: 21px;
      line-height: 28px;
    }
    .re-order p:nth-child(2), .re-order p:nth-child(3) {
      font-size: 14px;
      line-height: 16px;
    }
    .button-outline {
      min-width: 100px;
    }
    .order-details, .re-order {
      padding: 0 20px;
    }
    .recent {
      margin: 0;
    }
    .order-details, .re-order, .recent-order--img--block, .dropdown.open {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 600px) {
  .no-order {
    margin-left: 5px;
    margin-right: 5px;
    background-color: $white;
    box-shadow: 0px 0.3px 2px 0px rgba(0,0,0,0.1);
    padding: 0 20px 10px 20px;
    width: 100%;
  }
  .no-order-text p {
    // color: $gray;
    width: 100%;
    line-height: px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  img {
    height: 27% !important;
  }
}
@media (max-width: 420px) {
  .button-outline {
    min-width: 100px;
  }
  .order-details, .re-order, .recent-order--img--block, .dropdown.open {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(min-width: 3500px) {
  .single-recent .order-details p:first-of-type {
    font-size: 26px;
    line-height: 32px;
  }
  .re-order p:nth-child(1) {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 15px;
  }
  .re-order p:nth-child(2), .re-order p:nth-child(3) {
    font-size: 28px;
    line-height: 32px;
  }
  .button-outline {
    min-width: 250px;
  }
  .order-details, .re-order, .recent-order--img--block, .dropdown.open {
    padding-left: 60px;
    padding-right: 60px;
  }
  img {
    border-style: none;
    width: 8%;
  }
  .view-details span {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
  }
  button-outline span {
    font-size: 30px;
  }
}
@media (min-width: 1023px) and (max-width: 1600px) {
  .recent-order--img {
    max-width: 30px !important;
    max-height: 30px !important;
  }
  .img-responsive {
    max-height: 25px !important;
    max-width: 25px !important;
  }
}
.order-status-icon {
  font-weight: normal;
  height: 100%;
  border-radius: 50%;
  font-size: 18px;
}
.recent-order--img {
  border-radius: 50%;
  border: 2px solid #E3E8EE;
  width: 42px;
  height: 42px;
  margin: 1.9%;
  display: flex;
  align-items: center;
  justify-content: center
}
.ml0 {
  margin-left: 0;
}
.mr0 {
  margin-right: 0;
}
.pr5 {
  padding-right: 5px;
}
.blend {
  mix-blend-mode: multiply;
}
.img-responsive {
  max-height: 30px;
  max-width: 30px;
}
.recent-order--img p {
  margin: 24% 0 !important;
}
.dropdown-menu {
  display: none;
  text-align: left;
  border: 1px solid rgba(90, 122, 190, 0.08);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 10px 20px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
  padding: 5px 0;
  margin-top: 5px;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
}

.dropdown-menu li {
  // color: black;
  color: #253858;
  padding: 5px 30px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px;
  @media (min-width: 1023px) and (max-width: 1600px) {
    padding: 5px 15px;
  }
}

.is-visible {
  display: block;
}
.dropdown.open {
  padding-left: 30px;
  padding-right: 0;
  position: relative;
}
.products-count {
  cursor: pointer;
}
</style>
