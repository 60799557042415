<template>
  <div class="cart-single-product top-border row py20 my40">
    <div class="col-md-2 col-sm-2 hidden-xs img-div py10">
      <img class="blend img-responsive" v-lazy="thumbnail">
    </div>
    <div class="col-md-6 col-sm-6 col-xs-8 p0">
      <div class="">
        <p class="m0 cl-gloo-product-text">{{ product.name | htmlDecode }}</p>
        <p class="m0 product-quantity cl-gloo-product-text">Quantity: {{ product.qty }}</p>
      </div>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-4 row">
      <div>
        <div class="product-price">
          <p class="cl-error" v-if="product.special_price">{{ parseFloat(product.price * product.qty) | currency }} </p>
          <p class="price-original" v-if="product.special_price" >{{ parseFloat(product.originalPriceInclTax * product.qty) | currency }}</p>
          <p v-if="!product.special_price" class="">{{ parseFloat(product.price * product.qty) | currency }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from 'core/components/blocks/Checkout/Product'

export default {
  mixins: [Product]
}
</script>

<style scoped lang="scss">
.cart-single-product {
  padding: 20px 40px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.cart-single-product:nth-child(1) {
  padding-top: unset !important;
      border: none !important;
}
.price-original {
  text-decoration: line-through;
}
.blend {
  mix-blend-mode: multiply;
}
.img-responsive {
  max-height: 50px;
  max-width: 40px;
}
.material-icons {
  color: grey;
}
p {
   @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
    }
}
</style>
