<template>
  <div id="login-wrapper">
    <header class="">
      <div class="header-img">
        <a href="https://gloopro.com">
          <img
            src="/assets/logo-tagline.png"
            alt="Gloopro"
            class="logo-tagline"
            style=""
          >
        </a>
      </div>
    </header>
    <div class="modal-content bg-cl-primary">
      <div class="header-text hidden-xs">
        <p>Welcome!</p>
      </div>
      <form @submit.prevent="login" novalidate class="">
        <base-input
          class="mb35 login-input"
          type="email"
          name="email"
          focus
          v-model="email"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.'),
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.'),
            },
          ]"
        />
        <base-input
          class="mb35 login-input"
          type="password"
          name="password"
          v-model="password"
          :placeholder="$t('Password *')"
          :validation="{
            condition: !$v.password.required && $v.password.$error,
            text: $t('Field is required.'),
          }"
        />
        <button-full class="mb20 bg-cl-gloo-main" type="submit">
          <p class="p0 m0">LOGIN</p>
        </button-full>
        <div class="col-xs-12 col-sm-12 flex end-xs middle-xs pr0">
          <router-link to="/forgot-password" class="remind cl-gloo-main">
            {{ 'Forgot Your Password?' }}
          </router-link>
        </div>
        <template v-if="enableSSO">
          <div class="content-divider text-muted mb20 mt20">
            <span>or</span>
          </div>
          <div class="col-xs-12 col-sm-12 flex middle-xs google-signin">
            <a :href="`${ecmsUrl}/auth/google`" class="w-100"
            ><img
              src="/assets/btn_google_signin_dark_pressed_web@2x.png"
              alt="Google Sign In"
            ></a>
          </div>
        </template>
      </form>
    </div>
    <div class="align-center">
      <span class="back-to-gloopro cl-gloo-footer-text"
      >Go back to
      <a href="https://gloopro.com" class="cl-gloo-main">Gloopro.com</a></span
      >
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import i18n from 'core/lib/i18n'
import config from 'config'

export default {
  data () {
    return {
      remember: false,
      email: '',
      password: '',
      ecmsUrl: config.ecms.base_url,
      enableSSO: config.ecms.enableSSO
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  created () {
    let errorMessage = this.$route.query['errorMessage']
    if (errorMessage) {
      this.$bus.$emit('notification', {
        type: 'error',
        message: errorMessage,
        action1: { label: i18n.t('OK'), action: 'close' }
      })
    }
    const userDetails = this.$store.state.userDetails
    const user = this.$store.state.user
    if (userDetails.userId && user.current && user.token) {
      this.$router.push(this.localizedRoute('/'))
    }
  },
  methods: {
    switchElem () {
      this.$store.commit('ui/setAuthElem', 'register')
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    displayMessage (type, message) {
      this.$bus.$emit('notification-progress-stop')
      this.$bus.$emit('notification', {
        type: type,
        message: message,
        action1: { label: i18n.t('OK'), action: 'close' }
      })
    },
    login () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$bus.$emit('notification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
        return
      }
      // if ('caches' in window) {
      //   // Clear all caches
      //   caches.keys().then((cacheNames) => {
      //     cacheNames.forEach((cacheName) => {
      //       caches.delete(cacheName)
      //     })
      //   })
      // }
      console.log('logging in...')
      this.$bus.$emit(
        'notification-progress-start',
        i18n.t('Authorization in progress ...')
      )
      this.$store
        .dispatch('loginUserOnEcmsCore', {
          emailAddress: this.email,
          password: this.password
        })
        .then(({ data }) => {
          if (data.code === 200) {
            if (!(data.result.data && data.result.data.token)) {
              this.displayMessage('error', data.result.message)
              return
            }

            const userId = data.result.data.user.id

            this.$store
              .dispatch('fetchSingleUserDetails', {
                userId,
                updateState: true
              })
              .then(({ data }) => {
                if (data.code === 200) {
                  // check if user is active i.e. not disabled/suspended
                  const userStatus = data.result.data.user.isActive
                  if (!userStatus) {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('notification', {
                      type: 'error',
                      message: i18n.t('Your account has been suspended'),
                      action1: { label: i18n.t('OK'), action: 'close' }
                    })
                    this.$bus.$emit('user-before-logout')
                    this.$router.push('/login')
                    return
                  }

                  // prevent user without a location from being able to login
                  const location = data.result.data.user.location
                  if (!location) {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('notification', {
                      type: 'error',
                      message: i18n.t(
                        'You must be assigned to a location to be able to login'
                      ),
                      action1: { label: i18n.t('OK'), action: 'close' }
                    })
                    this.$bus.$emit('user-before-logout')
                    this.$router.push('/login')
                    return
                  }

                  const locationStatus =
                      data.result.data.user.location.isActive
                  if (!locationStatus) {
                    this.$bus.$emit('notification-progress-stop')
                    this.$bus.$emit('notification', {
                      type: 'error',
                      message: i18n.t(
                        'Your location has been disabled. Please contact your company admin'
                      ),
                      action1: { label: i18n.t('OK'), action: 'close' }
                    })
                    this.$bus.$emit('user-before-logout')
                    this.$router.push('/login')
                    return
                  }

                  let companyStoreViewCode = this.$store.state.userDetails
                    .companyDetails.companyCatalog
                    ? this.$store.state.userDetails.companyDetails
                      .companyCatalog.storeId
                    : ''

                  this.$bus.$emit('prepare-storeview', companyStoreViewCode)
                  this.$bus.$emit('on-reinitialize-storage', '') // this is to ensure that the cache ignores the concept of multistore

                  // log user in on magento
                  this.$store
                    .dispatch('user/login', {
                      username: this.email,
                      password: this.password
                    })
                    .then((result) => {
                      if (result.code !== 200) {
                        this.displayMessage('error', i18n.t(result.result))
                      } else {
                        this.$bus.$emit('notification-progress-stop')
                        this.displayMessage(
                          'success',
                          i18n.t('You are logged in!')
                        )
                        this.close()

                        // check if user has changed original password
                        const passwordChanged =
                            data.result.data.user.passwordChanged
                        if (passwordChanged === false) {
                          this.$router.push('/first-password-change')
                          return
                        }
                        //  get the original intended destination by the user
                        //  let destination = this.$route.query.redirect || ''
                        //  if (!destination) {
                        //  this.$router.push(`/`)
                        //  }
                        let destination = this.$route.query.redirect || '/'
                        // window.location.href = destination
                        this.$router.push(destination)
                        //  if (companyStoreViewCode) {
                        //  this.$router.push(destination)
                        //  return
                        //  }
                        //  if (!companyStoreViewCode) {
                        //  this.$router.push(destination)
                        //  return
                        //  }
                        //  at this point, user is trying to access store view that is not authorized to him or her
                        //  take the user to the correct home page
                        // this.$router.push(`/`)
                      }
                    })
                    .catch((err) => {
                      this.displayMessage('error', err.message)
                    })
                } else {
                  this.displayMessage('error', 'An error occured')
                }
              })
              .catch(() => {})
          } else {
            this.displayMessage('error', 'An error occured')
          }
        })
        .catch(() => {})
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $dark-border: color(secondary);
  $white: color(white);
  $black: color(black);

  .google-signin {
    img {
      width: 50%;
      height: auto;
      margin-left: 25%;
      margin-right: 25%;
    }
  }
  #login-wrapper {
    padding-top: 5%;
    padding-bottom: 1%;
    font-size: 1.125em;
    width: 40%;
    margin: auto;
  }
  form {
  }
  .modal-content {
    padding: 2em 5em;
    border-radius: 0.304em;
    box-shadow: 0 0 3px 0 #d7d7e0;
    text-align: center;
    margin: 3em;
  }
  header {
    margin: auto;
    padding: 0;
    text-align: center;

    img {
      height: auto;
      width: 50%;
    }
  }
  .header-text p {
    font-family: Roboto !important;
    /* margin: 0.5em 0; */
    padding-bottom: 1em;
    font-weight: lighter;
    line-height: 34px;
    font-size: 29px;
    color: #7d84a0;
  }
  input[type='text'] {
    /* border: none; */
    // border: 1px solid;
    // border-radius:  5px;
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0 0 3px 0 #d7d7e0 !important;
    // width: 534px;
    // height: 312px;
    border: 1px solid #dee5e9 !important;
    border-radius: 4px !important;
  }
  base-checkbox div a,
  .remind {
    // color: #3d4094 !important;
  }
  base-checkbox {
    font-family: Roboto !important;
    font-size: 20.47px;
    line-height: 29px;
    letter-spacing: 1%;
  }
  .button-full {
    border-radius: 3px;
  }
  .back-to-gloopro {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: right;
  }
  @media (min-width: 760px) and (max-width: 1280px) {
    #login-wrapper {
      padding-top: 25%;
      width: 70%;
    }
    // img {
    //   width: 120px;
    // }
  }
  @media (max-width: 360px) {
    #login-wrapper {
      padding-top: 40%;
      width: 100%;
      font-size: 1em;
      margin: auto;
    }
    // img {
    //   width: 96px;
    // }
    form {
      padding: 1.5em;
      box-shadow: none;
    }
    .modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    .h4 {
      font-size: 14px !important;
    }
    .remind {
      font-size: 12px;
    }
  }
  @media (max-width: 760px) {
    #login-wrapper {
      padding-top: 30%;
      width: 100%;
      font-size: 0.75em;
      margin: auto;
    }
  }
  .content-divider {
    color: #7d84a0;
  }
</style>
