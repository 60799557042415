import coreStore from '@vue-storefront/store/modules/cart'
import { extendStore } from 'core/lib/themes'
import config from 'config'
import store from 'core/store'
import * as types from 'core/store/mutation-types'
import EventBus from 'core/store/lib/event-bus'
import i18n from 'core/store/lib/i18n'
import omit from 'lodash-es/omit'

const actions = {
  addItem ({ commit, dispatch, state }, { productToAdd, forceServerSilence = false }) {
    let productsToAdd = []
    if (productToAdd.type_id === 'grouped') { // TODO: add bundle support
      productsToAdd = productToAdd.product_links.filter((pl) => { return pl.link_type === 'associated' }).map((pl) => { return pl.product })
    } else {
      productsToAdd.push(productToAdd)
    }
    let productHasBeenAdded = false
    let productIndex = 0
    for (let product of productsToAdd) {
      if (typeof product === 'undefined' || product === null) continue
      if (product.priceInclTax <= 0) {
        EventBus.$emit('notification', {
          type: 'error',
          message: i18n.t('Product price is unknown, product cannot be added to the cart!'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
        continue
      }
      if (config.entities.optimize && config.entities.optimizeShoppingCart) {
        product = omit(product, ['configurable_children', 'configurable_options', 'media_gallery', 'description', 'product_links', 'stock', 'description'])
      }
      if (product.errors !== null && typeof product.errors !== 'undefined') {
        let productCanBeAdded = true
        for (let errKey in product.errors) {
          if (product.errors[errKey]) {
            productCanBeAdded = false
            EventBus.$emit('notification', {
              type: 'error',
              message: product.errors[errKey],
              action1: { label: i18n.t('OK'), action: 'close' }
            })
          }
        }
        if (!productCanBeAdded) {
          continue
        }
      }
      const record = state.cartItems.find(p => p.sku === product.sku)
      dispatch('stock/check', { product: product, qty: record ? record.qty + 1 : (product.qty ? product.qty : 1) }, {root: true}).then(result => {
        product.onlineStockCheckid = result.onlineCheckTaskId // used to get the online check result
        if (result.status === 'volatile') {
          EventBus.$emit('notification', {
            type: 'warning',
            message: i18n.t('The system is not sure about the stock quantity (volatile). Product has been added to the cart for pre-reservation.'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        }
        if (result.status === 'out_of_stock') {
          EventBus.$emit('notification', {
            type: 'error',
            message: i18n.t('The product is out of stock and cannot be added to the cart!'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        }
        if (result.status === 'ok' || result.status === 'volatile') {
          commit(types.CART_ADD_ITEM, { product })
          productHasBeenAdded = true
        }
        if (productIndex === (productsToAdd.length - 1) && productHasBeenAdded) {
          let notificationData = {
            type: 'success',
            message: i18n.t('Product has been added to the cart!'),
            action1: { label: i18n.t('OK'), action: 'close' }
          }
          if (!config.externalCheckout) { // if there is externalCheckout enabled we don't offer action to go to checkout as it can generate cart desync
            notificationData.action2 = { label: i18n.t('Proceed to checkout'), action: 'goToCheckout' }
          }
          EventBus.$emit('notification', notificationData)
          if (config.cart.synchronize && !forceServerSilence) {
            dispatch('serverPull', { forceClientState: true })
          }
        }
        productIndex++
      })
    }
  },
  clear (context) {
    context.commit(types.CART_LOAD_CART, [])
    context.commit(types.CART_LOAD_CART_SERVER_TOKEN, null)
    if (config.cart.synchronize) {
      store.dispatch('cart/serverCreate', { guestCart: false }, {root: true}) // guest cart because when the order hasn't been passed to magento yet it will repopulate your cart
    }
  },
  clearOnAfterPlaceOrder (context) {
    context.commit(types.CART_LOAD_CART, [])
    context.commit(types.CART_LOAD_CART_SERVER_TOKEN, null)
    if (config.cart.synchronize) {
      store.dispatch('cart/serverCreate', { guestCart: true }, {root: true}) // guest cart because when the order hasn't been passed to magento yet it will repopulate your cart
    }
  },
  serverUpdateItem (context, cartItem) {
    if (!cartItem.quoteId) {
      cartItem = Object.assign(cartItem, { quoteId: context.state.cartServerToken })
    }
    return context.dispatch('sync/execute', { url: config.cart.updateitem_endpoint, // sync the cart
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          cartItem: cartItem
        })
      },
      callback_event: 'servercart-after-itemupdated'
    }, { root: true }).then(task => {
      // eslint-disable-next-line no-useless-return
      if (config.cart.synchronize_totals && context.state.cartItems.length > 0) {
        context.dispatch('refreshTotals')
      }
      const errorMessage = task.result.errorMessage ? task.result.errorMessage : null
      const payload = JSON.parse(task.payload.body)
      const cartItem = payload.cartItem ? payload.cartItem : null
      const companyId = context.rootState.userDetails.companyDetails.id
      if (errorMessage && cartItem && errorMessage.indexOf(i18n.t('to add is not available')) >= 0) {
        cartItem.status = 0
        store.commit('setFrequentlyBoughtItemProps', {product: cartItem, companyId})
        const currentProduct = store.getters['product/productCurrent']
        if (currentProduct && currentProduct.sku === cartItem.sku) {
          currentProduct.status = 0
          store.commit('product/product/SET_PRODUCT_CURRENT', currentProduct)
        }
      }
      return task
    })
  }
}

export default extendStore(coreStore, {
  actions
})
