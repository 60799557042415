<template>
  <div id="login-wrapper">
    <header class="">
      <div class="header-img">
        <a href="https://gloopro.com">
          <img src="/assets/logo-tagline.png" alt="Gloopro" class="logo-tagline" style="">
        </a>
      </div>
    </header>
    <div class="modal-content bg-cl-primary">
      <div v-if="!sent">
        <div class="header-text hidden-xs">
          <p>Reset Password</p>
        </div>
        <form @submit.prevent="resetPassword" novalidate class="">
          <p class="text-gray">Enter your email to receive instructions on how to reset your password</p>
          <base-input
            class="mb35 login-input"
            type="email"
            name="email"
            focus
            v-model="email"
            :placeholder="$t('E-mail address *')"
            :validations="[
              {
                condition: !$v.email.required && $v.email.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.email.email && $v.email.$error,
                text: $t('Please provide valid e-mail address.')
              }
            ]"
          />
          <button-full class="mb20 bg-cl-gloo-main" type="submit">
            <p class="p0 m0">Reset Password</p>
          </button-full>
          <div class="col-xs-12 col-sm-12 mb35 flex middle-xs pr0">
            <router-link to="/login" class="text-gray align-center">Return to login</router-link>
          </div>
        </form>
      </div>
      <div v-else>
        <p>You will receive an email with instructions to reset your password if you have an account with us.</p>
      </div>
    </div>
    <div class="align-center">
      <span class="back-to-gloopro cl-gloo-footer-text">Go back to <a href="https://gloopro.com" class="cl-gloo-main">Gloopro.com</a></span>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import i18n from 'core/lib/i18n'

export default {
  data () {
    return {
      email: '',
      sent: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  created () {
    const userDetails = this.$store.state.userDetails
    const user = this.$store.state.user
    if (userDetails.userId && user.current && user.token) {
      this.$router.push(this.localizedRoute('/'))
    }
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    displayMessage (type, message) {
      this.$bus.$emit('notification-progress-stop')
      this.$bus.$emit('notification', {
        type: type,
        message: message,
        action1: { label: i18n.t('OK'), action: 'close' }
      })
    },
    resetPassword () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$bus.$emit('notification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
        return
      }
      this.$bus.$emit('notification-progress-start', i18n.t('Resetting password...'))
      this.$store.dispatch('forgotPassword', { email: this.email }).then(({data}) => {
        if (data.code === 200) {
          this.sent = true
          this.displayMessage('success', 'Password reset initiated')
        } else {
          this.displayMessage('error', 'An error occured')
        }
      })
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $dark-border: color(secondary);
  $white: color(white);
  $black: color(black);
  img {
    height: auto;
    width: 50%;
  }
  #login-wrapper {
    padding-top: 5%;
    padding-bottom: 1%;
    font-size: 1.125em;
    width: 40%;
    margin: auto;
  }
  .modal-content {
    padding: 2em 5em;
    border-radius: 0.304em;
    box-shadow: 0 0 3px 0 #D7D7E0;
    text-align: center;
    margin: 3em
  }
  header {
    margin: auto;
    padding: 0;
    text-align: center;
  }
  .text-gray {
    color: #7D84A0;
  }
  .header-text p {
    font-family: Roboto !important;
    padding-bottom: 1em;
    font-weight: lighter;
    line-height: 34px;
    font-size: 29px;
    color: #7D84A0;
  }
  input[type=text] {
    background-color: #FFFFFF !important;
    border-radius: 5px !important;
    box-shadow: 0 0 3px 0 #D7D7E0 !important;
    border: 1px solid #DEE5E9 !important;
    border-radius: 4px !important;
  }
  base-checkbox div a, .remind {
    // color: #3d4094 !important;
  }
  base-checkbox {
    font-family: Roboto !important;
    font-size: 20.47px;
    line-height: 29px;
    letter-spacing: 1%;
  }
  .button-full {
    border-radius: 3px;
  }
  .back-to-gloopro {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: right;
  }
  @media (min-width: 760px) and (max-width: 1280px) {
    #login-wrapper {
      padding-top: 25%;
      width: 70%;
    }
  }
  @media (max-width: 360px) {
    #login-wrapper {
      padding-top: 40%;
      width: 100%;
      font-size: 1em;
      margin: auto;
    }
    form {
      padding: 1.5em;
      box-shadow: none;
    }
    .modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    .h4 {
      font-size: 14px !important;
    }
    .remind {
      font-size: 12px;
    }
  }
  @media (max-width: 760px) {
    #login-wrapper {
      padding-top: 30%;
      width: 100%;
      font-size: 0.75em;
      margin: auto;
    }
  }
  .align-center {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
</style>
