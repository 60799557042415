<template>
  <section class="main-slider w-100 bg-cl-th-accent cl-white">
    <div class="align-left user">
      <h1 class="mb0 cl-primary">Hello {{ user }}</h1>
      <p class="mt0 cl-gloo-gray">Welcome to Gloopro</p>
      <!-- <p class="mt0 cl-gloo-gray">We are happy to have you back</p> -->
      <button-outline color="light" class="browse" @click.native="scrollToElement('#company-categories')">
        Browse Catalog
      </button-outline>
      <router-link to="/dashboard" class="cl-accent">
        <button-outline color="light" class="view">
          View Dashboard
        </button-outline>
      </router-link>
    </div>
    <div class="align-right slider-image hidden-xs">
      <img src="/assets/mainslider-icon.svg">
    </div>
    <!-- <no-ssr>
      <carousel :per-page="1" pagination-active-color="#ffffff" pagination-color="#e0e0e0">
        <slide v-for="(slide, index) in slides" :key="index">
          <div class="container w-100 main" :style="{ backgroundImage: 'url(' + slide.image + ')' }">
            <div class="row middle-xs center-xs">
              <div class="col-md-12 px10p">
                <h1 class="title mb0 mt0 align-left cl-primary">{{ slide.title }}</h1>
                <h1 class="title mt0 align-left slider-subtitle cl-primary">{{ slide.subtitle }}</h1>
                <div class="align-left button">
                  <button-outline :link="slide.link" color="light">
                    {{ slide.button_text }}
                  </button-outline>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </no-ssr> -->
  </section>
</template>
<script>
// import NoSSR from 'vue-no-ssr'
// import { Carousel, Slide } from 'vue-carousel'
// import sliderData from 'theme/resource/slider.json'

import MainSlider from 'core/components/blocks/MainSlider/MainSlider'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  data () {
    return {
      user: null
    }
  },
  components: {
    ButtonOutline
    // ,
    // Carousel,
    // Slide,
    // 'no-ssr': NoSSR
  },
  mixins: [MainSlider],
  created () {
    // this.updateSliderData(sliderData)
  },
  mounted () {
    this.user = (this.$store.state.user && this.$store.state.user.current) ? this.$store.state.user.current.firstname : ''
  },
  methods: {
    scrollToElement (element) {
      document.querySelector(element).scrollIntoView()
    }
  }
}
</script>
<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-white: color(white);
$background: color(mainslider-background);

.black-overlay {
  background-color: black;
  opacity: 0.8;
  padding-bottom: 20px;
}
.main {
  background-color: $background;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-slider {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 767px) {
    display: none;
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: 15px;
  }
  .VueCarousel-dot--active .VueCarousel-dot-inner {
    border: 2px solid $color-white;
    margin-top: -2px;
  }
}
</style>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-white: color(white);
$background: color(mainslider-background);

section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
// h1 {
//   font-size: 36px !important;
//   font-family: 'Roboto' !important;
//   font-weight: Bold !important;
//   line-height: normal !important;
// }
.main-slider {
  //  background-image: linear-gradient(to left, #EEFAF4 0%, #A7CDA3 100%);
  background-color: $background;
  /* width: 1366px; */
  height: 343px;
}
.container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%; /* ensures that the slider image remains full width */
  padding: unset !important;
}
@media (max-width: 600px) {
  .user {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 36px !important;
    }
    p {
      font-size: 20px !important;
    }
    .browse {
      margin-right: 0 !important;
      margin-bottom: 20px !important;
    }
    .view {
      margin-left: 0 !important;
    }
    .button-outline {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
// .row {
//   height: 640px;
// }
// button {
//   outline: none;
// }
// slide .main:after {
//   width: 100%;
//   height: 100%;
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   pointer-events: none;
//   /* black overlay with 50% transparency */
//   background: rgba(0, 0, 0, 1);
// }
// button-outline {
//   padding: 20px 30px;
// }
// @media (max-width: 75em) {
//   .main-slider {
//     height: 400px;
//   }
//   .title {
//     font-size: 50px;
//   }
//   .subtitle {
//     font-size: 20px;
//   }
//   .row {
//     height: 400px;
//   }
// }
// @media (max-width: 64em) {
//   .main-slider {
//     height: 359px;
//   }
//   .container {
//     background-position: left;
//   }
//   .title {
//     font-size: 48px;
//   }
//   .subtitle {
//     font-size: 18px;
//   }
//   .button {
//     font-size: 16px;
//     font-family: 'Open-Sans';
//     font-weight: bold;
//     font-size: 18px;
//     line-height: 25px;
//     text-transform: capitalize;
//   }
//   .row {
//     height: 359px;
//   }
// }
.slider-image {
  margin-right: 5vw;
}
.user {
    padding: 0;
    margin-left: 5vw;
    h1 {
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
    }
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
    }
  }
  .button-outline {
    border-radius: 5px;
  }
  .browse {
    margin-right: 10px;
  }
  .view {
    margin-left: 10px;
  }
</style>
