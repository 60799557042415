<template>
  <div id="general-settings">
    <!-- General Settings header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6">
        <h3 class="m0 mb5">
          General settings
        </h3>
      </div>
    </div>
    <!-- General Settings body -->
    <show-loader v-if="showLoader"/>
    <div v-else class="h4">
      <div v-if="Array.isArray(paymentMethods)" >
        <p>Payment Methods: </p>
        <ul v-for="(paymentMethod, index) in paymentMethods" :key="index">
          <li class="text">{{ paymentMethod.name }}</li>
        </ul>
      </div>
      <div v-else>
        <p>Payment Method: <span class="text">{{ paymentMethods }}</span></p>
      </div>
      <div>
        <p>Contract Price Validity: <span class="text">{{ contractPeriod }}</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import ShowLoader from './ShowLoader.vue'

export default{
  name: 'GeneralSettings',
  data () {
    return {
      // stateCompanyDetails: Object.assign({}, this.$store.state.urlModule.companyDetails),
      paymentMethods: null,
      contractPeriod: '',
      showLoader: true
    }
  },
  components: {
    ShowLoader
  },
  created () {
  },
  destroyed () {
  },
  mounted () {
    this.paymentMethods = (this.$store.state.userDetails.companyDetails && this.$store.state.userDetails.companyDetails.paymentMethods && this.$store.state.userDetails.companyDetails.paymentMethods.length > 0)
      ? this.$store.state.userDetails.companyDetails.paymentMethods : 'You have not set a payment method'
    this.contractPeriod = (this.$store.state.userDetails.companyDetails) ? this.$store.state.userDetails.companyDetails.contractPriceValidity : 'You have not set a Contract Validity Period'
    this.showLoader = false
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
#general-settings{
  @extend .h4;
  width: 95%;
  .text{
    font-size: 15px;
  }
}
</style>
