import config from 'config'
import store from 'core/store'
import EventBus from 'core/store/lib/event-bus'
import apiService from 'theme/services/api_service'

EventBus.$on('on-app-load', () => {
  global.$VS.db.companyDetailsCollection.getItem('company-menu-items')
    .then(response => {
      store.commit('saveMenuItems', response)
    })
})

const state = {
  menuItems: []
}

const getters = {
  getMenuItems: (state) => {
    let items = state.menuItems
    if (!items) {
      return []
    }
    items.sort((item1, item2) => {
      if (item1.sortOrder > item2.sortOrder) {
        return 1
      }
      if (item1.sortOrder < item2.sortOrder) {
        return -1
      }
      return 0
    })
    return items
  }
}

const actions = {
  getMenu: ({ commit }, companyId) => {
    let endpoint = config.menu.endpoint
    endpoint = endpoint.replace('{{companyId}}', companyId)
    return apiService.send(endpoint, 'get')
      .then(({data}) => {
        commit('saveMenuItems', data.result.catalogMenu.catalogMenuItems)
      })
  }
}

const mutations = {
  saveMenuItems (state, items) {
    state.menuItems = items
    global.$VS.db.companyDetailsCollection.setItem('company-menu-items', items)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
