<template>
  <span
    @click="switchFilter(id, from, to)"
    :class="{ focus: focused }"
    class="cl-gloo-footer-text"
  >
    <button
      class="relative brdr-cl-bg-tertiary brdr-1 bg-cl-transparent mr10 pointer price-selector"
      :class="{ active: active }"
      :aria-label="$t('Price ') + content"
      @focusin="showInFocusState"
      @focusout="removeFromFocusState"
    >
      <div class="bg-cl-transparent absolute block square"/>
    </button>
    <span>{{ content }}</span>
  </span>
</template>

<script>
import PriceSelector from 'core/components/PriceSelector'

export default {
  data () {
    return {
      focused: false
    }
  },
  mixins: [PriceSelector],
  methods: {
    showInFocusState () {
      this.focused = true
    },
    removeFromFocusState () {
      this.focused = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  // $color-event: color(tertiary);
  $color-active: color(accent);
  $color-secondary: color(secondary);
  $color-white: color(white);
  $color-primary: color(primary);
  $square: color(gloo-footer-text);

  .price-selector {
    width: 24px;
    height: 24px;
    border: 2px solid $square;

    &:hover {
      .square {
        background-color: $square;
      }
    }

    &.active {
      .square {
        background-color: $color-secondary;
      }
    }
  }

  .square {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  span.price-select.mb10.block {
    // color: #828282;
    font-size: 18px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }

  .focus {
    color: #000000 !important;
  }
</style>
