<template>
  <div class="cart-single-product top-border row py20 my40 align-center">
    <div class="col-md-1 col-sm-1 hidden-xs img-div py10">
      <img class="blend img-responsive" v-lazy="thumbnailObj" alt="product.name">
    </div>
    <div class="col-md-5 col-sm-5 col-xs-4 p0 align-left">
      <p class="cl-gloo-product-text">{{ product.name | htmlDecode }}</p>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-1">
      <p class="product-quantity cl-gloo-product-text"> {{ product.qty }}</p>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-3 row">
      <p class="cl-error" v-if="product.special_price">{{ parseFloat(product.price) | currency }} </p>
      <p class="price-original cl-gloo-gray" v-if="product.special_price" >{{ parseFloat(product.originalPriceInclTax) | currency }}</p>
      <p v-if="!product.special_price" class="cl-gloo-gray">{{ parseFloat(product.price) | currency }}</p>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-4 row product-price">
      <p class="cl-error " v-if="product.special_price">{{ parseFloat(product.price * product.qty) | currency }} </p>
      <p class="price-original" v-if="product.special_price" >{{ parseFloat(product.originalPriceInclTax * product.qty) | currency }}</p>
      <p v-if="!product.special_price" class="">{{ parseFloat(product.price * product.qty) | currency }}</p>
    </div>
  </div>
</template>

<script>
import Product from 'core/components/blocks/Checkout/Product'

export default {
  mixins: [Product],
  data () {
    return {
      placeholder: '/assets/placeholder.jpg'
    }
  },
  computed: {
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.placeholder,
        error: this.placeholder
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cart-single-product:nth-child(1) {
  padding-top: unset !important;
  border: none !important;
}
.price-original {
  text-decoration: line-through;
}
.blend {
  mix-blend-mode: multiply;
}
.img-responsive {
  max-height: 50px;
  max-width: 40px;
}
.material-icons {
  color: grey;
}
p {
   @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (max-width: 360px) {
      font-size: 12px;
    }
}
</style>
