import omit from 'lodash-es/omit'
import bodybuilder from 'bodybuilder'
import coreStore from '@vue-storefront/store/modules/product'
import { extendStore } from 'core/lib/themes'
import EventBus from 'core/store/lib/event-bus'
import {
  configureProductAsync
} from '@vue-storefront/store/modules/product/helpers'

const actions = {
  single (
    context,
    {
      options,
      setCurrentProduct = true,
      selectDefaultVariant = true,
      key = 'sku'
    }
  ) {
    if (!options[key]) {
      throw Error(
        'Please provide the search key ' + key + ' for product/single action!'
      )
    }

    return new Promise((resolve, reject) => {
      const setupProduct = prod => {
        // set original product
        if (setCurrentProduct) {
          context.dispatch('setOriginal', prod)
        }
        // check is prod has configurable children
        const hasConfigurableChildren =
          prod &&
          prod.configurable_children &&
          prod.configurable_children.length
        if (prod.type_id === 'simple' && hasConfigurableChildren) {
          // workaround for #983
          prod = omit(prod, ['configurable_children', 'configurable_options'])
        }
        // set current product - configurable or not
        if (prod.type_id === 'configurable' && hasConfigurableChildren) {
          // set first available configuration
          // todo: probably a good idea is to change this [0] to specific id
          configureProductAsync(context, {
            product: prod,
            configuration: { sku: options.childSku },
            selectDefaultVariant: selectDefaultVariant
          })
        } else {
          if (setCurrentProduct) context.dispatch('setCurrent', prod)
        }
        return prod
      }

      context
        .dispatch('list', {
          // product list syncs the platform price on it's own
          query: bodybuilder()
            .query('match', key, options[key])
            .build(),
          prefetchGroupProducts: false,
          updateState: false
        })
        .then(res => {
          if (res && res.items && res.items.length) {
            let prd = res.items[0]
            const _returnProductNoCacheHelper = subresults => {
              if (EventBus.$emitFilter) {
                EventBus.$emitFilter('product-after-single', {
                  key: key,
                  options: options,
                  product: prd
                })
              }
              resolve(setupProduct(prd))
            }
            if (setCurrentProduct || selectDefaultVariant) {
              context
                .dispatch('setupVariants', { product: prd })
                .then(_returnProductNoCacheHelper)
            } else {
              _returnProductNoCacheHelper(null)
            }
          } else {
            reject(new Error('Product query returned empty result'))
          }
        })
    })
  }
}

export default extendStore(coreStore, {
  actions
})
