var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login-wrapper"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"modal-content bg-cl-primary"},[_vm._m(1),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb35 login-input",attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.'),
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.'),
          } ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{staticClass:"mb35 login-input",attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validation":{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.'),
        }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('button-full',{staticClass:"mb20 bg-cl-gloo-main",attrs:{"type":"submit"}},[_c('p',{staticClass:"p0 m0"},[_vm._v("LOGIN")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-12 flex end-xs middle-xs pr0"},[_c('router-link',{staticClass:"remind cl-gloo-main",attrs:{"to":"/forgot-password"}},[_vm._v("\n          "+_vm._s('Forgot Your Password?')+"\n        ")])],1),_vm._v(" "),(_vm.enableSSO)?[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-12 flex middle-xs google-signin"},[_c('a',{staticClass:"w-100",attrs:{"href":(_vm.ecmsUrl + "/auth/google")}},[_c('img',{attrs:{"src":"/assets/btn_google_signin_dark_pressed_web@2x.png","alt":"Google Sign In"}})])])]:_vm._e()],2)]),_vm._v(" "),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{},[_c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://gloopro.com"}},[_c('img',{staticClass:"logo-tagline",attrs:{"src":"/assets/logo-tagline.png","alt":"Gloopro"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-text hidden-xs"},[_c('p',[_vm._v("Welcome!")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-divider text-muted mb20 mt20"},[_c('span',[_vm._v("or")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-center"},[_c('span',{staticClass:"back-to-gloopro cl-gloo-footer-text"},[_vm._v("Go back to\n    "),_c('a',{staticClass:"cl-gloo-main",attrs:{"href":"https://gloopro.com"}},[_vm._v("Gloopro.com")])])])}]

export { render, staticRenderFns }