<template>
  <modal name="modal-edit-location" :width="600">
    <v-container grid-list-xl location-edit>
      <header class="modal-header py25 h3 weight-550">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-black"
          @click="close"
        >
          close
        </i>
        {{ 'Edit location' }}
      </header>
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 class="center-form form">
          <v-form @submit.prevent="editLocation" ref="form" lazy-validation>
            <v-text-field
              label="Name of location"
              v-model="location.name"
              outline
              type="text"
              :rules="[() => location.name.trim().length > 0 || 'This field is required']"
              required
            />
            <v-text-field
              label="Location code"
              v-model="location.code"
              outline
              type="text"
              :rules="[() => location.code.trim().length > 0 || 'This field is required']"
              required
            />
            <v-text-field
              label="Address Line 1"
              v-model="location.locationAddressId.streetAddress"
              outline
              type="text"
              :rules="[() => location.locationAddressId.streetAddress.trim().length > 0 || 'This field is required']"
              required
            />
            <v-text-field
              label="Address Line 2"
              v-model="location.locationAddressId.addressLine2"
              outline
              type="text"
            />
            <v-text-field
              label="Zip code"
              v-model="location.locationAddressId.zipcode"
              outline
              type="text"
            />
            <v-select
              :items="states"
              v-model="location.locationAddressId.state"
              label="State of location"
              outline
              item-text="name"
              item-value="name"
              :rules="[() => location.locationAddressId.state.length > 0 || 'Select a state for this location']"
              required
            />
            <v-select
              :items="filteredCities"
              v-model="location.locationAddressId.city"
              label="City of location"
              outline
              item-text="name"
              item-value="name"
              :rules="[v => !!v || 'Select a city for this location']"
              :error-messages="cityError.messages"
              :error="cityError.hasError"
              required
            />
            <v-text-field
              label="Minimum order amount"
              v-model="location.minimumOrderAmount"
              outline
              type="number"
              :rules="minimumOrderAmountRules"
              :error-messages="minimumOrderAmountError.messages"
              :error="minimumOrderAmountError.hasError"
              required
            />
            <!-- <span v-if="deliveryMethods && deliveryMethods.length > 0" class="v-input v-label align-left">Delivery Methods</span> -->
            <div class="row delivery-methods" v-for="deliveryMethod in deliveryMethods" :key="deliveryMethod.id" :v-model="location.locationDeliveryAttributes.deliveryMethodsId">
              <div class="col-md-6 col-xs-12 align-left">
                <!-- <p class="delivery-method-name">{{ deliveryMethod.name }}</p> -->
                <v-text-field
                  label="Delivery Method"
                  type="text"
                  outline
                  :disabled="true"
                  v-model="deliveryMethod.name"
                />
              </div>
              <div class="col-md-6 col-xs-12">
                <v-text-field
                  label="Delivery Fee"
                  type="number"
                  outline
                  :disabled="true"
                  v-model="deliveryMethod.deliveryFee"
                />
              </div>
            </div>
            <v-btn
              block
              type="submit"
              :disabled="formValid() === false"
              style="height: 45px;"
            >Save location changes</v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </modal>
</template>
<script>
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'
import i18n from 'core/lib/i18n'

export default {
  name: 'EditLocation',
  components: {
    Modal,
    ButtonFull
  },
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      minimumOrderAmountRules: [
        v => !!v || 'Minimum order amount is required'
      ],
      minimumOrderAmountError: {
        hasError: false,
        messages: []
      },
      cityError: {
        hasError: false,
        messages: []
      },
      filteredCities: []
    }
  },
  watch: {
    cities: function (val) {
      let filteredCities = val.filter(city => {
        return city.state.name === this.location.locationAddressId.state
      })
      this.filteredCities = filteredCities
    },
    'location.locationAddressId.state': function (val) {
      // change filtered cities to show only cities in the selected location
      let filteredCities = this.cities.filter(city => {
        return city.state.name === this.location.locationAddressId.state
      })
      this.filteredCities = filteredCities

      // check that input for city corresponds with cities found within a state
      this.stateCityMatch()
    },
    'location.minimumOrderAmount': function (val) {
      // check if the minimum order amount is less than the default amount configured for the selected city
      this.checkMininumOrderAmount()
    },
    'location.locationAddressId.city': {
      handler (val) {
        let locationDeliveryAttributes = {}
        this.deliveryMethods.forEach(method => {
          method.selected = true
          locationDeliveryAttributes[method.id] = method
        })

        // mark the existing location delivery attributes for deletion if in a different city
        this.location.locationDeliveryAttributes.forEach(attribute => {
          if (attribute.deliveryMethodsId && !locationDeliveryAttributes[attribute.deliveryMethodsId]) {
            locationDeliveryAttributes[attribute.deliveryMethodsId] = {
              id: attribute.deliveryMethodsId,
              selected: false
            }
          } else if (attribute.id && !locationDeliveryAttributes[attribute.id]) {
            locationDeliveryAttributes[attribute.id] = {
              id: attribute.id,
              selected: false
            }
          }
        })
        this.location.locationDeliveryAttributes = Object.values(locationDeliveryAttributes)

        // check that input for city corresponds with cities found within a state
        this.stateCityMatch()

        // check if the minimum order amount is less than the default amount configured for the selected city
        this.checkMininumOrderAmount()
      }
    }
  },
  mounted () {
    // change filtered cities to show only cities in the selected location
    this.$store.dispatch('fetchCities')
  },
  computed: {
    cities () {
      return this.$store.getters.getCities
    },
    deliveryMethods () {
      let deliveryMethods
      if (this.cities) {
        let cityName = this.location.locationAddressId.city
        this.cities.forEach(city => {
          if (city.name === cityName) {
            deliveryMethods = city.deliveryMethods
          }
        })
      }
      return deliveryMethods
    },
    states () {
      let states = []
      let setStates = new Set()
      if (this.cities) {
        this.cities.forEach(city => {
          let state = city.state
          if (!setStates.has(state.id)) {
            setStates.add(state.id)
            states.push(state)
          }
        })
      }
      return states
    },
    locations () {
      let locations = this.$store.getters.getCompanyLocationDetails
      if (!locations) {
        return []
      }
      // filter out disabled locations
      let enabledLocations = locations.filter(location => {
        return location.isActive
      })
      return enabledLocations
    },
    companyId () {
      let companyDetails = this.$store.getters.getCompanyDetails
      return companyDetails && companyDetails.id
    },
    userId () {
      return this.$store.state.userDetails && this.$store.state.userDetails.userId
    }
  },
  methods: {
    checkMininumOrderAmount () {
      // check if the minimum order amount is less than the default amount configured for the selected city
      if (!this.location.locationAddressId.city) {
        return
      }

      let selectedCityMinimumOrderAmount
      this.filteredCities.forEach(city => {
        if (city.name === this.location.locationAddressId.city) {
          selectedCityMinimumOrderAmount = city.minimumOrderAmount
        }
      })
      this.selectedCityMinimumOrderAmount = selectedCityMinimumOrderAmount

      if (Number(this.location.minimumOrderAmount) < Number(this.selectedCityMinimumOrderAmount)) {
        this.minimumOrderAmountError.hasError = true
        this.minimumOrderAmountError.messages = []
        this.minimumOrderAmountError.messages.push('Minimum order amount cannot be less than ' + this.$options.filters.currency(this.selectedCityMinimumOrderAmount))
      } else {
        this.minimumOrderAmountError.hasError = false
        this.minimumOrderAmountError.messages = []
      }
    },
    stateCityMatch () {
      // checks that input for city corresponds with cities found within a state
      let locationCity = this.location.locationAddressId.city
      let locationState = this.location.locationAddressId.state

      let isAMatch = this.filteredCities.some(city => city.name === locationCity && city.state.name === locationState)
      if (!isAMatch) {
        this.cityError.hasError = true
        this.cityError.messages = []
        this.cityError.messages.push('This field is required')
      } else {
        this.cityError.hasError = false
        this.cityError.messages = []
      }
    },
    formValid () {
      if (!(this.location.name.trim() && this.location.code.trim() && this.location.minimumOrderAmount &&
        this.location.locationAddressId.streetAddress.trim() && this.location.locationAddressId.state && this.location.locationAddressId.city)) {
        return false
      }
      // check if an error exists in the city entry
      if (this.cityError.hasError) {
        return false
      }
      // check if an error exists in the minimum order amount or delivery fee
      if (this.minimumOrderAmountError.hasError) {
        return false
      }
      return true
    },
    editLocation () {
      if (this.$refs.form.validate()) {
        let data = this.location
        let locationId = this.location.id
        this.$bus.$emit('notification-progress-start', 'Updating location details ...')
        this.$store.dispatch('updateLocation', {data, locationId})
          .then((result) => {
            this.$bus.$emit('notification-progress-stop')
            if (result.data.code === 200) {
              this.$store.dispatch('fetchSingleUserDetails', { userId: this.userId, updateState: true })
                .then(({data}) => {
                  this.$bus.$emit('notification', {
                    type: 'success',
                    message: i18n.t('Location updated successfully'),
                    action1: { label: i18n.t('OK'), action: 'close' }
                  })
                  this.close()
                  this.$bus.$emit('location-updated')
                  this.$store.dispatch('fetchLocationDetails', locationId)
                })
            } else {
              this.$bus.$emit('notification', {
                type: 'error',
                message: i18n.t(result.data.result.message),
                action1: { label: i18n.t('OK'), action: 'close' }
              })
            }
          }).catch(() => {
            this.$bus.$emit('notification-progress-stop')
          })
      }
    },
    close () {
      this.$bus.$emit('modal-hide', 'modal-edit-location')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gloo-main: color(gloo-main);
$color-white: color(white);

.location-edit {
  padding: 0 0 30px !important;
}

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

input::placeholder {
  color: rgba(130,130,130,0.25);
}

.form {
  display: block;
  padding: 25px 0 !important;
}

.location-edit {
  margin: 70px auto !important;
}

span.v-input.v-label {
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.delivery-method-name {
  margin-top: 15px
}

.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
  font-weight: 600;
  line-height: 17px;
}
</style>
