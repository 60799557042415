<template>
  <div
    class="microcart mw-100 fixed cl-secondary"
    :class="[productsInCart.length ? 'bg-cl-other' : 'bg-cl-primary', { active: isMicrocartOpen }]"
  >
    <div class="row middle-xs bg-cl-primary top-sm cart">
      <div class="row close-btn col-xs-12">
        <div class="col-xs-10"/>
        <div class="col-xs-2 end-xs">
          <button type="button" class="p0 brdr-none close" @click="closeMicrocartExtend">
            <i class="material-icons p15 cl-gloo-gray">
              close
            </i>
          </button>
        </div>
      </div>
      <div class="row caption-total col-xs-12 p0">
        <div class="col-xs-6 cl-gloo-section-header-text p0">
          <div class="row weight-700 middle-xs p0">
            <h2
              v-if="productsInCart.length"
              class="cl-gloo-section-header-text
              mb35 heading"
            >
              {{ $t('Shopping cart') }}
            </h2>
          </div>
        </div>
        <div class="col-xs-6 cl-gloo-section-header-text">
          <div class="row weight-700 middle-xs" v-for="(segment, index) in totals" :key="index" v-if="segment.code === 'grand_total'">
            <div class="col-xs align-right h2 total-price-value">
              {{ segment.value | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 v-if="!productsInCart.length" class="cl-secondary ml30">
      {{ $t('Your shopping cart is empty.') }}
    </h4>
    <div v-if="!productsInCart.length" class="ml30" @click="closeMicrocartExtend">
      {{ $t("Don't hesitate and") }}
      <router-link :to="localizedRoute('/')" class="no-underline cl-gloo-accent2 link">
        <span @click="closeMicrocartExtend">
          {{ $t('Return to shopping') }}
        </span>
      </router-link>
      {{ $t('to find something beautiful for You!') }}
    </div>
    <div class="py40 m0 cart-summary--block" v-else>
      <div>
        <span class="cart-sub--title cl-gloo-product-text">Order Summary ({{ productsInCart.length }} {{ productsInCart.length > 1 ? 'Items' : 'Item' }})</span>
        <div class="row align-left cart-summary--title">
          <span class="col-md-6 col-sm-5 col-xs-3 cart-summary p0">Item</span>
          <span class="col-md-2 col-sm-2 hidden-xs cart-summary p0">Quantity</span>
          <span class="hidden-md col-xs-3 cart-summary p0">Qty</span>
          <span class="col-md-2 col-sm-2 hidden-xs cart-summary p0">Unit Price</span>
          <span class="hidden-md col-xs-3 cart-summary p0">Price</span>
          <span class="col-md-2 sol-sm-3 hidden-xs cart-summary p0">Subtotal</span>
          <span class="hidden-md col-xs-3 cart-summary p0">Total</span>
        </div>
      </div>
      <div>
        <ul v-if="productsInCart.length" class="bg-cl-primary m0 p0  pb40 products">
          <product v-for="product in productsInCart" :key="product.sku" :product="product" />
        </ul>
        <div
          class="row middle-xs actions checkout-btn-wrapper"
          v-if="productsInCart.length && !isCheckoutMode"
        >
          <button-outline color="light" class="continue-shopping" @click.native="closeMicrocartExtend">
            Continue Shopping
          </button-outline>
          <button-outline
            color="dark"
            link="/checkout"
            @click.native="closeMicrocartExtend"
            v-if="hasPermission('create', 'order')"
          >
            Checkout
          </button-outline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Microcart from 'core/components/blocks/Microcart/Microcart'
import { mapGetters } from 'vuex'

import ButtonOutline from 'theme/components/theme/ButtonOutline'
import Product from 'theme/components/core/blocks/Microcart/Product'

export default {
  components: {
    Product,
    ButtonOutline
  },
  mixins: [Microcart],
  computed: {
    ...mapGetters({
      totals: 'getCartTotals'
    })
  },
  methods: {
    continueShopping () {
      this.closeMicrocartExtend()
      this.$router.push(this.localizedRoute('/#company-categories'))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/animations/transitions";
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $gloo-main: color(gloo-main);

  .microcart {
    top: 0;
    right: 0;
    z-index: 3;
    height: 100vh;
    width: 70%;
    min-width: 320px;
    @media (max-width: 991px) {
      width: 80%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-height: 700px) {
      overflow-y: scroll;
    }
    background: #fff;
    transform: translateX(100%);
    transition: transform 300ms $motion-main;
    overflow: hidden;
    &.active {
      transform: translateX(0)
    }
  }
  .top-sm .close-btn {
    height: 40px;
  }

  .close {
    i {
      opacity: 0.6;
    }
    &:hover,
    &:focus {
      i {
        opacity: 1;
      }
    }
  }

  .cart-summary--block {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
    }
    @media (max-width: 360px) {
      padding-top: 10px;
    }
  }
  .heading {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 35px;
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
      padding-left: unset;
    }
    @media (max-width: 360px) {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  .products {
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
    border-radius: 5px;
    border: 1px solid rgba(90, 122, 190, 0.08);
    box-shadow: 0 1px 3px 0 #D7D7E0;
    height: 50vh;
    overflow-y: auto;
  }

  .actions {
    .link {
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 20px 70px;
        &.checkout {
          margin-top: 55px;
          padding: 0;
        }
      }
    }
  }

  .checkout-btn-wrapper .col-xs-12 .button-full {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 3%;
    padding: 20px;
  }

  .checkout-btn-wrapper {
    background: #ffffff;
    margin: auto;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 569px) {
      padding-top: 20px;
    }
  }

  .checkout-btn-wrapper .col-xs-12 {
    padding: 0;
  }

  .button-outline {
    border: 1px solid;
    width: 300px;
    text-align: center;
    border-radius: 5px;
    margin: 40px 20px;
    @media (max-width: 1060px) {
      margin: 40px 5px;
    }
    @media (max-width: 569px) {
      margin: 20px 0px;
    }
    @media (min-width: 569px) and (max-width: 961px) {
      width: 200px;
      margin: 20px 10px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
      width: 100%;
      margin: 20px 0px;
    }
  }

  .button-outline.continue-shopping {
    padding: 15px 20px;
  }
  .button-outline:nth-child(2) {
    margin-right: 0;
  }

  .summary {
    @media (max-width: 767px) {
      padding:  0 15px;
      font-size: 12px;
    }
  }

  .summary-heading {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-label {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-value {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (max-width: 360px) {
      font-size: 16px;
      line-height: 18px;
    }
    // color: #3d4094;
  }

  .delete-button {
    vertical-align: middle;
  }

  .caption-total {
    border: none;
    border-bottom: 1px solid #E3E8EE;
    padding-right: 40px;
    @media (max-width: 600px) {
      padding-right: 10px;
    }
  }

  .cart {
    padding-left: 40px;
    @media (max-width: 767px) {
      padding-left: 10px;
    }
  }
  .cart-sub--title {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
    @media (max-width: 360px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .cart-summary {
    text-transform: uppercase;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    @media (max-width: 991px) {

    }
  }

  .cart-summary--title {
    padding: 25px 15px 25px 0;
  }

  .light {
    text-transform: none;
    background-color: #fff;
  }
</style>
