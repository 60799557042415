<template>
  <button class="brdr-none bg-cl-transparent p0 middle-xs inline-flex cl-secondary">
    <span class="h5">
      {{ $t('x Remove') }}
    </span>
    <!-- <i class="material-icons h4 p5 pr0">remove_shopping_cart</i> -->
  </button>
</template>

<script>
import RemoveButton from 'core/components/blocks/Microcart/RemoveButton'

export default {
  mixins: [RemoveButton]
}
</script>

<style lang="scss" scoped>
  .h5 {
    color: #ed2020;
    font-size: 14px;
    line-height: 16px;
  }
</style>
