import config from 'config'
import store from 'core/store'
import sumBy from 'lodash-es/sumBy'
import i18n from 'core/store/lib/i18n'
import EventBus from 'core/store/lib/event-bus'
import apiService from 'theme/services/api_service'
import { currentStoreView } from '@vue-storefront/store/lib/multistore'
import * as entities from 'core/store/lib/entities'

EventBus.$on('user-before-logout', () => {
  store.dispatch('clearPunchoutDetailsOnLogout')
})

EventBus.$on('after-punchout-action', () => {
  store.dispatch('clearPunchoutDetailsOnLogout')
})

const state = {
  sessionId: null,
  erpType: null
}

const actions = {
  resumeAribaSession: function ({ commit }, sessionId) {
    let endpoint = `${config.ecms.resumeAribaSession_endpoint}${sessionId}`
    commit('setSessionId', sessionId)
    return apiService.send(endpoint, 'get')
      .then((response) => {
        if (response.data.code === 200) {
          store.commit('setUserToken', response.data.result ? response.data.result.jwToken : null)
          commit('setErpType', 'ariba')
          return response
        }
      })
  },
  resumeCoupaSession: function ({ commit }, sessionId) {
    let endpoint = `${config.ecms.resumeCoupaSession_endpoint}${sessionId}`
    commit('setSessionId', sessionId)
    return apiService.send(endpoint, 'get')
      .then((response) => {
        if (response.data.code === 200) {
          store.commit('setUserToken', response.data.result ? response.data.result.jwToken : null)
          commit('setErpType', 'coupa')
          return response
        }
      })
  },
  resumeSAPSRMSession: function ({ commit }, sessionId) {
    let endpoint = `${config.ecms.resumeSAPSRMSession_endpoint}${sessionId}`
    commit('setSessionId', sessionId)
    return apiService.send(endpoint, 'get')
      .then((response) => {
        if (response.data.code === 200) {
          store.commit('setUserToken', response.data.result ? response.data.result.jwToken : null)
          commit('setErpType', 'sap-srm')
          return response
        }
      })
      .catch(err => {
        throw err
      })
  },
  processAribaPunchout ({ rootState }, { order }) {
    const storeView = currentStoreView()
    if (storeView.storeCode) {
      order.store_code = storeView.storeCode
    }
    const orderId = entities.uniqueEntityId(order)
    order.order_id = orderId.toString()
    order.created_at = new Date()
    order.updated_at = new Date()

    let endpoint = `${config.ecms.processAribaPunchoutOrder_endpoint}`
    const sessionId = rootState.punchout.sessionId
    const data = {
      sessionId,
      order
    }
    return apiService.send(endpoint, 'post', data)
  },
  processCoupaPunchout ({ rootState }, { order }) {
    const storeView = currentStoreView()
    if (storeView.storeCode) {
      order.store_code = storeView.storeCode
    }
    const orderId = entities.uniqueEntityId(order)
    order.order_id = orderId.toString()
    order.created_at = new Date()
    order.updated_at = new Date()

    let endpoint = `${config.ecms.processCoupaPunchoutOrder_endpoint}`
    const sessionId = rootState.punchout.sessionId
    const data = {
      sessionId,
      order
    }
    return apiService.send(endpoint, 'post', data)
  },
  processSAPSRMPunchout ({ rootState }, { order }) {
    const storeView = currentStoreView()
    if (storeView.storeCode) {
      order.store_code = storeView.storeCode
    }
    const orderId = entities.uniqueEntityId(order)
    order.order_id = orderId.toString()
    order.created_at = new Date()
    order.updated_at = new Date()

    let endpoint = `${config.ecms.processSAPSRMPunchoutOrder_endpoint}`
    const sessionId = rootState.punchout.sessionId
    const data = {
      sessionId,
      order
    }
    return apiService.send(endpoint, 'post', data)
  },
  clearCart (context) {
    context.dispatch('clearPunchoutDetailsOnLogout')
    store.dispatch('cart/clear')
    // clear cart stored in indexedDb
    let promises = []
    if (global.$VS && global.$VS.db && global.$VS.db.cartsCollection) {
      promises.push(global.$VS.db.cartsCollection.setItem('current-cart', []))
      promises.push(global.$VS.db.cartsCollection.setItem('current-cart-token', ''))
    }
    return Promise.all(promises)
  },
  clearPunchoutDetailsOnLogout (context) {
    context.commit('setSessionId', null)
    context.commit('setErpType', null)

    const usersCollection = global.$VS.db.usersCollection
    usersCollection.setItem('current-token', '')
    // store.dispatch('clearUserDetailsOnLogout')
  }
}

const getters = {
  getCartTotalsWithDeliveryFee () {
    let { state } = store
    let deliveryMethod = state.checkout.deliveryMethod
    if (deliveryMethod) {
      if (!state.userDetails.companyDetails) {
        return []
      }
      const priceIncludesVAT = state.userDetails.companyDetails.inclusiveVAT
      let cartTotal = sumBy(state.cart.cartItems, (p) => {
        return p.qty * p.price
      })
      if (priceIncludesVAT) {
        let subTotalTitle = 'Subtotal incl. tax'
        return [
          {
            code: 'subtotalInclTax',
            title: subTotalTitle,
            value: cartTotal
          },
          {
            code: 'shipping',
            title: deliveryMethod.name,
            value: deliveryMethod.deliveryFee
          },
          {
            code: 'grand_total',
            title: i18n.t('Grand total'),
            value: cartTotal + deliveryMethod.deliveryFee
          }
        ]
      } else {
        let subTotalTitle = 'Subtotal'
        let VAT = config.ecms.VAT
        if (!VAT || isNaN(VAT)) {
          VAT = 0.075
        }
        const tax = VAT * cartTotal
        return [
          {
            code: 'subtotalInclTax',
            title: subTotalTitle,
            value: cartTotal
          },
          {
            code: 'tax',
            title: 'Tax',
            value: tax
          },
          {
            code: 'shipping',
            title: deliveryMethod.name,
            value: deliveryMethod.deliveryFee
          },
          {
            code: 'grand_total',
            title: i18n.t('Grand total'),
            value: cartTotal + tax + deliveryMethod.deliveryFee
          }
        ]
      }
    } else {
      return []
    }
  },
  getCartTotals () {
    let { state } = store
    if (state.platformTotalSegments) {
      return state.platformTotalSegments
    } else {
      if (!state.userDetails.companyDetails) {
        return []
      }
      const priceIncludesVAT = state.userDetails.companyDetails.inclusiveVAT
      let subTotalTitle = 'Subtotal incl. tax'
      if (!priceIncludesVAT) {
        subTotalTitle = 'Subtotal without tax'
      }

      return [
        {
          code: 'subtotalInclTax',
          title: subTotalTitle,
          value: sumBy(state.cart.cartItems, (p) => {
            return p.qty * p.price
          })
        },
        {
          code: 'grand_total',
          title: i18n.t('Grand total'),
          value: sumBy(state.cart.cartItems, (p) => {
            return p.qty * p.price
          })
        }
      ]
    }
  },
  getErpType: (state) => {
    return state.erpType
  }
}

const mutations = {
  setSessionId: (state, sessionId) => {
    state.sessionId = sessionId
    global.$VS.db.usersCollection.setItem('punchout-session-id', sessionId)
  },
  endUserSession: (state) => {
    store.state.user.token = ''
    store.state.user.current = null
    store.state.user.session_started = null
  },
  setErpType: (state, erpType) => {
    global.$VS.db.usersCollection.setItem('punchout-erp-type', erpType)
    state.erpType = erpType
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
