<template>
  <div id="company-categories">
    <div class="mb20 mt20">
      <div class="col-xl-8 option-header">
        <h4 class="mb0 cl-gloo-gray section-header">Categories</h4>
        <p class="mb0 hidden-xs sub-header cl-gloo-gray">Check out your company's categories</p>
      </div>
      <hr >
    </div>
    <div class="row align-center">
      <!-- <div v-for="(category, index) in popularCategories" :key="index" class="col-xs-6 col-sm-3 col-lg-3 col-xl-3 col-md-3 category ">
        <router-link :to="localizedRoute(`${category.link}`)">
          <div class="single-category :cl-secondary pops-category">
            <img :src="category.image" :alt="category.title" height="70px" class=":cl-secondary img-category" style="color:#3d4094;">
            <p>{{ category.title }}</p>
          </div>
        </router-link>
      </div> -->
      <div v-for="(category, index) in categories" :key="index" class="col-xs-6 col-sm-3 col-lg-3 col-xl-3 col-md-3 category ">
        <router-link :to="localizedRoute(`${category.url}`)">
          <div class="single-category :cl-secondary pops-category">
            <img :src="`/assets/popular/${category.icon}`" :alt="category.label" height="70px" class=":cl-secondary img-category" style="color:#3d4094;">
            <p>{{ category.label }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularCategories',
  data () {
    return {
      popularCategories: []
    }
  },
  computed: {
    categories () {
      return this.$store.getters.getMenuItems
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gloo-secondary: color(gloo-secondary);
$hr: color(gloo-solitude);

  #home, h1, h4, p  {
    // font-family: Roboto !important;
  }
  .option-header h1 {
    line-height: 28px;
  }
  .option-header p {
    line-height: 21px;
  }
  .option-descr {
    position: relative;
  }
  .option-descr a {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $hr;
    margin: 1em 0;
    padding: 0;
    width: 100%;
  }
  .category {
    padding: 0.5rem;
  }
  .single-category {
    margin: 3%;
    max-width: 21vw !important;
  }
  .single-category {
    background-color: #FBFCFF;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #E3E8EE;
    padding: 5em 3em;
  }
  // .frequent .single-category {
  //   padding: 2em !important;
  //   margin: 1em 0;
  //   // height: 470px;
  // }
  .pops-category:hover {
    background-color: #FBFCFF;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #E3E8EE;

  }
  .pops-category:hover .img-category {
    color: #3D4094;
  }
  .single-category p {
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
  .section-header {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
    // line-spacing: 29px;
  }
  .sub-header {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
  }

  @media (min-width: 760px) and (max-width: 1280px) {
    img {
    height: 70px;
    width: 50px
  }
  .single-category {
    margin: 4%;
    max-width: 100% !important;
  }
  .single-category {
    padding: 2em 0;
  }
  .category {
    padding: 0;
  }
}
  @media (max-width: 760px) {
  img {
    height: 50px;
  }
  .single-category {
    margin: 4%;
    max-width: 100% !important;
  }
  .single-category {
    padding: 2em 0;
  }
  .category {
    padding: 0;
  }
}
</style>
