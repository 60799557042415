
<template>
  <div class="bottom-border customer-details--section py20">
    <div class="mb20 px20" v-if="!isActive && !isFilled">
      <div class="customer-details--title p0">
        <h3 class="customer-details--title p0">Personal Details</h3>
      </div>
    </div>
    <div class="" v-else>
      <div class="mb20">
        <h3 class="customer-details--title  p0">Personal Details</h3>
      </div>
      <div>
        <p class="m0 cl-gloo-product-text">{{ personalDetails.firstName }} {{ personalDetails.lastName }}</p>
        <p class="m0 cl-gloo-product-text">{{ personalDetails.emailAddress }}</p>
      </div>
      <div class="col-md-1" />
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import PersonalDetails from 'core/components/blocks/Checkout/PersonalDetails'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import Modal from 'theme/components/core/Modal'
import Tooltip from 'theme/components/core/Tooltip'

export default {
  components: {
    Tooltip,
    Modal,
    BaseCheckbox,
    BaseInput
  },
  data () {
    return {
      isFilled: true
    }
  },
  mixins: [PersonalDetails],
  methods: {
    sendDataToCheckout () {
      this.personalDetails.createAccount = false
      this.isFilled = true
      this.$bus.$emit('checkout-after-personalDetails', this.personalDetails, this.$v)
    }
  },
  mounted () {
    if (this.personalDetails.firstName) {
      this.sendDataToCheckout()
    }
  },
  validations: {
    personalDetails: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: underline;
}

.login-prompt {
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}
</style>
