<template>
  <v-progress-linear color="success" :indeterminate="true" v-if="!location" />
  <div id="location-details" v-else>
    <div>
      <div id="page-wrap" class="row pb20 px10">
        <div id="location-name-holder" class="col-md-8 col-sm-6 col-xs-12 margin-auto">
          <h3 id="location-name">{{ location && location.name }}</h3>
          <span class="location-status-chip" :class="[location.isActive ? activeClass : inactiveClass]">{{ location ? (location.isActive ? 'Enabled' : 'Disabled') : '' }}</span>
        </div>
        <div id="location-actions-holder" class="col-md-4 col-sm-6 col-xs-12 buttons">
          <v-btn large color="success" class="bg-cl-mine-shaft :bg-cl-th-secondary location-actions" @click="editLocation">Edit location</v-btn>
          <p v-if="location.isActive" id="disable-location" class="bg-cl-mine-shaft :bg-cl-th-secondary location-actions" @click="showDisableLocationModal">Disable location</p>
          <p v-else id="enable-location" class="bg-cl-mine-shaft :bg-cl-th-secondary location-actions" @click="enableLocation">Enable location</p>
        </div>
      </div>
    </div>
    <v-card>
      <div id="details" class="col-md-12 col-sm-12 col-xs-12 panel">
        <v-card-title>
          <i class="material-icons location-status-icon" :class="[location.isActive ? activeClass : inactiveClass]">check_circle_outline</i>
          <span class="section-title">Location Details</span>
        </v-card-title>
        <div class="details-wrapper row">
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Address</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>
                  {{ location && location.locationAddressId ? location.locationAddressId.streetAddress : '' }}
                  &nbsp; {{ location && location.locationAddressId ? location.locationAddressId.addressLine2 : '' }}
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">City / State</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>
                  {{ location && location.locationAddressId ? location.locationAddressId.city : '' }},&nbsp;
                  {{ location && location.locationAddressId ? location.locationAddressId.state : '' }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Number of Users</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>
                  {{ users.length }}
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Date Created</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>
                  {{ location ? formatDate(location.createdAt) : '' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-tabs
      v-model="active"
      id="location-tab"
      slider-color="success"
    >
      <v-tab ripple class="tablink">
        Report
      </v-tab>
      <v-tab ripple class="tablink">
        Orders
      </v-tab>
      <v-tab ripple class="tablink">
        Users
      </v-tab>
      <v-tab-item>
        <div class="panel">
          <div id="report" class="pb20">
            <v-card-title class="table-title">
              <span class="section-title">{{ location ? location.name : '' }} Report</span>
            </v-card-title>
            <div id="report-wrapper">
              <div class="row">
                <div class="reports-block col-sm-4">
                  <div class="report-icon">
                    <img src="/assets/total-amount-icon.svg" class="img-responsive">
                  </div>
                  <div class="total-amount">
                    <p class="reports-block--value">{{ totalOrdersAmount | number | currency }}</p>
                    <p class="reports-description">Total Amount</p>
                  </div>
                </div>
                <div class="reports-block col-sm-4">
                  <div>
                    <p class="reports-block--value">{{ location ? location.orders.length : '' }}</p>
                    <p class="reports-description">No of orders</p>
                  </div>
                </div>
                <div class="reports-block col-sm-4">
                  <div>
                    <p class="reports-block--value">{{ averageOrderValue | number | currency }}</p>
                    <p class="reports-description">Average order value</p>
                  </div>
                </div>
              </div>

              <!-- Order Chart -->
              <div id="purchase-graph" class="mt30">
                <line-chart
                  :chart-data="chartData"
                  v-if="location"
                  :options="{responsive: false, maintainAspectRatio: false}"
                  :width="400"
                  :height="180"
                />
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="panel">
          <v-card-title class="table-title">
            <span class="section-title">Order History</span>
          </v-card-title>
          <v-data-table
            v-if="orders"
            :headers="orderTableHeaders"
            :items="location ? location.orders : []"
            :rows-per-page-items="[25, 50]"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left"><router-link :to="'/orders/' + props.item.id">{{ props.item.id }}</router-link></td>
              <td class="text-xs-center">{{ formatDate(props.item.created_at) }}</td>
              <td class="text-xs-center">{{ orderTotal(props.item.id) | number | currency }}</td>
              <td class="text-xs-center order-status"><span class="order-status-chip" id="approved-order">{{ props.item.status }}</span></td>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="panel">
          <v-card-title class="table-title">
            <span class="section-title">Users</span>
          </v-card-title>
          <v-data-table
            v-if="location && location.users.length > 0"
            :headers="userTableHeaders"
            :items="location ? location.users : []"
            :search="search"
            :rows-per-page-items="[25, 50]"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.firstName }}</td>
              <td class="text-xs-left">{{ props.item.lastName }}</td>
              <td class="text-xs-center capitalized">{{ props.item.roleId ? props.item.roleId.name : '' }}</td>
              <td class="text-xs-center user-status">
                <span
                  class="user-status-chip"
                  :class="[props.item.isActive ? activeClass : inactiveClass]"
                >
                  {{ props.item.isActive ? 'Active' : 'Inactive' }}
                </span>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>

    <edit-location :location="location" />

    <div id="disable-location-modal">
      <modal name="disable-location-modal" :width="500">
        <header class="modal-header py25 px65 h3 weight-700">
          <i
            slot="close"
            class="modal-close material-icons p15 cl-bg-black"
            @click="cancelDisableLocation"
          >
            close
          </i>
          {{ 'Disable location' }}
        </header>
        <div class="disable-location-modal-content">
          <v-card>
            <v-card-title class="confirmation-text--wrapper">
              <p>Are you sure you want to disable this location?</p>
            </v-card-title>
            <v-card-actions class="row">
              <div class="col-sm-6 col-xs-6">
                <v-btn large color="error" class="rejection" id="diasble" @click="disableLocation()">Yes</v-btn>
              </div>
              <div class="col-sm-6 col-xs-6">
                <v-btn large class="rejection" id="do-not-disable" @click="cancelDisableLocation()">No</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import config from 'config'
import moment from 'moment'
import LineChart from 'core/components/LineChart'
import _ from 'lodash'
import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import Modal from 'theme/components/core/Modal.vue'
import EditLocation from './EditLocation'

export default {
  name: 'LocationDetails',
  components: {
    LineChart,
    Modal,
    EditLocation
  },
  data () {
    return {
      activeClass: 'active',
      inactiveClass: 'inactive',
      location: null,
      orderTableHeaders: [
        {
          text: 'Order ID',
          align: 'left',
          sortable: true,
          value: 'entity_id'
        },
        {text: 'Date', align: 'center', sortable: true, value: 'created_at'},
        {text: 'Total Amount', align: 'center', sortable: true, value: 'grand_total'},
        {text: 'Status', align: 'center', sortable: true, value: 'status'}
      ],
      userTableHeaders: [
        {
          text: 'First Name',
          align: 'left',
          sortable: true,
          value: 'firstName'
        },
        {
          text: 'Last Name',
          align: 'left',
          sortable: true,
          value: 'lastName'
        },
        {text: 'Role', align: 'center', sortable: true, value: 'roleId.name'},
        {text: 'Status', align: 'center', sortable: true, value: 'isActive'}
      ],
      search: null,
      active: null,
      orders: {},
      chartData: null
    }
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('view', 'location')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
    const userDeliveryLocations = this.$store.getters.getDeliveryLocations
    const locationId = this.$route.params.locationId
    let canView = userDeliveryLocations.find(location => {
      return location.id === locationId
    })
    if (!canView) {
      canView = acl.isUserAllowedPermission('view all', 'location')
    }

    if (!canView) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  },
  created () {
    const locationId = this.$route.params.locationId
    this.$store.dispatch('fetchLocationDetails', locationId)
      .then((response) => {
        let data = response && response.data
        if (data && data.code === 200) {
          this.location = data.result.data.location
          this.orders = _.keyBy(this.location.orders, (order) => {
            return order.id
          })
          let dataForChart = []
          let labels = []
          if (this.location) {
            let dataObject = {}
            _.each(this.location.orders, order => {
              if (dataObject[this.formatDate(order.created_at)]) {
                dataObject[this.formatDate(order.created_at)] += this.orderTotal(order.id)
              } else {
                dataObject[this.formatDate(order.created_at)] = this.orderTotal(order.id)
              }
            })
            labels = Object.keys(dataObject)
            dataForChart = Object.values(dataObject)
          }
          this.chartData = {
            labels: labels,
            datasets: [
              {
                label: 'Amount spent on Orders',
                data: dataForChart
              }
            ]
          }
        } else {
          this.$router.push(this.localizedRoute('/company-settings/location-settings'))
        }
      })
      .catch(err => {
        let errorMessage = err.message
        if (err.response && err.response.data.result) {
          errorMessage = err.response.data.result
        }
        this.$bus.$emit('notification', {
          type: 'error',
          message: errorMessage,
          action1: { label: 'OK', action: 'close' }
        })
      })
  },
  filters: {
    number: function (value) {
      if (!value) return ''
      value = value.toLocaleString(config.i18n.defaultLocale)
      return value
    },
    currency: function (value) {
      if (!value) return ''
      value = config.i18n.currencySign + value
      return value
    }
  },
  methods: {
    editLocation () {
      this.$bus.$emit('modal-show', 'modal-edit-location')
    },
    enableLocation () {
      this.$bus.$emit('notification-progress-start', 'Enabling location...')
      this.$store.dispatch('updateLocation', { data: { isActive: true }, locationId: this.location.id })
        .then(({data}) => {
          if (data.code === 200) {
            this.location = data.result.data.location
            this.$store.dispatch('fetchSingleUserDetails', { userId: this.userId, updateState: true })
              .then(({data}) => {
                this.$bus.$emit('notification-progress-stop')
                this.$bus.$emit('notification', {
                  type: 'success',
                  message: 'Location has been enabled',
                  action1: { label: 'OK', action: 'close' }
                })
              })
          }
        })
        .catch(err => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = err.message
          if (err.response && err.response.data.result) {
            errorMessage = err.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    cancelDisableLocation () {
      this.$bus.$emit('modal-hide', 'disable-location-modal')
    },
    disableLocation () {
      this.$bus.$emit('notification-progress-start', 'Disabling location...')
      this.$store.dispatch('updateLocation', { data: { isActive: false }, locationId: this.location.id })
        .then(({data}) => {
          if (data.code === 200) {
            this.location = data.result.data.location
            this.$store.dispatch('fetchSingleUserDetails', { userId: this.userId, updateState: true })
              .then(({data}) => {
                this.$bus.$emit('modal-hide', 'disable-location-modal')
                this.$bus.$emit('notification-progress-stop')
                this.$bus.$emit('notification', {
                  type: 'success',
                  message: 'Location has been disabled',
                  action1: { label: 'OK', action: 'close' }
                })
              })
          }
        })
        .catch(err => {
          this.$bus.$emit('notification-progress-stop')
          let errorMessage = err.message
          if (err.response && err.response.data.result) {
            errorMessage = err.response.data.result
          }
          this.$bus.$emit('notification', {
            type: 'error',
            message: errorMessage,
            action1: { label: 'OK', action: 'close' }
          })
        })
    },
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YYYY')
    },
    orderTotal (orderId) {
      let order = this.orders[orderId]
      let total = _.sumBy(order.products, product => {
        return product.qty * product.price
      })
      return total
    },
    showDisableLocationModal () {
      this.$bus.$emit('modal-show', 'disable-location-modal')
    }
  },
  computed: {
    totalOrdersAmount () {
      if (!(this.location && this.location.orders)) {
        return 0
      }
      let totalOrdersAmount = _.sumBy(this.location.orders, order => {
        return _.sumBy(order.products, product => {
          return product.qty * product.price
        })
      })
      return totalOrdersAmount
    },
    averageOrderValue () {
      if (!(this.location && this.location.orders)) {
        return 0
      }
      let totalOrdersAmount = _.sumBy(this.location.orders, order => {
        return _.sumBy(order.products, product => {
          return product.qty * product.price
        })
      })
      let averageOrderValue = totalOrdersAmount / this.location.orders.length
      return averageOrderValue
    },
    users () {
      return this.location && this.location.users ? this.location.users : []
    },
    userId () {
      return this.$store.state.userDetails && this.$store.state.userDetails.userId
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-success: color(gloo-success);
  $color-error: color(gloo-danger);
  $color-completed: color(gloo-completed);
  $color-failed: color(gloo-failed);
  $color-gloo-main: color(gloo-main);
  $color-gloo-text: color(gloo-text);
  $color-white: color(white);

  .margin-auto {
    margin: auto;
  }

  #location-name {
    display: inline-block;
    font-size: 30px;
    font-weight: 550;
    line-height: 36px;
  }

  .location-status-chip {
    font-size: 12px;
    font-weight: 450;
    letter-spacing: 0.21px;
    line-height: 12px;
    text-align: center !important;
    align-items: center;
    border-radius: 28px;
    display: inline-flex;
    vertical-align: middle;
    justify-content: space-between;
    padding: 10px 25px;
    height: 19px;
    align-content: center;
    margin: auto 15px;
  }

  .active {
    background-color: $color-completed;
    color: $color-success;
  }

  .inactive {
    background-color: $color-failed;
    color: $color-error;
  }

  .location-actions.v-btn {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 17px;
  }

  p.location-actions {
    margin: auto 15px;
    font-size: 15px;
    font-weight: 550;
    line-height: 17px;
    cursor: pointer;
  }

  p#disable-location {
    color: $color-error;
  }

  p#enable-location {
    color: $color-gloo-main;
  }

  .details-wrapper {
    padding: 10px 10px 0 !important;
  }

  #location-details {
    .v-card, .v-tabs {
      margin-top: 20px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    p span {
      position: relative;
      top: -8px;
      color: grey;
    }

    .details-values p {
      color: $color-gloo-text;
      position: relative;
      top: -13px;
      font-size: 16px;
      line-height: 32px;
    }
  }

  .icons {
    padding: 0 15px;
  }

  .location-status-icon {
    border-radius: 50%;
    font-size: 20px;
    margin-right: 5px;
  }

  #details {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0 !important;
    margin-top: 10px !important;

    > .v-card__title {
      padding: 30px !important;
    }

    .section-title {
      font-size: 16px;
      font-weight: 550;
      line-height: 19px;
      text-transform: capitalize;
    }
  }

  .table-title {
    margin-bottom: 30px;
  }

  #location-tab {
    .table-title {
      margin-top: 50px;
      margin-bottom: 0;
      padding: 20px 25px !important;
    }
    .section-title {
      font-size: 21px;
      font-weight: 500;
      line-height: 25px
    }
  }

  #report {
    padding-left: 0;
    padding-right: 0;
  }

  #details,
  #order-statistics {
    margin: 60px 0;
  }

  #report-wrapper {
    text-align: center;
    margin: 50px 0;
    // width: 80%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .reports-block {
    display: inline-block;
    min-width: 140px;
    color: color(white);
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    margin: auto;
    @media (max-width: 767px) {
      width: 100%;
      padding: 40px 0;
      margin: 15px 0px;
    }
  }

  .reports-block:nth-child(2) {
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: #d7d7e0;
  }

  p.reports-block--value, p.reports-description {
    margin: 0;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: 500;
  }

  p.reports-description {
    margin-bottom: 16px;
    color: #7d84a0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  p.reports-block--value {
    color: #353F69;
    font-size: 32px;
    font-weight: 505;
    line-height: 38px;
  }

  #reports-summary {
    p {
      color: white;
      font-weight: 800;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .reports-description {
      font-size: 20px;
      margin-left: 5px;
    }
    .reports-value {
      margin-left: 5px;
    }
  }

  #purchase-graph {
    padding: 0 30px 30px;
  }

  .disable-location-modal-content {
    padding: 20px;
    overflow-y: auto;
    .v-card {
      box-shadow: none;
    }
    .rejection {
      margin: 10px auto;
      width:  100%;
    }
  }

  .order-status {
    text-transform: uppercase;
  }

  .user-status-chip, .order-status-chip {
    letter-spacing: 0.21px;
    line-height: 12px;
    width: 120px;
    text-align: center !important;
    align-items: center;
    border-radius: 28px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0 38px;
    height: 32px;
    margin: auto;
    align-content: center;
  }

  #approved-order {
    background-color: $color-completed;
    color: $color-success;
    padding: 0 25px;
  }
  .active {
    background-color: $color-completed;
    color: $color-success;
  }

  .inactive {
    background-color: $color-failed;
    color: $color-error;
  }

  .capitalized {
    text-transform: capitalize;
  }

  header.modal-header {
    border-bottom: 1px solid #e9ebf6;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 0.23px;
    line-height: 24px;
  }

  .modal-close.material-icons {
    font-size: 26px;
    padding: 20px 30px 15px;
  }

  #do-not-disable.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
}

.confirmation-text--wrapper {
  padding: 20px !important;
}

  @media (max-width: 767px) {
    #location-name-holder {
      text-align: center;
    }
    #location-actions-holder {
      margin-top: 20px;
      justify-content: center !important;
    }
    #page-wrap {
      padding-top: 20px;
    }
  }
</style>
