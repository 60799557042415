import config from 'config'
import store from 'core/store'
import apiService from 'theme/services/api_service'

const actions = {
  resumeGoogleSSOSession: function ({ commit }, sessionId) {
    let endpoint = `${config.ecms.resumeGoogleSSOSession_endpoint}${sessionId}`
    return apiService.send(endpoint, 'get')
      .then((response) => {
        if (response.data.code === 200) {
          store.commit('setUserToken', response.data.result ? response.data.result.jwToken : null)
          return response
        }
      })
  }
}

export default {
  actions
}
