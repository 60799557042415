<template>
  <div class="header bg-cl-primary">
    <!-- <dashboard-menu /> -->
    <header
      class=" w-100 align-center"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="container p0 h-100">
        <div class="nav row">
          <div class="logo-categories col-lg-4 col-md-3 col-sm-3 col-xs-4 row header-item--wrapper">
            <div class="col-sm-6 h-100 hamburger-icon header-item">
              <template v-if="!isProductPage" class="hidden-md">
                <hamburger-icon class="icon pointer" v-if="!isProductPage"/>
              </template>
            </div>
            <!-- <li class="logo" v-if="isProductPage">
              <logo width height="27px"/>
            </li> -->
            <!-- <li class="logo" v-else-if="isDashboardPage">
              <span class="page-title">Dashboard</span>
            </li> -->
            <div class="logo header-item col-sm-6">
              <logo width height="27px"/>
            </div>
            <div class="h-100 hidden-xs categories header-item col-sm-6">
              <div class="product-categories-parent p10">
                <span class="black-text product-categories embolden m0 cl-gloo-gray">Product Categories</span>
              </div>
              <template v-if="!isProductPage">
                <hamburger-icon class="icon pointer" v-if="!isProductPage"/>
              </template>
              <template v-else>
                <return-icon class="p15 icon pointer" v-if="isProductPage"/>
              </template>
            </div>
          </div>
          <div class="details col-lg-8 col-md-9 col-sm-9 col-xs-8 row header-item--wrapper">
            <div class="search-field col-lg-6 col-md-7 col-sm-6 col-xs-3 header-item">
              <search-icon id="search-icon" class="icon pointer" />
            </div>
            <div class="cart col-lg-1 col-md-1 col-sm-1 col-xs-3 header-item">
              <microcart-icon class="p0 icon" />
            </div>
            <div class="user col-lg-4 col-md-4 col-sm-5 col-xs-6 h-100 header-item row">
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-3 margin-auto">
                <div class="company-first-letter">
                  <span class="black-text embolden m0 cl-gloo-gray">{{ (companyDetails && companyDetails.name) ? companyDetails.name.charAt(0) : '' }}</span>
                </div>
              </div>
              <div class="col-lg-10 col-md-9 col-sm-10 col-xs-9 company header-item--wrapper align-left row">
                <div class="col-md-11 col-sm-10 margin-auto">
                  <div class="col-sm-12 margin-auto">
                    <div class="company-name embolden pr-0 pl-0 cl-gloo-gray align-left header-item company-name">{{ ((companyDetails && companyDetails.name) ? companyDetails.name : '') | truncate(17) }}</div>
                  </div>
                  <div class="col-sm-12 margin-auto">
                    <div class="user-name pr-0 pl-0 header-item">
                      <span class="cl-gloo-section-header-text lighter">{{ (user ? (user.firstName + ' ' + user.lastName) : '') | truncate(17) }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 col-sm-2 margin-auto dropdown-icon_wrapper">
                  <account-icon class="icon hidden-xs pointer embolden align-left" />
                </div>
              </div>
              <!-- <account-icon class="col-sm-2 icon hidden-xs pointer embolden align-left" /> -->
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from 'core/components/blocks/Header/Header'

import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon'
import CompareIcon from 'theme/components/core/blocks/Header/CompareIcon'
import HamburgerIcon from 'theme/components/core/blocks/Header/HamburgerIcon'
import Logo from 'theme/components/core/Logo'
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon'
import ReturnIcon from 'theme/components/core/blocks/Header/ReturnIcon'
import SearchIcon from 'theme/components/core/blocks/Header/SearchIcon'
// import WishlistIcon from 'theme/components/core/blocks/Header/WishlistIcon'
// import DashboardMenu from 'theme/components/core/blocks/Dashboard/DashboardMenu'

export default {
  components: {
    AccountIcon,
    CompareIcon,
    HamburgerIcon,
    Logo,
    MicrocartIcon,
    ReturnIcon,
    SearchIcon
    // ,
    // WishlistIcon
    // ,
    // DashboardMenu
  },
  mixins: [Header],
  data () {
    return {
      productPageRoutes: [
        'product',
        'simple-product',
        'configurable-product',
        'downloadable-product',
        'grouped-product'
      ],
      dashboardPageRoutes: [
        'dashboard',
        'order-detail',
        'company-settings',
        'general-settings',
        'location-settings',
        'add-location',
        'single-location-settings',
        'edit-location',
        'catalog',
        'users',
        'single-user',
        'single-user-assign-permissions',
        'edit-user',
        'my-account',
        'my-shipping-details',
        'my-order',
        'my-orders',
        'order-approvals',
        'authorization-settings'
      ],
      isCheckout: false,
      isProductPage: false,
      isDashboardPage: false,
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 70,
      userId: this.$store.state.userDetails.userId
    }
  },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      companyDetails: state => state.userDetails.companyDetails,
      user () {
        if (this.userId) {
          return this.$store.getters.getUserDetails(this.userId)
        }
      }
    })
  },
  beforeCreated () {
    if (this.productPageRoutes.includes(this.$route.name)) {
      this.isProductPage = true
    }

    if (this.dashboardPageRoutes.includes(this.$route.name)) {
      this.isDashboardPage = true
    }
  },
  created () {
    if (this.$route.name === 'checkout') {
      this.isCheckout = true
    }

    if (this.dashboardPageRoutes.includes(this.$route.name)) {
      this.isDashboardPage = true
    }

    let companyDetails = this.$store.getters.getCompanyDetails
    this.$store.dispatch('fetchCompanyUsers', companyDetails.id)
  },
  beforeMount () {
    window.addEventListener('scroll', () => {
      this.isScrolling = true
    })

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  watch: {
    '$route.name': function () {
      if (this.productPageRoutes.includes(this.$route.name)) {
        this.isProductPage = true
      } else {
        this.isProductPage = false
      }

      if (this.dashboardPageRoutes.includes(this.$route.name)) {
        this.isDashboardPage = true
      } else {
        this.isDashboardPage = false
      }

      if (this.$route.name === 'checkout') {
        this.isCheckout = true
        this.menuFixed = true
      } else {
        this.isCheckout = false
        this.menuFixed = false
      }
    }
  },
  methods: {
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (this.scrollTop > this.lastScrollTop && this.scrollTop > this.navbarHeight) {
        this.navVisible = false
      } else {
        this.navVisible = true
      }
      this.lastScrollTop = this.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon-hover: color(tertiary, $colors-background);
$color-primary: color(primary);
$color-secondary: color(secondary);
$color-nav-item-border: color(gloo-nav-border);
$gloo-solitude: color(gloo-solitude);
$icon: color(gloo-catalina-blue);

.p0 {
  padding: 0px !important;
}
.m0 {
  margin: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.h-100 {
  height: 100%;
}
header {
  height: 70px;
  box-shadow: 7px 0 6px 0 rgba(195, 203, 222, 0.59);
  transition: top 0.2s ease-in-out;
  &.is-visible {
    top: 0;
  }
}
.container {
    min-width: 90% !important;
  }
.header-item--wrapper {
  padding: 0;
  list-style: none;
}
.nav {
  height: 70px;
}
.nav, .company, .logo-categories {
  display: flex;
}
.nav {
  align-items: center;
  height: 70px;
  justify-content: space-between;
}
.logo-categories {
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.company {
  width: 100%;
  justify-content: center;
  padding-right: 0;
}
.company-first-letter, .company, .account-icon {
  padding: 10px;
}
.company-first-letter {
  background: $gloo-solitude;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  padding: 12px 10px;
}
.nav .header-item {
  cursor: pointer;
  display: inline-flex;
  // padding-right: 30px;
  // padding-left: 30px;
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 1263px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.cart.header-item {
  margin: 0 30px;

  @media (max-width: 1199px) {
    margin: 0px;
  }
}
.search {
  min-width: 80%;
}
#search-icon {
  margin: auto;
}
.cart {
  height: 100%;
  border-right: 1px solid rgba(61,87,170,0.15);
  border-left: 1px solid rgba(61,87,170,0.15);
}
.margin-auto {
  margin: auto;
}
.dropdown-icon_wrapper {
  padding: 0;
}
.black-text {
  display: inline-block;
  margin-top: 25%;
}
.product-categories-parent {
  text-align: center;
  white-space: nowrap;
}
.product-categories-parent:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.product-categories {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  // font-weight: 500px;
  letter-spacing: 0.05px;
  line-height: 19px;
}

input {
  border: none !important;
}
.embolden {
  font-weight: bold;
}
.details {
  height: 100%;
  display: flex;
  align-items: center;
}
.hamburger-icon {
  display: none !important;
}
.logo {
  margin-left: -20px;
}
.links {
  text-decoration: underline;
}

@media (max-width: 1160px) {
  .hamburger-icon {
    display: initial !important;
  }
  .categories {
    display: none !important;
  }
}
@media (max-width: 1080px) {
  a, span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }

  .col-xs-2:first-of-type {
      padding-left: 0;
  }

  .col-xs-2:last-of-type {
      padding-right: 0;
  }

  a, span {
    font-size: 12px;
  }

  .logo-categories.header-item--wrapper {
    max-width: 60%;
    flex-basis: 60%;

    .hamburger-icon.header-item {
      max-width: 40%;
      flex-basis: 40%;
    }

    .logo.header-item {
      max-width: 60%;
      flex-basis: 60%;
    }
  }
  .details.header-item--wrapper {
    max-width: 40%;
    flex-basis: 40%;
  }
  .user.header-item {
    display: none;
  }
  .search-field.header-item, .cart.header-item{
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (max-width: 420px) {
  .nav .header-item {
    padding-right: 1.2em;
    padding-left: 1.2em;
  }
}
.lighter {
  color: #253858;
}
.company-name {
  display: -webkit-box !important;
  width: 100%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
</style>
