<template>
  <button
    type="button"
    class="relative bg-cl-transparent brdr-none inline-flex cart-icon-btn"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <img src="/assets/shopping-cart.svg">

    <span
      class="minicart-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-500 cl-white bg-cl-silver"
      v-cloak
      v-if="totalQuantity"
      data-testid="minicartCount"
      :style="{ fontSize: totalQuantity >= 1000 ? '10px' : '12px' }"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    >
      {{ displayQuantity }}
      <span v-if="totalQuantity > 99" class="total-count-tooltip">
        {{ totalQuantity }}
      </span>
    </span>
    <span
      v-if="totalQuantity > 99"
      class="plus absolute flex center-xs middle-xs border-box py0 px2 weight-500"
      :style="{ fontSize: '20px' }"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    >
      +
    </span>
  </button>
</template>

<script>
import MicrocartIcon from 'core/components/blocks/Header/MicrocartIcon'

export default {
  mixins: [MicrocartIcon],
  data () {
    return {
      showTotalCount: false
    }
  },
  computed: {
    displayQuantity () {
      if (this.totalQuantity === undefined || this.totalQuantity === null) {
        return 0
      } else {
        return this.totalQuantity < 100 ? this.totalQuantity : 99
      }
    }
  },
  methods: {
    showTooltip () {
      this.showTotalCount = true
    },
    hideTooltip () {
      this.showTotalCount = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

.cart-icon-btn {
  margin: auto;
}

.minicart-count {
  top: -11px;
  left: 50%;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color: color(gloo-secondary);
}

.plus {
  top: -19px;
  right: -18px;
  color: #bc0933;
  z-index: 1;
}

.minicart-count:hover .total-count-tooltip {
  display: block;
}

.total-count-tooltip {
  display: none;
  position: absolute;
  top: 6px;
  right: -43px;
  background-color: color(gloo-secondary);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}
</style>
