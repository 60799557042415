<template>
  <v-progress-linear color="success" :indeterminate="true" v-if="!selectedUser" />
  <div id="user-details" v-else>
    <!-- Username and User Action Buttons Section -->
    <div>
      <div id="page-wrap" class="row pb20 px10">
        <div id="username-holder" class="col-md-8 col-sm-6 col-xs-12 margin-auto">
          <h3 id="username">{{ selectedUser && selectedUser.firstName }} {{ selectedUser && selectedUser.lastName }}</h3>
        </div>
        <div id="user-actions-holder" class="col-md-4 col-sm-6 col-xs-12 buttons" v-if="hasPermission('edit', 'user')">
          <v-btn large color="success" class="bg-cl-mine-shaft :bg-cl-th-secondary user-actions" @click="editUser">Edit user</v-btn>
          <p v-if="selectedUser && selectedUser.isActive" id="disable-user" class="bg-cl-mine-shaft :bg-cl-th-secondary user-actions" @click="showDisableUserModal">Disable user</p>
          <p v-else id="enable-user" class="bg-cl-mine-shaft :bg-cl-th-secondary user-actions" @click="enableUser">Enable user</p>
        </div>
      </div>
    </div>

    <!-- Personal Details Section -->
    <v-card>
      <div id="details" class="panel panel-default pad-panel single-section">
        <v-card-title>
          <span class="section-title">Personal Details</span>
        </v-card-title>
        <div class="details-wrapper row">
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Name</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ selectedUser && selectedUser.firstName }} {{ selectedUser && selectedUser.lastName }}</p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Location</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ selectedUserLocation && selectedUserLocation.name }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 row">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Email</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p>{{ selectedUser && selectedUser.emailAddress }}</p>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <p>
                  <span class="ml5">Phone Number</span>
                </p>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-12 details-values">
                <p v-if="selectedUser && selectedUser.phoneNumber">{{ selectedUser && selectedUser.phoneNumber }}</p>
                <p v-else>Nil</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <!-- User's Locations and Permissions Tabs Section -->
    <v-tabs
      id="user-tab"
      slider-color="success"
    >
      <v-tab ripple class="tablink">
        Locations
      </v-tab>
      <v-tab ripple class="tablink">
        Permissions
      </v-tab>

      <!-- User's Assigned Locations Tab Pane -->
      <v-tab-item>
        <div class="panel" id="user-locations--wrapper" :class="{hidden: editingLocations }">
          <div id="user-locations">
            <div>
              <div class="pl0">
                <span class="section-title">Assigned Locations</span>
              </div>
            </div>
            <v-progress-linear :indeterminate="true" v-if="!deliveryLocations" color="success" />
            <div class="row pt20" v-else>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 delivery-address" v-for="deliveryLocation in deliveryLocations" :key="deliveryLocation.id">
                <h4>{{ deliveryLocation.name }}</h4>
                <p>{{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.streetAddress }}</p>
                <p v-if="deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.addressLine2">{{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.addressLine2 }}</p>
                <p>
                  {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.city }}&nbsp;
                  {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.zipcode }}
                </p>
                <p>
                  {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.state }},&nbsp;
                  {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.country }}
                </p>
              </div>
            </div>
          </div>
          <v-card-title class="tab-btn-holder">
            <div v-if="deliveryLocations && hasPermission('edit', 'user')">
              <v-btn large outline color="gray" class="edit" @click="toggleEditLocations(true)">Edit Locations</v-btn>
            </div>
          </v-card-title>
        </div>

        <!-- Edit Assigned Locations Tab Pane -->
        <!-- To be hidden unless the "Edit Locations Button is clicked" -->
        <div class="panel" id="edit-assigned--locations-wrapper" :class="{hidden: !editingLocations }">
          <div id="edit-assigned--locations">
            <div class="row">
              <div class="pl0 col-lg-8 col-md-8 col-sm-6 col-xs-12 margin-auto pb20">
                <span class="section-title">Assigned Locations</span>
              </div>
              <!-- Search field -->
              <div class="pl10 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="external-search--box">
                  <input type="text" placeholder="Search locations" class="search-box" v-model="searchText">
                  <i class="material-icons" aria-hidden="true">search</i>
                </div>
              </div>
            </div>
            <!-- List of available company locations -->
            <div id="edit-assigned-locations">
              <div class="edit-assigned--locations-content">
                <div id="assign-locations" class="row pt20">
                  <template v-if="filteredCompanyLocations.length > 0">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 py15 delivery-address" v-for="deliveryLocation in filteredCompanyLocations" :key="deliveryLocation.id">
                      <div class="row">
                        <div class="col-xl-1 col-md-2 col-sm-2 col-xs-2">
                          <v-checkbox
                            :value="deliveryLocation.id"
                            v-if="selectedUserLocation && selectedUserLocation.id === deliveryLocation.id"
                            v-model="deliveryLocation.id"
                            :disabled="true"
                          />
                          <v-checkbox
                            :value="deliveryLocation.id"
                            v-model="permittedDeliveryLocationsId"
                            v-else
                            color="success"
                          />
                        </div>
                        <div class="col-xl-11 col-md-10 col-sm-10 col-xs-10">
                          <h4>{{ deliveryLocation.name }}</h4>
                          <p>{{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.streetAddress }}</p>
                          <p v-if="deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.addressLine2">{{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.addressLine2 }}</p>
                          <p>
                            {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.city }}&nbsp;
                            {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.zipcode }}
                          </p>
                          <p>
                            {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.state }}&nbsp;
                            {{ deliveryLocation && deliveryLocation.locationAddressId && deliveryLocation.locationAddressId.country }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="col-xs pt20 pl0" v-else>
                    <p class="details py15">No location found</p>
                  </div>
                </div>
                <!-- Select all available locations options -->
                <div class="col-xs-12">
                  <v-checkbox
                    :label="selectAll ? 'Unselect all' : 'Select all'"
                    v-model="selectAll"
                    :value="true"
                    color="success"
                  />
                </div>
                <!-- <div class="row" id="locations-count">
                  <div class="col-md-12 pt20 align-right pt10">
                    <p class="mb10">{{ permittedDeliveryLocationsId.length }} of {{ companyLocations.length }} locations</p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <v-card-title class="tab-btn-holder">
            <div v-if="deliveryLocations && hasPermission('edit', 'user')">
              <v-btn large outline color="gray" class="edit" @click="updateUserAssignedLocations">Assign {{ permittedDeliveryLocationsId.length }} {{ permittedDeliveryLocationsId.length > 1 ? 'locations' : 'location' }}</v-btn>
              <v-btn large outline color="gray" @click="toggleEditLocations(false)" class="previous-link">
                <i class="material-icons">keyboard_backspace</i>Go Back
              </v-btn>
            </div>
          </v-card-title>
        </div>
      </v-tab-item>

      <!-- User's Permissions Tab Pane -->
      <v-tab-item>
        <div class="panel" id="user-permissions--wrapper" :class="{ hidden: editingPermissions }">
          <div id="user-permissions">
            <div>
              <div class="pl0">
                <span class="section-title">Permissions</span>
              </div>
            </div>
            <v-progress-linear :indeterminate="true" v-if="!permissions" color="success" />
            <div class="pt30" v-else-if="permissions.length > 0">
              <div class="row permissions">
                <p class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(permission, index) in permissions" :key="index" >{{ permission.name }}</p>
              </div>
            </div>
            <div class="col-xs pt20 pl0" v-else>
              <p class="details py15">No permission found</p>
            </div>
          </div>
          <v-card-title class="tab-btn-holder">
            <div v-if="permissions && hasPermission('edit', 'user')">
              <v-btn large outline color="gray" class="edit" @click="toggleEditPermissions(true)">Edit Permissions</v-btn>
            </div>
          </v-card-title>
        </div>

        <!-- Edit Permissions Tab -->
        <div id="edit-user--permissions-wrapper" :class="{ hidden: !editingPermissions }">
          <div id="edit-user--permissions">
            <div class="permissions col-xs-12">
              <assign-single-user-permissions />
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>

    <!-- Edit User Component -->
    <edit-user :user="selectedUser" v-if="selectedUser" />

    <!-- Disable User Modal -->
    <div id="disable-user-modal">
      <modal name="disable-user-modal" :width="500">
        <header class="modal-header py25 h3 weight-700">
          <i
            slot="close"
            class="modal-close material-icons p15 cl-bg-black"
            @click="cancelDisableUser"
          >
            close
          </i>
          {{ 'Disable user' }}
        </header>
        <div class="disable-user-modal-content">
          <v-card>
            <v-card-title class="confirmation-text--wrapper">
              <p>Are you sure you want to disable this user?</p>
            </v-card-title>
            <v-card-actions class="row">
              <div class="col-sm-6 col-xs-6">
                <v-btn large color="error" class="rejection" id="diasble" @click="disableUser()">Yes</v-btn>
              </div>
              <div class="col-sm-6 col-xs-6">
                <v-btn large class="rejection" id="do-not-disable" @click="cancelDisableUser()">No</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import Modal from 'theme/components/core/Modal.vue'
import _ from 'lodash'
import EditUser from './EditUser'
import AssignSingleUserPermissions from './AssignSingleUserPermissions'

export default {
  name: 'SingleUser',
  components: {
    Modal,
    EditUser,
    AssignSingleUserPermissions
  },
  data () {
    return {
      selectedUserLocation: null,
      permissions: null,
      deliveryLocations: null,
      permittedDeliveryLocationsId: [],
      companyLocations: [],
      searchText: '',
      filteredCompanyLocations: [],
      selectAll: false,
      editingPermissions: false,
      editingLocations: false
    }
  },
  computed: {
    usesPunchout () {
      return this.$store.state.userDetails.companyDetails && this.$store.state.userDetails.companyDetails.punchoutActive
    },
    selectedUser () {
      let userId = this.$route.params['userId']
      return this.$store.getters.getUserDetails(userId)
    }
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('view', 'user')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  },
  watch: {
    selectAll: function (val) {
      if (val === true) {
        let tempSet = new Set(this.permittedDeliveryLocationsId)
        _.each(this.filteredCompanyLocations, o => {
          if (o.id !== this.selectedUserLocation.id) {
            tempSet.add(o.id)
          }
          this.permittedDeliveryLocationsId = Array.from(tempSet)
        })
      } else {
        let tempSet = new Set(this.permittedDeliveryLocationsId)
        _.each(this.filteredCompanyLocations, o => {
          tempSet.delete(o.id)
          this.permittedDeliveryLocationsId = Array.from(tempSet)
        })
      }
      this.selectAll = val
    },
    searchText: function (val) {
      let filteredSet = new Set()
      val = val.trim().toLowerCase()
      this.filteredCompanyLocations = _.filter(this.companyLocations, o => {
        if (o.name.toLowerCase().indexOf(val) > -1) {
          filteredSet.add(o)
          return
        }
        if (o.locationAddressId.streetAddress.toLowerCase().indexOf(val) > -1 ||
          o.locationAddressId.addressLine2.toLowerCase().indexOf(val) > -1 ||
          o.locationAddressId.city.toLowerCase().indexOf(val) > -1 ||
          o.locationAddressId.state.toLowerCase().indexOf(val) > -1) {
          filteredSet.add(o)
        }
      })
      this.filteredCompanyLocations = Array.from(filteredSet)
      this.selectAll = false
    },
    selectedUser: function (val) {
      if (val !== null) {
        this.fetchUserDetails()
      }
    }
  },
  methods: {
    updateUserAssignedLocations () {
      let toBeUpdated = []
      _.each(this.companyLocations, o => {
        if (o.id !== this.selectedUserLocation.id) {
          let isIncluded = _.find(this.permittedDeliveryLocationsId, p => {
            return o.id === p
          })
          let data = {
            locationId: o.id,
            assign: isIncluded ? 1 : 0
          }
          toBeUpdated.push(data)
        }
      })
      this.$bus.$emit('notification-progress-start', 'Updating assigned locations ...')
      this.$store.dispatch('updateUser', {
        userId: this.selectedUser.id,
        userDeliveryLocations: toBeUpdated
      })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'Operation successful',
              action1: { label: 'OK', action: 'close' }
            })
            this.$store.dispatch('fetchSingleUserDetails', {
              userId: data.result.data.user.id,
              updateState: false
            })
            this.deliveryLocations = data.result.data.user.deliveryLocations
            this.toggleEditLocations(false)
          }
          this.$bus.$emit('notification-progress-stop')
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while saving details',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    editUser () {
      this.$bus.$emit('modal-show', 'modal-edit-user')
    },
    managePermissions () {
      this.$router.push(`/company-settings/users/${this.selectedUser.id}/assign-permissions`)
    },
    enableUser () {
      this.$bus.$emit('notification-progress-start', 'Enabling user account ...')
      this.$store.dispatch('updateUser', {
        userId: this.selectedUser.id,
        isActive: true
      })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'User has been enabled successfully',
              action1: { label: 'OK', action: 'close' }
            })
            let user = data.result.data.user
            this.$store.commit('setCompanyUserActiveStatus', { userId: user.id, status: user.isActive })
            // this.$store.dispatch('setCompanyUsers', null) // clear the users so to force refresh on next retrieval
            this.cancelDisableUser()
          }
          this.$bus.$emit('notification-progress-stop')
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while enabling user',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    disableUser () {
      this.$bus.$emit('notification-progress-start', 'Disabling user account ...')
      this.$store.dispatch('updateUser', {
        userId: this.selectedUser.id,
        isActive: false
      })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$bus.$emit('notification', {
              type: 'success',
              message: 'User has been disabled successfully',
              action1: { label: 'OK', action: 'close' }
            })
            let user = data.result.data.user
            this.$store.commit('setCompanyUserActiveStatus', { userId: user.id, status: user.isActive })
            // this.$store.dispatch('setCompanyUsers', null) // clear the users so to force refresh on next retrieval
            this.cancelDisableUser()
          }
          this.$bus.$emit('notification-progress-stop')
        })
        .catch(error => {
          this.$bus.$emit('notification-progress-stop')
          console.error(error)
          if (error.response && error.response.data) {
            this.$bus.$emit('notification', {
              type: 'error',
              message: error.response.data.code === 500 ? error.response.data.result.message : error.response.data,
              action1: { label: 'OK', action: 'close' }
            })
          } else {
            this.$bus.$emit('notification', {
              type: 'error',
              message: 'An error occured while disabling user',
              action1: { label: 'OK', action: 'close' }
            })
          }
        })
    },
    showDisableUserModal () {
      this.$bus.$emit('modal-show', 'disable-user-modal')
    },
    cancelDisableUser () {
      this.$bus.$emit('modal-hide', 'disable-user-modal')
    },
    fetchUserDetails () {
      let companyUsers = this.$store.state.userDetails.companyUsers
      this.companyLocations = this.$store.state.userDetails.companyDetails.locations
      this.filteredCompanyLocations = this.$store.state.userDetails.companyDetails.locations
      if (companyUsers) {
        // get location of user
        if (this.selectedUser && this.selectedUser.emailAddress) {
          let self = this
          this.$store.dispatch('fetchSingleUserDetails', { userId: this.selectedUser.id, updateState: false })
            .then(({ data }) => {
              if (data.code === 200) {
                self.selectedUserLocation = data.result.data.user && data.result.data.user.location
                self.permissions = data.result.data.user.permissions
                self.deliveryLocations = data.result.data.user.deliveryLocations
                self.permittedDeliveryLocationsId = []
                _.each(self.deliveryLocations, o => {
                  self.permittedDeliveryLocationsId.push(o.id)
                })
              }
            })
        }
      }
    },
    toggleEditPermissions (status) {
      this.editingPermissions = status
    },
    toggleEditLocations (status) {
      this.editingLocations = status
    }
  },
  beforeMount () {
    this.$bus.$on('user-updated', this.fetchUserDetails)
    this.$bus.$on('editing-permissions', this.toggleEditPermissions)
    this.$bus.$on('editing-locations', this.toggleEditLocations)
  },
  beforeDestroy () {
    this.$bus.$off('user-updated', this.fetchUserDetails)
    this.$bus.$off('editing-permissions', this.toggleEditPermissions)
    this.$bus.$off('editing-locations', this.toggleEditLocations)
  },
  created () {
    let companyDetails = this.$store.getters.getCompanyDetails
    this.$store.dispatch('fetchCompanyUsers', companyDetails.id)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-gloo-gray: color(gloo-gray);
$color-gloo-main: color(gloo-main);
$color-gloo-text: color(gloo-text);
$color-success: color(gloo-success);
$color-error: color(gloo-danger);

.margin-auto {
  margin: auto;
}

#username {
  display: inline-block;
  font-size: 30px;
  font-weight: 550;
  line-height: 36px;
}

.user-actions.v-btn {
  font-size: 15px !important;
  font-weight: 600;
  line-height: 17px;
}

p.user-actions {
  margin: auto 15px;
  font-size: 15px;
  font-weight: 550;
  line-height: 17px;
  cursor: pointer;
}

p#disable-user {
  color: $color-error;
}

p#enable-user {
  color: $color-gloo-main;
}

.details-wrapper {
  padding: 10px 10px 0 !important;
}

#user-details {
  .v-card, .v-tabs {
    margin-top: 20px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  p span {
    position: relative;
    top: -8px;
    color: grey;
  }

  .details-values p {
    color: $color-gloo-text;
    position: relative;
    top: -13px;
    font-size: 16px;
    line-height: 32px;
  }
}

#details {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0 !important;
  margin-top: 10px !important;

  > .v-card__title {
    padding: 30px !important;
  }

  .section-title {
    font-size: 16px;
    font-weight: 550;
    line-height: 19px;
    text-transform: capitalize;
  }
}

.table-title {
  margin-bottom: 30px;
}

#user-tab {
  .table-title {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 20px 25px !important;
  }
}

.tab-btn-holder {
  border: 1px solid #dfe3e8;
  background-color: #f9fafb;
}

.v-btn.edit {
  margin: 0;
}

.search-box {
  width: 100%;
}

.single-section,
#user-permissions, #user-locations, #edit-assigned--locations {
  margin: 50px 0 0;
  padding: 30px;
}

.previous-link {
  i {
    margin-right: 5px;
    font-size: 14px;
  }
}

.details, .details-title, .permissions {
  color: $color-gloo-gray;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

.disabled-text {
  font-size: bold;
  color: red;
}

.align-right {
  text-align: right;
}

.delivery-address {
  margin-bottom: 20px;
  h4 {
    color: $color-gloo-text;
    margin-top: 10px;
    line-height: 2;
    font-weight: 550;
  }
  p {
    color: $color-gloo-text;
    line-height: 28px;
    margin-bottom: 0;
    font-size: 16px;
  }
}

#user-locations .delivery-address {
  padding: 0;
}

.edit-assigned--locations-content {
  h3 {
    text-align: center;
  }
}

// #locations-count {
//   margin-top: 20px !important;

//   .pt10 {
//     padding-top: 10px !important;
//   }
// }

#disable-user-modal {

  .disable-user-modal-content {
    padding: 20px;
    overflow-y: auto;

    .v-card {
      box-shadow: none;
    }

    .rejection {
      margin: 10px auto;
      width: 100%;
    }

  }

}

header.modal-header {
  border-bottom: 1px solid #e9ebf6;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 0.23px;
  line-height: 24px;
}

.modal-close.material-icons {
  font-size: 26px;
  padding: 20px 30px 15px;
}

#do-not-disable.v-btn {
  background-color: $color-gloo-main !important;
  color: $color-white;
}

.confirmation-text--wrapper {
  padding: 20px !important;
}

#edit-assigned--locations-wrapper .tab-btn-holder .v-btn--large {
  @media (max-width: 449px) {
    padding: 0 16px !important;
  }
  @media (max-width: 348px) {
    padding: 0 10px !important;
  }
  @media (max-width: 360px) {
    padding: 0 8px !important;
  }
}

@media (max-width: 767px) {

  #username-holder {
    text-align: center;
  }

  #user-actions-holder {
    margin-top: 20px;
    justify-content: center !important;
  }

  #page-wrap {
    padding-top: 20px;
  }

}
</style>
