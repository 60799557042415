<template>
  <div class="py20 customer-details--section">
    <div class="row" v-if="!isActive && !isFilled">
      <div class="col-md-10 col-sm-10 col-xs-10 p0 customer-details--title--block">
        <h3 class="customer-details--title p0">Payment Method</h3>
      </div>
    </div>
    <div class="" v-else>
      <div class="">
        <template v-if="isActive">
          <div class="w-100">
            <div class="mb20">
              <div class="row">
                <div class="col-md-6 col-xs-8 p0 customer-details--title--block">
                  <h3 class="customer-details--title p0">Payment Method</h3>
                </div>
                <!-- <div class="col-md-6 col-xs-4 p0">
                  <button-full
                    @click.native="sendDataToCheckout"
                    data-testid="paymentSubmit"
                    class="save-changes"
                    :class="{ 'button-disabled' : $v.payment.code.$invalid }"
                  >
                    {{ 'Save Changes' }}
                  </button-full>
                </div> -->
              </div>
            </div>
            <div class="">
              <div v-for="(method, index) in paymentMethods" :key="index" class="">
                <label class="radioStyled cl-gloo-text"> {{ method.name }}
                  <input
                    type="radio"
                    :value="method"
                    name="payment-method"
                    v-model="payment"
                  >
                  <span class="checkmark"/>
                </label>
              </div>
              <span class="validation-error" v-if="isFilled && !$v.payment.code.required">{{ $t('Field is required') }}</span>
            </div>
          </div>
        </template>
        <template v-else-if="isFilled && !isActive">
          <div class="mb20">
            <div class="row">
              <div class="col-md-10 col-xs-9 p0 customer-details--title--block">
                <h3 class="text customer-details--title p0">Payment Method</h3>
              </div>
              <div class="col-md-2 col-xs-3 p0 align-right">
                <a href="#" @click.prevent="edit" class="cl-gloo-main">
                  Change
                </a>
              </div>
            </div>
          </div>
          <div>
            <p class="m0 cl-gloo-text mb5">{{ payment ? payment.name : 'n/a' }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ButtonFull from 'theme/components/theme/ButtonFull'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: {
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    ButtonFull
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      payment: {},
      isFilled: false,
      previousPaymentMethod: {}
    }
  },
  mounted () {
    this.previousPaymentMethod = cloneDeep(this.payment)
    if (Object.keys(this.savedPaymentMethod).length > 0) {
      this.payment = this.savedPaymentMethod
      this.sendDataToCheckout()
      return
    }

    if (this.paymentMethods.length === 1) {
      this.payment = this.paymentMethods[0]
      this.sendDataToCheckout()
    }
  },
  computed: {
    punchoutActive () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails
        ? this.$store.state.userDetails.companyDetails.punchoutActive
        : false
    },
    paymentMethods () {
      return this.$store.state.userDetails &&
        this.$store.state.userDetails.companyDetails &&
        this.$store.state.userDetails.companyDetails &&
        this.$store.state.userDetails.companyDetails.paymentMethods
        ? this.$store.state.userDetails.companyDetails.paymentMethods
        : []
    },
    savedPaymentMethod () {
      return this.$store.getters['checkout/getPaymentDetails']
    }
  },
  validations () {
    return {
      payment: {
        code: {
          required
        }
      }
    }
  },
  watch: {
    'payment.code': function (val) {
      this.$store.dispatch('checkout/savePaymentDetails', val)
      this.sendDataToCheckout()
    },
    isActive: function (val) {
      this.payment = this.previousPaymentMethod
    }
  },
  methods: {
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'payment')
      }
    },
    changePaymentMethod () {
      // reset the additional payment method component container if exists.
      if (document.getElementById('checkout-order-review-additional-container')) {
        document.getElementById('checkout-order-review-additional-container').innerHTML = '<div id="checkout-order-review-additional">&nbsp;</div>' // reset
      }

      // Let anyone listening know that we've changed payment method, usually a payment extension.
      this.$bus.$emit('checkout-payment-method-changed', this.payment)
    },
    sendDataToCheckout () {
      this.previousPaymentMethod = cloneDeep(this.payment)
      this.$bus.$emit('checkout-after-paymentDetails', this.payment, this.$v)
      this.isFilled = true
    }
  }
}
</script>
