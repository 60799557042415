<template>
  <div class="product-listing row start-md">
    <div
      v-for="(product, key) in products"
      :key="product.id"
      :class="['col-md-' + (12/columns)%10, wide(product.sale, product.new, key), 'col-lg-3', 'col-sm-4']"
      class="product-listing-inner"
    >
      <div class="w-100 product-list">
        <product-tile :product="product" :identity="identity"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProductListing from 'core/components/ProductListing'
import ProductTile from 'theme/components/core/ProductTile'
import AddToCart from 'theme/components/core/AddToCart'
let lastHero = 0
export default {
  components: {
    ProductTile,
    AddToCart
  },
  mixins: [ProductListing],
  props: {
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    identity: {
      type: String,
      required: true
    }
  },
  methods: {
    wide (isOnSale, isNew, index) {
      let deltaCondition = index > 0 && ((index - 1) - lastHero) % 2 === 0
      // last image always shouldn't be big, we also need to count from last promoted to check if it will look ok
      let isHero = ((isOnSale === '1' || isNew === '1') && deltaCondition) || (index === this.products.length - 1 && (index - lastHero) % 2 !== 0)
      if (isHero) {
        lastHero = index
      }
      return isHero ? 'col-xs-12' : 'col-xs-12'
    }
  }
}
</script>

<style lang="scss" scoped>
.product.align-center {
  background-color: white;
  // padding: 30px 15px !important;
  margin: 1.2em;
  .product-image img {
    height: 250px;
  }
}
// .product-list {
//   margin: 1.5em 0.5em;
// }
</style>
