import coreStore from '@vue-storefront/store/modules/sync'
import { extendStore } from 'core/lib/themes'
import { execute as taskExecute } from 'core/store/lib/task'
import { _prepareTask } from 'core/store/modules/sync/helpers'
import store from 'core/store'

const actions = {
  clearNotTransmited ({ commit }) {
    const syncTaskCollection = global.$VS.db.syncTaskCollection
    syncTaskCollection.iterate((task, id, iterationNumber) => {
      if (!task.transmited) {
        syncTaskCollection.removeItem(id)
      }
    })
  },
  execute ({ commit }, task) { // not offline task
    task = _prepareTask(task)
    const usersCollection = global.$VS.db.usersCollection
    const cartsCollection = global.$VS.db.cartsCollection
    return new Promise((resolve, reject) => {
      if (global.$VS.isSSR) {
        taskExecute(task, null, null).then((result) => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      } else {
        usersCollection.getItem('current-token', (err, currentToken) => { // TODO: if current token is null we should postpone the queue and force re-login - only if the task requires LOGIN!
          if (err) {
            console.error(err)
          }
          cartsCollection.getItem('current-cart-token', (err, currentCartId) => {
            if (err) {
              console.error(err)
            }
            if (!currentCartId && store.state.cart.cartServerToken) { // this is workaround; sometimes after page is loaded indexedb returns null despite the cart token is properly set
              currentCartId = store.state.cart.cartServerToken
            }
            if (!currentToken && store.state.user.cartServerToken) { // this is workaround; sometimes after page is loaded indexedb returns null despite the cart token is properly set
              currentToken = store.state.user.token
            }
            taskExecute(task, currentToken, currentCartId).then((result) => {
              resolve(result)
            }).catch(err => {
              reject(err)
            })
          })
        })
      }
    })
  }
}

export default extendStore(coreStore, {
  actions
})
