import coreStore from '@vue-storefront/store/modules/sync'
import { extendStore } from 'core/lib/themes'
import store from 'core/store'
import * as entities from 'core/store/lib/entities'
import EventBus from 'core/store/lib/event-bus'
import config from 'core/store/lib/config'
import { ValidationError } from 'core/store/lib/exceptions'
import { currentStoreView } from '@vue-storefront/store/lib/multistore'
import Ajv from 'ajv' // json validator
import apiService from 'theme/services/api_service'
import acl from 'theme/helpers'

EventBus.$on('on-app-load', () => {
  global.$VS.db.ordersCollection.getItem('linked-order-id')
    .then(response => {
      store.commit('order/setLinkedOrderId', response)
    })
})

const state = {
  pendingOrders: {},
  allCompanyOrders: {},
  myOrders: {},
  completedOrders: {},
  linkedOrderId: null
}
const getters = {
  getPendingOrders: (state) => {
    return Object.values(state.pendingOrders)
  },
  getAllCompanyOrders: state => Object.values(state.allCompanyOrders),
  getCompletedOrders: state => Object.values(state.completedOrders)
}

const actions = {
  disableCart (context) {
    let promise = new Promise((resolve, reject) => {
      global.$VS.db.cartsCollection.getItem('current-cart-token', (err, cartId) => {
        if (err) {
          console.log('Error occured while retrieving current cart token from local storage')
          throw err
        }

        let updateCartEndpoint = config.ecms.cart_endpoint
        let customerAccessToken = store.state.user.token
        let customerId = store.state.user.current.id

        let updateData = {
          isActive: false,
          customerId,
          customerAccessToken
        }
        return apiService.send(`${updateCartEndpoint}/${cartId}`, 'put', updateData)
          .then(data => {
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    })
    return promise
  },
  placeOrder (context, order) {
    const ajv = new Ajv()
    const validate = ajv.compile(require('./order.schema.json'))

    const storeView = currentStoreView()
    if (storeView.storeCode) {
      order.store_code = storeView.storeCode
    }

    if (!validate(order)) { // schema validation of upcoming order
      throw new ValidationError(validate.errors)
    } else {
      EventBus.$emit('order-before-placed', { order: order })
      const orderId = entities.uniqueEntityId(order) // timestamp as a order id is not the best we can do but it's enough
      order.order_id = orderId.toString()
      order.transmited = false
      order.created_at = new Date()
      order.updated_at = new Date()

      // disable cart before submitting order.
      // cart should only be disabled first if the person placing the order does not have approval rights
      if (acl.isUserAllowedPermission('approve', 'order')) {
        return new Promise((resolve, reject) => {
          const endpoint = config.ecms.orders_endpoint
          return apiService.send(endpoint, 'post', order)
            .then(({data}) => {
              context.commit('placeOrder', orderId)
              EventBus.$emit('order-after-placed', { order: data.result.data.order, magentoOrder: data.result.data.magentoOrder })
              context.commit('setLinkedOrderId', null) // clear linked order after successful order placement
              resolve()
            })
            .catch(err => {
              console.log(err)
              // EventBus.$emit('order-after-placed', null)
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          context.dispatch('order/disableCart', {}, { root: true })
            .then((response) => {
              let { data } = response
              if (data.code === 200) {
                // proceed to submit order to ECMS core
                const endpoint = config.ecms.orders_endpoint
                return apiService.send(endpoint, 'post', order)
                  .then(({data}) => {
                    context.commit('placeOrder', orderId)
                    EventBus.$emit('order-after-placed', { order: data.result.data.order })
                    context.commit('setLinkedOrderId', null) // clear linked order after successful order placement
                    resolve()
                  })
                  .catch(err => {
                    console.log(err)
                    EventBus.$emit('order-after-placed', null)
                    reject(err)
                  })
              }
              return response
            })
            .catch(err => {
              reject(err)
            })
        })
      }
    }
  },
  fetchOrdersForApproval: function (context) {
    const endpoint = `${config.ecms.orders_endpoint}`
    return apiService.send(`${endpoint}?filter=on&filter_by=pending-approval`, 'get')
      .then(response => {
        let orders = response.data.result.orders
        let newState = {}
        orders.forEach(order => {
          newState[order.id] = order
        })
        context.commit('setPendingOrders', newState)
        return response
      })
      .catch(err => {
        throw err
      })
  },
  fetchAllCompanyOrders: function (context) {
    if (!store.state.userDetails.jwToken) {
      return
    }
    const endpoint = `${config.ecms.orders_endpoint}`
    return apiService.send(endpoint, 'get')
      .then(response => {
        // let result = response.data.result
        let orders = response.data.result.orders
        let newState = {}
        orders.forEach(order => {
          newState[order.id] = order
        })
        context.commit('setAllCompanyOrders', newState)
        return response
      })
      .catch(err => {
        throw err
      })
  },
  fetchCompletedOrders: function (context) {
    if (!store.state.userDetails.jwToken) {
      return
    }
    // change something here
    let companyId = store.state.userDetails.companyDetails.id
    const endpoint = `${config.ecms.orders_endpoint}?filter=on&filter_by=company&companyId=${companyId}&order_status=approved`
    return apiService.send(endpoint, 'get')
      .then(response => {
        let orders = response.data.result.orders
        let newState = {}
        orders.forEach(order => {
          newState[order.id] = order
        })
        context.commit('setCompletedOrders', newState)
        return response
      })
      .catch(err => {
        throw err
      })
  },
  approveOrder: function (context, { orderId, data }) {
    const endpoint = `${config.ecms.orders_endpoint}`
    return apiService.send(`${endpoint}/${orderId}`, 'put', data)
  },
  fetchMyOrders: function (context) {
    const endpoint = `${config.ecms.orders_endpoint}`
    return apiService.send(`${endpoint}?filter=on&filter_by=my-orders`, 'get')
      .then(response => {
        let orders = response.data.result.orders
        let newState = {}
        orders.forEach(order => {
          newState[order.id] = order
        })
        context.commit('setMyOrders', newState)
        return response
      })
      .catch(err => {
        throw err
      })
  },
  saveLinkedOrder (context, id) {
    context.commit('setLinkedOrderId', id)
  }
}

const mutations = {
  placeOrder (state, orderId) {
    console.info('Order submitted for approval, orderId = ' + orderId)
  },
  setPendingOrders (state, orders) {
    state.pendingOrders = orders
  },
  setAllCompanyOrders (state, orders) {
    state.allCompanyOrders = orders
  },
  setCompletedOrders (state, orders) {
    state.completedOrders = orders
  },
  setMyOrders (state, orders) {
    state.myOrders = orders
  },
  setLinkedOrderId (state, id) {
    state.linkedOrderId = id
    const ordersCollection = global.$VS.db.ordersCollection
    ordersCollection.setItem('linked-order-id', id).then(() => {})
  },
  setOrderTotal (state, totalOrders) {
    state.totalOrders = totalOrders
  }
}

export default extendStore(coreStore, {
  actions,
  mutations,
  state,
  getters
})
