import coreStore from '@vue-storefront/store/modules/checkout'
import { extendStore } from 'core/lib/themes'
import EventBus from 'core/store/lib/event-bus'
import store from 'core/store'

EventBus.$on('user-before-logout', () => {
  store.commit('checkout/setDeliveryMethod', {})
  store.commit('checkout/setDeliveryLocation', {})
  store.commit('checkout/setPaymentDetails', {})
})

const state = {
  deliveryLocation: {},
  deliveryMethod: {},
  paymentDetails: {}
}

const getters = {
  getDeliveryMethod: state => state.deliveryMethod,
  getDeliveryLocation: state => state.deliveryLocation,
  getPaymentDetails: state => {
    if (state.paymentDetails.hasOwnProperty('taxId')) {
      return {}
    }
    return state.paymentDetails
  }
}

const actions = {
  saveDeliveryMethod ({ commit }, deliveryMethod) {
    commit('setDeliveryMethod', deliveryMethod)
    EventBus.$emit('recompute-cart-totals')
  },
  saveDeliveryLocation ({ commit }, deliveryLocation) {
    commit('setDeliveryLocation', deliveryLocation)
  },
  savePaymentDetails ({ commit }, paymentDetails) {
    commit('setPaymentDetails', paymentDetails)
  }
}

const mutations = {
  setDeliveryMethod: (state, deliveryMethod) => {
    state.deliveryMethod = deliveryMethod
  },
  setDeliveryLocation: (state, deliveryLocation) => {
    state.deliveryLocation = deliveryLocation
  },
  setPaymentDetails: (state, paymentDetails) => {
    state.paymentDetails = paymentDetails
  }
}

export default extendStore(coreStore, {
  actions,
  state,
  mutations,
  getters
})
