<template>
  <div>
    <v-progress-linear :indeterminate="true" v-if="!order" />
    <v-layout align-center justify-center row wrap v-else>
      <v-flex xs12 row class="">
        <div class="myHeading row col-sm-12 p0 py20">
          <div class="col-sm-6 p0">
            <div id="order-id" class="align-left">
              <h3 class="p0 cl-gloo-cornflower-blue">Order #{{ order ? order.increment_id : '' }}</h3>
            </div>
            <div class="row align-center">
              <div>
                <p id="order-date" class="p0 cl-gloo-text2 m0">{{ formatDate(order.created_at) }}</p>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12 align-left first-order-status-chip">
                <p class="order-status-chip m0" :class="[orderStatus[order.status]]">{{ order ? order.status : '' }}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 right align-right reorder-btn">
            <div v-if="hasPermission('create', 'order')">
              <v-btn color="success" @click="remakeOrder(skipGrouped(order.items))">Re-Order</v-btn>
            </div>
          </div>
        </div>
        <v-card class="myHeading order-info">
          <div class="order-details align-left row px40 align-center">
            <!-- <p>{{ parseDate(order.created_at) }}</p> -->
            <!-- <img src="/assets/completed-icon.svg" class=""> -->
            <i class="material-icons pr-0 icon" :class="[orderStatus[order.status]]">check_circle_outline</i>
            <p class="col-md-10 col-xs-10 align-left m0 cl-gloo-text">Order Details</p>
          </div>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3 class="cl-gloo-footer-text">Requester Name:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p class="cl-gloo-text m0">{{ order ? order.customer_firstname : '' }} {{ order? order.customer_lastname : '' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3 class="cl-gloo-footer-text">Delivery Location:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p class="cl-gloo-text m0">{{ shippingAddress.street[0] }}, {{ shippingAddress.street[1] }}, {{ shippingAddress.city }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row class="align-center">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3 class="cl-gloo-footer-text">Order Status:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8 align-left">
              <p class="order-status-chip m0" :class="[orderStatus[order.status]]">{{ order ? order.status : '' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3 class="cl-gloo-footer-text">Shipping Method:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p class="cl-gloo-text m0">{{ order ? order.shipping_description : '' }}</p>
            </div>
          </v-flex>
          <v-flex xs12 row>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h3 class="cl-gloo-footer-text">Payment Method:</h3>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8">
              <p class="cl-gloo-text m0">{{ paymentMethod }}</p>
            </div>
          </v-flex>
        </v-card>
      </v-flex>
      <v-flex xs12 class="order-table">
        <v-card class="items-ordered ">
          <v-card-title class="section-title cl-gloo-section-header-text">Items in Order</v-card-title>
          <div class="table-container">
            <table >
              <thead>
                <tr class="bg-cl-gloo-table-background cl-gloo-cornflower-blue">
                  <th class="text-xs-left">Product Name</th>
                  <th class="text-xs-left">SKU</th>
                  <th class="text-xs-right">Unit Price</th>
                  <th class="text-xs-right">Quantity</th>
                  <th class="text-xs-right">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                <tr class="brdr-top-1 brdr-cl-gloo-table-border  cl-gloo-catalina-blue" v-for="item in skipGrouped(order.items)" :key="item.item_id">
                  <td class="text-xs-left" >{{ item.name }}</td>
                  <td class="text-xs-left" >{{ item.sku }}</td>
                  <td class="text-xs-right" >{{ item.price_incl_tax | currency }}</td>
                  <td class="text-xs-right">{{ item.qty_ordered }}</td>
                  <td class="text-xs-right">{{ item.row_total_incl_tax | currency }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="brdr-top-1 brdr-cl-gloo-table-border">
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2"/>
                  <td colspan="3"/>
                </tr>
                <tr >
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2" class="text-xs-right cl-gloo-catalina-blue">Sub Total</td>
                  <td colspan="3" class="text-xs-right cl-gloo-text">{{ order.subtotal | currency }}</td>
                </tr>
                <tr>
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2" class="text-xs-right cl-gloo-catalina-blue">Shipping</td>
                  <td colspan="3" class="text-xs-right cl-gloo-text">{{ order.shipping_amount | currency }}</td>
                </tr>
                <tr v-if="!inclusiveVat">
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2" class="text-xs-right cl-gloo-catalina-blue">Tax</td>
                  <td colspan="3" class="text-xs-right cl-gloo-text">{{ order.tax_amount + order.discount_tax_compensation_amount | currency }}</td>
                </tr>
                <tr v-if="order.discount_amount">
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2" class="text-xs-right cl-gloo-catalina-blue">Discount</td>
                  <td colspan="3" class="text-xs-right cl-gloo-text">{{ order.discount_amount | currency }}</td>
                </tr>
                <tr class="bg-cl-gloo-table-background brdr-bottom-1 brdr-cl-gloo-table-border">
                  <td colspan="1" class="text-xs-right"/>
                  <td colspan="2" class="text-xs-right  cl-gloo-catalina-blue">Grand Total</td>
                  <td colspan="3" class="text-xs-right cl-gloo-text">{{ order.grand_total | currency }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import MyOrder from 'core/components/blocks/MyAccount/MyOrder'
import moment from 'moment'

export default {
  data () {
    return {
      orderStatus: {
        'pending': 'pending-order',
        'canceled': 'canceled-order',
        'treated': 'treated-order',
        'processing': 'processing-order',
        'suspected fraud': 'suspected-fraud',
        'pending payment': 'pending-payment',
        'payment review': 'payment-review',
        'open': 'open-order',
        'closed': 'closed-order',
        'complete': 'completed-order'
      }
    }
  },
  computed: {
    inclusiveVat () {
      let company = this.$store.state.userDetails && this.$store.state.userDetails.companyDetails
      if (company && company.inclusiveVAT) {
        return true
      }
      return false
    }
  },
  mixins: [MyOrder],
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMMM D, YYYY') + ' at ' + moment(dateString).format('LT')
    },
    getOrder () {
      return this.ordersHistory.find(order => {
        return parseInt(order.increment_id) === parseInt(this.$route.params.orderId)
      }, (this))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/base/text";
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-silver: color(silver);
$color-active: color(secondary);
$color-white: color(white);
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-white-smoke: color(white-smoke);
$pending-background: color(gloo-pend);
$pending-text: color(gloo-pending);
$approved-background: color(gloo-completed);
$approved-text: color(gloo-success);
$disapproved-background: color(gloo-failed);
$disapproved-text: color(gloo-danger);
$resubmitted-background: color(gloo);
$resubmitted-text: color(gloo-);
$color-success: color(gloo-success);
$color-pending: color(gloo-pending);
$color-error: color(gloo-danger);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-pend: color(gloo-pend);
$color-white: color(white);
$color-gloo-gray: color(gloo-gray);

.order-status-chip {
  text-transform: uppercase;
  font-size: 12px !important;
  height: unset !important;
  line-height: 20px !important;
}

.first-order-status-chip{
  margin: 0 20px;
  // max-width: 120px;
}

.order-info {
  text-align: left;
  h3, p {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
  }

  #order-id h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    @media (max-width: 830px) {
      font-size: 24px;
    }
  }

  .reorder-btn button {
    margin: 0;
  }
  .v-btn {
    font-weight: 600;
    margin: 20px 0;
  }

  p {
    font-size: 16px;
    font-weight: normal;
  }

  #order-date {
    padding: 0;
  }

  i {
    font-weight: normal;
    height: 100%;
    border-radius: 50%;
    font-size: 20px;
  }

  .myHeading {
    padding: 20px;
  }

  .myHeading:first-child {
    margin-bottom: 40px !important;
  }

  & .myHeading:first-child div {
    margin: 30px 0 !important;
    @media (max-width: 495px) {
      text-align: center;
      float: none !important;
      display: block;
    }
  }

  .myHeading.order-info {
    padding: 20px !important;
    .col-xs-12 {
      h3 {
        padding-bottom: 0;
      }
      p {
        padding-top: 10px;
        padding-left: 46px;
      }
    }
    .flex.xs12.row {
      padding: 15px 0;
    }
  }
}

.v-card {
  width: 100%;
}

.v-card {
  padding: 0 !important;
}

.order-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  padding: 20px 10px !important;
}
.v-card.items-ordered {
  box-shadow: 0 2px 4px 0 #D7D7E0 !important;
}

.v-card.items-ordered > div:not(:first-child) {
  padding: 0 !important;
}

.v-card.v-card.items-ordered >div:first-child {
  padding: 20px 20px !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: left;
}

thead > tr {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}

tbody > tr {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  width: 383px;
  text-align: left;
}

tfoot >tr {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.order-table {
  margin-top: 40px;
  .no-right-padding {
  padding-right: 0 !important;
  }

  .no-left-padding {
    padding-left: 0 !important;
  }

  .table-container {
    overflow-x: auto;
  }
  table {
    box-shadow: 0 2px 4px 0 #D7D7E0;
  }
}

.card-title {
  font-size: 20px;
  color: $color-primary;
  text-transform: uppercase;
  float: none;
  width: 100%;
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  width: 100%;

  @media (max-width: 767px) {
    border-top: none;
  }

  th, td {
    padding: 20px;
  }

  @media (max-width: 1199px) {
    padding: 10px;
  }

  tbody {

    tr {
      &:nth-child(even) {
        td {
          background-color: $color-white-smoke;
        }
      }

    }

    td {
      &:first-child {
        @media (max-width: 767px) {
          padding: 20px 20px 10px 20px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          padding: 10px 20px 20px 20px;
        }
      }
    }

  }

  tfoot {
    td {
      font-weight: 600;
    }
  }
}
</style>
<style>
  .v-btn {
    text-transform: none !important;
  }
</style>
