<template>
  <div id="login-wrapper">
    <header class="" v-if="tokenValid !== null">
      <div class="header-img">
        <img src="/assets/gloopro-logo.png" alt="GlooPro" height="30px">
      </div>
      <div class="header-text hidden-xs" v-if="!changeSuccessful && tokenValid === true">
        <p>Enter new password</p>
      </div>
    </header>
    <div class="modal-content pt30 pb60 px65">
      <form @submit.prevent="submit" novalidate v-if="!changeSuccessful && tokenValid === true">
        <v-text-field
          class="mb35"
          label="New password"
          type="password"
          v-model="password"
          required
          :rules="passwordRules"
        />
        <v-text-field
          class="mb35"
          label="Confirm New password"
          type="password"
          v-model="confirmPassword"
          required
          :rules="passwordRules"
        />
        <button-full class="mb20" type="submit">
          <p class="p0 m0">SUBMIT</p>
        </button-full>
      </form>
      <div v-else-if="tokenValid === false">
        <v-alert
          :value="true"
          type="error"
        >
          Invalid password reset token
        </v-alert>
      </div>
      <div v-if="changeSuccessful === true">
        <v-alert
          :value="true"
          type="success"
        >
          Password successfully changed
        </v-alert>
        <router-link to="/login">Click here to login to your account</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'

export default {
  data () {
    return {
      password: '',
      confirmPassword: '',
      resetToken: '',
      tokenValid: null,
      changeSuccessful: null,
      passwordRules: [
        v => !!v.trim() || 'This field is required',
        (value) => {
          let matches = 0
          value = value.trim()
          if (value.match(/[A-Z]/g)) {
            matches++
          }
          if (value.match(/[a-z]/g)) {
            matches++
          }
          if (value.match(/[0-9]/g)) {
            matches++
          }
          if (value.match(/[^a-zA-Z0-9]/g)) {
            matches++
          }
          return (typeof value === 'string' && value.length >= 8 && matches >= 3) || 'Invalid password format. Password should contain lowercase, uppercase, number and special character'
        }
      ]
    }
  },
  methods: {
    submit () {
      if (this.password !== this.confirmPassword) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'Passwords do not match',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$bus.$emit('notification-progress-start', 'Saving new password...')
      this.$store.dispatch('resetPassword', {
        passwordResetToken: this.resetToken,
        password: this.password
      })
        .then((response) => {
          this.$bus.$emit('notification-progress-stop')
          this.changeSuccessful = true
        })
    }
  },
  components: {
    ButtonFull
  },
  mounted () {
    this.resetToken = this.$route.params.token
    this.$bus.$emit('notification-progress-start', 'Validating password reset token...')
    // validate token
    this.$store.dispatch('validatePasswordResetToken', this.resetToken)
      .then((response) => {
        this.$bus.$emit('notification-progress-stop')
        let data = response.data
        if (data.result.valid) {
          this.tokenValid = true
        }
      })
      .catch(() => {
        this.tokenValid = false
      })
  }
}
</script>

<style scoped>
#login-wrapper {
  padding-top: 5%;
  font-size: 1.125em;
  width: 60%;
  margin: auto;
}
form {
  padding: 5em 5em 0.8em 5em;
  border-radius: 0.304em;
  box-shadow: 0px 0.71px 5px 0px rgba(0,0,0,0.1);
}
header {
  margin: auto;
    padding: 0;
    text-align: center;
}
.header-text p {
  font-family: Roboto !important;
  margin: 0.357em 0 0.357em 0;
  font-weight: lighter;
  line-height: 34px;
  font-size: 29px;
  color: #828282;
}
input {
  border: none;
  border-bottom: 1px solid;
  border-radius:  0;
}
base-checkbox div a, .remind {
  /* color: #3d4094 !important; */
}
base-checkbox {
  font-family: Roboto !important;
  font-size: 20.47px;
  line-height: 29px;
  letter-spacing: 1%;
}
@media (min-width: 760px) and (max-width: 1280px) {
  #login-wrapper {
  padding-top: 25%;
  width: 100%;
}
}
@media (max-width: 760px) {
  #login-wrapper {
    padding-top: 40%;
    width: 100%;
    font-size: 1em;
    margin: auto;
}
form {
  padding: 2em 3em;
  box-shadow: none;
}
.modal-content {
  padding-left: 10px;
  padding-right: 10px;
}
.h4 {
  font-size: 14px !important;
}
.remind {
  font-size: 12px;
}
}
@media (max-width: 760px) {
  #login-wrapper {
    padding-top: 30%;
    width: 100%;
    font-size: 0.75em;
    margin: auto;
}
}

div.error--text {
  color: red !important;
}

div.v-alert.error {
  background-color: red;
}

div.v-alert.success {
  background-color: #4caf50;
}
</style>
