import config from 'config'
import apiService from 'theme/services/api_service'

const state = {
  approvalWorkflows: []
}

const getters = {
  getApprovalWorkflows: state => {
    return state.approvalWorkflows
    // return [
    //   { name: 'Workflow 1', status: true, noOfLevels: 3 }
    // ]
  }
}

const actions = {
  saveApprovalWorkflow: (context, data) => {
    let endpoint = `${config.ecms.approvalWorkflow_endpoint}`
    return apiService.send(endpoint, 'post', data)
      .then((response) => {
        if (response.data.code === 200) {
          context.dispatch('fetchApprovalWorkflows')
          return response
        }
      })
  },
  fetchApprovalWorkflows: (context) => {
    let companyId = context.rootState.userDetails.companyDetails.id
    let endpoint = `${config.ecms.approvalWorkflow_endpoint}?companyId=${companyId}`
    return apiService.send(endpoint, 'get')
      .then((response) => {
        if (response.data.code === 200) {
          context.commit('setApprovalWorkflows', response.data.result.data)
          return response
        }
      })
  }
}

const mutations = {
  setApprovalWorkflows (state, value) {
    state.approvalWorkflows = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
