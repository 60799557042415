<template>
  <div
    class="product align-center w-100 pb20 no-left-margin productTile bg-cl-gloo-solitude"
    v-observe-visibility="visibilityChanged"
  >
    <router-link
      class="no-underline product-link"
      :to="localizedRoute({
        name: product.type_id + '-product',
        params: {
          parentSku: product.parentSku ? product.parentSku : product.sku,
          slug: product.slug,
          childSku: product.sku
        }
      })"
      data-testid="productLink"
    >
      <div class="product align-left">
        <div
          class="product-image relative align-center"
          :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]">
          <img
            :alt="product.name"
            :src="thumbnailObj.loading"
            v-lazy="thumbnailObj"
            height="200">
        </div>
        <div class="product-details">
          <p class="mb10 mt5 product-name">
            {{ product.name }}
          </p>
          <p
            class="lh40 price m0 mb5"
            v-if="parseFloat(product.price) > 0 && identity !== 'frequently-ordered'"
          >
            {{ product.price | currency }}
          </p>
          <div class="" v-if="identity === 'frequently-ordered'">
            <button-outline class="light full-width" data-testid="i">
              <span style="marginLeft: 10px; marginRight: 10px;">View</span>
            </button-outline>
          </div>
        </div>
      </div>
      <div class="" v-if="identity === 'alternative-product'">
        <button-outline class="light full-width alternative-details" data-testid="i">
          <span style="marginLeft: 10px; marginRight: 10px;">View Details</span>
        </button-outline>
      </div>
    </router-link>
    <router-link
      class="no-underline product-link"
      :to="{
        name: 'alternative-products',
        params: {
          parentSku: product.parentSku ? product.parentSku : product.sku,
          slug: product.slug,
          childSku: product.sku
        }
      }"
      data-testid="productLink"
    >
      <div class="alt-class" v-if="product.product_stock_status === 'out-of-stock'">
        <button-outline class="light unavailable" data-testid="i">
          <span>Unavailable, View Alternatives</span>
        </button-outline>
      </div>
    </router-link>
    <div v-if="hasPermission('create', 'order')" class="add">
      <add-to-cart
        :product="product"
        class="col-xs-12"
        @click.native="addToCart"
        v-if="(!isInCart || product.status === 0) && product.product_stock_status === 'in-stock'"
      >
        <img src="/assets/cart.png" alt="cart">
        <span>ADD TO CART</span>
      </add-to-cart>
      <!-- Add To Cart Widget -->
      <div class="row col-xs-12 widget" v-if="isInCart && product.status === 1 && product.product_stock_status === 'in-stock'">
        <button-outline color="light" class="col-xs-4 decrement" @click.native="decrement()">
          <i class="material-icons h4 p5">remove</i>
        </button-outline>
        <div class="col-xs-4 widget-input cl-gloo-main">
          <input type="number" class="h4" v-model="qty" @blur="onBlur">
        </div>
        <button-outline color="light" class="col-xs-4 increment" @click.native="increment()">
          <i class="material-icons h4">add</i>
        </button-outline>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import rootStore from '@vue-storefront/store'
import ProductTile from 'core/components/ProductTile'
import AddToCart from 'theme/components/core/AddToCart'
import AddToCartWidget from 'theme/components/core/AddToCartWidget'

import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'
import IncrementButton from 'theme/components/core/blocks/Microcart/IncrementButton'
import DecrementButton from 'theme/components/core/blocks/Microcart/DecrementButton'

export default {
  components: {
    AddToCart,
    AddToCartWidget,
    ButtonOutline,
    IncrementButton,
    DecrementButton
  },
  data () {
    return {
      qty: 0,
      isInCart: false,
      priceVAT: null,
      inclusiveVAT: null
    }
  },
  watch: {
    qty: function (val) {
      if (val === '') {
        return
      }
      this.qty = parseInt(val)
      if (this.qty < 0) {
        this.qty = 0
      }
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: parseInt(this.qty) })
    }
  },
  mixins: [ProductTile],
  props: {
    labelsActive: {
      type: Boolean,
      requred: false,
      default: true
    }
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        if (config.products.configurableChildrenStockPrefetchDynamic && config.products.filterUnavailableVariants) {
          const skus = [this.product.sku]
          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]
              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
    },
    increment () {
      this.qty++
      // this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: this.qty })
    },
    decrement () {
      this.qty--
      // this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: this.qty })
    },
    addToCart () {
      if (!this.product.status) {
        this.$bus.$emit('notification', {
          type: 'error',
          message: 'The selected product is not available',
          action1: { label: 'OK', action: 'close' }
        })
        return
      }
      this.$store.dispatch('cart/addItem', { productToAdd: this.product })
      this.qty = 1
      this.isInCart = true
    },
    onBlur () {
      if (this.qty === '') {
        this.qty = 0
      }
    }
  },
  created () {
    const VAT = config.ecms.VAT
    this.priceVAT = this.product.price / (1 + VAT)
    this.inclusiveVAT = (this.$store.state.userDetails.companyDetails) ? this.$store.state.userDetails.companyDetails.inclusiveVAT : false
    let cartItems = this.$store.state.cart ? this.$store.state.cart.cartItems : []
    let prod = cartItems.find(item => {
      return item.sku === this.product.sku
    })
    if (!prod) {
      this.qty = 0
    } else {
      this.isInCart = true
      this.qty = prod.qty
    }
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
    this.$bus.$on('cart-after-update', ({ product }) => {
      if (product.sku === this.product.sku) {
        this.qty = parseInt(product.qty)
        if (this.qty === 0) {
          this.isInCart = false
          this.$store.dispatch('cart/removeItem', product)
        } else if (this.qty > 0) {
          this.isInCart = true
        }
      }
    })
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);
$color-primary: color(primary);
$color-secondary: color(secondary);
$color-tertiary: color(tertiary);
$gloo-main: color(gloo-main);
$gloo-gray: color(gloo-gray);

.product.align-center {
  // background-color: white;
  background-color: #FFFFFF;
  border: 1px solid #E3E8EE;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #D7D7E0;
  min-height: 430px;
  padding: 20px;
  box-shadow: 0px 0.3px 2px 0px rgba(0,0,0,0.1);
  &:hover,
  &:active,
  &:focus {
    // box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 13px 0 rgba(0,0,0,.12);
    box-shadow: 0 1px 3px 0 #D7D7E0, 0 5px 9px 2px #E3E8EE;
  }
}
.product-details {
  min-height: 5em;
  margin-bottom: 15px;
}
.price {
  font-size: 18px;
  font-weight: 550;
  line-height: 19px;
  text-align: left;
  color: $gloo-gray;
}
.priceVAT {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.no-left-margin {
  margin-left: 0 !important;
}
.button-outline {
  min-width: unset;
}
// .price-original {
//   text-decoration: line-through;
// }

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}

.product-image {
  width: 100%;
  overflow: hidden;
  max-height: 300px;
  padding-bottom: 20px;

  &:hover {
    img {
      opacity: 1;
      transform: scale(1.1);
    }

    &.sale::after,
    &.new::after {
      opacity: 0.8;
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    margin: auto;
    mix-blend-mode: darken;
    opacity: 0.8;
    transform: scale(1);
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;

    &[lazy="loaded"] {
      animation: products-loaded;
      animation-duration: 0.3s;
    }

    @keyframes products-loaded {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.8;
      }
    }
  }

  &.sale {
    &::after {
      @extend %label;
      content: 'Sale';
    }
  }

  &.new {
    &::after {
      @extend %label;
      content: 'New';
    }
  }
}

.product-name {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

// button-full {
//   font-size: 40px;
// }
.widget, .widget div {
  padding: 0 !important;
  margin: 0 !important;
}
.widget {
  border: 2px solid $gloo-main;
  border-radius: 4px;
  text-align: center;
  // margin: 10px 0 !important;
  width: 100%;
  height: 40px;
}
.widget-input {
  width: 100%;
}
.widget-input input {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  // width: 52px;
  // height: 40px;
  font-size: 15px;
  font-weight: 300;
  line-height: 31px;
  // background: $color-primary;
  // font-weight: 700;
  padding: 0;
}
.widget-input input:focus {
  outline: none;
}
i {
  // font-size: 30px;
  // font-weight: bolder;
  font-size: 20px;
  line-height: 31px;
  font-weight: 300;
}
// button {
//   padding: 0;
//   max-height: 2.5em;
// }
button {
  // padding: 15px 20px !important;
  // max-height: 3em !important;
  // height: 55px;
  // margin-top: 10px;
  padding: 0 !important;
  height: 40px;
}
.widget button {
    height: 100%;
    border: none;
    min-width: unset;
}
button.decrement {
  border-right: 2px solid;
  border-radius: unset;
}
button.increment {
  border-left: 2px solid;
  border-radius: unset;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
   margin: 0;
}
p {
  // height: 3em;
  @media (min-width: 1280px) {
    // height: 3.2em;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    color: #637381;
  }
}
p
.bolden-price {
  font-size: 18px;
}
.info-icon {
  position: relative;
}
i.info-tip {
  font-size: 14px;
  color: #637381;
  position: absolute;
  bottom: 0;
  &:hover,
  &:active,
  &:focus {
    .tool-tip {
      display: inline-block;
    }
  }
}
i.info-tip:hover + .tool-tip {
  display: inline-block;
}
.tool-tip {
  // display: none;
  background-color: #E7F4DD;
  border: 1px solid rgba(90, 122, 190, 0.08);
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(121, 161, 140, 0.4), 0 1px 1px 0 rgba(90, 122, 190, 0.1);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  padding: 1px;
  right: 10%;
  bottom: 20px;
  position: relative;
  display: none;
}

// @media (max-width: 64em) {
    // .products-list {
    //   max-width: 530px;
    // }
  .widget button{
    padding: 0 !important
  }
  .button-full {
    font-size: 18px ;
  }
  .light {
    background-color: #fff;
  }
  .full-width {
    width: 100%;
    margin-top: 50px;
  }
  .alternative-details{
    margin-top: 0px;
    margin-bottom: 10px;
    border: none !important;
  }
  .unavailable {
    width: 100%;
    font-size: 1rem;
  }
  @media (min-width: 1900px) {
    .product.align-center {
      padding-left: 2em;
      padding-right: 2em;
    }
  }
  // @media (max-width: 1500px) {
  //   .product.align-center{
  //     min-height: 450px;
  //   }
  // }
  // @media (min-width: 1520px) {
  //   .product.align-center{
  //     min-height: 429px;
  //   }
  // }
  @media (max-width: 1020px) {
    .button-full {
      font-size: 16px !important;
    }
    .unavailable {
      width: 100%;
      font-size: 1rem;
    }
    // .product-details {
    //   min-height: 5em;
    // }
    .product.align-center {
      padding: 30px;
    }
  }
  @media (max-width: 600px) {
    .button-full {
      font-size: 14px ;
    }
    .product.align-center {
      padding: 30px;
    }
  }
  // }
</style>
