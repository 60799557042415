<template>
  <div class="overlay fixed w-100" :class="{ vh90: isSearchOpen }" @click="close" v-if="isVisible" />
</template>

<script>
import Overlay from 'core/components/Overlay'

export default {
  data () {
    return {
      isSearchOpen: false
    }
  },
  mixins: [Overlay],
  beforeCreate () {
    // document.documentElement.classList.add('no-scroll')
  },
  mounted () {
    this.$bus.$on('search-status-changed', this.toggleHeight)
  },
  beforeDestroy () {
    this.$bus.$off('search-status-changed', this.toggleHeight)
  },
  destroyed () {
    document.documentElement.classList.remove('no-scroll')
  },
  watch: {
    isVisible: function (val) {
      if (!val) {
        this.toggleHeight(false)
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('ui/setOverlay', false)
      this.$store.commit('ui/setMicrocart', false)
      this.$store.commit('ui/setWishlist', false)
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/setSidebar', false)
    },
    toggleHeight (value) {
      if (value) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
      this.isSearchOpen = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-bg: color(black);

.overlay {
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color-bg, 0.4);
  z-index: 3;
}
.vh90 {
  height: 92vh !important;
  top: unset !important;
  bottom: 0 !important;
}
</style>
