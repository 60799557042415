<template>
  <div>
    <div id="users">
      <div class="page-title">
        <v-card-title>
          <span class="section-title">Users</span>
        </v-card-title>
      </div>
      <div class="top-row row">
        <div class="caption col-sm-6 col-xs-6">
          <div class="external-search--box">
            <input type="text" placeholder="Search through users" class="search-box" v-model="search">
            <i class="material-icons" aria-hidden="true">search</i>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6 add-user" v-if="hasPermission('create', 'user')">
          <v-btn large color="success" @click.prevent="addNewUser"><i class="material-icons">add</i>Add new user</v-btn>
        </div>
      </div>
      <div class="panel">
        <v-card-title class="table-title">
          <h3 v-if="companyUsers && companyUsers.length > 0" class="no-of-users">{{ companyUsers && companyUsers.length }} {{ companyUsers && (companyUsers.length > 1 || companyUsers.length === 0) ? 'Users' : 'User' }}</h3>
        </v-card-title>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="companyUsers"
            :search="search"
            :loading="companyUsers.length<1"
            :rows-per-page-items="[25, 50]"
          >
            <v-progress-linear slot="progress" color="success" indeterminate/>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.firstName }}</td>
              <td class="text-xs-left">{{ props.item.lastName }}</td>
              <td class="text-xs-left">{{ props.item.emailAddress }}</td>
              <td class="text-xs-left capitalize">{{ props.item.role }}</td>
              <td class="text-xs-left user-status"><span class="user-status-chip" :class="[props.item.isActive ? activeClass : inactiveClass]">{{ props.item.isActive ? 'Active' : 'Inactive' }}</span></td>
              <td class="text-xs-left">
                <p class="table-cta" @click="showUser(props.item)">View user</p>
              </td>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <create-users />
  </div>
</template>
<script>
import ShowLoader from './ShowLoader.vue'
import acl from 'theme/helpers'
import EventBus from 'core/store/lib/event-bus'
import CreateUsers from 'theme/components/core/blocks/CompanySettings/CreateUsers.vue'

export default{
  name: 'Users',
  data () {
    return {
      activeClass: 'active',
      inactiveClass: 'inactive',
      showLoader: true,
      companyId: null,
      result: [],
      roles: [],
      search: '',
      headers: [
        {
          text: 'First Name',
          align: 'left',
          sortable: true,
          value: 'firstName'
        },
        { text: 'Last Name', sortable: true, value: 'lastName' },
        { text: 'Email', sortable: true, value: 'emailAddress' },
        { text: 'Role', sortable: true, value: 'role' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Action', sortable: false }
      ]
    }
  },
  components: {
    ShowLoader,
    CreateUsers
  },
  beforeCreate () {
    if (!acl.isUserAllowedPermission('view', 'user')) {
      EventBus.$emit('notification', {
        type: 'error',
        message: 'You are not authorized to access this page',
        action1: { label: 'OK', action: 'close' }
      })
      this.$router.push('/')
    }
  },
  created () {
    this.$bus.$on('new-user-added', ({ data }) => {
      this.result = data.result && data.result.data ? data.result.data.users : []
    })
  },
  destroyed () {
  },
  methods: {
    showUser (user) {
      this.$router.push(`/company-settings/users/${user.id}`)
    },
    getRoleName (roleId) {
      let role = this.roles.find(obj => {
        return obj.id === roleId
      })
      return role ? role.name : 'n/a'
    },
    addNewUser () {
      let companyLocations = this.$store.getters.getCompanyLocationDetails
      if (!companyLocations) {
        EventBus.$emit('notification', {
          type: 'error',
          message: 'Your Company does not have any locations. You need at least one location before you can create a new user profile.',
          action1: { label: 'OK', action: 'close' }
        })
      } else {
        this.$bus.$emit('modal-show', 'modal-adduser')
      }
    }
  },
  computed: {
    companyUsers () {
      return this.result.map((item, index) => {
        item.status = item.isActive ? 'Active' : 'Inactive'
        item.role = item.roleId ? this.getRoleName(item.roleId) : 'n/a'
        return item
      })
    }
  },
  mounted () {
    this.companyId = this.$store.state.userDetails.companyDetails ? this.$store.state.userDetails.companyDetails.id : null
    if (this.companyId) {
      // check state for presence of users first
      let users = this.$store.state.userDetails.companyUsers
      if (!users) {
        this.$store.dispatch('fetchCompanyUsers', this.companyId)
          .then(({ data }) => {
            if (data.code === 200) {
              this.result = data.result && data.result.data ? data.result.data.users : []
            }
            this.showLoader = false
          })
          .catch(err => {
            console.error(err)
            this.showLoader = false
          })
      } else {
        this.result = users
        this.showLoader = false
      }
      this.$store.dispatch('fetchCompanyRoles', this.companyId)
        .then(({ data }) => {
          if (data.code === 200) {
            this.roles = data.result ? data.result.data.roles : []
          }
          this.showLoader = false
        })
        .catch(() => {
          this.showLoader = false
        })
    } else {
      this.showLoader = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
$color-success: color(gloo-success);
$color-error: color(gloo-danger);
$color-completed: color(gloo-completed);
$color-failed: color(gloo-failed);
$color-gloo-gray: color(gloo-gray);

.no-of-users {
  font-weight: 550;
  color: $color-gloo-gray;
  font-size: 21px;
  line-height: 25px;
}

.panel {
  margin-top: 35px !important;
}

table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    text-align: left;
    padding: 20px;

    @media (max-width: 1199px) {
      padding: 10px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.hide-on-xs {

      @media (max-width: 767px) {
        display: none;
      }

    }

  }

  i {
    vertical-align: middle;
  }

}

.capitalize {
  text-transform: capitalize;
}

.add-user {
  text-align: end;
  margin: auto;
}

.add-user {
  text-align: end;
  margin: auto;

  .success {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
  }
}

.user-status-chip {
  letter-spacing: 0.21px;
  line-height: 12px;
  width: 120px;
  text-align: center !important;
  align-items: center;
  border-radius: 28px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 38px;
  height: 32px;
  margin: auto;
  align-content: center;
}

.active {
  background-color: $color-completed;
  color: $color-success;
}

.inactive {
  background-color: $color-failed;
  color: $color-error;
}

.table-cta {
  color: $color-success;
  margin-bottom: 0;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;

  &:hover {
    cursor: pointer;
  }
}
</style>
