import config from 'config'

/**
 * Converts number to currency string
 * @param {Number} value
 */
export function currency (value) {
  if (typeof value !== 'number') return value
  const formatter = new Intl.NumberFormat(config.i18n.defaultLocale, {
    style: 'currency',
    currency: config.i18n.currencyCode,
    currencyDisplay: 'symbol'
  })
  return formatter.format(value)
}
